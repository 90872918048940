import React, { useEffect, useState } from 'react'
import "../../css/InstantExpert.css"
import icon from "../../assets/icons/49489-healthengine-generic-tickbox 1 1.png"
import SplashInstant from '../Splash/SplashInstant'
import instantIcon from "../../assets/icons/Rectangle 367.png"
import CloseIcon from '@mui/icons-material/Close';
import { Button } from '@mui/material';
import { experts, positionScreen, smallScreen, windowheight, windowWidth } from '../../constants/constant'
import OTPInput from "otp-input-react";
import PhoneInput from 'react-phone-input-2'
import { useUserAuth } from '../../context/AuthContext'
import { otpverification, otpverificationforInstantUser, signinOTP } from '../../Functions/functions'
import DisplaySnackbar from '../../constants/DisplaySnackbar'
import OTP from '../Authentication/OTP'
import { useNavigate } from 'react-router'
import Model from '../../constants/Model'
import Checkout from '../Availability/Checkout'
import { auth, db } from '../../firebase'
import { collection, getDocs, query } from 'firebase/firestore'
const InstantExpert = () => {
  const snackbardata = {
    open: false,
    severity: "",
    message: "",
    statusCode: ""
  }
  const [position, setPosition] = useState("bottom");
  const [otp, setOTP] = useState("");
  const { typeOfUser } = useUserAuth()
  const [euset, SetEUSet] = useState("Explorers")
  const navigate = useNavigate()
  const [open, setOpen] = useState(false)
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [flag, setFlag] = useState(false)
  const [instantName, setInstantName] = useState()
  const [instantDesc, setInstantDesc] = useState()
  const [confirmObj, setConfirmObj] = useState("")
  const [snackbarOpen, setSnackbarOpen] = useState(snackbardata)
  const [phone, setPhone] = useState('')
  const [loginButton, setLoginButton] = useState(false)
  const { setUpRecaptcha } = useUserAuth()
  const [type, setType] = useState(experts)
  const [profile, setVerifyProfile] = useState({})
  const { getUserType } = useUserAuth()
  const { user } = useUserAuth()
  const [error, setErrorName] = useState()
  const [errphone, setErrorPhone] = useState()
  const [errDesc, setErrorDesc] = useState()

  const togglePosition = () => {
    positionScreen(setPosition, isSmallScreen, position)
  };

  useEffect(() => {
    smallScreen(setIsSmallScreen, 835)
  }, []);
  const getProfileById = async () => {
    await getUserType(setType, auth.currentUser?.uid)
  }
  useEffect(() => {
    let isMounted = true
    getProfileById().then(async () => {
      if (isMounted) {
        setVerifyProfile(await getUserType(setType, auth?.currentUser?.uid))
      }
    })
    return () => { isMounted = false }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.currentUser?.uid, type])
  const snackbarStart = (severity, message, st = "", sc = "") => {
    setSnackbarOpen({
      open: true,
      message: message,
      severity: severity,
      statusCode: `${st} ${sc}`
    })
  }
  const captcha = () => {
    signinOTP(phone, setLoginButton, setUpRecaptcha, setFlag, setConfirmObj, snackbarStart)
  }
  const verifyOTP = async () => {
    try {
      if (user) {
        if (!instantDesc) {
          setErrorDesc(!instantDesc ? "Please Enter a Description" : null);
        }
        else {
          setOpen(true);
        }

      } else {
        if (!instantName || !phone || !instantDesc) {
          setErrorName(!instantName ? "Please Enter your Name" : null);
          setErrorPhone(!phone ? "Please Enter your Phone Number" : null);
          setErrorDesc(!instantDesc ? "Please Enter a Description" : null);
        } else {
          await otpverificationforInstantUser(
            otp,
            navigate,
            confirmObj,
            setOpen,
            instantName,
            typeOfUser
          );
        }
      }
    } catch (error) {
      console.log("Error in otpverificationforInstantUser: ", error);
      // Handle the error here
    }
  };
  const gotoExplorer = () => {
    SetEUSet("Explorers")
    navigate('/login', { state: "Explorers" })
  }
  const gotoExperts = () => {
    SetEUSet("Experts")
    navigate('/login', { state: "Experts" })
  }
  return (
    <div className='instantExpert'>

      <SplashInstant />

      <div style={{ height: windowheight, width: windowWidth }} className='instantleft'>
        <div className='instantlefttext'>
          <img src={icon} alt="" />
          <p style={{ fontSize: !isSmallScreen ? 28 : "", fontWeight: "bold" }}>Get help from top certified experts with 10-30 years of experience</p>
        </div>
        <div className='instantlefttext1'>
          <img src={icon} alt="" />
          <p style={{ fontSize: !isSmallScreen ? 28 : "", fontWeight: "bold" }}>We will connect you with a qualified expert within 48 hours or your money back</p>
        </div>
        <div className='instantlefttext2'>
          <img src={icon} alt="" />
          <p style={{ fontSize: !isSmallScreen ? 28 : "", fontWeight: "bold" }}>State your issue below to begin your journey</p>
        </div>
        {!isSmallScreen ? <><br /> <br /></> : ""}
        {!isSmallScreen ? <>
          <div style={{ background: 'none' }} className='ins_explore'>
            <p onClick={gotoExplorer} style={{ fontSize: 20 }}>Not ready yet? <span style={{ fontSize: 20, cursor: "pointer" }}>Sign up</span> to explore our experts and book a session later</p>
            <p onClick={gotoExperts} style={{ fontSize: 20 }}>Are you an Expert? <span style={{ fontSize: 20, cursor: "pointer" }}>Click here</span></p>
          </div>
        </> : ""}
      </div>

      <div style={{
        position: isSmallScreen ? "absolute" : "",
        bottom: position === "top" ? 0 : "100%",
        top: position === "bottom" ? "100%" : "0%",
        transition: "all 0.3s ease-out",
        height: position === "bottom" ? "" : windowheight,
        width: windowWidth,

      }} className='instantright'>
        <div>
          {position === 'top' || !isSmallScreen ? "" : <div className='ins_explore'>
            <DisplaySnackbar
              open={snackbarOpen.open}
              closeSnackbar={() => { setSnackbarOpen({ open: false, severity: "", message: "" }) }}
              message={snackbarOpen.message}
              severity={snackbarOpen.severity}
              statusCode={snackbardata.statusCode}
            />
            <p onClick={gotoExplorer}>Not ready yet? <span>Sign up</span> to explore our experts and book a session later</p>
            <p onClick={gotoExperts}>Are you an Expert? <span>Click here</span></p>
          </div>}
          <div onClick={togglePosition} style={{ height: !isSmallScreen || position === 'top' ? windowheight : "" }} className='instantdata'>
            {isSmallScreen && position === 'top' ? <div className='instantClose'>
              <div style={{ textAlign: "right" }}><CloseIcon onClick={() => setPosition('bottom')} /></div>
            </div> : ""}
            <div style={{ textAlign: "center" }}> {position === "top" || !isSmallScreen ? <img src={instantIcon} /> : ""}

            </div>
            <div className='instantdescription'>
              <p>Please describe your problem so we can match you with the best expert<span style={{ color: "#FA533C", textDecoration: "none" }}>*</span></p>
              <textarea onChange={(e) => setInstantDesc(e.target.value)} className='instantarea' placeholder='type here' type='textarea' style={{ padding: 25, textDecoration: "none", outline: "none", resize: "none", width: "100%", height: 100, border: "1px solid rgb(233, 230, 255)", background: "rgba(255, 255, 255, 0.8)", borderRadius: 15 }}></textarea>
              <label style={{ color: "red", fontSize: 11, marginLeft: 5 }}>{errDesc}</label>
              <br />
              <br />
              {position === "top" || !isSmallScreen ? <>
                {flag === false ? <><p >Please provide your mobile number<span style={{ color: "#FA533C", textDecoration: "none" }}>*</span></p>
                  <div className='instantInput'>
                    <PhoneInput
                      placeholder="Enter phone number"
                      value={user ? profile?.phone_number : phone}
                      onChange={setPhone}
                      enableSearch
                      country="in"
                    />
                  </div>
                  <br />
                  {user ? "" : <div className='instantButton'>
                    <Button onClick={captcha} style={{
                      width: "110px",
                      background: "#E0E4FD",
                      border: "1px solid #0B5394",
                      color: "#3FAA6B"
                    }}>Verify</Button>
                  </div>}</> : <>
                  <OTP instant='instant' phone={phone} setPhone={setPhone} flag={flag} setOTP={setOTP} otp={otp} confirmObj={confirmObj} />
                </>}
                <label style={{ color: "red", fontSize: 11, marginLeft: 5 }}>{errphone}</label>
                <div id='recaptcha-container'>
                </div>
                <br />

                <br />
                <p >May we know your name?
                  <span style={{ color: "#FA533C", textDecoration: "none" }}>*</span></p>
                <div className='instantInput'>
                  <input defaultValue={user ? profile?.username : ""} onChange={(e) => setInstantName(e.target.value)} type='textInstant' placeholder='Enter your name' />
                  <label style={{ color: "red", fontSize: 11, marginLeft: 5 }}>{error}</label>
                </div>
                <br />
                <div className='instantButton'>
                  <Button onClick={verifyOTP} style={{
                    width: "140px",
                    background: "rgba(0, 78, 150, 0.8)",
                    border: "1px solid #0B5394",
                    color: "white",
                    borderRadius: 7
                  }}>Submit</Button>
                </div>
              </> : ""}
              <Model
                close={() => setOpen(false)}
                show={open}
                component={<Checkout instantName={instantName} typeIns={type} getTypeUser={profile} phone={phone} area={instantDesc} instant='instant' />}
                size='md'
                button="no"
                sign="cross"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default InstantExpert
