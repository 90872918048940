import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import "../css/Header.css"
import { useUserAuth } from '../context/AuthContext';
import { useNavigate } from 'react-router';
import { Badge, BottomNavigation, BottomNavigationAction } from '@mui/material';
import { Link } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import { auth } from '../firebase';
import { experts, navigationTabs, smallScreen } from '../constants/constant';
import settingsicon from "../assets/icons/icons8-setting-96 2 (1).png"
import noticon from "../assets/icons/coolicon.png"
import proficon from "../assets/icons/coolicon1.png"
import NotificationsIcon from '@mui/icons-material/Notifications';
import PersonIcon from '@mui/icons-material/Person';
import { getUserDetails, scrollEffect, windowScroll } from '../Functions/functions';
import SearchIcon from '@mui/icons-material/Search';
import SettingsIcon from '@mui/icons-material/Settings';
const Header = (props) => {
  const { getUserType } = useUserAuth()
  const [profile, setVerifyProfile] = useState()
  const pathname = window.location.pathname;
  const [value, setValue] = React.useState(pathname);
  const [type, setType] = useState(experts)
  const [isVisible, setIsVisible] = useState(true);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const getProfileById = async () => {
    await getUserType(setType, auth?.currentUser?.uid)
  }

  useEffect(() => {
    getUserDetails(setVerifyProfile, setType, getProfileById, getUserType,type)
  }, [])
  useEffect(() => {
    scrollEffect(setIsVisible)
  }, []);
  useEffect(() => {
    windowScroll(setIsVisible)
  }, []);
  useEffect(() => {
    smallScreen(setIsSmallScreen, 500)
  }, [])
  const transition = 'bottom 0.3s ease-out';
  return (

   props.from || isSmallScreen ? 
    <>
      <Box className='fromHeader' sx={{ position: "fixed", width: "100%", bottom: isVisible ? "0" : "-200px", zIndex: "999", transition }} >
        <BottomNavigation
          showLabels
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
        >
          <BottomNavigationAction sx={{
            '& .Mui-selected': {
              color: 'white',
            },
          }} value="/home" component={Link} to="/home" label="Home" icon={<HomeIcon />} />
          <BottomNavigationAction sx={{
            '& .Mui-selected': {
              color: 'white',
            },
          }} value={`/notifications/${type}/${auth?.currentUser?.uid}`} component={Link} to={`/notifications/${type}/${auth?.currentUser?.uid}`} label="Notifications" icon={<><img src={noticon} /></>} />
          <BottomNavigationAction sx={{
            '& .Mui-selected': {
              color: 'white',
            },
          }} value={`/userProfile/${type}/${auth?.currentUser?.uid}`} component={Link} to={`/userProfile/${type}/${auth?.currentUser?.uid}`} label="Profile" icon={<><img src={proficon} /></>} />
          {type == experts ? <BottomNavigationAction sx={{
            '& .Mui-selected': {
              color: 'white',
            },
          }} value={`/settings/${type}/${auth?.currentUser?.uid}`} component={Link} to={`/settings/${type}/${auth?.currentUser?.uid}`} label="Settings" icon={<><img src={settingsicon} /></>} /> : ""}
        </BottomNavigation>

      </Box>
    </> :""
    
    
  )
}

export default Header