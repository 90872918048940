import React, { useState } from 'react'
import Model from '../../constants/Model'
import "../../css/SinglePost.css"
const SinglePost = (props) => {
  const [open, setOpen] = useState(false)
  return (
    <>
      <div className='SinglePost'>
        <div>
          {props?.singlePostsOnly?.map((data, index) => (
            <div key={index}>
              {data?.status === "Now Showing" ? <img alt="" src={data?.img_url} /> : ""}
            </div>
          ))}
        </div>
      </div>
      <Model
        close={() => setOpen(false)}
        show={open}
        component={""}
      />
    </>
  )
}
export default SinglePost
