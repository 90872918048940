import { doc, updateDoc } from 'firebase/firestore'
import React from 'react'
import { useNavigate, useParams } from 'react-router'
import { topicData } from '../../constants/constant'
import "../../css/OnBoardingExplorer.css"
import { db } from '../../firebase'
const Topics = () => {
    const navigate = useNavigate()
    const { type, id } = useParams()
    const navigateToProblem = async (data) => {
        await updateDoc(doc(db, type, id), {
            topic: data.title
        }).then(() => {
            navigate(`/describe/${type}/${id}`, { state: data })
        })
    }
    return (
        <div className='newUser_container'>
            <div className='preferred_heading'>
                <h2>Which of the following topics do you need help with?</h2>
            </div>
            <br />
            <br />
            <div className='prefred_container'>
                {topicData?.map((data, index) => (
                    <div onClick={() => navigateToProblem(data)} key={index} className='prefered_flex'>
                        <h4>{data?.title}</h4>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default Topics
