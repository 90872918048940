import $ from 'jquery'
import MaleIcon from '@mui/icons-material/Male';
import FemaleIcon from '@mui/icons-material/Female';
import TransgenderIcon from '@mui/icons-material/Transgender';
import v12 from "../assets/images/v12 (1).png"
import v11 from "../assets/images/v11.png"
import v10 from "../assets/images/v10.png"
import v9 from "../assets/images/v9.png"
import v8 from "../assets/images/v8.png"
import v7 from "../assets/images/v7.png"
import v6 from "../assets/images/v6.png"
import v5 from "../assets/images/v5.png"
import v4 from "../assets/images/v4.png"
import v3 from "../assets/images/v3.png"
import v2 from "../assets/images/v2.png"
import v1 from "../assets/images/v1.png"
import v13 from "../assets/images/v13.png"
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookIcon from '@mui/icons-material/Facebook';
import settingsicon from "../assets/icons/icons8-setting-96 2 (1).png"
import noticon from "../assets/icons/coolicon.png"
import proficon from "../assets/icons/coolicon1.png"
import contactus from "../assets/images/icons8-monetization-of-web-content-with-a-dollar-sign-24 2.png"
import privacy from "../assets/images/icons8-lightning-control-with-night-mode-function-in-a-smart-home-24 1.png"
import logout from "../assets/images/icons8-monetization-of-web-content-with-a-dollar-sign-24 1.png"
import NotificationsIcon from '@mui/icons-material/Notifications';
import PersonIcon from '@mui/icons-material/Person';
import SearchIcon from '@mui/icons-material/Search';
import SettingsIcon from '@mui/icons-material/Settings';
import HomeIcon from '@mui/icons-material/Home';
export const pricingforTier2 = 1
export const pricingforTier3 = 1
export const star = [
    {
        id: 1,
        rating: 1
    },
    {
        id: 2,
        rating: 2
    },
    {
        id: 3,
        rating: 3
    },
    {
        id: 4,
        rating: 4
    },
    {
        id: 5,
        rating: 5
    }
]
export const gender = [
    {
        id: 1,
        gender: "Male"
    },
    {
        id: 2,
        gender: "Female"
    },
    {
        id: 3,
        gender: "other"
    }
]

export const values = [
    0, 2, 4, 6, 8, 10, 12, 14, 18, 20, 22, 30
]

export const dummyMenuItems = [
    {
        icon: contactus,
        title: "Sessions History"
    },
    {
        icon: contactus,
        title: "Contact Us"
    },
    {
        icon: privacy,
        title: "Privacy Policy"
    },
    {
        icon: logout,
        title: "Logout"
    }
];


export const editCard1 = {
    title: "Put a face to your name! Upload a profile picture that clearly shows your face."
}
export const editCard2 = {
    title: "Don’t open duplicate accounts and make duplicate usernames."
}
export const domaincard1 = {
    title: "Accurately describe your professional skills to help you get more sessions."
}
export const domaincard2 = {
    title: "Add domain expertise which defines your knowledge to explorer."
}
export const domaincard3 = {
    title: "To keep our community secure for everyone, we may ask you to verify your Documents."
}
export const domaincard4 = {
    title: "Don’t provide any duplicate documents. This may imapact your document verification."
}

export const profilecard1 = {
    title: "Take your time in creating  your profile so its exactly as you want  it to be"
}
export const profilecard2 = {
    title: "Don’t providing any misleading or inaccurate information about your identity."
}



// export const tier = [
//     {
//         id: 1,
//         name: "Apply for Expert User",
//         price: "FREE"
//     }
// ]


// export const tier2 = [
//     {
//         id: 2,
//         name: " Apply for Expert Pro",
//         price: pricingforTier2,
//     }
// ]

export const tier3 = [{
    id: 3,
    name: " Apply for Expert Pro+",
    price: pricingforTier3,
}]


// export const tierExp = [
//     {
//         id: 1,
//         name: "Expert",
//         price: "FREE",
//         paragraph: "Perfect plan for you to just get started",
//         list: "limited free sessions",
//         list1: "To participated and understand the app",

//     },

// ]

// export const tierExp1 = [
//     {
//         id: 2,
//         name: "Expert",
//         subname: "Pro",
//         price: pricingforTier2,
//         paragraph: "Our most popular tier and perfect to grow and nurture your connection with knowledge sharing.",
//         list: "Upload KYC Documets",
//         list1: "Pay one time fee",
//         list2: "Set your availability",
//         list3: "Host Sessions",
//         list4: "Fixed Session Fees",

//     },

// ]
export const tierExp2 = [
    {
        id: 3,
        name: "Expert",
        subname: "Pro+",
        price: pricingforTier3,
        paragraph: "This is our POWERHOUSE! Unlock the best opportunities to engage & earn",
        list: "Upload KYC Documets",
        list1: "Pay one time fee",
        list2: "Set your availability",
        list3: "Host Sessions",
        list4: "Set your own Session Fees",
    },

]



// export const scrollSetting = () => {
//     $(document).ready(function () {
//         $(window).scroll(function () {
//             var value = $(window).scrollTop()
//             if (value > 100) {
//                 $('.setting_search_screen').css({ "height": "180px", })
//                 $('.setting_image_logo').css({ "height": "270px" })
//                 $('.setting_image_logo img').css({ "display": "none" })
//                 $('.input_settings').css({ "margin-top": "-220px", })
//             }
//             else {
//                 $('.setting_search_screen').css({ "height": "200px" })
//                 $('.setting_image_logo').css({ "height": "270px" })
//                 $('.setting_image_logo img').css({ "display": "block" })
//                 $('.input_settings').css({ "margin-top": "-220px", })
//             }
//         })
//     })
// }


export const scrollSearch = () => {
    $(document).ready(function () {
        $(window).scroll(function () {
            var value = $(window).scrollTop()
            if (value > 100) {
                $('.search_screen').css({ "height": "120px" })
                $('.home_logo').css({ "height": "120px" })
                $('.home_logo img').css({ "display": "none" })
                $('.home_logoss').css({ "height": "120px" })
                $('.home_logoss img').css({ "display": "none" })
                $('.bLog').css({ "height": "150px" })
                $('.bLog img').css({ "display": "none" })
                $('.filter_box_image img ').css({ "display": "block" })
                $('.bLog h1').css({ 'display': 'none' })
                $('.bLog h1').css({ 'margin-top': '0px' })
                $('.home_logo h1').css({ 'display': 'none' })
                $('.home_logo h1').css({ 'margin-top': '0px' })
                $('.home_logoss h1').css({ 'display': 'none' })
                $('.home_logoss h1').css({ 'margin-top': '0px' })

            }
            else {
                $('.search_screen').css({ "height": "140px" })
                $('.home_logo').css({ "height": "140px" })
                $('.home_logo img').css({ "display": "block" })
                $('.home_logoss').css({ "height": "140px" })
                $('.home_logoss img').css({ "display": "block" })
                $('.bLog').css({ "height": "200px" })
                $('.bLog img').css({ "display": "block" })
                $('.filter_box_image img ').css({ "display": "block" })
                $('.bLog h1').css({ 'display': 'block' })
                $('.bLog h1').css({ 'margin-top': '-40px' })
                $('.home_logo h1').css({ 'display': 'block' })
                $('.home_logoss h1').css({ 'display': 'block' })
            }
        })
    })
}

export const scrollSearchs = () => {
    // $(document).ready(function () {
    //     $(window).scroll(function () {
    //         var value = $(window).scrollTop()
    //         if (value > 100) {
    //             $('.search_screen').css({ "height": "220px" })
    //             $('.home_logo').css({ "height": "270px" })
    //             $('.home_logo img').css({ "display": "none" })
    //             $('.filter_box_image img ').css({"display":"block"})
    //             $('.bLog').css({ "height": "200px" })
    //             $('.bLog img').css({ "display": "none" })
    //         }
    //         else {
    //             $('.search_screen').css({ "height": "290px" })
    //             $('.home_logo').css({ "height": "402px" })
    //             $('.home_logo img').css({ "display": "block" })
    //             $('.filter_box_image img ').css({"display":"block"})
    //             $('.bLog').css({ "height": "280px" })
    //             $('.bLog img').css({ "display": "block" })


    //         }
    //     })
    // })
}


export const videos = [
    {
        id: 1,
        title: "How to make the most from your search experience on bedot - The Human Search Engine!",
        link: "https://youtube.com/shorts/GiCbxe1_O9Y?feature=share",
        image: v12
    },
    {
        id: 2,
        title: "How to choose an experienced user on bedot - The Human Search Engine!",
        link: "https://youtube.com/shorts/8eDJwxXCDmE",
        image: v11
    },
    {
        id: 3,
        title: "How to review your session on bedot - The Human Search Engine!",
        link: "https://youtube.com/shorts/GI24tnb0vw8",
        image: v10
    },
    {
        id: 4,
        title: "How to reject a session on bedot - The Human Search Engine!",
        link: "https://youtube.com/shorts/49nFo259MsA",
        image: v9
    },
    {
        id: 5,
        title: "How to approve a session on bedot - The Human Search Engine!",
        link: "https://youtube.com/shorts/ttrz4lBtSTE",
        image: v8
    },
    {
        id: 6,
        title: "How to share your profile with others on bedot - The Human Search Engine!",
        link: "https://youtube.com/shorts/jS1rlSPGl8o",
        image: v7
    },
    {
        id: 7,
        title: "How to join a session on bedot - The Human Search Engine!",
        link: "https://youtube.com/shorts/KP3f5ldhIoQ",
        image: v6
    },
    {
        id: 8,
        title: "How to update your profile settings on bedot - The Human Search Engine!",
        link: "https://youtube.com/shorts/pyvboKtQ51s",
        image: v5
    },
    {
        id: 9,
        title: "How to make payment for a session on bedot - The Human Search Engine!",
        link: "https://youtube.com/shorts/ebmPISaklbY",
        image: v4
    },
    {
        id: 10,
        title: "How to search on bedot - The Human Search Engine!",
        link: "https://youtube.com/shorts/zg0ApdDUpUU",
        image: v3
    },
    {
        id: 11,
        title: "How to book a session on bedot - The Human Search Engine!",
        link: "https://youtube.com/shorts/lc8rikobMPo",
        image: v2
    },
    {
        id: 12,
        title: "How to sign up as an experienced user on bedot - The Human Search Engine!",
        link: "https://youtu.be/G8ZyfCEUssU",
        image: v1
    },
    {
        id: 13,
        title: "Why should you sign up as an experienced user on bedot - The Human Search Engine!",
        link: "https://youtube.com/shorts/chu6zmos3sE",
        image: v13
    }
]


export const genderIntro = [
    {
        id: 1,
        gender: "Male",
        icon: <MaleIcon />
    },
    {
        id: 2,
        gender: "Female",
        icon: <FemaleIcon />
    },
    {
        id: 3,
        gender: "Others",
        icon: <TransgenderIcon />
    }
]

export const insta = {
    icon: <InstagramIcon style={{ width: 30, height: 30, color: "#0B5394" }} />,
    Link: `https://www.instagram.com/bedot.io`
}

export const facebookicon = {
    icon: <FacebookIcon style={{ width: 30, height: 30, color: "#0B5394" }} />,
    Link: `https://www.facebook.com/thebedotapp`
}


export const twittericon = {
    icon: <TwitterIcon style={{ width: 30, height: 30, color: "#0B5394" }} />,
    Link: `https://twitter.com/bedot_social`
}
export const youtubeIcon = {
    Link: `https://www.youtube.com/channel/UCYkPy-MGAbUBSPy_2R9UxhA`
}
export const Linkedin = {
    Link: `https://in.linkedin.com/company/bedot`
}

export const inquiry = `Have an inquiry or some feedback for us? Contact us our team will get back to you within 24 hours`
export const whatsappnumber = 9137541741
export const socialemail = 'social@bedot.io'
export const avgRating = 'avgRating'
export const no_of_sessions = 'no_of_sessions'
export const experts = 'EUU'
export const explorer = "Seeker"

export const EXPLORERS = "Explorers"
export const EXPERT = "Experts"

export const languages = [
    'Mandarin',
    'Spanish',
    'English',
    'Hindi',
    'Arabic',
    'Portuguese',
    'Bengali',
    'Russian',
    'Japanese',
    'Punjabi',
    'German',
    'Javanese',
    'Chinese',
    'Malay',
    'Telugu',
    'Vietnamese',
    'Korean',
    'French',
    'Marathi',
    'Tamil',
    'Urdu',
    'Turkish',
    'Italian',
    'Yue',
    'Thai',
    'Gujarati',
    'Jin',
    'Southern',
    'Persian',
    'Polish',
    'Pashto',
    'Kannada',
    'Xiang',
    'Malayalam',
    'Sundanese',
    'Hausa',
    'Odia',
    'Burmese',
    'Hakka',
    'Ukrainian',
    'Bhojpuri',
    'Tagalog',
    'Yoruba',
    'Maithili',
    'Uzbek',
    'Sindhi',
    'Amharic',
    'Fula',
    'Romanian',
    'Oromo',
    'Igbo',
    'Azerbaijani',
    'Awadhi',
    'Gan',
    'Cebuano',
    'Dutch',
    'Kurdish',
    'Serbo',
    'Malagasy',
    'Saraiki',
    'Nepali',
    'Sinhala',
    'Chittagonian',
    'Zhuang',
    'Khmer',
    'Turkmen',
    'Assamese',
    'Madurese',
    'Somali',
    'Marwari',
    'Magahi',
    'Haryanvi',
    'Hungarian',
    'Chhattisgarhi',
    'Greek',
    'Chewa',
    'Deccan',
    'Akan',
    'Kazakh',
    'Northern',
    'Sylheti',
    'Zulu',
    'Czech',
    'Kinyarwanda',
    'Dhundhari',
    'Haitian',
    'Eastern',
    'Ilocano',
    'Quechua',
    'Swedish',
    'Belarusian',
    'Balochi',
    'Konkani',
]

export const preferredAs = [
    {
        id: 1,
        title: "He/him "
    },
    {
        id: 2,
        title: "She/her"
    },
    {
        id: 3,
        title: "They/them"
    },
    {
        id: 4,
        title: "Prefer not to say"
    }
]
export const states = [
    'Andhra Pradesh', 'Arunachal Pradesh', 'Assam', 'Bihar', 'Chhattisgarh', 'Goa', 'Gujarat', 'Haryana', 'Himachal Pradesh	', 'Jammu and Kashmir', 'Jharkhand', 'Karnataka', 'Kerala', 'Madhya Pradesh', 'Maharashtra', 'Manipur', 'Meghalaya', 'Mizoram', 'Nagaland', 'Odisha', 'Punjab', 'Rajasthan', 'Sikkim', 'Tamil Nadu', 'Telangana', 'Tripura', 'Uttar Pradesh', 'Uttarakhand', 'West Bengal'," Andaman and Nicobar Islands","Dadra and Nagar Haveli","Daman","Diu","Chandigarh","Lakshadweep","Puducherry","Delhi","Ladakh",
]
export const topicData = [
    {
        id: 1,
        title: "Life Coaching "
    },
    {
        id: 2,
        title: "Career Counselling"
    },
    {
        id: 3,
        title: "Business Consulting"
    },

]
export const period = [
    {
        id: 1,
        time: 30
    }
]

export const times = [
    {
        id: 1,
        time: "12:00 AM"
    },
    {
        id: 2,
        time: "12:30 AM"
    },
    {
        id: 3,
        time: "01:00 AM"
    },
    {
        id: 4,
        time: "01:30 AM"
    },
    {
        id: 5,
        time: "02:00 AM"
    },
    {
        id: 6,
        time: "02:30 AM"
    },
    {
        id: 7,
        time: "03:00 AM"
    },
    {
        id: 8,
        time: "03:30 AM"
    },
    {
        id: 9,
        time: "04:00 AM"
    },
    {
        id: 10,
        time: "04:30 AM"
    },
    {
        id: 11,
        time: "05:00 AM"
    },
    {
        id: 12,
        time: "05:30 AM"
    },
    {
        id: 13,
        time: "06:00 AM"
    },
    {
        id: 14,
        time: "06:30 AM"
    },
    {
        id: 15,
        time: "07:00 AM"
    },
    {
        id: 16,
        time: "07:30 AM"
    },
    {
        id: 17,
        time: "08:00 AM"
    },
    {
        id: 18,
        time: "08:30 AM"
    },
    {
        id: 19,
        time: "09:00 AM"
    },
    {
        id: 20,
        time: "09:30 AM"
    },
    {
        id: 21,
        time: "10:00 AM"
    },
    {
        id: 22,
        time: "10:30 AM"
    },
    {
        id: 23,
        time: "11:00 AM"
    },
    {
        id: 24,
        time: "11:30 AM"
    },
    {
        id: 25,
        time: "12:00 PM"
    },
    {
        id: 26,
        time: "12:30 PM"
    },
    {
        id: 27,
        time: "01:00 PM"
    },
    {
        id: 28,
        time: "01:30 PM"
    },
    {
        id: 29,
        time: "02:00 PM"
    },
    {
        id: 30,
        time: "02:30 PM"
    },
    {
        id: 31,
        time: "03:00 PM"
    },
    {
        id: 32,
        time: "03:30 PM"
    },
    {
        id: 33,
        time: "04:00 PM"
    },
    {
        id: 34,
        time: "04:30 PM"
    },
    {
        id: 35,
        time: "05:00 PM"
    },
    {
        id: 36,
        time: "05:30 PM"
    },
    {
        id: 37,
        time: "06:00 PM"
    },
    {
        id: 38,
        time: "06:30 PM"
    },
    {
        id: 39,
        time: "07:00 PM"
    },
    {
        id: 40,
        time: "07:30 PM"
    },
    {
        id: 41,
        time: "08:00 PM"
    },
    {
        id: 42,
        time: "08:30 PM"
    },
    {
        id: 43,
        time: "09:00 PM"
    },
    {
        id: 44,
        time: "09:30 PM"
    },
    {
        id: 45,
        time: "10:00 PM"
    },
    {
        id: 46,
        time: "10:30 PM"
    },
    {
        id: 47,
        time: "11:00 PM"
    },
    {
        id: 48,
        time: "11:30 PM"
    },
]

export const options = [
    'Business Consultants', 'Career Counsellors', 'Life Coaches'
]
export const webinar_lang = [
    'English'
]

export const aboutWebinar = [
    {
        id: 1,
        title: "About"
    }
]


// export const smallScreen = (setIsSmallScreen) => {
//     const mediaQuery = window.matchMedia("(max-width: 835px)");
//     setIsSmallScreen(mediaQuery.matches);
//     const handleMediaChange = (mediaQuery) => setIsSmallScreen(mediaQuery.matches);
//     mediaQuery.addListener(handleMediaChange);
//     return () => {
//         mediaQuery.removeListener(handleMediaChange);
//     };
// }
// export const smallLoginScreen = (setIsSmallScreen) => {
//     const mediaQuery = window.matchMedia("(max-width: 845px)");
//     setIsSmallScreen(mediaQuery.matches);
//     const handleMediaChange = (mediaQuery) => setIsSmallScreen(mediaQuery.matches);
//     mediaQuery.addListener(handleMediaChange);
//     return () => {
//         mediaQuery.removeListener(handleMediaChange);
//     };
// }

export const smallScreen = (setIsSmallScreen, size) => {
    const mediaQuery = window.matchMedia(`(max-width: ${size}px)`);
    setIsSmallScreen(mediaQuery.matches);
    const handleMediaChange = (mediaQuery) => setIsSmallScreen(mediaQuery.matches);
    mediaQuery.addListener(handleMediaChange);
    return () => {
        mediaQuery.removeListener(handleMediaChange);
    };
}


export const windowWidth = window.innerWidth
export const windowheight = window.innerHeight

export const positionScreen = (setPosition, isSmallScreen, position) => {
    if (isSmallScreen) {
        if (position === "bottom") {
            setPosition("top");
        } else {
        }
    }
}


