import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import { auth } from '../../firebase'
import "../../css/Consult.css"
import "../../css/AvailableSlots.css"
import Search from '../Search/Search'
import 'react-calendar/dist/Calendar.css';
import moment from 'moment'
import { Agenda, Day, Inject, Month, MonthAgenda, ScheduleComponent, ViewDirective, ViewsDirective, Week } from '@syncfusion/ej2-react-schedule'
import { useUserAuth } from '../../context/AuthContext'
import { allEvents, cellStatus, checkoutpay, databoundStatus, eventClickStatus, getMonetisationRates, getMonetisationRatesfo30min, paymentStatus, renderStatus, Tier1Book } from '../../Functions/functions';
import { experts, period, times } from '../../constants/constant'
import Time from './Time'
const AvailableSlots = () => {
    const { id } = useParams()
    const [events, setEvents] = React.useState([])
    const [open, setOpen] = React.useState(false);
    const [eudata, setData] = useState({})
    const [event, setEvent] = useState([])
    const [rec, setRec] = useState([])
    const [activeButtons, setActiveButtons] = useState("");
    const [subject, setSubject] = useState([])
    const [payment, setPayment] = useState(false)
    const [area, setArea] = useState()
    const [type, setType] = useState(experts)
    const [profile, setVerifyProfile] = useState({})
    const { getUserType, getEUUUsersById } = useUserAuth()
    const navigate = useNavigate()
    const [euRate, setEURate] = useState()
    const [euRatefor30, setEURatefor30] = useState()
    const [celldata, setCellData] = useState()
    const [timenull, setTimeNull] = useState(times)
    const isProd = true;
    const getProfileById = async () => {
        await getUserType(setType, auth?.currentUser?.uid)
    }
    // const scheduleObj = (document.querySelector('.e-schedule'))?.ej2_instances[0]

    useEffect(() => {
        let isMounted = true
        getProfileById().then(async () => {
            if (isMounted) {
                setVerifyProfile(await getUserType(setType, auth?.currentUser?.uid))
            }
        })
        return () => { isMounted = false }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, auth?.currentUser?.uid])
    async function fetchData() {
        await getEUUUsersById(id)
    }

    useEffect(() => {
        let isMounted = true
        fetchData().then(async () => {
            if (isMounted) {
                setData(await getEUUUsersById(id))
            }
        })
        return () => { isMounted = false }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])
    moment.suppressDeprecationWarnings = true;
    useEffect(() => {
        allEvents(id, setEvents)

    }, [id])
    const onPopupOpen = (args) => {
        args.cancel = true
    }
    const eventClick = (data) => {
        eventClickStatus(data, setOpen, setEvent)
    }
    const onCell = (args) => {
        setCellData(args)
        // setTimeNull(null)
        cellStatus(args, setRec, events)
    }
    const onRenderCell = (args) => {
        renderStatus(args)
    }
    const Payment = () => {
        paymentStatus(area, activeButtons, setPayment, setOpen)
    }
    // const Wallet = async () => {
    //     if (eudata?.tier === 'TIER.T1') {
    //         Tier1Book(id, event, activeButtons, eudata, profile, navigate, area, type)
    //     }
    //     else {
    //         checkoutpay(navigate, type, id, getPayment, cashfreeProd, cashfreeSandbox, isProd, event, eudata, profile, area, activeButtons, euRate, euRatefor30, rec)
    //     }
    // }
    const getRatesEUU = async () => {
        await getMonetisationRates(id)
    }
    useEffect(() => {
        let isMounted = true
        getRatesEUU().then(async () => {
            if (isMounted) {
                setEURate(await getMonetisationRates(id))
            }
            return () => isMounted = false
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])
    const getRatesEUUfor30 = async () => {
        await getMonetisationRatesfo30min(id)
    }
    useEffect(() => {
        let isMounted = true
        getRatesEUUfor30().then(async () => {
            if (isMounted) {
                setEURatefor30(await getMonetisationRatesfo30min(id))
            }
            return () => isMounted = false
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])

    const onDataBound = () => {
        databoundStatus()
    }
    return (
        <>
            <div className='homestuffs'>
                <div className='home_pages'>
                    <Search />
                </div>
            </div>
            <div style={{ paddingBottom: "80px" }}>
                <ScheduleComponent
                    eventSettings={{ dataSource: events }}
                    popupOpen={(args) => onPopupOpen(args)}
                    eventRendered={(args) => setSubject(args.data)}
                    renderCell={(args) => onRenderCell(args)}
                    cellClick={(args) => onCell(args)}
                    dataBound={(args) => onDataBound(args)}
                >
                    <ViewsDirective>
                        <ViewDirective option='MonthAgenda' isSelected={true}></ViewDirective>
                    </ViewsDirective>
                    <Inject services={[Day, Month, Week, Agenda, MonthAgenda]} />
                </ScheduleComponent>
                <div className='number_of_slots'>
                    <Time type={type} id={id} profile={profile} euRatefor30={euRatefor30} rec={rec} celldata={celldata} eudata={eudata} />
                </div>
            </div>
        </>
    )
}

export default AvailableSlots