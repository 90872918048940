import { Button } from '@mui/material'
import React, { useState } from 'react'
import {
    InstantSearch,
} from 'react-instantsearch-dom';
import StarIcon from '@mui/icons-material/Star';
import algoliasearch from 'algoliasearch';
import "../../css/SearchCard.css"
import "../../css/Search.css"
import RefineTags from './widgets/RefineTags';
import CustomHits from './widgets/CustomHits';
import SearchBox from './widgets/SearchBox';
import Model from '../../constants/Model';
import 'jquery/dist/jquery.js';
import 'ion-rangeslider/css/ion.rangeSlider.min.css';
import 'ion-rangeslider/js/ion.rangeSlider.min.js';
import Switch from '@mui/material/Switch';
import Header from '../Header';
import { gender, star, values } from '../../constants/constant';
import { RangeSlider1, RangeSlider2 } from '../../Functions/functions';
import { Col, Container, Row } from 'react-bootstrap';
const label = { inputProps: { 'aria-label': 'Switch demo' } };
const SearchCard = () => {
    const [second, setSecond] = useState(false)
    const [slider, setSlider] = useState('')
    const [sessionslider, setSessionSlider] = useState('')
    const [hitsdata, setHits] = useState([])
    const [activeButtons, setActiveButtons] = useState('')
    const [genderButtons, setGenderButtons] = useState('')
    const [toggle, setToggle] = useState(true)

    const searchClient = algoliasearch('J1ZTRUQQTV', '3757e5598ea97e3eb781ccebdf618ae8')
    const index = searchClient.initIndex('profile_posts')
    React.useEffect(() => {
        RangeSlider1(values, setSlider)
    }, [second])
    React.useEffect(() => {
        RangeSlider2(values, setSessionSlider)
    }, [second])
    const refine = () => {
        if (!activeButtons || !slider) {
            alert("Please")
        }
        else {
            index.search('', {
                filters: `avgRating=${activeButtons.rating}  AND yearOfXP:${slider.from_value} TO ${slider.to_value} `
            }).then(({ hits }) => {
                setHits(hits)
                setSecond(false)
            })
        }
    }
    return (
        <>
            <InstantSearch
                searchClient={searchClient}
                indexName="profile_posts"
            >
                <SearchBox fromSearch="filters" sessionslider={sessionslider} toggle={toggle} setSecond={setSecond} setActiveButtons={setActiveButtons} setGenderButtons={setGenderButtons} setSlider={setSlider} setSessionSlider={setSessionSlider} />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <Container>
                    
                    <Row>
                        <Col>
                            <RefineTags
                                attribute="_tags"
                                hitsdata={hitsdata}
                            />
                            <CustomHits hitsdata={hitsdata} />
                        </Col>
                    </Row>
                </Container>
            </InstantSearch>
            <Model
                close={() => { setSecond(false); setActiveButtons(null); setGenderButtons(null); setSlider(null); setSessionSlider(null) }}
                title="Filters"
                show={second}
                Button={<><Button onClick={refine} variant='contained'>APPLY</Button></>}
                component={<>
                    <div className='filter_container'>
                        <div className='filter_rating'>
                            <h5>Rating</h5>
                            <br />
                            <div className='filter_rating_grid'>
                                {star?.map((rate, index) => (
                                    <div onClick={() => setActiveButtons(rate)} style={{ background: activeButtons === rate ? " #D6DBFA" : "" }} key={index} className='filter_content'>
                                        <h5>{rate.rating}</h5>
                                        <StarIcon style={{ color: "rgba(255, 217, 102, 0.4)" }} />
                                    </div>
                                ))}
                            </div>
                        </div>
                        <br />
                        <div className='gender_component'>
                            <h5>Gender</h5>
                            <br />
                            <div className='filter_rating_grid'>
                                {gender?.map((gen, index) => (
                                    <div onClick={() => setGenderButtons(gen)} style={{ background: genderButtons === gen ? " #D6DBFA" : "" }} key={index} className='filter_content'>
                                        <h5>{gen.gender}</h5>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className='experiance_component'>
                            <br />
                            <h5>Experiance</h5>
                            <br />
                            <input type="text" className="js-range-slider" name="my_range" />
                        </div>
                        <div className='experiance_component'>
                            <br />
                            <h5>Sessions</h5>
                            <br />
                            <input type="text" className="js-range-sliders" name="my_range" />
                        </div>
                        <div className='verified_profiles'>
                            <br />
                            <h5>Verified Profiles only</h5>
                            <Switch onChange={(event) => setToggle(event.target.checked)} {...label} defaultChecked />
                            <br />
                        </div>

                    </div>

                </>}
                size='lg'
            />
        </>
    )
}

export default SearchCard

