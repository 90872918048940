import { createContext, useContext, useEffect, useState } from "react";
import { FacebookAuthProvider, getAdditionalUserInfo, GoogleAuthProvider, onAuthStateChanged, RecaptchaVerifier, signInWithPhoneNumber, signInWithPopup, signOut } from "firebase/auth";
import { auth, db, getTokens } from "../firebase";
import { useNavigate } from "react-router";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { experts, explorer } from "../constants/constant";
import { checkUser } from "../Functions/functions";
const userAuthContext = createContext()
export function AuthContextProvider({ children }) {
    const [tokenFound, setTokenFound] = useState()
    const navigate = useNavigate()
    const [user, setUser] = useState('')
    //////////////////////////////////////////LOG OUT////////////////////////////////
    function logOut() {
        return signOut(auth).then(() => {
            navigate('/login')
        })
    }

    //////////////////////////////////////////  GOOGLE SIGN IN ////////////////////////////////
    function googleSignIn(euset) {
        const googleProvider = new GoogleAuthProvider()
        return signInWithPopup(auth, googleProvider).then(async (result) => {
            const cred = getAdditionalUserInfo(result)
            if (cred.isNewUser === true) {
                await typeOfUser(result?.user, euset).then(() => {
                    navigate(`/phone_number/${euset==="Explorers" ? "Seeker" : "EUU"}/${auth?.currentUser?.uid}`)
                })
                // getTokens()
            }
            else {
                navigate('/home')
                // getTokens()
                // checkUser(result, navigate,euset)

            }

        }).catch(error => {
           
            if (error.code === 'auth/account-exists-with-different-credential') {
                console.log(error)
            }
        })
    }

    function setUpRecaptcha(phone, setConfirmObj) {
        const number = "+" + phone
        const recaptchaVerifier = new RecaptchaVerifier(
            "recaptcha-container",
            {'size': 'invisible'},
            auth
        );
        recaptchaVerifier.render()
        return signInWithPhoneNumber(auth, number, recaptchaVerifier).then(async (result) => {
            setConfirmObj(result)
        }).catch((error) => {
            console.log(error.message)
        })
    }




    //////////////////////////////////////////Type of user////////////////////////////////
    async function typeOfUser(user, type, username) {
        if (type === "Explorers") {
            const docRef = doc(db, "Seeker", auth.currentUser?.uid)
            return setDoc(docRef, {
                _tags:[],
                avgRating: 0,
                current_profile: user.email,
                email: user.email ? user.email : "",
                eu_id: user.uid,
                no_of_sessions: 0,
                numRatings: 0,
                profile_img_url: null,
                short_intro: "-",
                username: username ? username : "",
                age:"",
                pronouns:"",
                last_visit:false,
                phone_number:user.phoneNumber ? user.phoneNumber :"" ,
                problem_description:"",
                state_address:"",
                topic:"",
            })
        }
        else if (type === "Experts") {
            const docRef = doc(db, experts, auth.currentUser?.uid)
            return setDoc(docRef, {
                _tags: [],
                avgRating: 0,
                current_profile: user.email ? user.email : "",
                email: user.email ? user.email : "",
                eu_id: user.uid,
                eu_requested: [],
                is_eu_verified: false,
                no_of_sessions: 0,
                numRatings: 0,
                profile_img_url: null,
                short_intro: "",
                tier: "TIER.T1",
                username: username ? username : "",
                yearOfXP: 0,
                unverified_domains: [],
                language: ["English"],
                gender: null,
                phone_number:user.phoneNumber ? user.phoneNumber :"" ,
            })
        }
    }



    async function getEUUUsersById(id) {
        const docRef = doc(db, experts, id);
        const docSnap = await getDoc(docRef)
        return docSnap.data()
    }






    ////////////////////////////////////////// GET USER ////////////////////////////////

    async function getUserType(setType, id) {
        try {
            const docRef = doc(db, experts, id);
            const docSnap = await getDoc(docRef)
            if (docSnap.exists()) {
                return docSnap.data()

            }
            else {
                setType(explorer)
                const docRef = doc(db, explorer, id);
                const docSnap = await getDoc(docRef)
                if (docSnap.exists()) {
                    return docSnap.data()
                }
            }
        }
        catch (error) {

        }
    }

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
            if (currentUser) {
                setUser(currentUser)
            }

        })
        return () => {
            unsubscribe()
        }
    }, [])
    return (
        <userAuthContext.Provider value={{ user, logOut, googleSignIn, typeOfUser, getUserType, getEUUUsersById, setUpRecaptcha }}>{children}</userAuthContext.Provider>
    )
}
export function useUserAuth() {
    return useContext(userAuthContext)
}