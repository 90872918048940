import { Button, Dialog, DialogContent, Rating, Stack } from '@mui/material'
import React, { useState } from 'react'
import { auth, db } from '../../firebase'
import "../Video/Video.css"
import { useNavigate } from 'react-router'
import { experts } from '../../constants/constant'
import { useUserAuth } from '../../context/AuthContext'
import Block from '../Rating/Block'
import { styled } from '@mui/material/styles';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { doc, getDoc } from 'firebase/firestore'
import CallEndIcon from '@mui/icons-material/CallEnd';
import VideocamIcon from '@mui/icons-material/Videocam';
import VideocamOffIcon from '@mui/icons-material/VideocamOff';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};
const VideoCall = (props) => {
  const [isClick, setIsClick] = useState(false)
  const [off, setOff] = useState(false)
  const [second, setSecond] = useState(false)
  const [third, setThird] = useState(false)
  const [rateit, setRateit] = useState('')
  const btnCam = document.getElementById('btnCam');
  const btnMic = document.getElementById('btnMic');
  const btnStart = document.getElementById('btnStart');
  const btnStop = document.getElementById('btnStop');
  const me = document.getElementById('me');
  const remote = document.getElementById('remote');
  const [profile, setVerifyProfile] = useState({})
  const [profile2, setVerifyProfile2] = useState({})
  const [type, setType] = useState(experts)
  const [type2, setType2] = useState(experts)
  const { getUserType, user } = useUserAuth()
  const navigate = useNavigate()
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setThird(true);
  };
  const handleClose = () => {
    setThird(false);
  };
  const getProfileById = async () => {
    await getUserType(setType, props?.sessiondata?.seekerId)
  }
  const getProfileById2 = async () => {
    await getUserType(setType2, props?.sessiondata?.euId)
  }
  React.useEffect(() => {
    let isMounted = true
    getProfileById().then(async () => {
      if (isMounted) {
        if (auth?.currentUser?.uid === props?.sessiondata?.euId) {
          setVerifyProfile(await getUserType(setType, props?.sessiondata?.seekerId))
        }

      }
    })
    return () => { isMounted = false }
  }, [props?.sessiondata?.seekerId, props?.sessiondata?.euId])

  React.useEffect(() => {
    let isMounted = true
    getProfileById2().then(async () => {
      if (isMounted) {
        if (auth.currentUser.uid != props?.sessiondata?.euId) {
          setVerifyProfile2(await getUserType(setType2, props?.sessiondata?.euId))
        }
      }
    })
    return () => { isMounted = false }
  }, [props?.sessiondata?.euId])
  const cameraOff = async () => {
    setIsClick(true)
    if (props.c) {
      await props.c.setMuted(true)
    }
  }
  const cameraon = async () => {
    setIsClick(false)
    if (props.c) {
      await props.c.setMuted(false)
    }

  }
  const soundoff = async () => {
    if (props.audioClose) {
      await props.audioClose.setEnabled(false)
    }
    setOff(true)
  }
  const soundon = async () => {
    if (props.audioClose) {
      await props.audioClose.setEnabled(true)
    }
    setOff(false)
  }

  const endCall = async () => {
    await props.users.unpublish(props.c)
    await props.users.unpublish(props.audioClose)
    props.c.stop()
    props.audioClose.stop()
    props.c.close()
    props.users.remoteUsers.forEach(user => {
      const playerContainer = document.getElementById(user.uid);
      playerContainer && playerContainer.remove();
    })
    await props.users.leave().then(() => {

    })
  }
  const end = async () => {
    if (props.videoCall === true) {
      endCall().then(async () => {
        if (auth.currentUser.uid === props.sessiondata.euId) {
          const docRef = doc(db, `${type}/${props?.sessiondata?.seekerId}/ratings/${props?.sessiondata?.id}`);
          const docSnap = await getDoc(docRef);
          if (docSnap.exists()) {
            props.setOpen(false)
          }
          else {
            setSecond(true)
          }
        }
        else if (auth?.currentUser?.uid != props?.sessiondata?.euId) {
          const docRef = doc(db, `${type2}/${props?.sessiondata?.euId}/ratings/${props?.sessiondata?.id}`);
          const docSnap = await getDoc(docRef);
          if (docSnap.exists()) {
            props.setOpen(false)
          }
          else {
            setSecond(true)
          }
        }
      })
    }

  }
  const rating = async () => {
    navigate('/rating', {
      state: {
        star: rateit,
        data: props.sessiondata,
        v: props.videoCall,
        type: type,
        type2: type2,
        vd: props.videoData
      }
    })

  }

  return (
    <>

      {
        props.videoCall === true ? <>
          <div className='video_container'>
            <div id='me' className='local_container'>
              <div id='remote' className='remote_container'>
              </div>
              <div className='option_box'>
                <div className='opt_icon'>
                  {
                    isClick === true ? <>
                      <div onClick={cameraon} className='icon_o'>
                        <VideocamOffIcon className='icon_font' icon="fa-solid fa-video-slash" />
                      </div>
                    </> : <>
                      <div onClick={cameraOff} className='icon_o'>
                        <VideocamIcon className='icon_font' icon="fas fa-video" />
                      </div>
                    </>
                  }
                  <div className='icon_os'>
                    <CallEndIcon onClick={end} className='icon_font' icon="fa-solid fa-phone" />
                  </div>
                  {
                    off === true ? <>
                      <div onClick={soundon} className='icon_o'>
                        <img className='icon_fonts' style={{ cursor: 'pointer' }} src="https://img.icons8.com/ios/50/undefined/mute--v1.png" />
                      </div>
                    </> : <>
                      <div onClick={soundoff} className='icon_o'>
                        <img className='icon_fonts' style={{ cursor: 'pointer' }} src="https://img.icons8.com/ios/48/undefined/room-sound.png" />
                      </div>
                    </>
                  }
                </div>
              </div>
            </div>

          </div>
          <Dialog open={second}>
            <DialogContent>
              <div className='rating_expe'>
                {/* <MoneySplit/> */}
                <h6>
                  How would you rate your experience with {auth.currentUser.uid === props.sessiondata.euId ? props.sessiondata.seeker_name : props.sessiondata.eu_name}?
                </h6>
                <br/>
                <div style={{ textAlign: "center", margin: "auto", width: "100%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                  <Stack spacing={1}>
                    <Rating onChange={(e) => setRateit(e.target.value)} style={{ transform: 'scale(3)', color: "#0B5394" }}  defaultValue={0} />
                  </Stack>
                  <br/>
                  <Button onClick={rating} variant='contained'>Next</Button>
                </div>
              </div>

            </DialogContent>
            <p onClick={() => setThird(true)} style={{ textAlign: "center", cursor: "pointer", width: 'fit-content', margin: "auto", padding: 5,fontSize:10 }}>Report user</p>
          </Dialog></> : ""
      }
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={third}
      >

        <DialogContent dividers>
          <Typography gutterBottom>
            <Block />
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Save changes
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </>
  )
}

export default VideoCall