import { Avatar } from '@mui/material'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import ShoppingCart from '@mui/icons-material/ShoppingCart'
import { auth } from '../../firebase'
import { getRatingbyYou, getRatingForYou } from '../../Functions/functions'
import { experts, pricingforTier2 } from '../../constants/constant'
import { useUserAuth } from '../../context/AuthContext'
import StarIcon from '@mui/icons-material/Star';
const OrderDetails = (props) => {
    const [type, setTyp] = useState(experts)
    const [typess, setTypes] = useState(experts)
    const [rateforyou, setRateForYou] = useState()
    const [ratebyyou, setRatebyYou] = useState()
    const { getUserType } = useUserAuth()
    const getRatingbyu = async () => {
        if (auth?.currentUser?.uid === props?.orderdata?.euId) {
            await getUserType(setTyp, props?.orderdata?.seekerId)

        }
        else {
            await getUserType(setTyp, props?.orderdata?.euId)
        }
    }
    const getrateForYou = async () => {
        await getUserType(setTypes, auth?.currentUser?.uid)
    }
    useEffect(() => {
        getRatingbyu().then(() => {
            getRatingbyYou(type, setRatebyYou, props)
        })
         // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props?.orderdata?.seekerId, props?.orderdata?.euId, type])

    useEffect(() => {
        getrateForYou().then(() => {
            getRatingForYou(typess, setRateForYou, props)
        })
         // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props?.orderdata?.seekerId, typess, props?.orderdata?.euId])

    return (
        <div>
            <div className='checkout'>
                <div className='checkout_display'>
                    <div className='left_checkout'>
                        <Avatar
                            className='check_avatar'
                            style={{
                                height: "110px",
                                width: "110px",
                                margin: "auto"
                            }}
                            src={auth?.currentUser?.uid === props?.orderdetails?.eu_id ? "" : props?.orderdetails?.profile_img_url}
                        />
                    </div>
                    <div className='right_checkout'>
                        <h4>{props?.orderdetails?.username}</h4>
                        <h6> Date: {new Date(props.orderdata.from).toDateString()}</h6>
                        <h6> Time: {moment(new Date(props.orderdata.from).getTime()).format('hh:mm A')}</h6>

                    </div>
                </div>
                {/* <h6 style={{ color: "#0B5394", fontSize: "15px", padding: "10px" }}>Session(s)</h6> */}
                <div className='clean_code'>
                    <div className='clean_code_title'>
                        <h6>Session Fee</h6>
                        <h6>Conveniance Fee</h6>
                    </div>
                    <div className='clean_code_price'>
                        <h5>&#8377;{pricingforTier2}</h5>
                        <h5>&#8377;39.80</h5>
                    </div>

                </div>
                <div style={{ borderTop: "1px solid silver" }}></div>
                <div className='check_total'>
                    <div className='check_total_left'>
                        <ShoppingCart className='shoppingcart' style={{ color: "#0B5394" }} />
                        <h6>Total:</h6>
                    </div>
                    <div className='check_total_right'>
                        <h5>&#8377;0</h5>
                    </div>
                </div>
            </div>
            <div className='notes'>
                <h5>Your note</h5>
                <textarea disabled defaultValue="" style={{ width: "100%", height: "120px", outline: "none", border: "none", resize: "none", padding: "15px", background: "rgba(215, 232, 240, 0.6)", borderRadius: "20px", color: "#0B5394" }} />
            </div>
            {ratebyyou ? <>
                <h6>Review By You</h6>
                <div className='stars_consults'>
                    <div className='re_detailss'>
                        <div className='re_container'>
                            <div className='res_avatar'>
                                <Avatar />
                                <h5>{ratebyyou?.seeker_name}</h5>
                            </div>
                            <div className='re_rating'>
                                <div className='re_rating_points'>
                                    <StarIcon style={{
                                        width: "27px",
                                        height: "27px",
                                        marginTop: "2px",
                                        color: "#FFD966"
                                    }} />
                                    <p>{ratebyyou?.rating}</p>
                                </div>
                            </div>
                        </div>
                        <div className='re_text'>
                            <p>{ratebyyou?.feedback}</p>
                        </div>
                    </div>
                </div>
            </> : ""}
            <br />
            {rateforyou ? <> <h6>Review For You</h6>
                <div className='stars_consults'>
                    <div className='re_detailss'>
                        <div className='re_container'>
                            <div className='res_avatar'>
                                <Avatar />
                                <h5>{rateforyou?.seeker_name}</h5>
                            </div>
                            <div className='re_rating'>
                                <div className='re_rating_points'>
                                    <StarIcon style={{
                                        width: "27px",
                                        height: "27px",
                                        marginTop: "2px",
                                        color: "#FFD966"
                                    }} />
                                    <p>{rateforyou?.rating}</p>
                                </div>
                            </div>
                        </div>
                        <div className='re_text'>
                            <p>{rateforyou?.feedback}</p>
                        </div>
                    </div>
                </div></> : ""}
        </div>
    )
}
export default OrderDetails
