import React from 'react'
import "../../css/Login.css"
import "../../css/Signup.css"
import 'react-phone-input-2/lib/style.css'
const SignUp = () => {
    // const onSignInSubmit = async (e) => {
    //     e.preventDefault()
    //     phoneErrorHnadling(username, snackbarStart, phone, type, setSignUpButton, setUpRecaptcha, setConfirmObj, setFlag)
    // }
    // const snackbarStart = (severity, message, st = "", sc = "") => {
    //     setSnackbarOpen({
    //         open: true,
    //         message: message,
    //         severity: severity,
    //         statusCode: `${st} ${sc}`
    //     })
    // }
    return (
        // <div className='main_login_container'>
        //     <div className='main_login_image'>
        //         <Contact />
        //         <div className='main_login_up'>
        //             <img src={Logo} />
        //             <h1 className='main_span'></h1>
        //             <h4 className='main_spans'></h4>

        //         </div>
        //         <DisplaySnackbar
        //             open={snackbarOpen.open}
        //             closeSnackbar={() => { setSnackbarOpen({ open: false, severity: "", message: "" }) }}
        //             message={snackbarOpen.message}
        //             severity={snackbarOpen.severity}
        //             statusCode={snackbardata.statusCode}
        //         />
        //         <Toaster
        //             toastOptions={{
        //                 style: {
        //                     borderRadius: '10px',
        //                     background: '#ECEEFC',
        //                     color: '#0B5394',
        //                 },
        //             }}
        //         />
        //         <div className='illustrations'>  <img src={homn_log} /></div>
        //         <div className='login_containers_back'>
        //             <div className='login'>
        //                 <h2>Log in</h2>
        //                 {
        //                     flag === false ? <>  <form onSubmit={onSignInSubmit}>
        //                         <div className='login_container'>

        //                             <div className='icon'>
        //                                 <PersonOutlineIcon style={{
        //                                     width: "40px",
        //                                     height: "45",
        //                                     display: "flex",
        //                                     justifyContent: "center",
        //                                     alignItems: "center",
        //                                     margin: "auto",
        //                                     color: "#0B5394"
        //                                 }} />
        //                             </div>

        //                             {/* Email */}
        //                             <div className='field'>
        //                                 <input onChange={(e) => setUsername(e.target.value)} type="text" placeholder='Enter username' />
        //                             </div>
        //                         </div>

        //                         <div className='login_container'>
        //                             <div id='recaptcha-container'></div>
        //                         </div>
        //                         <div className='login_container'>
        //                             {/* Email */}
        //                             <div className='fields'>
        //                                 <PhoneInput
        //                                     placeholder="Enter phone number"
        //                                     value={phone}
        //                                     onChange={setPhone}
        //                                     enableSearch
        //                                     country="in"
        //                                 />

        //                             </div>
        //                         </div>

        //                         {/* Type of User */}
        //                         <div className='login_container'>
        //                             <div className='selectField'>
        //                                 <select onChange={(e) => setType(e.target.value)}>
        //                                     <option value="">Who are you ?</option>
        //                                     <option>{EXPLORERS}</option>
        //                                     <option>{EXPERT}</option>
        //                                 </select>

        //                             </div>
        //                         </div>
        //                         <br />
        //                         {/* Signup in Button */}
        //                         <div className='login_container'>
        //                             <div className='buttonfield'>
        //                                 <button type="submit" className='logButton'>
        //                                     Sign up
        //                                 </button>
        //                             </div>
        //                         </div>
        //                         {/* Already memebr */}
        //                         <div className='login_container'>
        //                             <h5
        //                                 style={{ textAlign: "center" }}>
        //                                 <Link style={{ textDecoration: "none" }} to="/login">
        //                                     <span className='forgot'> Already a member ? Log in </span></Link>
        //                             </h5>
        //                         </div>

        //                         <div id='recaptcha-container'>
        //                         </div>
        //                     </form></> : <> <OTP signupButton={signupButton} phone={phone} setPhone={setPhone} flag={flag} setOTP={setOTP} otp={otp} confirmObj={confirmObj} type={type} username={username} /></>
        //                 }
        //             </div>
        //         </div>
        //     </div>
        // </div>
        <></>
    )
}

export default SignUp