import React from 'react'
import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';
import "../css/Popcards.css"
const PopupCards = (props) => {
    return (
        <div>
            <AwesomeSlider
            organicArrows={false}
            mobileTouch={true}
            >
                {props.card1 ? props.card1:""}
                {props.card2 ? props.card2 : ""}
            </AwesomeSlider>
        </div>
    )
}

export default PopupCards