import { Avatar, Button } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import "../../css/Featured.css"
import { featuredPosts } from '../../Functions/functions'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Autoplay, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import "swiper/css/pagination";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import { smallFeatureScreen, smallScreen } from '../../constants/constant'
const FeaturedPosts = () => {
    const [isSmallScreen, setIsSmallScreen] = useState(false);
    const [allData, setAllData] = useState()
    const navigate = useNavigate()
    useEffect(() => {
        featuredPosts(setAllData)
    }, [allData?.status])
    const goto = (data) => {
        navigate(`/profile/${data?.eu_id}`)
    }
    useEffect(() => {
        smallScreen(setIsSmallScreen, 785)
    }, [])
    return (
        <>
            {allData?.length > 0 ? <Container fluid  >
                <Row lg={1}>
                    <Col className='featured_col'>
                        <h3 style={{ textAlign: "center", color: "#0B5394", fontWeight: 700, textTransform: "uppercase" }}>Featured Profile</h3>
                        {allData?.length > 0 ? <Swiper navigation={isSmallScreen ? false : true} pagination={{ clickable: true }} autoplay={{
                            delay: 15500,
                            disableOnInteraction: false,
                        }} modules={[Pagination, Navigation, Autoplay]} className={isSmallScreen ? "swiper_feature" : "swiper_features"}>
                            {allData?.length > 0 ? <>
                                {allData?.map((data, index) => (
                                    data.status === "Now Showing" ? <SwiperSlide key={index}>
                                        <div className='featuredposts_admin'>
                                            <div className='featuredposts_tile'>
                                                <div className='feature_posts_titles'>
                                                    <h5>Book your session with</h5>
                                                    <h6>{data?.username}</h6>
                                                </div>
                                                <div className='feature_down'>
                                                    <div className='feature_down_avatar'>
                                                        <Avatar className='feature_avatar_min' style={{ width: 120, height: 120, margin: "auto" }} src={data?.profile_img_url} />
                                                    </div>
                                                    <div className='feature_down_intro'>
                                                        <textarea   value={data?.feature_title} readOnly className='feature_textarea' style={{ width: "100%", resize: "none", background: " #FFFFFF", color: "#0B5394", borderRadius: 15, padding: "5px", border: "none", height: 140, fontSize: 15,outline:"none" }} />
                                                        <div className='feature_button_admin'> <Button onClick={() => goto(data)} className='feature_button_min' style={{ background: "#0B5394", borderRadius: "10px", color: "white", marginTop: 10 }} variant='contained'>Book Session</Button></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide> : ""
                                ))}
                            </> : ""}
                        </Swiper> : ""}
                    </Col>
                </Row>
            </Container> : ""}
        </>
    )
}
export default FeaturedPosts
