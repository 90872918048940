import React, { useEffect, useRef, useState } from 'react'
import "../../css/Leaderboard.css"
import { useNavigate } from "react-router-dom"
import { auth } from '../../firebase';
import Popup from '../../constants/Popup';
import RejectDocs from '../ExperienceUser/RejectDocs';
import { checksComplete, verifyRequestStatus } from '../../Functions/functions';
import { experts, smallScreen } from '../../constants/constant';
import { useUserAuth } from '../../context/AuthContext';
import "react-multi-carousel/lib/styles.css";
import "swiper/css";
import "swiper/css/navigation";
// import required modules
import { Autoplay, Navigation } from "swiper";
import { Pagination } from "swiper";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import { Col, Container, Row } from 'react-bootstrap';
import Carousel from 'react-grid-carousel'
import { Avatar } from '@mui/material';
import graphics from "../../assets/images/graphics.png"
export const Leaderboards = ({ post }) => {
    const [second, setSecond] = useState(false)
    const swiperRef = useRef(null);
    const [type, setType] = useState(experts)
    const [profile, setVerifyProfile] = useState({})
    const { getUserType } = useUserAuth()
    const [verifyData, setVerifyData] = useState()
    const [isSmallScreen, setIsSmallScreen] = useState(false);
    const navigate = useNavigate()
    const Profile = (data) => {
        navigate(`/profile/${data?.eu_id}`)
    }
    useEffect(() => {
        verifyRequestStatus(setVerifyData, setSecond)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [auth?.currentUser?.uid, verifyData?.status])
    useEffect(() => {
        checksComplete(navigate, type)
    }, [auth?.currentUser?.uid, type])
    const getProfileById = async () => {
        await getUserType(setType, auth.currentUser?.uid)
    }
    useEffect(() => {
        let isMounted = true
        getProfileById().then(async () => {
            if (isMounted) {
                setVerifyProfile(await getUserType(setType, auth?.currentUser?.uid))
            }
        })
        return () => { isMounted = false }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [auth.currentUser?.uid, type])
    const ordering = post && post.sort((a, b) => a.order_id - b.order_id)
    const filterOrdering = ordering?.filter((database) => {
        if (database.status === "Now Showing") {
            return database
        }
    })
    useEffect(() => {
        smallScreen(setIsSmallScreen, 1000)
    }, [])
    return (
        <div className='leader_column_new'>
            <Container >
                <Row>
                    <Col className='leader_column'>
                        <div className='leaderboards'>
                            <div className='heading_led'>
                                <h5 style={{ marginTop: 40, fontWeight: 700, fontSize: 30 }}>Top Experts</h5>
                            </div>
                        </div>
                        <br />
                        <br />
                        <Carousel responsiveLayout={[{
                            breakpoint: 1400,
                            cols: 3,
                            rows: 1,

                        }, {
                            breakpoint: 991,
                            cols: 2,
                            rows: 1,
                        }]} cols={isSmallScreen ? 2 : 4} rows={1} gap={20} loop>
                            {filterOrdering?.map((data, index) => (
                                <Carousel.Item  key={index}>
                                    <div onClick={() => Profile(data, profile)}  className={`leaderboard_onlytop ${index % 2 === 0 ? 'even' : 'odd'}`}>
                                        <Avatar style={{ height: 100, width: 100 }} src={data?.profile_img_url} />
                                        <br />
                                        <div className='leaderboard_onlytoptitle'>
                                            <h5>{data?.username}</h5>
                                        </div>
                                    </div>
                                </Carousel.Item>
                            ))}
                        </Carousel>
                    </Col>
                </Row>
            </Container>
            <Popup
                open={second}
                // onClose={()=>setSecond(false)}
                content={<RejectDocs setSecond={setSecond} />}
            />
        </div>
    )
}
