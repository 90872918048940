import React, { useEffect, useState } from 'react'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { doc, onSnapshot } from 'firebase/firestore';
import { db } from '../../firebase';
import "../../css/Domain.css"
import { Button } from '@mui/material';
import { useLocation, useNavigate, useParams } from 'react-router';
import { useUserAuth } from '../../context/AuthContext';
import CloseIcon from '@mui/icons-material/Close';
import { newDomPopup, PreviousDomains, removeDomains, selectDomain, tagsList } from '../../Functions/functions';
import Model from '../../constants/Model';
import img from "../../assets/icons/8.png"
import img1 from "../../assets/icons/9.png"
import img2 from "../../assets/icons/10.png"
import img3 from "../../assets/icons/11.png"
import DomainCards from '../../constants/DomainCards';
import { domaincard1, domaincard2, domaincard3, domaincard4, experts } from '../../constants/constant';
import DisplaySnackbar from '../../constants/DisplaySnackbar';
import CustomDomain from './CustomDomain';
const snackbardata = {
  open: false,
  severity: "",
  message: "",
  statusCode: ""
}
const NewDomain = (props) => {
  const [domains, setDomains] = useState([])
  const [domaindata, setDomainData] = useState()
  const [cancel, setCancel] = useState(false)
  const [check, setcheck] = useState([])
  const [checks, setchecks] = useState(null)
  const [value, setValue] = useState(null)
  const [dataDom, setDataDom] = useState([])
  const navigate = useNavigate()
  const { types, id } = useParams()
  const [profile, setVerifyProfile] = useState([])
  const [type, setType] = useState(experts)
  const { getUserType } = useUserAuth()
  const [open, setOpen] = useState(false)
  const [acc, setAccept] = useState(null)
  const [snackbarOpen, setSnackbarOpen] = useState(snackbardata)
  const [custom, setCustom] = useState([])
  let c = domains?.map((str, index) => ({ value: str, index }))
  const getProfileById = async () => {
    await getUserType(setType, id)
  }
  const { state } = useLocation()
  useEffect(() => {
    const unsub = onSnapshot(doc(db, "Domains", "wMkb6yf8DJO4aGRkN2VD"), (doc) => {
      if (doc.data()) {
        setDomains(doc.data().fields)
      }
    });
    return () => unsub();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useEffect(() => {
    let isMounted = true
    getProfileById().then(async () => {
      if (isMounted) {
        setVerifyProfile(await getUserType(setType, id))
      }
    })
    return () => { isMounted = false }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])
  const callComponent = (e) => {
    selectDomain(check, state, profile, navigate, types, dataDom, snackbarStart)
  }
  const f = () => {
    document.getElementById("myDropdown").classList.toggle("show");
  }
  const domCheck = async (data, e) => {
    PreviousDomains(e, check, data, setcheck, profile, setDataDom)
  }

  const remove = async (data) => {
    setCancel(true)
    setDomainData(data)

  }
  const DomainRemoval = () => {
    removeDomains(value, type, domaindata, getProfileById, setVerifyProfile, getUserType, setType, id, dataDom, setDataDom, check, setcheck)
    setCancel(false)
  }
  const searchTags = (e) => {
    tagsList(e, setCustom, c, setchecks)
  }
  useEffect(() => {
    const unsub = onSnapshot(doc(db, experts, id), (doc) => {
      setValue(doc.data());
    });
    return () => unsub();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  useEffect(() => {
    const unsub = onSnapshot(doc(db, "VerifyReq", id), async (doc) => {
      if (doc.data()) {
        setAccept(doc.data())
      }
    });
    return () => unsub();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [acc?.status])

  useEffect(() => {
    newDomPopup(value, setOpen, types)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [types])
  const snackbarStart = (severity, message, st = "", sc = "") => {
    setSnackbarOpen({
      open: true,
      message: message,
      severity: severity,
      statusCode: `${st} ${sc}`
    })
  }
  return (
    <div style={{ paddingBottom: 150 }}>
      <DisplaySnackbar
        open={snackbarOpen.open}
        closeSnackbar={() => { setSnackbarOpen({ open: false, severity: "", message: "" }) }}
        message={snackbarOpen.message}
        severity={snackbarOpen.severity}
        statusCode={snackbardata.statusCode}
      />
      <div className='dos' style={{ padding: "20px", textAlign: "center" }}>
        <div className='domain_heading'>
          <h5>Please select the fields you are experienced in</h5>
        </div>
        <div className='search_dom'>
          <div className="dropdown">
            <button onClick={f} className="dropbtn">Select Domain  <ArrowDropDownIcon /> </button>
            <div id="myDropdown" className="dropdown-content">
              <br />
              <div style={{ padding: "0px 20px" }}>   <input onChange={(e) => searchTags(e)} type='search' placeholder='search' /></div>
              <br />
              <div className='inner_domain'>

                {
                  checks ? <>  {custom.length > 0 ? <>  {
                    custom && custom.map((data, index) => (
                      <div key={index} style={{ display: "flex" }}>
                        <input defaultChecked={profile?._tags?.includes(data.value)} style={{ width: "10%", margin: "10px" }} onClick={(e) => domCheck(data.value, e)} type='checkbox' />
                        <p style={{ margin: "8px", marginLeft: "-12px" }}>{data.value}</p>
                      </div>
                    ))
                  }</> : <CustomDomain id={id} profile={profile} setchecks={setchecks} check={check} checks={checks} setcheck={setcheck} />}</> : c.map((alllist, index) => (
                    <div key={index} style={{ display: "flex" }}>
                      <input defaultChecked={profile?._tags?.includes(alllist.value)} style={{ width: "10%", margin: "10px" }} onClick={(e) => domCheck(alllist.value, e)} type='checkbox' />
                      <p style={{ margin: "8px", marginLeft: "-12px" }}>{alllist.value}</p>
                    </div>
                  ))
                }
              </div>
            </div>
          </div>
        </div>
        <div className='domain_stuff'>
          <div >
            <div className='domain_tags'>
              {
                check && check.map((data, index) => (
                  <div key={index} className='tags1'>
                    <h6>{data}&nbsp;<CloseIcon style={{ cursor: "pointer" }} onClick={() => remove(data)} /></h6>
                  </div>

                ))
              }
            </div>
          </div>
        </div>
      </div>
      <div style={{ width: "100%", textAlign: "center", padding: "20px", marginTop: "-40px" }} >
        <div style={{ margin: "auto", textAlign: "center", width: "100%", padding: "10px 20px" }}>
          <Button onClick={callComponent} variant='contained' style={{ width: "140px", borderRadius: "15px", background: "#D4DAFF", color: "#0B5394" }}>NEXT</Button>
        </div>
        <br />
        <br />
        {
          value?._tags?.length > 0 ? <>
            <div className='domain_heading'>
              <h5>Your domains</h5>
            </div>
            <div style={{ maxWidth: "600px", gap: "10px", display: "flex", paddingBottom: "80px", flexWrap: "wrap", margin: "auto", justifyContent: "center", alignItems: "center" }}>
              {
                value?._tags?.map((data, index) => (
                  <div key={index} className='tags1'>
                    <h6>{data}&nbsp;<CloseIcon style={{ cursor: "pointer" }} onClick={() => remove(data)} /></h6>
                  </div>
                ))
              }
            </div>
          </> : ""
        }


        <Model
          close={() => setOpen(false)}
          show={open}
          component={<><DomainCards setOpen={setOpen}
            card1={<>
              <div className='popcards'>
                <img alt="" src={img} />
                <p>{domaincard1.title}</p>
                <Button disabled variant='contained'>Lets do this</Button>
              </div>
            </>}
            card2={<>
              <div className='popcards'>
                <img alt="" src={img1} />
                <br />
                <p>{domaincard2.title}</p>
                <Button disabled variant='contained'>Lets do this</Button>
              </div>
            </>}
            card3={<>
              <div className='popcards'>
                <img alt="" src={img2} />
                <br />
                <p>{domaincard3.title} </p>
                <Button disabled variant='contained'>Lets do this</Button>
              </div>
            </>}
            card4={<>
              <div className='popcards'>
                <h5 style={{ width: "100%", textAlign: "right" }}><CloseIcon onClick={() => setOpen(false)} /></h5>
                <img alt="" src={img3} />
                <br />
                <p>{domaincard4.title} </p>
                <Button onClick={() => setOpen(false)} style={{ background: "#0B5394" }} variant='contained'>Lets do this</Button>
              </div>
            </>}
          /></>}
          size='mg'
          button='no'
        />
      </div>
      <Model
        show={cancel}
        close={() => setCancel(false)}
        button="no"
        component={<><h5 style={{ color: "#0B5394" }}>Are You sure you want to Delete this Domain ?</h5>
          <div style={{ display: "flex", gap: 20, justifyContent: "center", padding: 10 }}>
            <Button onClick={DomainRemoval} style={{ background: "green" }} variant='contained'>Yes</Button>
            <Button onClick={() => setCancel(false)} style={{ background: "red" }} variant='contained'>No</Button>
          </div>
        </>}
        sign="cross"
        size="sx"
      />
    </div>
  )
}

export default NewDomain