import React from 'react'
import SelectTier from "../Tier/SelectTier"
const Tags = () => {
    
    return (

        <>
          <SelectTier/>
        </>



    )
}

export default Tags