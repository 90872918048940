import { Button } from '@mui/material'
import React, { useState } from 'react'
import "../../css/Webinar.css"
import uploadIcon from "../../assets/icons/icons8-add-image-30 1.png"
import Dropdown from 'react-dropdown'
import { languages, options, webinar_lang } from '../../constants/constant'
import { DatePickerComponent, TimePickerComponent } from '@syncfusion/ej2-react-calendars'
import { getPhotoUrl, webinarPhoto } from '../../Functions/functions'
import CancelIcon from '@mui/icons-material/Cancel';
import MeetLink from '../Webinar/MeetLink'
import moment from 'moment'
import { useParams } from 'react-router'
const Webinar = (props) => {
    const [photo, setPhoto] = useState(null)
    const [open, setOpen] = useState(false)
    const [pic, setPic] = useState(null)
    const [category, setWebinarCategory] = useState()
    const [language, setWebLanguage] = useState()
    const [title, setWebTitle] = useState()
    const [description, setWebDesc] = useState()
    const [webinarFor, setWebFor] = useState()
    const [requirements, setWebReq] = useState()
    const [date, setWebDate] = useState()
    const [time, setWebTime] = useState()
    const [duration, setWebDuration] = useState()
    const [fees, setWebFees] = useState()
    const [ytlink,setYTlink]=useState()
    const { types } = useParams()
    const filesChange = (e) => {
        getPhotoUrl(setPhoto, setPic, e)
    }
    const webinarData = () => {
        webinarPhoto("", category, language, date, description, duration, fees, title, webinarFor, requirements, time, props, pic, setOpen, open)
    }


    return (
        open === true ? <div className='webinar_page'><MeetLink photo={photo} category={category} language={language} date={date} description={description} duration={duration} fees={fees} time={time} props={props} title={title} webinarFor={webinarFor} requirements={requirements} pic={pic} setOpen={setOpen} open={open} types={types} ytlink={ytlink} /></div> : <div className='webinar_page'>
            <h3>Create a Webinar</h3>
            {pic?.photo ? <div style={{ float: "right", padding: 3, width: "fit-content" }}><CancelIcon style={{ cursor: "pointer", color: "#0B5394" }} onClick={() => setPic(null)} /></div> : ""}
            <div className='webinar_image_upload'>
                {pic?.photo ? <img alt='' style={{ width: "100%", height: "100%", borderRadius: 8 }} src={pic?.photo} /> : <>
                    <img alt='' src={uploadIcon} />
                    <br />
                    <h5>Add an Image for your Webinar<span style={{ color: "red" }}>*</span></h5>
                    <h6 className='recommend_webinar'>(Reccommended size-300*300 pix)</h6>
                    <br />
                    <label htmlFor='file'>
                        <div className='web_upload_file'>
                            <span style={{ background: "#0B5394", borderRadius: 10 }}>Upload</span>
                        </div>
                        <input onChange={(e) => filesChange(e)} id="file" type="file" style={{ display: "none" }} />

                    </label>

                </>}
            </div>
            <br />
            <div>
                <h5 className='desc_title_web'>Add an Video link for your webinar (Optional)</h5>
                <textarea onChange={(e) => setYTlink(e.target.value)} placeholder='https://youtube.com/
             ' className='desc_wbinars'></textarea>
            </div>
            <div className='webinar_select_category'>
                <h5>Select webinar Category<span style={{ color: "red" }}>*</span></h5>
                <Dropdown onChange={(e) => setWebinarCategory(e)} className='myClassName' options={options} placeholder="Select Category" />
                <br />
                <h5>Select webinar Language<span style={{ color: "red" }}>*</span></h5>
                <Dropdown onChange={(e) => setWebLanguage(e)} className='myClassName' options={languages} placeholder="Select Language" />
                <br />
                <h5>Webinar title<span style={{ color: "red" }}>*</span></h5>
            </div>
            <div>
                <textarea onChange={(e) => setWebTitle(e.target.value)} placeholder='Write title here...
             ( e.g 12 Reasons Why Your Child Needs to learn life skills)
             ' className='title_wbinar'></textarea>
            </div>
            <br />
            <div>
                <h5 className='desc_title_web'>Webinar Description<span style={{ color: "red" }}>*</span></h5>
                <textarea onChange={(e) => setWebDesc(e.target.value)} placeholder='Type brief description here... 
            ( e.g You can learn from this webinar

               Life Skills Education Develops Teamwork Skills
              Strengthens the Self-Respect of Children
              Gives Children Tools to Improve Their Quality of Life.
              Kids Develop a Sense of Social Responsibility
                    And so much.)
             ' className='desc_wbinar'></textarea>
            </div>
            <br />
            <div>
                <h5 className='desc_title_web'>Who is this online webinar for?<span style={{ color: "red" }}>*</span></h5>
                <textarea onChange={(e) => setWebFor(e.target.value)} placeholder='Type here...    
           Anyone Parents or students who is interested in learning Life skills for your children or interested in learning how to boost your children’s career. 
             ' className='online_for_wbinar'></textarea>
            </div>
            <br />
            <div>
                <h5 className='desc_title_web'>Requirements and materials<span style={{ color: "red" }}>*</span></h5>
                <textarea onChange={(e) => setWebReq(e.target.value)} placeholder='Type here...    
          No previous experience is necessary.
          To take this webinar you only need a pen and paper. 
             ' className='online_for_wbinar'></textarea>
            </div>
            <br />
            <div>
                <h5 className='desc_title_web'>Select Webinar Date and Time<span style={{ color: "red" }}>*</span></h5>
                <div className='webinar_dattime'>
                    <DatePickerComponent delayUpdate={true} onChange={(e) => setWebDate(e.target.value)} min={moment().toDate()} />
                    <TimePickerComponent delayUpdate={true} placeholder='select a time' onChange={(e) => setWebTime(e.target.value)} />
                </div>
            </div>
            <br />
            <div className='webinar_duration'>
                <h5 className='desc_title_web'>Webinar Duration<span style={{ color: "red" }}>*</span></h5>
                <input onChange={(e) => setWebDuration(e.target.value)} type='textofprice' />
            </div>
            <br />
            <div>
                <h5 className='desc_title_web'>Registration Fees<span style={{ color: "red" }}>*</span></h5>
                <input onChange={(e) => setWebFees(e.target.value)} placeholder='e.g Rs 500 ( Enter your session fee per person )' style={{ marginLeft: 0 }} type='number' />

            </div>
            <br />
            <br />
            <div className='webinar_button'>
                <Button onClick={webinarData} variant='contained' style={{ width: "100%", background: "#0B5394", color: "white", height: 45 }}>Next</Button>
            </div>
        </div>
    )
}

export default Webinar
