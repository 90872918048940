
import React, { useState } from 'react'
import { useParams } from 'react-router';
import HistoryIcon from '@mui/icons-material/History';
import Model from '../../constants/Model';
import "../../css/VerifiedEUU.css"
import { experts, explorer } from '../../constants/constant';
import sessImg from "../../assets/images/Rectangle 279.png"
import SessionHistory from './SessionHistory';
import { getWebinarPosts } from '../../Functions/functions';
import WebinarHistory from './WebinarHistory';
import moment from 'moment';
const Sessions = (props) => {
    const [opens, setOpen] = useState(false)
    const [wposts, setWPosts] = useState()
    const { id, types } = useParams()
    var now = new Date()
    const filterDate = props.sess?.filter((data) => new Date(data.to) >= now);
    const filterDates = props.sess?.filter((data) => new Date(data?.from) < now);
    React.useEffect(() => {
        getWebinarPosts(setWPosts, types)
    }, [id])
    const PrevfilterWebinars = wposts?.filter((data) => new Date(data.to) < now)
    const filterWebinars = wposts?.filter((web) => {
        if (new Date(moment(web.date.toDate()).add(web.duration, 'm').format('YYYY-MM-DD hh:mm:ss A')) >= new Date()) {
            return web
        }
    })
    return (
        <>
            {(filterDate?.length || filterDates.length) > 0 ? <div className='sessionhistory'>
                {filterDate?.length > 0 ? <div className='sessionhistorytitle'>
                    <h6>Sessions</h6>
                </div> : <div></div>}
              {!types ? "" : (filterDates.length) > 0 ? <div onClick={() => setOpen(true)} className='sessionhistoryicon'>
                    <HistoryIcon style={{ color: "" }} />
                    <h6>See Sessions history</h6>
                </div> : ""}

            </div> : ""}
            {
                (filterDate?.length > 0) ? <>
                    <div className='sessionhistorytitle'>
                        {/* <h6>Upcoming Sessions</h6> */}

                    </div>
                    <SessionHistory from={props.from} session="UPCOMING" profile={props.profile} filters={filterDate} />
                    <br />
                </> : ""
            }
            {
                filterWebinars?.length > 0 ? <div>
                    <div className='sessionhistorytitle'>
                        <h6>Upcoming Webinars</h6>
                    </div>
                </div> : ""
            }
            {
                (filterWebinars?.length > 0) ? <>
                    <div className='sessionhistorytitle'>
                        {/* <h6>Upcoming Sessions</h6> */}

                    </div>
                    <WebinarHistory from={props.from} session="UPCOMING" profile={props.profile} filters={filterWebinars} wposts={wposts} />
                    <br />
                </> : ""
            }
            {window.location.pathname === `/userProfile/${experts}/${id}` ? (filterDate.length) === 0 ? <div className='session_dummy_icon'>
                <img alt="" src={sessImg} />
                <br />
                <br />
                <h5>No Sessions Available</h5>
            </div> : "" : ""}
            {/* {window.location.pathname === `/userProfile/${explorer}/${id}` ? (filterDate.length) === 0 ? <div className='session_dummy_icon'>
                <img alt="" src={sessImg} />
                <br />
                <br />
                <h5>No Sessions Available</h5>
            </div> : "" : ""} */}
            <Model
                close={() => setOpen(false)}
                show={opens}
                component={<>
                    <SessionHistory session="DONE" history="history" filters={filterDates} />
                </>}
                  button="no"
                  sign='cross'
                
            />
            
        </>
    )
}

export default Sessions