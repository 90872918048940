import { Avatar, Button } from '@mui/material'
import React, { useState } from 'react'
import "../../css/Request.css"
import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';
import { AcceptRequest, acceptRequestsIfPaid, rejectingReqifnotpaid, RejectRequest } from '../../Functions/functions'
import { experts, explorer } from '../../constants/constant'
import Popup from "../../constants/Popup"
import axios from 'axios';
import moment from 'moment';
import Graphics from './Graphics';
const RequestPanel = ({ types, request, getRequests, id, profile }) => {
    const [type, setType] = React.useState(experts)
    const [rejectedData, setRejectedData] = useState()
    const [open, setOpen] = useState(false)
    const [area, setArea] = useState("")
    const reject = async (data) => {
        setRejectedData(data)
        if (open === false) {
            setOpen(true)
        }
    }

    const accept = async (data) => {
        if (data?.paid === true) {
            acceptRequestsIfPaid(data, profile, getRequests, setType)
        }
        else {
            await AcceptRequest(data, getRequests, setType)
        }
    }
    const rejectFlow = async () => {
        if (rejectedData.paid === true) {
            await axios.get(`https://us-central1-oraculi-90497.cloudfunctions.net/getOrder/order/?order_id=${rejectedData?.id}`).then(async (refunding) => {
                await axios.get(`https://us-central1-oraculi-90497.cloudfunctions.net/createRefund/refund/${rejectedData.id}/${rejectedData.id}/1`).then(async (done) => {
                    await RejectRequest(rejectedData, id, getRequests, setType, setOpen)
                })
            })
        }
        else {
            rejectingReqifnotpaid(rejectedData, area, setOpen, getRequests)
        }
    }
    return (
        types === experts ? <>
            <div className='request_panel'>
                <div className='req_container'>
                    {request.map((data, index) => (
                        <div key={index}> <div  className='req_panel_container'>
                            <div className='req_panel_header'>
                                <div className='req_panel_avatar'>
                                    <Avatar
                                        style={{
                                            width: "60px",
                                            height: "60px",
                                            textAlign: "center",
                                        }}
                                    />
                                </div>
                                <div className='req_panel_innerheader'>
                                    <h5>Session with {data.receiver_name}</h5>
                                    <h6>Date : {moment(data.from).format("DD MMM YYYY")} at  {moment(data.from).format("hh:ss A")}</h6>
                                </div>
                            </div>
                            <div className='req_panel_paragraph'>
                                <p> {data.reason}</p>
                            </div>
                            <div className='req_panel_buttons'>
                                <Button onClick={() => reject(data, type)} style={{ background: " #E9E6E6", color: "black", boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.25)", borderRadius: 15 }} variant='contained'>Reject</Button>
                                <Button onClick={() => accept(data)} style={{ background: " #0CBC52", boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.25)", borderRadius: 15 }} variant='contained'>Accept</Button>
                            </div>
                        </div>
                        </div>
                    ))}
                    <Popup
                        open={open}
                        onClose={() => setOpen(false)}
                        content={<> <textarea onChange={(e) => setArea(e.target.value)} placeholder='Type here a reason for rejection' style={{ width: "100%", height: "120px", outline: "none", border: "none", resize: "none", padding: "15px", background: "rgba(215, 232, 240, 0.6)", borderRadius: "20px" }} />
                            <div>
                                <Button onClick={() => rejectFlow()}>okay</Button>
                            </div>
                        </>}
                    />
                </div>

            </div>
        </> : <Graphics request='request'/>

    )
}

export default RequestPanel