import { doc, updateDoc } from 'firebase/firestore'
import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import { preferredAs } from '../../constants/constant'
import { db } from '../../firebase'
import { preferredOnBoarding } from '../../Functions/functions'

const Preferred = () => {
    const [isActive,setIsActive]=useState()
    const navigate=useNavigate()
    const {type,id}=useParams()
    const navigateToAge=async(data)=>{
        setIsActive(data?.title)
        preferredOnBoarding(id, data, navigate, type)
    }
    return (
        <div className='newUser_container'>
            <div className='preferred_heading'>
                <h3>That’s a nice name!</h3>
                <br />
                <h2>You prefer being addressed <br /> as…</h2>
            </div>
            <br />
            <div className='prefred_container'>
                {preferredAs?.map((data, index) => (
                    <div onClick={()=>navigateToAge(data)} style={{border:isActive===data?.title ? "1px solid" :""}} key={index} className='prefered_flex'>
                        <h3>{data?.title}</h3>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default Preferred
