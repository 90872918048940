import React, { useEffect, useState } from 'react'
import "../../css/Home.css"
import { IconButton } from '@mui/material'
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import UpcomingSessions from '../Sessions/UpcomingSessions'
import MenuIcon from '@mui/icons-material/Menu';
import { Leaderboards } from './Leaderboards'
import Search from '../Search/Search'
import AllPosts from './AllPosts'
import Suggested from './Suggested'
import Model from '../../constants/Model'
import { allUsers, getSinglePosts, getUserDetails, leaderboards, SessionsBoard } from '../../Functions/functions'
import { auth, db } from '../../firebase'
import CategoriesContent from '../AdminPosts/CategoriesContent'
import FeaturedPosts from './FeaturedPosts'
import Courses from '../Webinar/Courses';
import HomeInstant from '../InstantExpert/HomeInstant';
import { collection, getDocs, query } from 'firebase/firestore';
import SearchBar from '../Search/SearchBar';
import Header from '../Header';
const Home = () => {
    const [post, setPosts] = useState(null)
    const [career, setCareer] = useState([])
    async function fetch() {
        await leaderboards()
    }

    useEffect(() => {
        let isMounted = true
        fetch().then(async () => {
            if (isMounted) {
                setPosts(await leaderboards())
            }
        })
        return () => { isMounted = false }
    }, [])
    useEffect(() => {
        getSinglePosts(setCareer)
    }, [career?.status])
    return (
        <div className='main_page_home' style={{ paddingBottom: 80, background: "white" }}>
            {
                auth.currentUser?.uid ? <>
                    <Search from="Home" />

                    <div>
                        <div>
                            <div className='onGoing'>
                                <UpcomingSessions />
                            </div>
                            <div className='leaderboards'>
                                <FeaturedPosts />
                                <br />
                                <br />
                                <HomeInstant />
                                <CategoriesContent career={career} />
                            </div>
                            <br />


                        </div>

                        <Leaderboards post={post} />
                        <Courses />
                    </div>
                    <br />
                    <br />
                </> : ""
            }


        </div>


    )
}

export default Home

