import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { doc, getFirestore, setDoc } from 'firebase/firestore';
import { getMessaging, getToken, onMessage} from "firebase/messaging";
const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID
  }
  const app = initializeApp(firebaseConfig);
  export const auth=getAuth(app)
  const db = getFirestore(app);
  const messaging = getMessaging(app);
  export default app
  export {db}
  export const getTokens =async() => {
    return getToken(messaging, {vapidKey: 'BIVFyk5Kfd8fnUcm0GQoJil50O13dJpf1r9XaZtPdTWP6nUD-WYK_Mtp5gcwz5p3m-2ScvQNIDPbrA8acz1x6RI'}).then(async(currentToken) => {
      if (currentToken) {
        await setDoc(doc(db,"webNotifications",auth?.currentUser?.uid),{
          tokens:[currentToken]
      })
      } else {
        console.log('No registration token available. Request permission to generate one.');
      }
    }).catch((err) => {
      console.log('An error occurred while retrieving token. ', err);
    });
  }
  export const onMessageListener = () =>
  new Promise((resolve) => {
      onMessage((payload) => {
      resolve(payload);
    });
  });
