import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import StarIcon from '@mui/icons-material/Star';
import EditIcon from '@mui/icons-material/Edit';
import { AppBar, Avatar, Drawer, IconButton, List, Menu, MenuItem } from '@mui/material';
import "../../css/VerifiedEUU.css"
import SeekerProfile from './SeekerProfile';
import Popup from '../../constants/Popup';
import EditProfile from '../Settings/EditProfile';
import DisplaySnackbar from '../../constants/DisplaySnackbar';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { dummyMenuItems, explorer, whatsappnumber } from '../../constants/constant';
import SocialMedia from '../Settings/SocialMedia';
import { useUserAuth } from '../../context/AuthContext';
import { useNavigate, useParams } from 'react-router';
import Expert from './Expert';
import { getImageOfProfile } from '../../Functions/functions';
import SessionHistory from './SessionHistory';
import Model from '../../constants/Model';
import Close from '@mui/icons-material/Close';
import group from "../../assets/images/Group 596.png"
import group1 from "../../assets/images/Group 597.png"

const snackbardata = {
    open: false,
    severity: "",
    message: "",
    statusCode: ""
}
const ProfileTop = (props) => {
    const [open, setOpen] = useState(false)
    const [second, setSecond] = useState(false)
    const [profile, setVerifyProfile] = useState({})
    const [snackbarOpen, setSnackbarOpen] = useState(snackbardata)
    const [anchorEl, setAnchorEl] = React.useState(null);
    const { logOut } = useUserAuth()
    const { types, id } = useParams()
    const navigate = useNavigate()
    const [third, setThird] = useState(false);

    const handleOpen = () => {
        setThird(true);
    };

    const handleCloses = () => {
        setThird(false);
    };
    const handleClick = e => {
        setAnchorEl(e.currentTarget);
    };
    const handleClose = async (item) => {
        if (item.title === "Logout") {
            await logOut()
            navigate("/login")
        }
        else if (item.title === "Contact Us") {
            window.open(`https://wa.me/${whatsappnumber}`)
        }
        else if (item.title === "Sessions History") {
            setSecond(true)
            setThird(false)
        }
        else if(item.title==="Privacy Policy"){
            window.open("https://www.bedot.io/privacy-policy")
        }
        setAnchorEl(null);
    };
    const snackbarStart = (severity, message, st = "", sc = "") => {
        setSnackbarOpen({
            open: true,
            message: message,
            severity: severity,
            statusCode: `${st} ${sc}`
        })
    }
    useEffect(() => {
        getImageOfProfile(types, id, setVerifyProfile)
    }, [profile.profile_img_url, id, types])
    const filterDates = props.sess?.filter((data) => new Date(data?.from) < new Date());
    return (
        <div>
            <DisplaySnackbar
                open={snackbarOpen.open}
                closeSnackbar={() => { setSnackbarOpen({ open: false, severity: "", message: "" }) }}
                message={snackbarOpen.message}
                severity={snackbarOpen.severity}
                statusCode={snackbardata.statusCode}
            />
            <Container>
                <Row>
                    <Col>
                        <div className='seekerprofilecontainer'>
                            <div className='seekleft'>
                                <div className='avatar-container'>
                                    <Avatar
                                        className='seekerleftavatarand'
                                        style={{
                                            height: "100px",
                                            width: "100px",
                                        }}
                                        src={profile?.profile_img_url}
                                    />
                                    {profile?.tier === "TIER.T3" ? <img
                                        className='avatar-bottom-image'
                                        src={group1}
                                        alt='bottom image'
                                    /> : ""}
                                </div>
                                <div className='seekleftname'>
                                    <h3>{profile?.username}</h3>
                                    <p>Edit profile <EditIcon onClick={() => setOpen(true)} style={{ height: 17, width: 17, marginTop: -4 }} /> </p>
                                    <div className='apply_veu'>
                                        <div className='app_seeker'>
                                            <div className='prof_seeker'>
                                                <h6>{(props.profile?.avgRating)?.toFixed(1)}</h6>
                                                <StarIcon style={{
                                                    height: "20px",
                                                    width: "20px",
                                                    color: "#0B5394"
                                                }} />
                                            </div>
                                            <div className='prof_rating_seeker'>
                                                <h6>100+ Ratings</h6>
                                            </div>
                                        </div>
                                        <br />

                                    </div>
                                </div>
                            </div>
                            {types === explorer ? <>
                                <Drawer PaperProps={{
                                    style: {
                                        backgroundColor: 'white',
                                    },
                                }} anchor="right" open={third} onClose={handleCloses}>


                                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                        <div style={{ padding: 10, cursor: 'pointer' }}>
                                            <Close onClick={() => setThird(false)} style={{ color: "#0B5394" }} />
                                        </div>
                                    </div>


                                    <List sx={{ py: 0 }}>
                                        {dummyMenuItems.map((item,index) => (

                                            <div key={index} className='dropdowncontents'>
                                                <div onClick={() => handleClose(item)} className='dropdowndrawer'>
                                                    <img src={item.icon} />
                                                    <h5> {item.title}</h5>
                                                </div>
                                            </div>
                                        ))}
                                        <br />
                                        <br />
                                        <SocialMedia />
                                    </List>
                                </Drawer>
                                <div className='seekright'>
                                    <IconButton onClick={handleOpen}
                                        aria-controls="simple-menu"
                                        aria-haspopup="true"
                                        aria-label="Open to show more"
                                        title="Open to show more"
                                    >
                                        <MoreVertIcon />
                                    </IconButton>

                                </div>
                            </> : ""}
                        </div>
                        {props.expert ? <> <h3 style={{ color: "#0B5394", fontSize: 20, fontWeight: 500, padding: 10 }}>Short Introduction</h3>
                            <div className='text_editors'>
                                <p>{profile?.short_intro}</p>
                            </div></> : ""}

                        {props.expert ? <Expert nocard='no' rate={props.rate} value={props.value} setValue={props.setValue} sess={props.sess} type={props.type} profile={props.profile} /> : <SeekerProfile sess={props.sess} type={props.type} profile={props.profile} />}

                        <br />
                        <br />


                    </Col>
                </Row>

            </Container>
            <Model
                show={open}
                close={() => setOpen(false)}
                component={<EditProfile profile={profile} setOpen={setOpen} snackbarStart={snackbarStart} />}
                button="no"
                sign="cross"
                size="lg"

            />

            <Model
                show={second}
                close={() => setSecond(false)}
                component={<><SessionHistory session="DONE" history="history" filters={filterDates} /></>}
                button="no"
                sign='cross'
            />

        </div>
    )
}

export default ProfileTop
