import { Avatar, Button } from '@mui/material'
import React, { useState } from 'react'
import "../../css/EditProfile.css"
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import { auth, db } from '../../firebase';
import { useParams } from 'react-router';
import { doc, updateDoc } from 'firebase/firestore';
import { getPhotoUrl, uploadPhoto } from '../../Functions/functions';
const EditProfile = ({ profile, setOpen, snackbarStart }) => {
  const [photo, setPhoto] = useState(null)
  const [pic, setPic] = useState(null)
  const { types, id } = useParams()
  const [currentprofile, setCurrentProfile] = useState(profile?.username)
  const [shortintro, setShortIntro] = useState(profile?.short_intro)

  ///////////////////////////////////////// FILE CHANGE ////////////////////////////
  const onChangeFile = (e) => {
    e.preventDefault()
    getPhotoUrl(setPhoto, setPic, e, shortintro)
  }
  ///////////////////////////////////////// UPLOAD PHOTO ////////////////////////////
  const handleSubmit = async (e) => {
    
    e.preventDefault()
    if (shortintro?.length > 200) {
      snackbarStart("error", "Max 200 words allowed")
    }
    else {
      if (!photo) {
        await updateDoc(doc(db, types, id), {
          short_intro: shortintro,
          username: currentprofile
        })
        snackbarStart("success", "Profile Updated")
        setOpen(false)

      }
      else {
        await uploadPhoto(photo, auth.currentUser, types, currentprofile, shortintro, snackbarStart)
        setOpen(false)
      }
    }


  }
  return (
    <>
      <form style={{ background: "white" }} onSubmit={handleSubmit} >
        <div className='edit_profile'>
          <div>
            <div className='edit_avatar'>
              <label htmlFor="file">
                <span>
                  <Avatar
                    style={{
                      width: "100px",
                      height: "100px",
                      cursor: "pointer",
                      backgroundColor: "#0B5394",
                      objectFit: "contain",

                    }}
                    src={pic === null ? profile?.profile_img_url : pic?.photo}
                  />
                </span>
              </label>
              <div style={{ position: "absolute", left: "50%", bottom: "0%", zIndex: "999", cursor: "pointer" }}>
                <label htmlFor="file"><span className='camera'><CameraAltIcon style={{ color: "rgb(11, 83, 148)", height: "25px", width: "22px", cursor: "pointer" }} /></span></label>
              </div>
              <input onChange={onChangeFile} accept='image/*' id="file" type="file" style={{ display: "none" }} />
            </div>

          </div>
          <div className='personal_info'>
            <h5 style={{ textAlign: "center" }}>Personal Information</h5>
          </div>
          <div className='edit_username'>
            <h5>Username</h5>
            <input type="text" value={currentprofile} onChange={(e) => setCurrentProfile(e.target.value)} />
            <br />
            <br />
            <h5>Short Introduction</h5>
            <textarea id="text" value={shortintro} onChange={(e) => setShortIntro(e.target.value)} style={{ height: "100px", width: "100%", marginLeft: 6, outline: "none", resize: "none", borderRadius: "8px", padding: 10, background: "#F6F6F6", border: "1px solid rgba(11, 83, 148, 0.3)" }} placeholder='Short Introduction' />
          </div>
          <div style={{ textAlign: "center" }}>
            <Button style={{
              background: "#0B5394",
            }} variant='contained' type="submit">Save</Button>
          </div>
        </div>

      </form>
    </>
  )
}

export default EditProfile