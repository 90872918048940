import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router'
import "../../css/Verification.css"
import UploadIcon from '@mui/icons-material/Upload';
import { Button } from '@mui/material';
import image from "../../assets/icons/icons8-pdf-80.png"
import DeleteIcon from '@mui/icons-material/Delete';
import { auth, db } from '../../firebase';
import { doc, serverTimestamp, updateDoc } from 'firebase/firestore';
import { useUserAuth } from '../../context/AuthContext';
import Popup from '../../constants/Popup';
import { insertLinkData, verifyDocs } from '../../Functions/functions';
import gif from "../../assets/images/icons8-dots-loading.gif"
import DisplaySnackbar from '../../constants/DisplaySnackbar';
import { experts } from '../../constants/constant';
const snackbardata = {
  open: false,
  severity: "",
  message: "",
  statusCode: ""
}
const Verification = () => {
  const [photo, setPhoto] = useState([])
  const [profile, setVerifyProfile] = useState({})
  const [type, setType] = useState(experts)
  const { state } = useLocation()
  const navigate = useNavigate()
  const { types, id } = useParams()
  const [d, setD] = useState()
  const { getUserType } = useUserAuth()
  const [open, setOpen] = React.useState(false);
  const [progresspercent, setProgresspercent] = useState(0);
  const [snackbarOpen, setSnackbarOpen] = useState(snackbardata)
  const [insertlink, setInsertLink] = useState()
  // const expert = profile?._tags?.map((data, index) => data)
  // let images = [];
  let imagePaths = [];
  const onFilesChange = (e) => {
    setPhoto(oldArray => [...oldArray, {
      id:Math.random(),
      data: d,
      file: e.target.files[0],
    }])
  }
  async function getProfileById() {
    await getUserType(setType, id)
  }
  useEffect(() => {
    let isMounted = true
    getProfileById().then(async () => {
      if (isMounted) {
        setVerifyProfile(await getUserType(setType, id))
      }
    })
    return () => { isMounted = false }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])
  console.log(photo)
  const remove = (datas) => {
    console.log(datas)
    const filteredFiles = photo.filter(file => file.id !== datas.id);
    setPhoto(filteredFiles)
  }
  const pro = profile?.unverified_domains?.concat(state?.tags)
  const upload = async (e) => {
    if (insertlink !== undefined) {
      insertLinkData(insertlink, state, profile, setOpen)
    }
    else {
      verifyDocs(e, photo, setProgresspercent, imagePaths, serverTimestamp, state, profile, setOpen, d, pro,progresspercent)
    }
    if (state.tier === "TIER.T2" && types === experts) {
      if (state.status2 === 'reuploading') {
      }
      else {
        await updateDoc(doc(db, experts, auth?.currentUser?.uid), {
          _tags: []
        })
      }
    }

  }

  const addDocs = (data) => {
    setD(data)
  }
  const nav = () => {
    setOpen(false)
    if ((state.tier === "" && state.status === 'reupload') || (state.tier === "TIER.T1" && state.status === 'reupload')) {
      navigate(`/home`)
    }
    else if (state.status2 === 'reuploading') {
      navigate(`/home`)
    }
    else {
      navigate(`/paymentDetails/${id}`, {
        state: {
          tier: state.tier,
          tags: state.tags,
          dom: state.dom,
          types: types,
          errorSolve:type
        }
      })
    }
  }
  // const snackbarStart = (severity, message, st = "", sc = "") => {
  //   setSnackbarOpen({
  //     open: true,
  //     message: message,
  //     severity: severity,
  //     statusCode: `${st} ${sc}`
  //   })
  // }
  return (
    <>
      <DisplaySnackbar
        open={snackbarOpen.open}
        closeSnackbar={() => { setSnackbarOpen({ open: false, severity: "", message: "" }) }}
        message={snackbarOpen.message}
        severity={snackbarOpen.severity}
        statusCode={snackbardata.statusCode}
      />
      <div className='verification'>
        <div className='verification_heading'>
          <h3>Document Verification</h3>
          <br />

          <div className='upper_domains'>
            {profile?.unverified_domains?.length >= 0 ? <>{
              pro.map((data, index) => (
                <div key={index} style={{ background: d === data ? "rgb(5, 72, 131)" : "", color: d === data ? "white" : "" }} onClick={() => addDocs(data)} className='upper_grid'>
                  <h5>{data}</h5>
                </div>
              ))
            }</> : ""}
          </div>

        </div>
        <div className='verification_subheading'>
          <h4>Please Upload the document and attach link for</h4>
          <span>
            {d}
          </span>
          <p>We need to verify your documents you are expertise in</p>
        </div>
        <Button disabled={d === undefined ? true : false} style={{ margin: "auto", textAlign: "center", display: "flex", width: "100%", background: "white" }} className='upload_container'>
          <label htmlFor='file'>
            <div className='upload_file'>
              <UploadIcon />
              <span>Add File</span>
            </div>
            <input onChange={onFilesChange} id="file" type="file" multiple style={{ display: "none" }} />
          </label>
        </Button>
        <div className='checkbox_input'>
          <input type="checkbox" />&nbsp;<span>Add Previusly Uploaded File</span>
        </div>
        <h5 style={{ textAlign: "center" }}>Or</h5>
        <div className='insert_link'>
          <input disabled={d === undefined ? true : false} onChange={(e) => setInsertLink(e.target.value)} style={{ borderRadius: "5px 0px 0px 5px" }} type="text" placeholder='inset link here'></input>
          <div className='insert_button'>
            {insertlink === undefined || insertlink.length === 0 ? <Button style={{ width: "100%", background: " rgba(11, 83, 148, 0.5)", color: "white", height: "53px", borderRadius: "0px 5px 5px 0px" }}>Upload</Button> : <Button style={{ width: "100%", background: "green", color: "white", height: "53px", borderRadius: "0px 5px 5px 0px" }}>Uploaded</Button>}
          </div>
        </div>
        <div className='checkbox_input'>
          <input type="checkbox" />&nbsp;<span>Add Previusly Uploaded Link</span>
        </div>
        <div className='file_container'>
          {
            photo.length > 0 ? <>
              {
                photo.filter((val, index) => {
                  if (val.data === d) {
                    return val
                  }
                  return false
                }).map((datas, index) => {
                  return (
                    <>
                      <div key={index} className='all_file'>
                        <img alt="" src={image} />

                        <div style={{ display: "flex", justifyContent: "space-between", width: "100%", padding: 5 }}>
                          <h5>{datas?.file?.name}</h5>

                          <div>
                            <DeleteIcon onClick={()=>remove(datas)} style={{ cursor: "pointer" }} />
                          </div>
                        </div>
                      </div>
                    </>
                  )
                })
              }
            </> : ""
          }
        </div>
        {(((state.tier === "" && state.status === 'reupload') || (state.tier === "TIER.T1" && state.status === 'reupload'))) ? <div style={{ margin: "auto", textAlign: "center", width: "100%", padding: "10px 20px" }}>
          <Button disabled={d === undefined ? true : false} onClick={upload} variant='contained' style={{ width: "140px", borderRadius: "15px", background: "#D4DAFF", color: "#0B5394" }}>Submit</Button>
        </div> : (state.tier && !state.status2 ? <div style={{ margin: "auto", textAlign: "center", width: "100%", padding: "10px 20px" }}>
          <Button disabled={d === undefined ? true : false} onClick={upload} variant='contained' style={{ width: "140px", borderRadius: "15px", background: "#D4DAFF", color: "#0B5394" }}>NEXT</Button>
        </div> : (state.status2 === 'reuploading' ? <div style={{ margin: "auto", textAlign: "center", width: "100%", padding: "10px 20px" }}>
          <Button disabled={d === undefined ? true : false} onClick={upload} variant='contained' style={{ width: "140px", borderRadius: "15px", background: "#D4DAFF", color: "#0B5394" }}>Submit</Button>
        </div> : ""))}
        <Popup
          open={open}
          onClose={() => setOpen(false)}
          content={<>
            <div className='horizon'>
              <img alt="" src={gif} />
            </div>
            <div className='pop_container'>
              <h4 className='teams'>Our team will verify details shortly</h4>
              <br />
              <div className='pop_button'>
                <Button onClick={nav} style={{ borderRadius: "10px", width: "100%", background: "rgb(5, 72, 131)" }} variant='contained'>Okay</Button>
              </div>
            </div>
          </>}
        />
      </div>
    </>
  )
}

export default Verification