import axios from "axios"
import { pricingforTier2 } from "../constants/constant"
export const getPayment = async (docRef, eudata, props) => {
    const rates = eudata?.tier === "TIER.T1" ? 0 : (eudata?.tier === "TIER.T2" ? pricingforTier2 + 20 : (eudata?.tier === "TIER.T3" ? props.euRatefor30.price + 20 : ""))
    return await axios.get(`https://us-central1-oraculi-90497.cloudfunctions.net/createOrder/?orderId=${docRef.id}&orderAmount=${rates}&customerId=1417&customerPhone=8928762936`)
}
export const getPay = async (docRef, activeButtons) => {
    return await axios.get(`https://us-central1-oraculi-90497.cloudfunctions.net/createSingleOrder/sOrder/?orderId=${docRef.id}&orderAmount=${activeButtons?.price}&customerId=1417&customerPhone=8928762936`)
}
export const getPaymentWebinar = async (docRef, props) => {
    return await axios.get(`https://us-central1-oraculi-90497.cloudfunctions.net/createOrder/?orderId=${docRef.id}&orderAmount=${props?.Book?.fees + 20}&customerId=1417&customerPhone=8928762936`)
}

export const getInstantPayment = async (docRef) => {
    return await axios.get(`https://us-central1-oraculi-90497.cloudfunctions.net/createOrder/?orderId=${docRef.id}&orderAmount=500&customerId=1417&customerPhone=8928762936`)
}
