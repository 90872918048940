import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router'
import "../../css/Introduction.css"
import KeyboardVoiceIcon from '@mui/icons-material/KeyboardVoice';
import { Button } from '@mui/material';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import { useUserAuth } from '../../context/AuthContext';
import { addTierToDatabase } from '../../Functions/functions';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import DisplaySnackbar from '../../constants/DisplaySnackbar';
import { experts, genderIntro } from '../../constants/constant';
import Language from './Language';
const snackbardata = {
    open: false,
    severity: "",
    message: "",
    statusCode: ""
}
const Introduction = () => {
    const { transcript} = useSpeechRecognition()
    const { id } = useParams()
    const [search, setSearch] = useState("")
    const navigate = useNavigate()
    const [profile, setVerifyProfile] = useState([])
    const [type, setType] = useState(experts)
    const { getUserType } = useUserAuth()
    const [snackbarOpen, setSnackbarOpen] = useState(snackbardata)
    const [g, setG] = useState("")
    const { state } = useLocation()
    const addStuff = async () => {
        if(search?.length>200){
            snackbarStart("error", "Maximum 200 words allowed")
        }
        else{
            addTierToDatabase(search, g, state, profile, type, navigate, transcript, snackbarStart, id, state.types)
        }
        
    }
    const getProfileById = async () => {
        await getUserType(setType, id)
    }
    useEffect(() => {
        let isMounted = true
        getProfileById().then(async () => {
            if (isMounted) {
                setVerifyProfile(await getUserType(setType, id))
            }
        })

        return () => { isMounted = false }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])
    const snackbarStart = (severity, message, st = "", sc = "") => {
        setSnackbarOpen({
            open: true,
            message: message,
            severity: severity,
            statusCode: `${st} ${sc}`
        })
    }
    return (
        <>
            <DisplaySnackbar
                open={snackbarOpen.open}
                closeSnackbar={() => { setSnackbarOpen({ open: false, severity: "", message: "" }) }}
                message={snackbarOpen.message}
                severity={snackbarOpen.severity}
                statusCode={snackbardata.statusCode}
            />
            <div className='introduction'>
                <div className='intro_heading'>
                    <h3>Last Step to become Expert </h3>
                </div>
                <h5>Short Introduction</h5>
                <div className='intro_details'>
                    <textarea  value={search ? search : transcript} onChange={(e) => setSearch(e.target.value)} style={{ width: "100%", height: "350px", outline: "none", resize: "none", padding: "20px", border: " 1px solid #0B5394", borderRadius: "20px" }} />
                    <label style={{textAlign:'right',width:"100%"}}>Max 200 characters allowed</label>
                </div>
                {/* {
                    audiogif === true ? <div className='audio'>
                        <Button onClick={stopRecord} variant='contained' style={{ background: "white", color: "black" }}>Stop &nbsp; <RadioButtonCheckedIcon style={{ color: "red" }} /></Button>
                    </div> : <>
                        <h5>Record Audio Introduction</h5>
                        <div className='audio'>
                            <KeyboardVoiceIcon style={{ height: "40px", width: "50px" }} />
                            <Button onClick={recordAudio} style={{ background: "rgba(11, 83, 148, 0.1)", color: "#0B5394" }} variant='contained'>Tap to record Audio</Button>
                        </div>
                    </>
                } */}
                <h5>Select your gender</h5>
                <div className='gender'>
                    <div className='gender_container'>
                        {genderIntro && genderIntro.map((data, index) => (
                            <div key={index} className='gender_buttons'>
                                <Button variant='contained' onClick={() => setG(data)} style={{ color: "#0B5394", display: "flex", flexDirection: "column", width: "100%", background: g === data ? "#D4DAFF" : "#ECEEFC", borderRadius: "15px" }}>{data.icon}{data.gender}</Button>
                            </div>
                        ))}
                    </div>
                </div>
                <br />
                <Language g={g} type={type} id={id} />

                <br />
                <div style={{ margin: "auto", textAlign: "center", width: "100%", padding: "10px 20px" }}>
                    <Button onClick={addStuff} variant='contained' style={{ width: "140px", borderRadius: "15px", background: "#D4DAFF", color: "#0B5394" }}>SUBMIT</Button>
                </div>
            </div>
        </>
    )
}

export default Introduction