import { Button } from '@mui/material';
import React from 'react'
import Lottie from 'react-lottie';
import data from "../assets/lottie.json"
const NoInternet = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: data,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };
  const connection = () => {
    if (navigator.onLine === false) {
      alert("Please Check your Connection")
    }

  }
  return (
    <div style={{ height: "100vh", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", maxWidth: "500px", margin: "auto" }}>
      <Lottie
        options={defaultOptions}
        height={650}
      />
      <h3 style={{ color: "#0B5394", width: "100%", textAlign: "center" }}>Oops,No Internet Connection</h3>
      <p style={{ fontSize: "18px", textAlign: "center" }}> Make sure Wi-Fi or cellular data is turned on and then try again.</p>
      <br />
      <Button onClick={connection} style={{ background: "#0B5394" }} variant='contained'>
        Try Again
      </Button>
    </div>
  )
}

export default NoInternet