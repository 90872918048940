import { Avatar, Button } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { ImageUrlUsers, PayModel, PayModel1, RejectedRouting } from '../../Functions/functions';
import { useNavigate } from 'react-router';
import moment from 'moment';
import Model from '../../constants/Model';
import Payment from './Payment';
import { useParams } from 'react-router'
import { doc, getDoc, onSnapshot } from 'firebase/firestore';
import { db } from '../../firebase';
import notificationIcon from "../../assets/images/Image from iOS (3) 1.png"
import Graphics from './Graphics';
const Status = (props) => {
  const navigate = useNavigate()
  const [open, setOpen] = useState(false)
  const [opens, setOpens] = useState(false)
  const [data, setData] = useState()
  const { id } = useParams()
  const [notificationData, setNotificationData] = useState()
  const [rejDetails, setRejDetails] = useState()
  const [pricingdetails, setPricingDetails] = useState()
  const gotoVerify = () => {
    RejectedRouting(navigate, props?.acc)
  }
  const payfees = async (notified) => {
    PayModel1(setOpen, setData, setNotificationData, notified, setPricingDetails)
  }
  const details = async (no) => {
    PayModel(setOpens, no, setData, setPricingDetails, setRejDetails)
  }
  console.log(props.notifications)
  return (
    <div>
      <div>
        {props.notifications?.length > 0 ? <> <div className='tpyeRejectedorMessage'>
          {
            props?.notifications?.map((no, index) => (
              (no?.type === "Type.Message" || no?.type === "Type.RejectedRequest") ?
                <div key={index} className='ver_card_type'>
                  <div className='ver_card_avatar'>
                    <Avatar style={{
                      height: "58px",
                      width: "58px",
                      margin: "auto"
                    }}
                      src={no.profile_img_url}
                    />
                  </div>
                  <div className='ver_card_right'>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                      <h5 style={{ color: no.type === "Type.RejectedRequest" ? "red" : "#3FAA6B" }}>{no.type === "Type.RejectedRequest" ? "Request Rejected" : "Session Confirmed"}</h5>
                    </div>
                    <h6 style={{ color: "#9772C4" }}> {no.type === "Type.RejectedRequest" ? <>Oops ! {no.receiver_name} Expert has rejected your session request <br /> Date : {moment(no.from).format("MMM DD , YYYY")} at {moment(no.from).format("hh:mm A")}</> : <>Congratulations! Your session is with {no.receiver_id === id ? no.sender_name : no.receiver_name} on Date : {moment(no.from).format("MMM DD , YYYY")} at {moment(no.from).format("hh:mm A")} </>} </h6>
                    <div style={{ float: "right" }}>
                      {no.type === "Type.RejectedRequest" ? <Button onClick={() => details(no)} style={{ background: "#0B5394", color: "white", borderRadius: "10px" }}>View</Button> : ""}
                    </div>
                  </div>
                </div> : ""
            ))
          }
        </div>
          <br />
          <div className='tpyeRejectedorMessage'>
            {props?.notifications?.map((notified, index) => (
              (notified.type === "Type.Payment") ? <div key={index} className='ver_card_type'>
                <div className='ver_card_avatar'>
                  <Avatar style={{
                    height: "58px",
                    width: "58px",
                    margin: "auto"
                  }}
                    src={notified.profile_img_url}
                  />
                </div>
                <div className='ver_card_right'>
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <h5 style={{ color: "#3FAA6B" }}>Request Accepted</h5>
                  </div>
                  <h6 style={{ color: "#9772C4" }}>Congratulations! to Confirm session booking pay session fee</h6>
                  <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "space-between" }}>
                    {/* <h6>1 miunte ago</h6> */}
                    <h6>Date : {moment(notified.from).format("MMM DD , YYYY")} at {moment(notified.from).format("hh:mm A")}</h6>
                    {notified.is_payment_done === true ? <Button disabled>Pay Now</Button> : (new Date(notified.to) < new Date() ? <Button disabled>Pay Now</Button> : <Button onClick={() => payfees(notified)} style={{ background: "#0B5394", borderRadius: "15px", color: "white", textTransform: "none" }}>Pay Now</Button>)}
                  </div>
                </div>
              </div> : <></>
            ))}
          </div></> : <Graphics/>}
      </div>
      <br />
      <Model
        close={() => setOpen(false)}
        show={open}
        component={<Payment euRatefor30={pricingdetails} type={props.type} data={data} notificationData={notificationData} />}
        size='md'
      />
      <Model
        close={() => setOpens(false)}
        show={opens}
        component={<Payment euRatefor30={pricingdetails} notificationData={rejDetails} data={data} />}
        size='md'
      />
    </div>
  )
}

export default Status