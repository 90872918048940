import React, { useEffect, useState } from 'react'
import Header from '../Header'
import "../../css/Settings.css"
import EditProfile from './EditProfile';
import DomainExpertise from './DomainExpertise';
import ApplyForVerifiedEUU from './ApplyForVerifiedEUU';
import PsychologyIcon from '@mui/icons-material/Psychology';
import LogoutIcon from '@mui/icons-material/Logout';
import $ from "jquery";
import { useUserAuth } from '../../context/AuthContext';
import { useNavigate, useParams } from 'react-router';
import { Avatar, Button } from '@mui/material';
import Search from '../Search/Search';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import Availability from './Availability';
import Model from '../../constants/Model';
import SettingSerachScreen from './SettingSerachScreen';
import { doc, onSnapshot } from 'firebase/firestore';
import { db } from '../../firebase';
import Monetisation from './Monetisation';
import CloseIcon from '@mui/icons-material/Close';
import PopupCards from '../../constants/PopupCards';
import img from "../../assets/icons/8 10.png"
import img1 from "../../assets/icons/8 10-1.png"
import DisplaySnackbar from '../../constants/DisplaySnackbar';
import { editCard1, editCard2, smallScreen } from '../../constants/constant';
import CallIcon from '@mui/icons-material/Call';
import ContactUs from './ContactUs';
import group from "../../assets/images/Group 596.png"
import group1 from "../../assets/images/Group 597.png"
import SocialMedia from './SocialMedia';
import { editpopup, getImageOfProfile } from '../../Functions/functions';
import BankAccountDetails from './BankAccountDetails';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import PrivacyTipIcon from '@mui/icons-material/PrivacyTip';
import Options from './Options';
import Webinar from './Webinar';
import WebIcon from "../../assets/images/icons8-webinar-64.png"

const snackbardata = {
    open: false,
    severity: "",
    message: "",
    statusCode: ""
}

const Settings = () => {
    const [profile, setVerifyProfile] = useState({})
    const { types, id } = useParams()
    const { logOut } = useUserAuth()
    const [isSmallScreen, setIsSmallScreen] = useState(false);
    const navigate = useNavigate()
    const [second, setSecond] = useState(false)
    const [bar, setBar] = useState(<Options />)
    const [open, setOpen] = useState(false)
    const [acc, setAccept] = useState(null)
    const [snackbarOpen, setSnackbarOpen] = useState(snackbardata)
    useEffect(() => {
        getImageOfProfile(types, id, setVerifyProfile)
    }, [profile.profile_img_url, id, types])
    useEffect(() => {
        $(document).on('click', 'a', function () {
            $(this).addClass('active').siblings().removeClass('active')
        })
    }, [id])
    useEffect(() => {
        const unsub = onSnapshot(doc(db, types, id), (doc) => {
            if (doc.data()) {
                setAccept(doc.data())
            }
        });
        return () => unsub();

    }, [id, types])
    const editData = async () => {
        editpopup(setSecond, setOpen)
    }

    const snackbarStart = (severity, message, st = "", sc = "") => {
        setSnackbarOpen({
            open: true,
            message: message,
            severity: severity,
            statusCode: `${st} ${sc}`
        })
    }
    const logoutclick = async () => {
        await logOut()
        navigate("/login")
    }
    useEffect(() => {
        smallScreen(setIsSmallScreen, 800)
    }, [])
    return (
        <>

            <DisplaySnackbar
                open={snackbarOpen.open}
                closeSnackbar={() => { setSnackbarOpen({ open: false, severity: "", message: "" }) }}
                message={snackbarOpen.message}
                severity={snackbarOpen.severity}
                statusCode={snackbardata.statusCode}
            />
            <div className='setting_container'>
                <Search types={types} />
                <div className="sidebar">
                    <h4 style={{ fontSize: "15px", color: " #0B539480", padding: "20px 25px", marginTop: 130, textAlign: "left" }}>Account settings</h4>
                    {
                        types === "Seeker" ? <>

                            <a href="#bottomgoto" onClick={() => { setBar(<ApplyForVerifiedEUU />) }} >
                                <MonetizationOnIcon className='circle-icon' size={23} />
                                <span >Apply for<span className='pro_text_font' style={{ marginLeft: 5 }}>Expert</span> <span className='pro_text_font' style={{ marginLeft: 3, fontFamily: "Pacifico" }}></span></span>
                            </a>
                            <a href="#bottomgoto" onClick={() => setBar(<ContactUs />)}>
                                <CallIcon className='circle-icon' size={23} />
                                <span>Contact Us</span>
                            </a>
                        </> : <>
                            <a href="#bottomgoto" onClick={() => setBar(<DomainExpertise />)}>
                                <PsychologyIcon className='circle-icon' size={23} />
                                <span>Change Domain Expertise</span>
                            </a>
                            {
                                profile.tier === "TIER.T3" ? <a href="#bottomgoto" onClick={() => setBar(<Monetisation snackbarStart={snackbarStart} />)}>
                                    <MonetizationOnIcon className='circle-icon' size={23} />
                                    <span>Monetisation Setting</span>
                                </a> : ""
                            }
                            {acc?.tier === "TIER.T3" ? "" : <a href="#bottomgoto" onClick={() => { setBar(<ApplyForVerifiedEUU />) }} >
                                <MonetizationOnIcon className='circle-icon' size={23} />
                                {acc?.tier === 'TIER.T1' ? <span >Apply for<span className='pro_text_font' style={{ marginLeft: 5 }}>Expert</span> <span className='pro_text_font' style={{ marginLeft: 3, fontFamily: "Pacifico" }}>Pro</span></span> : (acc?.tier === "TIER.T2" ? <span>Apply for<span className='pro_text_fonts' style={{ marginLeft: 5 }}>Expert</span><span className='pro_text_fonts' style={{ marginLeft: 3, fontFamily: "Pacifico" }}>Pro+</span></span> : "")}
                            </a>}
                            <a href="#bottomgoto" onClick={() => setBar(<Webinar profile={profile} snackbarStart={snackbarStart} />)}>
                                <img style={{ height: 28, width: 28 }} src={WebIcon} className='circle-icon' />
                                <span>Create a Webinar</span>
                            </a>

                            <a href="#bottomgoto" onClick={() => setBar(<Availability />)}>
                                <EventAvailableIcon className='circle-icon' size={23} />
                                <span>Availability</span>
                            </a>
                            {
                                profile?.tier === "TIER.T3" || profile.tier === "TIER.T2" ? <a href={() => false} onClick={() => setBar(<BankAccountDetails snackbarStart={snackbarStart} />)}>
                                    <AccountBalanceIcon className='circle-icon' size={23} />
                                    <span>Bank Account Details</span>
                                </a> : ""
                            }
                            <a href="#bottomgoto" onClick={() => setBar(<ContactUs />)}>
                                <CallIcon className='circle-icon' size={23} />
                                <span>Contact Us</span>
                            </a>
                            <a onClick={() => window.open('https://www.bedot.io/privacy-policy')}>
                                <PrivacyTipIcon className='circle-icon' size={23} />
                                <span>Privacy Policy</span>
                            </a>
                            <a href={() => false} onClick={logoutclick}>
                                <LogoutIcon className='circle-icon' size={23} />
                                <span>Logout</span>
                            </a>
                        </>
                    }
                    <br />

                    <SocialMedia />
                </div>
                <Model
                    close={() => setOpen(false)}
                    title={<div className='edit_header'>
                        <h5>Edit Proflie</h5>
                    </div>}
                    show={open}
                    component={<EditProfile snackbarStart={snackbarStart} profile={profile} setOpen={setOpen} />}
                    size='lg'

                />
                <Model
                    close={() => setSecond(false)}
                    show={second}
                    component={<><PopupCards setSecond={setSecond}
                        card1={<>
                            <div className='popcards'>
                                <img alt="" src={img} />
                                <p>{editCard1.title}</p>
                                <Button disabled variant='contained'>Lets do this</Button>
                            </div>
                        </>}
                        card2={<>
                            <div className='popcards'>
                                <h5 style={{ width: "100%", textAlign: "right", marginTop: "-30px" }}><CloseIcon onClick={() => { setSecond(false); setOpen(true) }} /></h5>
                                <img alt="" src={img1} />
                                <br />
                                <p>{editCard2.title}</p>
                                <Button onClick={() => { setSecond(false); setOpen(true) }} style={{ background: "#0B5394" }} variant='contained'>Lets do this</Button>
                            </div>
                        </>}
                    /></>}
                    size='mg'
                    button='no'
                />
                <div className='content'>
                    <div className='side_contain2'>
                        <div className='homestuffs'>
                            <div className='home_pages'>


                            </div>
                        </div>
                    </div>
                    <section id="bottomgoto">
                        {bar}
                    </section>
                </div>
            </div>



        </>
    )
}

export default Settings