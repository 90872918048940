import { Avatar, IconButton } from '@mui/material';
import moment from 'moment';
import React, { useState } from 'react'
import "../../css/VerifiedEUU.css"
import { auth, db } from '../../firebase';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Orders from './Orders';
import { doc, onSnapshot } from 'firebase/firestore';
import { experts } from '../../constants/constant';
import sessImg from "../../assets/images/Rectangle 279.png"
const SessionHistory = (props) => {
  const [anchorEls, setAnchorEls] = React.useState(null);
  const [orderdata, setOrderdata] = useState()
  const [orderdetails, setOrderDetails] = useState()
  const open = Boolean(anchorEls);
  const handleClick = (event, data) => {
    setAnchorEls(event.currentTarget);

  };
  const handleClose = () => {
    setAnchorEls(null);
  };
  const getOrderDetails = (data) => {
    const unsub = onSnapshot(doc(db, experts, data?.euId), (doc) => {
      setOrderDetails(doc.data());
    });
    return () => unsub();
  }
  return (
    <>
      <br />
      <br />
      <div className='ver_session_container'>
        {
          (props?.sess?.length || props.filters?.length) > 0 ? <>
            {
              (props.filters)?.map((data, index) => (
                props?.session === data?.session_status ?
                  <div style={{ background: "rgba(236, 238, 252, 0.8)" }} key={index} className='ver_card'>
                    <div className='ver_card_avatar'>
                      <Avatar style={{
                        height: "58px",
                        width: "58px",
                        margin: "auto"
                      }} />
                      <h6 onClick={() => { setOrderdata(data); getOrderDetails(data) }} className='avatarsett_icon'>
                        <IconButton
                          aria-label="more"
                          id="long-button"
                          aria-controls={open ? 'long-menu' : undefined}
                          aria-expanded={open ? 'true' : undefined}
                          aria-haspopup="true"
                          onClick={handleClick}
                        >
                          <MoreVertIcon style={{ width: 15, height: 40 }} />
                        </IconButton>
                      </h6>
                    </div>
                    <div className='ver_card_right'>
                      <div style={{ display: "flex", justifyContent: "space-between" }}>
                        {
                          auth?.currentUser?.uid !== data.seekerId ? <>
                            <h5>Session with {data.seeker_name}</h5>
                          </> : <h5>Session with {data.eu_name}</h5>
                        }
                        <h6 onClick={() => { setOrderdata(data); getOrderDetails(data) }} className='sett_icon'>
                          <IconButton
                            aria-label="more"
                            id="long-button"
                            aria-controls={open ? 'long-menu' : undefined}
                            aria-expanded={open ? 'true' : undefined}
                            aria-haspopup="true"
                            onClick={handleClick}
                          >
                            <MoreVertIcon />
                          </IconButton>

                        </h6>
                      </div>
                      <h6>Date : {moment(data.from).format('MMM DD,YYYY')}</h6>
                      <h6>Time : {moment(data.from).format('hh:mm a')} to {moment(data.to).format('hh:mm a')} </h6>

                    </div>
                  </div> : ""
              ))
            }
          </> : <></>
        }
      </div>
      {props.filters?.length === 0 ? <div style={{height:"91vh",display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center"}}>
        <img style={{height:220}} src={sessImg} />
        <h4>No Previous Sessions available</h4>
      </div> :""}
      {props?.history ? <Orders orderdetails={orderdetails} anchorEl={anchorEls} handleClose={handleClose} orderdata={orderdata} /> : ""}
    </>
  )
}

export default SessionHistory