import React, { useEffect } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router'
import "../../css/OnBoardingExplorer.css"
import successLogo from "../../assets/images/Rectangle 313.png"
import { LastVisited } from '../../Functions/functions'
const SuccessMatching = () => {
    const { state } = useLocation()
    const {id,type}=useParams()
    const navigate=useNavigate()
    useEffect(() => {
        const timer = setTimeout(() => {
            navigate(`/Category/${state==="Life Coaching" ? "Life Coaches" :(state==="Career Counselling" ? "Career Counsellors" :(state==="Business Consulting" ? "Business Consultants" :"Life Coaches"))}`)
            LastVisited(id, type)
        }, 4000);
        return () => clearTimeout(timer);
         // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <div className='newUser_container'>
            <div className='successMatch_image'>
                <img alt="" src={successLogo} />
                <h2>Great!
                    Matching you with experts…
                </h2>
            </div>
        </div>
    )
}

export default SuccessMatching
