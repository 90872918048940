import { Avatar } from '@mui/material'
import React from 'react'
import { connectHits } from 'react-instantsearch-dom'
import StarIcon from '@mui/icons-material/Star';
import { Link } from 'react-router-dom';
import group from "../../../assets/images/Group 596.png"
import group1 from "../../../assets/images/Group 597.png"
const CustomHits = ({ hits, hitsdata }) => {
    return (
        <>
            <div>
                {hitsdata?.length > 0 ? <><div className='hit_contain'>
                    {
                        hitsdata.map((hit, index) => (
                            <Link key={index} to={`/profile/${hit?.eu_id}`}>
                                <div >
                                    <div className='hit_grid_contain'>
                                        <div className='avatar'>
                                            <Avatar style={{
                                                height: "85px",
                                                width: "85px",
                                            }}
                                                src={hit?.profile_img_url}
                                            />
                                        </div>
                                        <div className='right_details'>
                                            <div className='right_flex_details'>
                                                <div className='hit_username'>
                                                    <h6>{hit?.username}</h6>
                                                    {hit?.avgRating > 0 ? <div className='hit_rate'>
                                                        <span><StarIcon style={{
                                                            height: "20px",
                                                            width: "20px",
                                                            color: "#FFE894",
                                                            marginTop: "3px",
                                                            marginLeft: 5,
                                                        }} /></span>
                                                        <h6>{Math.round((hit?.avgRating + Number.EPSILON) * 100) / 100}</h6>
                                                    </div> : ""}
                                                </div>
                                                {hit?.no_of_sessions > 0 ? <div className='hit_sessions'>
                                                    <h6>Sessions : {hit?.no_of_sessions}</h6>
                                                </div> : ""}
                                                {hit?.yearOfXP > 0 ? <div className='hit_exp'>
                                                    <div>
                                                        <h6>Experiance : {hit?.yearOfXP} yr</h6>
                                                    </div>
                                                </div> : ""}

                                            </div>
                                            {hit?.tier === 'TIER.T2' && hit?.is_eu_verified === true ? <div className='expert_icons'>
                                                <img alt="" src={group} />
                                            </div> : (hit?.tier === 'TIER.T3' && hit?.is_eu_verified === true ? <div className='expert_icons'>
                                                <img alt="" src={group1} />
                                            </div> : "")}
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        ))
                    }
                </div></> : <><div className='hit_contain'>
                    {hits.sort((a, b) => {
                        if (a.is_eu_verified && !b.is_eu_verified) {
                            return -1; // a comes first
                        } else if (!a.is_eu_verified && b.is_eu_verified) {
                            return 1; // b comes first
                        } else {
                            return 0; // leave them in the same order
                        }
                    }).map((hit, index) => (
                        <Link key={index} to={`/profile/${hit?.eu_id}`}>
                            <div>
                                <div className='hit_grid_contain'>
                                    <div className='avatar'>
                                        <Avatar style={{
                                            height: "85px",
                                            width: "85px",
                                        }}
                                            src={hit?.profile_img_url}
                                        />
                                    </div>
                                    <div className='right_details'>
                                        <div className='right_flex_details'>
                                            <div className='hit_username'>
                                                <h6>{hit?.username}</h6>
                                                <div className='hit_rate'>
                                                    <h6>{Math.round(hit?.avgRating * 10) / 10}</h6>
                                                    <StarIcon style={{
                                                        height: "20px",
                                                        width: "20px",
                                                        color: "#FFE894",
                                                        marginTop: "3px",
                                                        marginLeft: 5,
                                                    }} />
                                                </div>
                                            </div>
                                            <div className='hit_sessions'>
                                                <h6>Sessions : {hit?.no_of_sessions}</h6>
                                            </div>
                                            <div className='hit_exp'>
                                                <div>
                                                    <h6>Experiance : {hit?.yearOfXP} yr</h6>
                                                </div>
                                            </div>

                                        </div>
                                        {hit?.tier === 'TIER.T2' && hit?.is_eu_verified === true ? <div className='expert_icons'>
                                            <img alt="" src={group} />
                                        </div> : (hit?.tier === 'TIER.T3' && hit?.is_eu_verified === true ? <div className='expert_icons'>
                                            <img alt="" src={group1} />
                                        </div> : "")}
                                    </div>
                                </div>
                            </div>
                        </Link>
                    ))}

                </div></>}
            </div>
        </>
    )
}

export default connectHits(CustomHits)