import React from 'react'
import notificationIcon from "../../assets/images/Image from iOS (3) 1.png"
import reqIocn from "../../assets/images/Image from iOS (4) 1.png"
const Graphics = (props) => {
    return (
        <div>
            <div className='nonotifications'>
                <img src={props.request ? reqIocn : notificationIcon} />
                <p>{props.request ? "No Requests" :"No Notifications"}</p>
            </div>
        </div>
    )
}

export default Graphics
