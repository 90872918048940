import { Box, Button, TextField } from '@mui/material'
import React, { useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router'
import "../../css/Splits.css"
import { BankUpdate } from '../../Functions/functions'
const SplitPayment = (props) => {
  const [phone, setPhone] = useState('')
  const [email, setEmail] = useState('')
  const [account, setAccount] = useState('')
  const [holder, setHolder] = useState('')
  const [code, setCode] = useState('')
  const [vpa, setVpa] = useState('')
  const navigate = useNavigate()
  const { id, types } = useParams()
  const { state } = useLocation()
  const onSubmit = async () => {
    BankUpdate(phone,email,account,holder,code,vpa,id,props,state,navigate)
  }
  return (
    state || props?.settings ? <>
      <div className='split_container'>
        <div className={props?.settings ? 'splits_pay_fromsettings' : 'splits_pay'}>
          <h4>Please enter the Bank Details</h4>
          <p>Don’t worry! Your Bank details will not be shared with others on our platform.<br /> This will help us to process your payments. </p>
          <div>
            <Box className={props?.settings ? 'split_box' : ''}
              component="form"
              sx={{
                '& > :not(style)': { width: '46ch' },
              }}
              noValidate
              autoComplete="off"
            >
              <TextField onChange={(e) => setPhone(e.target.value)} className='split_textfield' type='texts' id="outlined-basic" label="Phone number" variant="outlined" /><br />
              <TextField onChange={(e) => setEmail(e.target.value)} className='split_textfield' type='texts' id="outlined-basic" label="Email address" variant="outlined" /> <br />
              <TextField onChange={(e) => setAccount(e.target.value)} className='split_textfield' type='texts' id="outlined-basic" label="Enter Bank account number" variant="outlined" /> <br />
              <TextField onChange={(e) => setHolder(e.target.value)} className='split_textfield' type='texts' id="outlined-basic" label="Enter Account Holder Name" variant="outlined" /> <br />
              <TextField onChange={(e) => setCode(e.target.value)} className='split_textfield' type='texts' id="outlined-basic" label="Enter IFSC Code" variant="outlined" /> <br />
              <TextField onChange={(e) => setVpa(e.target.value)} className='split_textfield' type='texts' id="outlined-basic" label="Enter VPA" variant="outlined" /> <br />
              {props?.settings ? <Button onClick={onSubmit} style={{ background: "#D4DAFF", color: "#0B5394", margin: "auto", width: "40%", height: "45px" }} variant='contained'>SUBMIT</Button> : <Button onClick={onSubmit} style={{ background: "#D4DAFF", color: "#0B5394", margin: "auto", width: "40%", height: "45px" }} variant='contained'>NEXT</Button>}
            </Box>
          </div>
        </div>
      </div>
    </> : ""
  )
}
export default SplitPayment