import { Box, Button, Tab, Tabs } from '@mui/material'
import React from 'react'
import {TabPanel } from '../../Functions/functions'
import FilterCourses from './FilterCourses'

const CategoryCourses = (props) => {
    const [value, setValue] = React.useState("Business Consultants")
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const filterCategoryTitle = props.categoryweb?.categories?.filter((item) => {
        if (item !== "Others") {
            return item
        }
    })
    const filterPosting = props.filterWebinar(props.filterWebinarByDate) && props.filterWebinar(props.filterWebinarByDate).filter((wData) => {
        if (wData.category === value) {
            return wData
        }
    })
    return (
        <>
            {props.slice ? <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs variant='scrollable' value={value} onChange={handleChange} aria-label="basic tabs example">
                        {filterCategoryTitle?.map((item, index) => (
                            <Tab key={index} label={item} value={item} />
                        ))}
                    </Tabs>
                </Box>
                {props.categoryweb?.categories.map((item, index) => (
                    <div key={index}>
                        <TabPanel value={value} index={index}>
                            {item}
                        </TabPanel>
                    </div>
                ))}

            </Box> : ""}
            <br />
            <div className='coursescontainer'>
                {props.filterWebinarByDate?.length > 0 ?
                    props.slice ? filterPosting?.map((wData, index) => (
                        <FilterCourses wData={wData} BookingWebinar={props.BookingWebinar} key={index} />
                    )) : props.filterWebinar(props.filterWebinarByDate) && props.filterWebinar(props.filterWebinarByDate).slice(0, 3).map((wData, index) => (
                        <FilterCourses wData={wData} BookingWebinar={props.BookingWebinar} key={index} />
                    ))
                    : ""}
            </div>
        </>
    )
}

export default CategoryCourses
