import React, { useEffect, useState } from 'react'
import { Dialog, DialogContent } from '@mui/material'
import moment from 'moment'
import "../../css/Calendar.css"
import { ScheduleComponent, Inject, ViewsDirective, ViewDirective, MonthAgenda } from '@syncfusion/ej2-react-schedule';
import Success from './Success';
import Search from '../Search/Search';
import { useLocation, useParams } from 'react-router';
import { addEvents, allEvents, databoundStatus } from '../../Functions/functions';
import DateTime from './DateTime';
const Calendar = () => {
    const { state } = useLocation()
    const [third, setThird] = React.useState(false);
    // const [second, setSecond] = React.useState([]);
    const [events, setEvents] = React.useState([])
    const [times, setTimes] = useState("")
    const [values, setValues] = useState("")
    const { id } = useParams()
    const [time, setTime] = useState("")
    const [value, setValue] = useState("")
    const [checkedOne, setCheckedOne] = useState();
    const [checkedTwo, setCheckedTwo] = useState()
    var timeAndDate = new Date(moment(value).format('YYYY-MM-DD') + ' ' + (new Date(time).getHours() + ':' + new Date(time).getMinutes()))
    var timeDate = new Date(moment(value).format('YYYY-MM-DD') + ' ' + (new Date(times).getHours() + ':' + new Date(times).getMinutes()))
    var until = new Date(moment(values).format('YYYY-MM-DD') + ' ' + (new Date(times).getHours() + ':' + new Date(times).getMinutes()))
    var endDate = moment(until)?.format('YYYYMMDD')
    let second = [];
    useEffect(() => {
        allEvents(id, setEvents)
    }, [id])
    const onPopupOpen = (args) => {
        args.cancel = true
    }
    const onCell = (args) => {
        if (args.startTime < new Date(new Date().setHours(0, 0, 0, 0))) {
            args.cancel = true
        }
    }
    const onRenderCell = (args) => {
        if (args.date < new Date(new Date().setHours(0, 0, 0, 0))) {
            args.element.classList.add('e-disable-cell');
        }
    }
    const onDataBound = (args) => {
        databoundStatus()
    }
    const handleSubmit = async () => {
        await addEvents(checkedOne, timeAndDate, timeDate, second, endDate, setThird, setValues, setValue, setTimes, setTime, checkedTwo)
    }
    return (
        <>
            {
                state?.location === false ? <>
                    <div className='homestuffs'>
                        <div style={{ borderBottom: "1px solid silver" }} className='home_pages'>
                            <Search />
                        </div>
                    </div>
                </> : ""
            }
            <br />
            <div className='calendar_slot_container'>
                <h1 style={{ textAlign: "center", fontSize: "25px", color: "#0B5394" }}>Confirm Your availability</h1>
                <br></br>
                <ScheduleComponent
                    delayUpdate={true}
                    eventSettings={{ dataSource: events }}
                    popupOpen={(args) => onPopupOpen(args)}
                    cellClick={(args) => onCell(args)}
                    renderCell={(args) => onRenderCell(args)}
                    minDate={moment().toDate().setHours(0, 0, 0, 0)}
                    dataBound={(args) => onDataBound(args)}
                >
                    <ViewsDirective>
                        <ViewDirective option='MonthAgenda' isSelected={true}></ViewDirective>
                    </ViewsDirective>
                    <Inject services={[MonthAgenda]} />
                </ScheduleComponent>
                <br />
                <DateTime checkedTwo={checkedTwo} setCheckedOne={setCheckedOne} checkedOne={checkedOne} setCheckedTwo={setCheckedTwo} setValue={setValue} setValues={setValues} setTimes={setTimes} setTime={setTime} values={values} time={time} times={times} handleSubmit={handleSubmit} />
                <Dialog
                    onClose={() => setThird(false)}
                    open={third}
                >
                    <DialogContent>
                        <Success setThird={() => setThird(false)} />
                    </DialogContent>
                </Dialog>
            </div>
        </>
    )
}

export default Calendar