import { Avatar, IconButton } from '@mui/material';
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { auth } from '../../firebase';
import MoreVertIcon from '@mui/icons-material/MoreVert';

const WebinarHistory = (props) => {
    const [anchorEls, setAnchorEls] = React.useState(null);
    const open = Boolean(anchorEls);

    const handleClick = (event, data) => {
        setAnchorEls(event.currentTarget);

    };
    const handleClose = () => {
        setAnchorEls(null);
    };
    return (
        <div className={props.from ? 'ver_session_container' :'ver_session_container'}>
            {(props.wposts?.length || props.filters?.length) > 0 ? <>
                {
                    props?.filters?.map((data, index) => (
                        <div key={index} className='ver_card'>
                            <div className='ver_card_avatar'>
                                <Avatar style={{
                                    height: "58px",
                                    width: "58px",
                                    margin: "auto"
                                }}
                                    src={data?.image_url}
                                />
                                <h6 className='avatarsett_icon'>
                                    <IconButton
                                        aria-label="more"
                                        id="long-button"
                                        aria-controls={open ? 'long-menu' : undefined}
                                        aria-expanded={open ? 'true' : undefined}
                                        aria-haspopup="true"
                                        onClick={handleClick}
                                    >
                                        <MoreVertIcon style={{ width: 15, height: 40 }} />
                                    </IconButton>
                                </h6>
                            </div>
                            <div className='ver_card_right'>
                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                    <h5>{data?.title}</h5>
                                    <h6 className='sett_icon'>
                                        <IconButton
                                            aria-label="more"
                                            id="long-button"
                                            aria-controls={open ? 'long-menu' : undefined}
                                            aria-expanded={open ? 'true' : undefined}
                                            aria-haspopup="true"
                                            onClick={handleClick}
                                        >
                                            <MoreVertIcon />
                                        </IconButton>

                                    </h6>
                                </div>
                                <h6>Date : {moment(data.date.toDate()).format('MMM DD,YYYY')}</h6>
                                <h6>Time : {moment(data.date.toDate()).format("hh:mm A")} to {moment(data.date.toDate()).add(data?.duration, 'm').format("hh:mm A")}</h6>

                            </div>
                        </div>
                    ))
                }
            </> : ""}
        </div>
    )
}

export default WebinarHistory
