import React, { useState, useEffect } from 'react'
import Rating from '@mui/material/Rating';
import Stack from '@mui/material/Stack';
import "../../css/Rating.css"
import { Button } from '@mui/material'
import { useLocation, useNavigate } from 'react-router';
import { useUserAuth } from '../../context/AuthContext'
import { ratingEU } from '../../Functions/functions';
import { experts } from '../../constants/constant';
import { auth } from '../../firebase';
const Star = () => {
  const { state } = useLocation()
  const [area, setArea] = useState('')
  const [type, setType] = useState(experts)
  const [senderData, setSenderData] = useState({})
  const { getUserType } = useUserAuth()
  const navigate = useNavigate()
  const getProfileById = async () => {
    await getUserType(setType, state?.data?.seekerId)
  }
  const getProfileById2 = async () => {
    await getUserType(setType, state?.data?.euId)
  }

  useEffect(() => {
    if (auth?.currentUser?.uid === state.data.euId) {
      getProfileById().then(async () => {
        setSenderData(await getUserType(setType, state?.data?.seekerId))
      })
    }
    else if (auth?.currentUser?.uid !== state.data?.euId) {
      getProfileById2().then(async () => {
        setSenderData(await getUserType(setType, state?.data?.euId))
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth?.currentUser?.uid])
  
  const addReview = async () => {
    ratingEU(state, area, senderData, navigate,type)
  }
  return (
    state?.v === true ? <>
      <div className='star'>
        <br />
        <Stack spacing={0}>
          <Rating size='large' defaultValue={state.star} style={{transform: 'scale(3)'}} readOnly />
        </Stack>
        <br/>
        <h5>Tell us a bit more about why you choose {state.star} star</h5>
        <textarea className='star_area' onChange={(e) => setArea(e.target.value)} style={{ width: "100%", outline: "none", border: "1px solid rgba(11, 83, 148, 0.7)", resize: "none", height: "300px", padding: "10px", borderRadius: "20px", }} />
        <br />
        <Button onClick={addReview} style={{ background: "#0B5394" }} variant='contained'>Submit</Button>
      </div>
    </> : ""

  )
}

export default Star