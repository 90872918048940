import { Button } from '@mui/material'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { CategoriesWebinar } from '../../Functions/functions'

const FilterCourses = (props) => {
    return (
        <div>
            <div key={props.index} className='coursesbox'>
                <div className='courselowerbox'>
                    <img src={props.wData?.image_url} alt='' />
                    <div className='course_info'>
                        <div className='course_info_title'>
                            <h5>{props.wData?.title}</h5>
                        </div>
                        <span>by {props.wData?.eu_name} | UI/UX Designer</span>
                        <br />
                        <br />
                        <p>{moment(props.wData?.date.toDate()).format("MMMM DD")} | {moment(props.wData?.date.toDate()).format("hh:mm A")}</p>
                        <h6 style={{ marginTop: -14 }}>Rs. {props.wData?.fees}</h6>
                        <div className='coursesbuttonwebinar'>
                            <Button onClick={() => props.BookingWebinar(props.wData)} variant='contained' style={{ width: "100%", borderRadius: 15, background: "#0B5394", border: "1px solid rgba(11, 83, 148, 0.7)" }}>Book Now</Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FilterCourses
