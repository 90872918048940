import React, { useEffect } from 'react'
import "../../css/Landing.css"
import inImg from "../../assets/images/an.gif"
import { Button } from '@mui/material'
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import YouTubeIcon from '@mui/icons-material/YouTube';
import { useNavigate } from 'react-router'
import data from "../../assets/home.json"
import lottie from 'lottie-web'
import { useUserAuth } from '../../context/AuthContext'
import { facebookicon, insta, Linkedin, twittericon, youtubeIcon } from '../../constants/constant';
const LandingPage = () => {
    const navigate = useNavigate()
    const { user } = useUserAuth()
    const goto = () => {
        if (user) {
            navigate('/home')
        }
        else {
           navigate('/instantExpert')
        }
        

    }
    useEffect(() => {
        const anim = lottie.loadAnimation({
            container: document.getElementById('lottie_containers'),
            renderer: 'svg',
            loop: false,
            autoplay: false,
            animationData: data,
        })
        anim.goToAndPlay(100, 20)
        return () => anim.destroy();
    }, [])
    return (
        <div>
            <div className='landingpage'>
                <div className='inner_container_landing'>
                    <div className='inner_left_land'>
                        <div id="lottie_containers">
                        </div>
                        <div className='inner_paragraph'>
                            <p>The Human Search Engine</p>
                            <br />
                            <div className='innerspan'>
                                <span>Meet bedot, the only human search engine where you can find answers to all your questions from top of the class verified experts via sessions. We will help you find the most relevant and trusted industry experts from the fields of education, career, health, relationships and much more.</span>
                            </div>
                            <br />
                            <br />
                            <div className='innerbutton'>
                                <Button className='innerButton' style={{ background: "#0B5394" }} onClick={goto} variant='contained'>Get Started</Button>
                            </div>
                            <br />
                            <br />
                            <div className='iconsinner'>
                                <InstagramIcon onClick={() => window.location.href = `${insta.Link}`} style={{ height: 30, width: 30, color: "gray", cursor: "pointer" }} />
                                <FacebookIcon onClick={() => window.location.href = `${facebookicon.Link}`} style={{ height: 30, width: 30, color: "gray", cursor: "pointer" }} />
                                <TwitterIcon onClick={() => window.location.href = `${twittericon.Link}`} style={{ height: 30, width: 30, color: "gray", cursor: "pointer" }} />
                                <LinkedInIcon onClick={() => window.location.href = `${Linkedin.Link}`} style={{ height: 30, width: 30, color: "gray", cursor: "pointer" }} />
                                <YouTubeIcon onClick={() => window.location.href = `${youtubeIcon.Link}`} style={{ height: 30, width: 30, color: "gray", cursor: "pointer" }} />
                            </div>
                            <br></br>
                            <div className='clickhere'>
                                <h5 >Want to learn more about bedot? <u onClick={() => window.open("https://www.bedot.io/")} style={{ cursor: "pointer" }}>Click here</u></h5>
                            </div>
                        </div>
                    </div>

                    <div className='inner_right_land'>
                        <img className='biglog' src={inImg} alt="" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LandingPage
