import React from 'react'
import "../../css/Rating.css"
import Logo from "../../assets/images/lf20_nft4b16l.png"
import { Button } from '@mui/material'
import { useNavigate } from 'react-router'
const ReviewSuccess = () => {
  const navigate=useNavigate()
  return (
    <div>
        <div className='reviewSuccess'>
                <h5>Help us help you.</h5>
                <h5> What can we do better?</h5>
                <img style={{height:"40%",width:"80%"}} alt="" src={Logo}/>
                <br/>
                <h4>Thank You</h4>
                <Button onClick={()=>navigate('/home')} style={{width:"40%",background:"#0B5394"}} variant='contained'>Done</Button>
        </div>
    </div>
  )
}

export default ReviewSuccess