import React from 'react'
import img from "../../assets/icons/2.png"
import img1 from "../../assets/icons/logo 1 3-2.png"
import "../../css/Swipe.css"
const VerificationCard = (props) => {
    return (

        <div className='swipe_containers'>
            <div className='swipe_logs'>
                <div className='swipe_img_logo'>
                    <img alt="" src={img1} />
                </div>
                <div className='letters'>
                    <h5 onClick={props?.tier}>Skip</h5>
                </div>
            </div>
            <div style={{ marginTop: "20px" }}>
                <div className='swipe1_card' >
                    <img alt="" src={img} />
                </div>
                <br />
                <br />
                <div className='areas-of-exps'>
                    <h1>Upload documents <br /> for verification</h1>
                </div>
            </div>

        </div>

    )
}

export default VerificationCard