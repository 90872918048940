import { Button } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import webinarLogo from "../../assets/images/webinar 2.gif"
import "../../css/Rating.css"
const WebinarConfirmed = (props) => {
    const navigate=useNavigate()
    return (
        <div>
            <div>
                <img style={{width:"100%",position:"relative"}}   alt="" src={webinarLogo} />
                <div className='webinarReview'>
                <h6 style={{ color: "#B2B2B2B2" }}>Now you will see the Google meet link on your homepage and Profile</h6>
                <br/>
                <Button onClick={()=>navigate('/home')}  style={{ width: "40%", background: "#D4DAFF", color: "#0B5394", border: "1px solid #0B5394" }} variant='contained'>Okay</Button>
                </div>
            </div>
           

        </div>
    )
}

export default WebinarConfirmed
