// import axios from 'axios';
// import { collection, doc, onSnapshot, query, updateDoc, where } from 'firebase/firestore';
// import React, { useEffect, useState } from 'react'
// import { auth, db } from '../../firebase';
// const MoneySplit = (props) => {
//     const [donesess, setDone] = useState({})
//     const [setting, setSetting] = useState({})
//     const [timeData, setTimeData] = useState([])
//     const [s,setS]=useState()
//     const t= query(collection(db, `${props?.type}/${auth?.currentUser?.uid}/Sessions`), where("session_status", "==", "DONE"));
//     useEffect(() => {
//         const unsubscribe = onSnapshot(t, (querySnapshot) => {
//             const doneSession = [];
//             querySnapshot.forEach(async(doc) => {
//                 if (doc.data()) {
//                     doneSession.push({ ...doc.data(), id: doc.id });
//                         setS(doc.data())
//                         await axios.get(`https://us-central1-oraculi-90497.cloudfunctions.net/getOrder/order/?order_id=${doc.data()?.order_id}`).then(async(data)=>{  
//                             if(data.data?.order_status==='PAID')
//                             {
//                                 await axios.get(`https://us-central1-oraculi-90497.cloudfunctions.net/getSplit/split/abhcd234/${doc.data()?.order_id}/${data.data.order_amount-20}`).then((done)=>{
                                    
//                                   }).catch((error)=>console.log(error))
//                             }
//                         }).catch((error)=>console.log(error.message))  
//                 }
//             });
//             setDone(doneSession)
//         });
//         return () => unsubscribe()
//     }, [s?.session_status, props?.type, auth?.currentUser?.uid])
//     const q = query(collection(db, `${props?.type}/${auth?.currentUser?.uid}/Sessions`));
//     React.useEffect(()=>{
//         const unsub = onSnapshot(q, (querySnapshot) => {
//             const setting = [];
//             querySnapshot.forEach(async(docs) => {
//                 setTimeData(docs.data())
//                 if (docs.data()) {                   
//                     setting.push({ ...docs.data(), id: docs.id });
//                     if(new Date()>=new Date(docs.data().to))
//                     {
//                         await updateDoc(doc(db,`${props?.type}/${docs.data().euId}/Sessions/${docs.id}`),{
//                             session_status:"DONE"
//                         })                        
//                     }
//                     else{     
//                     }
                    
//                 }

//             });
//             setSetting(setting)

//         });
//         return () => {unsub()}
//     },[auth?.currentUser?.uid])
//   return (
//     <div></div>
//   )
// }

// export default MoneySplit

import React from 'react'

function MoneySplit() {
  return (
    <div>
      
    </div>
  )
}

export default MoneySplit
