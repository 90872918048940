import { doc, onSnapshot } from 'firebase/firestore'
import React, { useEffect, useRef, useState } from 'react'
import { useUserAuth } from '../../context/AuthContext'
import { auth, db } from '../../firebase'
import "../../css/Upcoming.css"
import "react-responsive-carousel/lib/styles/carousel.min.css";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import VideoCall from '../Video/VideoCall'
import Model from '../../constants/Model'
import AllSessions from './AllSessions'
import VideoDevice from './VideoDevice'
import { Destinationofbanner, getALLSesisonsforSplit, getEUWebinarDetails, getSessionPosts, getWebinarPosts, videoCalling, webinarExpertData } from '../../Functions/functions'
import { experts, explorer, whatsappnumber } from '../../constants/constant'
import MoneySplit from '../SplitPayment/MoneySplit'
import { Avatar, Button } from '@mui/material'
import moment from 'moment'
import ScheduleIcon from '@mui/icons-material/Schedule';
import { useNavigate } from 'react-router'
import WebinarBooking from '../Webinar/WebinarBooking'
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import "swiper/css";
import "swiper/css/navigation";
import { Pagination } from "swiper";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import phoneIcon from "../../assets/images/icons8-contact-64 1.png"
import people from "../../assets/images/icons8-people-96 1.png"
import { Col, Container, Row } from 'react-bootstrap'
const UpcomingSessions = (props) => {

    const [videoCall, setVideoCall] = useState(false);
    const navigate = useNavigate()
    const [open, setOpen] = useState(false);
    const swiperRef = useRef(null);
    const [second, setSecond] = useState(false);
    const [third, setThird] = useState(false);
    const [c, setC] = useState({})
    const [audioClose, setAudioClose] = useState()
    const [index, setIndex] = useState(0);
    const [users, setUsers] = useState({})
    const [sessiondata, setSessionData] = useState({})
    const [EUData, setEUData] = useState()
    const [videoData, setVideodata] = useState([])
    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    }
    const [profile, setVerifyProfile] = useState({})
    const [type, setType] = useState(experts)
    const { getUserType } = useUserAuth()
    const [postSession, setPostsSession] = useState()
    const [s, setSession] = React.useState([])
    const [wposts, setWPosts] = useState()
    const [euwposts, setEUWPosts] = useState()
    const [webData, setWebData] = useState()
    const getProfileById = async () => {
        await getUserType(setType, auth?.currentUser?.uid)
    }
    useEffect(() => {
        getSessionPosts(setPostsSession)
    }, [])
    useEffect(() => {
        getALLSesisonsforSplit(type, setSession)
    }, [s.session_status, type])
    useEffect(() => {
        const unsub = onSnapshot(doc(db, type, auth?.currentUser.uid), (doc) => {
            setVideodata(doc.data());
        });
        return () => unsub()
    }, [videoData?.no_of_sessions, type])
    React.useEffect(() => {
        let isMounted = true
        getProfileById().then(async () => {
            if (isMounted) {
                setVerifyProfile(await getUserType(setType, auth?.currentUser?.uid))
            }
        })
        return () => { isMounted = false }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    var now = new Date()
    const filterDate = s?.filter((data) => new Date(data.to) >= now);
    const joinChannel = async (data) => {
        videoCalling(data, setVideoCall, setOpen, setC, setUsers, setSessionData, sessiondata, setAudioClose)
    }
    React.useEffect(() => {
        getWebinarPosts(setWPosts, type)
    }, [type])
    React.useEffect(() => {
        getEUWebinarDetails(setEUWPosts)
    }, [type])
    const filterWebinarofCurrentUser = wposts?.filter((web) => {
        if (new Date(moment(web.date.toDate()).add(web.duration, 'm').format('YYYY-MM-DD hh:mm:ss A')) >= new Date()) {
            return web
        }
    })
    const filterPostSession = postSession?.filter((post) => {
        if (post.status === "Now Showing" && post?.can_everyone_see === true) {
            return post
        }
        if (type === experts && post.status === "Now Showing" && post?.can_expert_see === true) {
            return post
        }
        if (type === explorer && post.status === "Now Showing" && post?.can_explorer_see === true) {
            return post
        }
    })
    const Destination = (data) => {
        Destinationofbanner(data, navigate, type)
    }
    const webinarData = (data) => {
        setThird(true)
        setWebData(data)
        webinarExpertData(setEUData)
    }
    return (
        <Container>
            <Row>
                <Col>
                    <div >
                        <MoneySplit type={type} />
                        {
                            filterDate?.length >= 0 ? <>
                                <div className='whenscrolling' style={{ padding: "20px" }}>
                                    <div className='fitcontentMobile' style={{ marginTop: 150 }}>
                                        {filterDate?.length > 0 || filterWebinarofCurrentUser?.length > 0 ? <div onClick={() => setSecond(true)} className='bookig_all'>
                                            <h6 >see all</h6>
                                        </div> : ""}
                                        <Swiper
                                            breakpoints={{
                                                120: {
                                                    slidesPerView: 1,
                                                    slidesPerGroup: 1,
                                                    spaceBetween: 20
                                                },
                                                995: {
                                                    slidesPerView: 2,
                                                    slidesPerGroup: 1,
                                                    spaceBetween: 0
                                                },
                                                1426: {
                                                    slidesPerView: 3,
                                                    slidesPerGroup: 1,
                                                    spaceBetween: 0
                                                },
                                            }}
                                            autoplay={{
                                                delay: 50000500,
                                                disableOnInteraction: false,
                                            }} spaceBetween={0} ref={swiperRef}
                                             slidesPerView="auto"
                                            slidesPerGroup={1} centerInsufficientSlides={true} pagination={{
                                                clickable: true,
                                            }} modules={[Pagination]} className="swiper_newcards">
                                            {filterDate?.map((data, index) => (
                                                <SwiperSlide key={`sessions-${data.id}-${index}`}>
                                                    <div >

                                                        <div >

                                                            <div className='sessionswipercontents'>
                                                                <div className='session_button_div'>
                                                                    <VideoDevice data={data} joinChannel={joinChannel} />
                                                                </div>
                                                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: 20 }}>
                                                                    <Avatar className='sessionswiperAvatar' style={{ width: 70, height: 70, }} />
                                                                    <div className='sessionswipertext'>
                                                                        {profile?.eu_id === data?.seekerId ? <>
                                                                            <h4>Session with {data?.eu_name}</h4>
                                                                            <span><CalendarMonthIcon /> {moment(data.from).format("DD-MMM-YYYY")}</span><br />
                                                                            <span><ScheduleIcon /> {moment(data.from).format("hh:mm A")} to {moment(data.to).format("hh:mm A")}</span>
                                                                        </> : <>
                                                                            <h4>Session with {data?.seeker_name}</h4>
                                                                            <span><CalendarMonthIcon /> {moment(data.from).format("DD-MMM-YYYY")}</span><br />
                                                                            <span><ScheduleIcon /> {moment(data.from).format("hh:mm A")} to {moment(data.to).format("hh:mm A")}</span>
                                                                        </>}


                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>

                                                    </div>
                                                </SwiperSlide>
                                            ))}
                                            {filterWebinarofCurrentUser?.map((data, index) => (
                                                <SwiperSlide key={`webinars-${data.id}-${index}`}>
                                                    <div >
                                                        <div className='issuesjoining'>
                                                            <h6>Upcoming Webinar</h6>
                                                            <div onClick={() => window.open(`https://wa.me/${whatsappnumber}`)} className='issuejoiningtitle'>
                                                                <img src={phoneIcon} />
                                                                <h6>issues in joining ?</h6>
                                                            </div>
                                                        </div>
                                                        <div onClick={() => webinarData(data)} className='titleofWebinarUpcoming'>
                                                            <div className='swiperwebinarconents'>
                                                                <img src={data.image_url} />
                                                            </div>
                                                            <div className='swiperwebinartitle'>
                                                                <div className='peoplewebinar'>
                                                                    <p className='swiperparagraph'>{data?.title}</p>
                                                                    <div className='peopletitle' style={{ display: "flex" }}>
                                                                        <img src={people} />
                                                                        <h6>25</h6>
                                                                    </div>
                                                                </div>
                                                                <div className='webButtons'>
                                                                    <CalendarMonthIcon style={{ height: 20, width: 20, color: "#0B5394" }} />
                                                                    <h6 style={{ textAlign: "left" }}>Date : {moment(data.date.toDate()).format("DD MMM YYYY")} | Time : {moment(data.date.toDate()).format("hh:mm A")}</h6>
                                                                </div>
                                                                <div className='webButton'>
                                                                    <div style={{ display: "flex" }}>
                                                                        <ScheduleIcon style={{ height: 20, width: 20, color: "#0B5394" }} />
                                                                        <h6>{data?.duration} Min</h6>
                                                                    </div>
                                                                    <Button onClick={() => window.open(`${data?.google_meet_link}`)} style={{ borderRadius: 10, background: "#0B5394" }} variant='contained'>
                                                                        join
                                                                    </Button>
                                                                </div>

                                                            </div>
                                                        </div>


                                                    </div>
                                                </SwiperSlide>
                                            ))}
                                            {filterPostSession?.map((data, index) => (
                                                <SwiperSlide key={`posts-${data.id}-${index}`}>
                                                    <div>

                                                        {data.status != "stopped" ? <div style={{ cursor: 'pointer' }} onClick={() => Destination(data)}>
                                                            <img className='second_slider' alt="" style={{ height: "220px", width: "390px", borderRadius: "38px" }} src={data?.img_url} />
                                                        </div> : ""}
                                                    </div>
                                                </SwiperSlide>
                                            ))}
                                        </Swiper>
                                    </div>
                                </div>
                            </> : ""
                        }

                        <Model
                            show={open}
                            component={<VideoCall type={type} videoData={videoData} users={users} c={c} sessiondata={sessiondata} setOpen={setOpen} videoCall={videoCall} audioClose={audioClose} />}
                            close={() => setOpen(false)}
                            button='no'
                        />
                        <Model
                            show={second}
                            close={() => setSecond(false)}
                            component={<AllSessions filterDate={filterDate} profile={profile} joinChannel={joinChannel} setSecond={setSecond} />}
                            button="no"
                            sign="cross"
                        />
                        <Model
                            show={third}
                            onHide={() => setThird(false)}
                            component={<WebinarBooking EUData={EUData} upcomingshow='onlydata' bookingdata={webData} />}
                            button='no'
                            size='lg'
                        />

                    </div>
                </Col>
            </Row>
        </Container>
    )
}

export default UpcomingSessions