
import React, { useEffect } from 'react'
import "../../css/Rating.css"
import Search from '../Search/Search';
import { useLocation } from 'react-router';
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-cards";

// import required modules
import { EffectCards } from "swiper";
import Swipe from './Swipe';
import { ReviewEu } from '../../Functions/functions'
import { experts } from '../../constants/constant';
const Reviews = () => {
    const [count, setCount] = React.useState('')
    const [tick, setTick] = React.useState('')
    const [count1, setCount1] = React.useState('')
    const [count2, setCount2] = React.useState('')
    const [tick2, setTick2] = React.useState('')
    const [count3, setCount3] = React.useState('')
    const [count4, setCount4] = React.useState('')
    const [tick3, setTick3] = React.useState('')
    const [count5, setCount5] = React.useState('')
    const [count6, setCount6] = React.useState('')
    const [tick4, setTick4] = React.useState('')
    const [count7, setCount7] = React.useState('')
    const { state } = useLocation()
    useEffect(() => {
        if (count === '#FFD25E') {
            setCount1('white')
        }
        else if (count === 'white') {
            setCount1(' #F7B1B2')

        }

    }, [count, count1])


    useEffect(() => {
        if (count2 === '#FFD25E') {
            setCount3('white')
        }
        else if (count2 === 'white') {
            setCount3(' #F7B1B2')

        }

    }, [count2, count3])

    useEffect(() => {
        if (count4 === '#FFD25E') {
            setCount5('white')
        }
        else if (count4 === 'white') {
            setCount5(' #F7B1B2')

        }

    }, [count4, count5])


    useEffect(() => {
        if (count6 === '#FFD25E') {
            setCount7('white')
        }
        else if (count6 === 'white') {
            setCount7(' #F7B1B2')

        }

    }, [count6, count7])
    const change = async (e) => {
        ReviewEu(e, state, tick, tick2, tick3, tick4)
    }
    return (

        <div style={{paddingBottom:20}}>
            <div className='homestuffs'>
                <div className='home_pages'>
                    <Search />
                </div>
            </div>
            <Swiper
                effect={"cards"}
                grabCursor={true}
                modules={[EffectCards]}
                className="mySwiper"
                onSliderMove={(e) => change(e)}
            >
                {state.type === experts ? <><SwiperSlide><Swipe title="Did you get what you were looking for?" setCount={setCount} setTick={setTick} count={count} count1={count1} /></SwiperSlide>
                    <SwiperSlide><Swipe title="Was Expert easy to understand?" setCount={setCount2} setTick={setTick2} count={count2} count1={count3} /></SwiperSlide>
                    <SwiperSlide><Swipe title="Did Expert understand your problem" setCount={setCount4} setTick={setTick3} count={count4} count1={count5} /></SwiperSlide>
                    <SwiperSlide><Swipe state={state} button='yes' tick4={tick4} title=" Was Expert polite and courteous?" setCount={setCount6} setTick={setTick4} count={count6} count1={count7} /></SwiperSlide></> : <>

                    <SwiperSlide><Swipe title="Did you get what you were looking for?" setCount={setCount} setTick={setTick} count={count} count1={count1} /></SwiperSlide>
                    <SwiperSlide><Swipe title="Was Explorer easy to understand?" setCount={setCount2} setTick={setTick2} count={count2} count1={count3} /></SwiperSlide>
                    <SwiperSlide><Swipe title="Did Explorer understand your problem" setCount={setCount4} setTick={setTick3} count={count4} count1={count5} /></SwiperSlide>
                    <SwiperSlide><Swipe state={state} button='yes' tick4={tick4} title=" Was Explorer polite and courteous?" setCount={setCount6} setTick={setTick4} count={count6} count1={count7} /></SwiperSlide>
                </>}

            </Swiper>
        </div>
    )
}

export default Reviews
