import axios from "axios"
import { addDoc, arrayRemove, arrayUnion, collection, deleteDoc, doc, getDoc, getDocs, onSnapshot, orderBy, query, runTransaction, setDoc, updateDoc, where } from "firebase/firestore"
import { getDownloadURL, getStorage, ref, uploadBytesResumable } from "firebase/storage"
import moment from "moment"
import { auth, db } from "../firebase"
import AgoraRTC from "agora-rtc-sdk-ng";
import { alpha, Box, Menu, styled, Typography } from "@mui/material"
import PropTypes from 'prop-types';
import $, { data } from 'jquery'
import { avgRating, experts, explorer, no_of_sessions, pricingforTier2, pricingforTier3 } from "../constants/constant"
import { getAdditionalUserInfo } from "firebase/auth"
export const allDomains = async () => {
    const docRef = doc(db, "Domains", "wMkb6yf8DJO4aGRkN2VD")
    const docSnap = await getDoc(docRef)
    return docSnap.data().fields
}
export async function leaderboards() {
    let users = []
    const docRef = query(collection(db, 'Top Experts'));
    const querySnapshot = await getDocs(docRef)
    querySnapshot.forEach((doc) => {
        users.push({ ...doc.data(), id: doc.id })
    })
    return users

}
export async function SessionsBoard(rating_score) {
    let users = []
    const docRef = query(collection(db, experts), where(no_of_sessions, ">=", rating_score), orderBy(no_of_sessions, "desc"));
    const querySnapshot = await getDocs(docRef)
    querySnapshot.forEach((doc) => {
        users.push({ ...doc.data(), id: doc.id })
    })
    return users

}



///////////Music Posts//////////////////
export async function business(sub, setBusinessPost) {
    let businesspost = []
    const docRef = query(collection(db, experts), where('_tags', 'array-contains-any', sub))
    const querySnapshot = await getDocs(docRef)
    querySnapshot.forEach((doc) => {
        businesspost.push({ ...doc.data(), id: doc.id })
    })
    setBusinessPost(businesspost)
}

export async function LifeCoaches() {
    let life = []
    const docRef = query(collection(db, experts), where('_tags', 'array-contains-any', ["Personal Development", "Life Coach", "NFT", "Parenting", "Life Skills", "Leadership", "Grooming", "Lifestyle", "Image consultant", "Financial Literacy"]))
    const querySnapshot = await getDocs(docRef)
    querySnapshot.forEach((doc) => {
        life.push({ ...doc.data(), id: doc.id })
    })
    return life
}

export async function CareerCouncelllor() {
    let career = []
    const docRef = query(collection(db, experts), where('_tags', 'array-contains-any', ["Career Guidance", "Student Counselling", "Entrance Exam", "College Entrance", "Job Interview"]))
    const querySnapshot = await getDocs(docRef)
    querySnapshot.forEach((doc) => {
        career.push({ ...doc.data(), id: doc.id })
    })
    return career
}

export async function allUsers() {
    let allusers = []
    const docRef = collection(db, experts);
    const querySnapshot = await getDocs(docRef)
    querySnapshot.forEach((doc) => {
        allusers.push({ ...doc.data(), id: doc.id })
    })
    return allusers
}

export const getSinglePostsRedirect = (setSinglePostsOnly, name) => {
    const q = query(collection(db, "Single Posts"), where("name", "==", name));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const users = [];
        querySnapshot.forEach((doc) => {
            if (doc.data()) {
                users.push(doc.data());
            }

        });
        setSinglePostsOnly(users)
    });
    return () => unsubscribe();
}

export const singlePostsOnly = async (setSub, name) => {
    const unsub = onSnapshot(doc(db, "Single Posts", name), (doc) => {
        setSub(doc.data().sub_domains)
    });
    return () => unsub()
}
export const CategoriesWebinar = async (setCategoryWeb) => {
    const unsub = onSnapshot(doc(db, "Categories", "categories"), (doc) => {
        setCategoryWeb(doc.data())
    });
    return () => unsub()
}


export async function videoCalling(data, setVideoCall, setOpen, setC, setUsers, setSessionData, sessiondata, setAudioClose) {
    await axios.get(`https://us-central1-oraculi-90497.cloudfunctions.net/app/access_token?channelName=${data?.id}`).then(async (res) => {
        const options = {
            appId: "fef0e822f65043aba196d7f9326f1415",
            channel: data?.id,
            token: res.data.token,
            uid: auth?.currentUser?.uid
        }
        navigator.mediaDevices.getUserMedia({ video: true, audio: true }).then(async (stream) => {
            // User has a camera and microphone, continue with creating the track
            if (stream.getVideoTracks().length === 0) {
                alert("There is no camera available. Please connect a camera to continue.");
            } else if (stream.getAudioTracks().length === 0) {
                alert("There is no microphone available. Please connect a microphone to continue.");
            } else {
                setVideoCall(true)
                const client = AgoraRTC.createClient({ mode: 'rtc', codec: 'vp8' });
                await client.join(
                    options.appId,
                    options.channel,
                    options.token,
                    options.uid);

                // Both camera and microphone are available, create the local video track
                setOpen(true)

                const localVideoTrack = await AgoraRTC.createCameraVideoTrack()
                const localAudioTrack = await AgoraRTC.createMicrophoneAudioTrack()
                client.publish(localVideoTrack)
                client.publish(localAudioTrack)
                localVideoTrack.play('me')
                localAudioTrack.play('me')
                setC(localVideoTrack)
                setAudioClose(localAudioTrack)
                client.on('user-published', async (user, mediaType) => {
                    await client.subscribe(user, mediaType);
                    if (mediaType === 'video') {
                        const remoteVideoTrack = user.videoTrack;
                        remoteVideoTrack.play('remote');
                    }
                    if (mediaType === 'audio') {
                        const remoteAudioTrack = user.audioTrack;
                        remoteAudioTrack.play();
                    }
                })
                setUsers(client)
                setSessionData(data)
            }
        }).catch(error => {
            // There was an error accessing the camera and microphone, show an error message
            alert("There is an error accessing the camera and microphone. Please check your device settings and try again.");
        });



    })
}






export async function RejectRequest(data, id, getRequests, setType, setOpen) {
    const a = new Date(data.from)
    const docRef = doc(db, `${experts}/${id}/time_slots/${data.timeslot_id}`);
    const docSnap = await getDoc(docRef)
    const recRef = doc(db, `${experts}/${id}/time_slots/${docSnap.data()?.recurrenceId}`)
    await updateDoc(recRef, {
        exception: arrayRemove(a)
    }).then(async () => {
        await updateDoc(doc(db, `${experts}/${id}/time_slots/${data.timeslot_id}`), {
            status: "REJECTED"
        }).then(async () => {
            await updateDoc(doc(db, `${experts}/${id}/Requests/${data?.id}`), {
                status: 'Rejected',
                reject_reason: data.reason
            }).then(async () => {
                const docRef = await getDoc(doc(db, experts, data.sender_id))
                if (docRef.exists()) {
                    await addDoc(collection(db, `${experts}/${data?.sender_id}/Notifications`), {
                        createdAt: new Date(),
                        eu_id: data?.receiver_id,
                        is_payment_done: true,
                        is_recent: true,
                        reason: data.reason,
                        request_id: data?.id,
                        seeker_id: data?.sender_id,
                        timeslot_id: data?.timeslot_id,
                        type: "Type.RejectedRequest"
                    }).then(() => {
                        setOpen(false)
                        getRequests()
                    })

                }
                else {
                    setType(explorer)
                    const docSnap = await getDoc(doc(db, explorer, data.sender_id))
                    if (docSnap.exists()) {
                        await addDoc(collection(db, `${explorer}/${data?.sender_id}/Notifications`), {
                            createdAt: new Date(),
                            eu_id: data?.receiver_id,
                            is_payment_done: true,
                            is_recent: true,
                            reason: data.reason,
                            request_id: data?.id,
                            seeker_id: data?.sender_id,
                            timeslot_id: data?.timeslot_id,
                            type: "Type.RejectedRequest"
                        }).then(() => {
                            setOpen(false)
                            getRequests()
                        })

                    }
                }
            })
        })
    })
}


export async function AcceptRequest(data, getRequests, setType) {
    try {
        const docRef = await getDoc(doc(db, experts, data.sender_id))
        if (docRef.exists()) {
            await updateDoc(doc(db, `${experts}/${data?.receiver_id}/Requests/${data?.id}`), {
                status: "Accepted"
            }).then(async () => {
                await addDoc(collection(db, `${experts}/${data?.sender_id}/Notifications`), {
                    createdAt: new Date(),
                    eu_id: data?.receiver_id,
                    is_payment_done: false,
                    is_recent: false,
                    reason: data.reason,
                    request_id: data?.id,
                    seeker_id: data?.sender_id,
                    timeslot_id: data?.timeslot_id,
                    type: "Type.Payment"
                }).then(() => {
                    getRequests()
                })
            })
        }
        else {
            setType(explorer)
            const docSnap = await getDoc(doc(db, explorer, data.sender_id))
            if (docSnap.exists()) {
                await updateDoc(doc(db, `${experts}/${data?.receiver_id}/Requests/${data?.id}`), {
                    status: "Accepted"
                }).then(async () => {
                    await addDoc(collection(db, `${explorer}/${data?.sender_id}/Notifications`), {
                        createdAt: new Date(),
                        eu_id: data?.receiver_id,
                        is_payment_done: false,
                        is_recent: false,
                        reason: data.reason,
                        request_id: data?.id,
                        seeker_id: data?.sender_id,
                        timeslot_id: data?.timeslot_id,
                        type: "Type.Payment"
                    }).then(() => {
                        getRequests()
                    })
                })
            }
        }
    }
    catch (error) {

    }
}
export async function addEvents(checkedOne, timeAndDate, timeDate, second, endDate, setThird, setValues, setValue, setTimes, setTime, checkedTwo) {
    if (checkedOne) {
        let ob = {};
        let starting = moment(timeAndDate).subtract(30, 'minutes')
        let ending = moment(timeDate)
        let sub = ending.subtract(30, 'minutes')
        do {
            let counter = 0
            let l = counter + 30
            let a = new Date(starting.add(l, 'minutes'))
            let k = moment(a)
            let f = new Date(k.add(l, 'minutes'))
            ob = {
                StartTime: a,
                EndTime: f
            }
            second.push(ob)
            second.filter((current) => {
                if (current.StartTime === ob.StartTime) {
                    return current
                }

            }).map(async (x) => {
                await addDoc(collection(db, `${experts}/${auth?.currentUser?.uid}/time_slots`), {
                    StartTime: x.StartTime,
                    EndTime: x.EndTime,
                    recurrenceRule: `FREQ=DAILY;UNTIL=${endDate}`,
                    exception: arrayUnion(),
                    status: "AVAILABLE",
                    minss: 30,
                    price: 0,
                    createdAt: Date.now()
                }).then(() => {
                    setThird(true)
                    setValues('')
                    setValue('')
                    setTimes('')
                    setTime('')
                })
            })

        }

        while (starting < sub)

    }
    else if (checkedTwo) {
        let ob = {};
        let starting = moment(timeAndDate).subtract(30, 'minutes')
        let ending = moment(timeDate)
        let sub = ending.subtract(30, 'minutes')
        do {
            let counter = 0
            let l = counter + 30
            let a = new Date(starting.add(l, 'minutes'))
            let k = moment(a)
            let f = new Date(k.add(l, 'minutes'))
            ob = {
                StartTime: a,
                EndTime: f
            }
            second.push(ob)
            second.filter((current) => {
                if (current.StartTime === ob.StartTime) {
                    return current
                }

            }).map(async (x) => {
                await addDoc(collection(db, `${experts}/${auth?.currentUser?.uid}/time_slots`), {
                    StartTime: x.StartTime,
                    EndTime: x.EndTime,
                    recurrenceRule: `FREQ=WEEKLY;BYDAY=SU,SA;UNTIL=${endDate}`,
                    exception: arrayUnion(),
                    status: "AVAILABLE",
                    minss: 30,
                    price: 0,
                    createdAt: Date.now()
                }).then(() => {
                    setThird(true)
                    setValues('')
                    setValue('')
                    setTimes('')
                    setTime('')
                })
            })
        }
        while (starting < sub)
    }
}




export async function allEvents(id, setEvents) {

    const q = query(collection(db, `${experts}/${id}/time_slots`));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const array = [];
        let obj = {}
        querySnapshot.forEach((doc) => {
            if (doc.data().status === "AVAILABLE") {
                obj = {
                    StartTime: doc.data().StartTime.toDate(),
                    EndTime: doc.data().EndTime.toDate(),
                    id: doc.id,
                    IsAllDay: false,
                    status: doc.data().status,
                    Subject: doc.data().status === "AWAITING" ? "BOOKED" : moment(new Date(doc.data().StartTime.toDate())).format("hh:mm A"),
                    RecurrenceRule: doc.data().recurrenceRule === `FREQ=WEEKLY;BYDAY=SU,SA;UNTIL=${moment(doc.data().StartTime.toDate()).format("YYYYMMDD")}` ? `FREQ=WEEKLY;BYDAY=SU,SA;UNTIL=${moment(doc.data().recurrenceRule.substr(30, 37)).format("YYYYMMDD") + "T" + "1800" + "00Z"}` : (doc.data().recurrenceRule === `FREQ=DAILY;UNTIL=${moment(doc.data().StartTime.toDate()).format("YYYYMMDD")}` ? `FREQ=DAILY;UNTIL=${moment(doc.data().recurrenceRule.substr(17, 24)).format("YYYYMMDD") + "T" + "1800" + "00Z"}` : doc.data().recurrenceRule),
                    RecurrenceException: doc.data().exception?.map((data, index) => moment(new Date(data?.seconds * 1000)).format('YYYYMMDD')).toString()
                }
                array.push(obj)
            }

        });
        setEvents(array)

    });
    return () => unsubscribe();
}

const storage = getStorage()
export async function uploadPhoto(file, user, types, currentprofile, shortintro, snackbarStart) {
    const fileRef = ref(storage, auth.currentUser.uid + '.png')
    const uploadTask = uploadBytesResumable(fileRef, file)
    uploadTask.on('state_changed', (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log('Upload is ' + progress + '% done');
    },
        (error) => {
        },
        async () => {
            await getDownloadURL(fileRef).then(async (pURL) => {
                const docRef = doc(db, types, auth.currentUser.uid);
                await updateDoc(docRef, {
                    profile_img_url: null ? null : pURL.toString(),
                    username: currentprofile,
                    short_intro: shortintro
                }).then(() => {
                    snackbarStart("success", "Profile Updated")
                })
            }).catch((error) => {
                snackbarStart("error", "")
            })
        }
    )
}

export const webinarPhoto = async (file, webinarCategory, weblanguage, webdate, webdesc, webduration, webfees, webtitle, webfor, webreq, webtime, props, pic, setOpen, open, types, setSecond, googlemeet, ytlink) => {
    if (!pic?.photo) {
        props.snackbarStart("error", "Upload  Webinar Image")
    }
    else if (!webinarCategory) {
        props.snackbarStart("error", "Select  Webinar Category")
    }
    else if (!weblanguage) {
        props.snackbarStart("error", "Select  Webinar Language")
    }
    else if (!webtitle) {
        props.snackbarStart("error", "Select  Webinar Title")
    }
    else if (!webdesc) {
        props.snackbarStart("error", "Select  Webinar Description")
    }
    else if (!webfor) {
        props.snackbarStart("error", "Select What is this Online Webinar For")
    }
    else if (!webreq) {
        props.snackbarStart("error", "Select Webinar Requirements")
    }
    else if (!webdate) {
        props.snackbarStart("error", "Select Webinar Date")
    }
    else if (!webtime) {
        props.snackbarStart("error", "Select Webinar Time")
    }
    else if (!webduration) {
        props.snackbarStart("error", "Select Webinar Duration")
    }
    else if (!webfees) {
        props.snackbarStart("error", "Select Webinar Fees")
    }
    else if (open === false) {
        setOpen(true)
    }
    else {
        const fileRef = ref(storage, file.name + '.png')
        const uploadTask = uploadBytesResumable(fileRef, file)
        uploadTask.on('state_changed', (snapshot) => {
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log('Upload is ' + progress + '% done');
        },
            (error) => {
            },
            async () => {
                await getDownloadURL(fileRef).then(async (pURL) => {
                    await addDoc(collection(db, "Webinar"), {
                        category: webinarCategory.value,
                        date: new Date(moment(webdate).format("YYYY-MM-DD") + " " + moment(webtime).format('hh:mm:ss A')),
                        description: webdesc,
                        duration: Number(webduration),
                        eu_id: props.props.profile.eu_id,
                        eu_name: props.props.profile.username,
                        fees: Number(webfees),
                        google_meet_link: googlemeet,
                        image_url: pURL.toString(),
                        language: weblanguage.value,
                        requirements: webreq,
                        time: moment(webtime).format('HH:mm'),
                        title: webtitle,
                        webinarFor: webfor,
                        youtube_link: ytlink ? ytlink : "",
                        seeker_booked: [],
                    }).then(async (docRef) => {
                        await setDoc(doc(db, `${types}/${auth?.currentUser?.uid}/Webinar`, docRef.id), {
                            category: webinarCategory.value,
                            date: new Date(moment(webdate).format("YYYY-MM-DD") + " " + moment(webtime).format('hh:mm:ss A')),
                            description: webdesc,
                            duration: Number(webduration),
                            eu_id: props.props.profile.eu_id,
                            eu_name: props.props.profile.username,
                            fees: Number(webfees),
                            google_meet_link: googlemeet,
                            image_url: pURL.toString(),
                            language: weblanguage.value,
                            requirements: webreq,
                            time: moment(webtime).format('HH:mm'),
                            title: webtitle,
                            webinarFor: webfor,
                            youtube_link: ytlink ? ytlink : "",
                        }).then(() => {
                            setSecond(true)
                        })
                    })
                }).catch((error) => {
                    console.log(error.message)
                })
            }
        )
    }


}

export const webinarExpertData = async (setEUData) => {
    const wEudata = doc(db, `${experts}/${auth?.currentUser?.uid}`);
    const EU = await getDoc(wEudata)
    setEUData(EU.data())
}


export const webinarPaymentDone = async (props, navigate, getPaymentWebinar, isProd, cashfreeProd, cashfreeSandbox) => {

    navigate('/payment')
    await addDoc(collection(db, `${props.type}/${auth?.currentUser?.uid}/Orders`), {
        SUCCESS: null,
        orderAmount: props.Book.fees,
    }).then((docRef) => {
        getPaymentWebinar(docRef, props).then(async (data) => {
            let parent = document.getElementById('drop_in_container');
            let cashfree;
            if (isProd) {
                cashfree = new cashfreeProd.Cashfree();
            } else {
                cashfree = new cashfreeSandbox.Cashfree();
            }
            cashfree.initialiseDropin(parent, {
                orderToken: data.data.order_token,
                components: ["card", "app", "upi", "order-details", "netbanking"],
                onSuccess: async function cbs(pay) {
                    await axios.get(`https://us-central1-oraculi-90497.cloudfunctions.net/getPayment/pay/?order_id=${pay.order.orderId}&cf_payment_id=${pay.transaction.transactionId}`).then(async (pio) => {
                        await setDoc(doc(db, `${props.type}/${auth?.currentUser?.uid}/Orders`, pay.order.orderId), {
                            euId: props.Book.eu_id,
                            from: props.Book.date?.toDate(),
                            to: moment(props.Book.date?.toDate()).add(props.Book.duration, 'm').format("YYYY-MM-DD HH:mm:ss") + '.000',
                            orderAmount: pio?.data.payment_amount,
                            paymentMode: pio?.data.payment_group,
                            referenceId: pio?.data.cf_payment_id,
                            seekerId: auth?.currentUser?.uid,
                            seeker_img: "",
                            txStatus: pio?.data?.payment_status,
                            txTime: pio.data.payment_time,
                            eu_img: props.data.profile_img_url,
                            timeslot_id: docRef.id,

                        }).then(async () => {
                            await setDoc(doc(db, `${props.type}/${auth?.currentUser?.uid}/Webinar`, props.Book.id), {
                                category: props.Book.category,
                                date: props.Book.date.toDate(),
                                description: props.Book.description,
                                duration: props.Book.duration,
                                eu_id: props.Book.eu_id,
                                eu_name: props.Book.eu_name,
                                fees: props.Book.fees,
                                google_meet_link: "",
                                image_url: props.Book.image_url,
                                language: props.Book.language,
                                requirements: props.Book.requirements,
                                time: props.Book.time,
                                title: props.Book.title,
                                webinarFor: props.Book.webinarFor,
                                youtube_link: "",
                                seeker_booked: props.Book.seeker_booked,
                                order_id: pay.order.orderId,
                                webinar_status: "UPCOMING",
                                to: moment(props.Book.date.toDate()).add(props.Book.duration, 'm').format("YYYY-MM-DD HH:mm:ss.000")
                            }).then(async () => {
                                await updateDoc(doc(db, `Webinar`, props.Book.id), {
                                    seeker_booked: arrayUnion(auth?.currentUser?.uid)
                                })
                            })
                        })
                    })

                },
                onFailure: async function cbf(pay) {

                }
            })
        })
    })
}









export const filterWebinar = (filterWebinarByDate) => filterWebinarByDate.filter((f) => {
    if (f.eu_id != auth.currentUser.uid) {
        return f
    }
})

export const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        color:
            theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity,
                ),
            },
        },
    },
}));


export const label = { inputProps: { 'aria-label': 'Switch demo' } };

export function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box component={'div'} sx={{ p: 3 }}>
                    <Typography component={'div'}>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

export function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


export async function addingRates(id, monetisationRates, rating, snackbarStart) {
    await setDoc(doc(db, `${experts}/${id}/Pricing`, "p1"), {
        mins: 30,
        price: parseInt(monetisationRates) ? parseInt(monetisationRates) : rating.price
    }).then((res) => {
        snackbarStart("success", "Monetisation rates added")
    })

}

export const RangeSlider1 = (values, setSlider) => {
    $(".js-range-slider").ionRangeSlider({
        grid: true,
        skin: "big",
        type: "double",
        values: values,
        onChange: function (data) {
            if (data) {
                setSlider(data)
            }

        },
        onFinish: function (data) {

        },
    })
}

export const RangeSlider2 = (values, setSessionSlider) => {
    $(".js-range-sliders").ionRangeSlider({
        grid: true,
        skin: "big",
        type: "double",
        values: values,
        onChange: function (data) {
            setSessionSlider(data)
        },
        onFinish: function (data) {

        },
    })
}



export const TiercardsALL = async (activeButtons, type, id, profile, isProd, cashfreeProd, cashfreeSandbox, getPay, navigate) => {
    if (!activeButtons) {
        alert("please select tier")
    }
    else if (activeButtons.price === "FREE" && type === experts) {
        navigate(`/domain/${type}/${id}`, {
            state: {
                tier: "TIER.T1",
                profile: profile,

            }
        })
    }
    else if (activeButtons.price === "FREE" && type === explorer) {
        await setDoc(doc(db, experts, id), {
            _tags: [],
            current_profile: auth?.currentUser?.email,
            email: auth?.currentUser?.email,
            eu_id: auth?.currentUser?.uid,
            eu_requested: [],
            is_eu_verified: false,
            profile_img_url: profile.profile_img_url,
            short_intro: '-',
            tier: activeButtons.price === pricingforTier2 ? "TIER.T2" : (activeButtons.price === pricingforTier3 ? "TIER.T3" : "TIER.T1"),
            username: profile?.username,
            yearOfXP: 0,
            avgRating: 0,
            numRatings: 0,
            no_of_sessions: 0,
            gender: null,
            unverified_domains: [],
            language: ["English"]
        }).then(() => {
            navigate(`/domain/${type}/${id}`, {
                state: {
                    tier: "TIER.T1",
                    profile: profile
                }
            })
        })

    }

    else if (activeButtons.price === pricingforTier2 || activeButtons.price === pricingforTier3) {
        navigate('/payment')
        await addDoc(collection(db, `${type}/${id}/Orders`), {
            SUCCESS: null,
            euId: type === explorer ? "euId" : id,
            orderAmount: 0,
        }).then(async (docRef) => {
            getPay(docRef, activeButtons).then(async (data) => {
                let parent = document.getElementById('drop_in_container');
                let cashfree;
                if (isProd) {
                    cashfree = new cashfreeProd.Cashfree();
                } else {
                    cashfree = new cashfreeSandbox.Cashfree();
                }
                cashfree.initialiseDropin(parent, {
                    orderToken: data.data.order_token,
                    components: ["card", "app", "upi", "order-details", "netbanking"],
                    onSuccess: async function cbs(pay) {
                        if (type === "Seeker") {
                            await setDoc(doc(db, experts, id), {
                                _tags: [],
                                current_profile: auth?.currentUser?.email,
                                email: auth?.currentUser?.email,
                                eu_id: auth?.currentUser?.uid,
                                eu_requested: [],
                                is_eu_verified: false,
                                profile_img_url: profile.profile_img_url,
                                short_intro: '-',
                                tier: activeButtons.price === pricingforTier2 ? "TIER.T2" : (activeButtons.price === pricingforTier3 ? "TIER.T3" : "TIER.T1"),
                                username: profile?.username,
                                yearOfXP: 0,
                                avgRating: 0,
                                numRatings: 0,
                                no_of_sessions: 0,
                                gender: null,
                                unverified_domains: [],
                                language: ["English"]
                            }).then(() => {
                                navigate(`/domain/${experts}/${id}`, {
                                    state: {
                                        tier: activeButtons.price === pricingforTier2 ? "TIER.T2" : "TIER.T3",
                                        profile: profile
                                    }
                                })
                            })
                        }
                        else if (type === experts) {
                            await updateDoc(doc(db, experts, id), {
                                is_eu_verified: false,
                                tier: activeButtons.price === pricingforTier2 ? "TIER.T2" : "TIER.T3"
                            }).then(() => {
                                navigate(`/domain/${experts}/${id}`, {
                                    state: {
                                        tier: activeButtons.price === pricingforTier2 ? "TIER.T2" : "TIER.T3",
                                        profile: profile
                                    }
                                })
                            })

                        }
                    },
                    onFailure: async function cbf(pay) {

                    }
                })

            })
        })


    }
}
export const seekerCards = async (navigate, types, id, type, getPay, isProd, cashfreeProd, cashfreeSandbox, data, profile) => {
    // if (data.price === 'FREE' && type === experts) {

    //     navigate(`/domain/${types}/${id}`, {
    //         state: {
    //             tier: 'TIER.T1',
    //         }
    //     })
    // }
    // else if (data.price === 'FREE' && type === explorer) {
    //     await setDoc(doc(db, experts, id), {
    //         _tags: [],
    //         current_profile: auth?.currentUser?.email,
    //         email: auth?.currentUser?.email,
    //         eu_id: auth?.currentUser?.uid,
    //         eu_requested: [],
    //         is_eu_verified: false,
    //         profile_img_url: profile?.profile_img_url,
    //         short_intro: '-',
    //         tier: data.price === pricingforTier2 ? "TIER.T2" : (data.price === pricingforTier3 ? "TIER.T3" : "TIER.T1"),
    //         username: profile?.username,
    //         yearOfXP: 0,
    //         avgRating: 0,
    //         numRatings: 0,
    //         no_of_sessions: 0,
    //         gender: null,
    //         unverified_domains: [],
    //         language: ["English"]
    //     }).then(() => {
    //         navigate(`/domain/${experts}/${id}`, {
    //             state: {
    //                 tier: "TIER.T1",
    //                 profile: profile
    //             }
    //         })
    //     })
    // }
    if ((data.price === pricingforTier2 || data.price === pricingforTier3)) {
        navigate('/payment')
        await addDoc(collection(db, `${type}/${id}/Orders`), {
            SUCCESS: null,
            euId: type === explorer ? "euId" : id,
            orderAmount: 0,
        }).then(async (docRef) => {
            getPay(docRef, data).then(async (data) => {
                let parent = document.getElementById('drop_in_container');
                let cashfree;
                if (isProd) {
                    cashfree = new cashfreeProd.Cashfree();
                } else {
                    cashfree = new cashfreeSandbox.Cashfree();
                }
                cashfree.initialiseDropin(parent, {
                    orderToken: data.data.order_token,
                    components: ["card", "app", "upi", "order-details", "netbanking"],
                    onSuccess: async function cbs(pay) {
                        if (type === "Seeker") {
                            await setDoc(doc(db, experts, id), {
                                _tags: [],
                                current_profile: auth?.currentUser?.email,
                                email: auth?.currentUser?.email,
                                eu_id: auth?.currentUser?.uid,
                                eu_requested: [],
                                is_eu_verified: false,
                                profile_img_url: profile.profile_img_url,
                                short_intro: '-',
                                tier: "TIER.T3",
                                username: profile?.username,
                                yearOfXP: 0,
                                avgRating: 0,
                                numRatings: 0,
                                no_of_sessions: 0,
                                gender: "",
                                unverified_domains: [],
                                language: ["English"]
                            }).then(() => {
                                navigate(`/domain/${experts}/${id}`, {
                                    state: {
                                        tier: "TIER.T3",
                                        profile: profile
                                    }
                                })
                            })
                        }
                        else if (type === experts) {
                            await updateDoc(doc(db, experts, id), {
                                is_eu_verified: false,
                                tier: "TIER.T3"
                            }).then(() => {
                                navigate(`/domain/${experts}/${id}`, {
                                    state: {
                                        tier: "TIER.T3",
                                        profile: profile
                                    }
                                })
                            })
                        }


                    },
                    onFailure: async function cbf(pay) {

                    }
                })

            })
        })
    }
}


export const addTierToDatabase = async (search, g, state, profile, type, navigate, transcript, snackbarStart, id) => {
    if (!search) {
        snackbarStart("error", "Please describe yourself")
    }
    else if (!g) {
        snackbarStart("error", "Please select gender")
    }
    else if (state?.tier === "TIER.T1" || profile?.tier === "TIER.T1") {
        await updateDoc(doc(db, experts, auth?.currentUser?.uid), {
            _tags: state?.dom?.length > 0 ? state?.dom : state?.tags,
            gender: g.gender,
            unverified_domains: [],
            short_intro: search
        }).then(() => {
            navigate('/home')
        })

    }
    else if ((state?.tier === 'TIER.T2' || profile.tier === "TIER.T2") || (state?.tier === "TIER.T3" || profile?.tier === "TIER.T3")) {
        await updateDoc(doc(db, type, auth?.currentUser?.uid), {
            short_intro: search,
            gender: g.gender
        }).then(() => {
            navigate(`/home`)
        }).catch((error => console.log(error)))
    }
    else {

    }
}

export async function paymentInstantExpert(pay, docRef, props, navigate) {
    await axios.get(`https://us-central1-oraculi-90497.cloudfunctions.net/getPayment/pay/?order_id=${pay.order.orderId}&cf_payment_id=${pay.transaction.transactionId}`).then(async (pio) => {
        await setDoc(doc(db, `${props.typeIns}/${auth?.currentUser?.uid}/Orders`, pay.order.orderId), {
            euId: props.getTypeUser.eu_id,
            from: moment().format("YYYY-MM-DD HH:mm:ss") + '.000',
            to: moment().format("YYYY-MM-DD HH:mm:ss") + '.000',
            orderAmount: pio?.data.payment_amount,
            paymentMode: pio?.data.payment_group,
            referenceId: pio?.data.cf_payment_id,
            seekerId: props.getTypeUser.eu_id,
            seeker_img: props.getTypeUser?.profile_img_url,
            txStatus: pio?.data?.payment_status,
            txTime: pio.data.payment_time,
            eu_img: "",
            timeslot_id: "Instant connect payment"
        }).then(async () => {
            await addDoc(collection(db, `${props.typeIns}/${auth?.currentUser?.uid}/InstantConnect`), {
                createdAt: moment().format("YYYY-MM-DD HH:mm:ss") + '.000',
                note: props.area ? props.area : "",
                order_id: pay.order.orderId,
                phone_number: props.phone ? props.phone : props.getTypeUser.phone_number,
                userId: auth?.currentUser?.uid,
                username: props.instantName ? props.instantName : props.getTypeUser.username
            }).then(() => {
                navigate('/instantPaymentSuccess')
            })
        })
    })
}


export async function paymentDetails(event, pay, type, id, profile, eudata, area, props) {
    await axios.get(`https://us-central1-oraculi-90497.cloudfunctions.net/getPayment/pay/?order_id=${pay.order.orderId}&cf_payment_id=${pay.transaction.transactionId}`).then(async (pio) => {
        await addDoc(collection(db, `${experts}/${id}/time_slots`), {
            StartTime: event?.StartTime,
            EndTime: new Date(new Date(event.StartTime).setMinutes(new Date(event.StartTime).getMinutes() + 30)),
            recurrenceId: event?.timeslot_id,
            status: "AWAITING",
            recurrenceRule: "",
            price: eudata?.tier === "TIER.T1" ? 0 : (eudata?.tier === "TIER.T2" ? pricingforTier2 : (eudata?.tier === "TIER.T3" ? props.euRatefor30?.price : "")),
            minss: 30,
            exception: arrayUnion()
        }).then(async (docRef) => {
            await setDoc(doc(db, `${type}/${auth?.currentUser?.uid}/Orders`, pay.order.orderId), {
                euId: id,
                from: moment(event?.StartTime).format("YYYY-MM-DD HH:mm:ss") + '.000',
                to: moment(event.StartTime).add(30, 'm').format("YYYY-MM-DD HH:mm:ss") + '.000',
                orderAmount: pio?.data.payment_amount,
                paymentMode: pio?.data.payment_group,
                referenceId: pio?.data.cf_payment_id,
                seekerId: auth?.currentUser?.uid,
                seeker_img: profile?.profile_img_url,
                txStatus: pio?.data?.payment_status,
                txTime: pio.data.payment_time,
                eu_img: eudata.profile_img_url,
                timeslot_id: docRef.id
            }).then(async () => {
                const docRefs = doc(db, `${type}/${auth.currentUser.uid}/Orders`, pay.order.orderId);
                const docSnap = await getDoc(docRefs);
                await setDoc(doc(db, `${experts}/${id}/Requests`, pay.order.orderId), {
                    createdAt: new Date(),
                    from: moment(event?.StartTime).format("YYYY-MM-DD HH:mm:ss") + '.000',
                    to: moment(event.StartTime).add(30, 'm').format("YYYY-MM-DD HH:mm:ss") + '.000',
                    reason: area,
                    receiver_id: id,
                    receiver_name: eudata.username,
                    sender_id: auth?.currentUser?.uid,
                    sender_name: profile?.username,
                    status: 'request sent.',
                    timeslot_id: docSnap.data().timeslot_id,
                    paid: true
                }).then(async () => {
                    const docRefss = doc(db, `${experts}/${id}/time_slots/${docSnap.data().timeslot_id}`);
                    const docSnaps = await getDoc(docRefss)
                    await updateDoc(doc(db, `${experts}/${id}/time_slots/${docSnaps.data().recurrenceId}`), {
                        exception: arrayUnion(event.StartTime)
                    })
                })
            })
        })
    })
}
export async function FailurePayment(event, type, docRef, id, eudata, profile, pay) {
    await setDoc(doc(db, `${type}/${auth.currentUser.uid}/Orders`, pay.order.orderId), {
        euId: id,
        eu_img: eudata?.profile_img_url,
        from: moment(event?.StartTime).format("YYYY-MM-DD HH:mm:ss") + '.000',
        to: moment(event?.EndTime).format("YYYY-MM-DD HH:mm:ss") + '.000',
        orderAmount: null,
        paymentMode: null,
        referenceId: null,
        seekerId: auth.currentUser.uid,
        seeker_img: profile?.profile_img_url,
        txStatus: 'CANCELLED',
        txTime: null
    })
}
// export async function Tier1Book(id, event, activeButtons, eudata, profile, navigate, area, type) {
//     await addDoc(collection(db, `${experts}/${id}/time_slots`), {
//         StartTime: event?.StartTime,
//         EndTime: activeButtons.time === 15 ? new Date(new Date(event.StartTime).setMinutes(new Date(event.StartTime).getMinutes() + 15)) : event?.EndTime,
//         recurrenceId: event?.id,
//         status: "AWAITING",
//         recurrenceRule: "",
//         price: 0,
//         minss: activeButtons?.time,
//         exception: arrayUnion()
//     }).then(async (docRef) => {
//         await addDoc(collection(db, `${type}/${auth.currentUser.uid}/Orders`), {
//             euId: id,
//             from: moment(event?.StartTime).format("YYYY-MM-DD HH:mm:ss") + '.000',
//             to: activeButtons.time === 15 ? moment(event.StartTime).add(15, 'm').format("YYYY-MM-DD HH:mm:ss") + '.000' : moment(event?.EndTime).format("YYYY-MM-DD HH:mm:ss") + '.000',
//             orderAmount: 0,
//             paymentMode: null,
//             referenceId: null,
//             seekerId: auth?.currentUser?.uid,
//             seeker_img: profile?.profile_img_url,
//             txStatus: "SUCCESS",
//             txTime: null,
//             eu_img: eudata.profile_img_url,
//             timeslot_id: docRef.id
//         }).then(async (docRef) => {
//             const docRefs = doc(db, `${type}/${auth.currentUser.uid}/Orders`, docRef.id);
//             const docSnap = await getDoc(docRefs);
//             await setDoc(doc(db, `${experts}/${id}/Requests`, docRef.id), {
//                 createdAt: Date.now(),
//                 from: moment(event?.StartTime).format("YYYY-MM-DD HH:mm:ss") + '.000',
//                 to: activeButtons.time === 15 ? moment(event.StartTime).add(15, 'm').format("YYYY-MM-DD HH:mm:ss") + '.000' : moment(event?.EndTime).format("YYYY-MM-DD HH:mm:ss") + '.000',
//                 reason: area,
//                 receiver_id: id,
//                 receiver_name: eudata.username,
//                 sender_id: auth?.currentUser?.uid,
//                 sender_name: profile?.username,
//                 status: 'request sent.',
//                 timeslot_id: docSnap.data().timeslot_id
//             }).then(async () => {
//                 const docRefss = doc(db, `${experts}/${id}/time_slots/${docSnap.data().timeslot_id}`);
//                 const docSnaps = await getDoc(docRefss)
//                 await updateDoc(doc(db, `${experts}/${id}/time_slots/${docSnaps.data().recurrenceId}`), {
//                     exception: arrayUnion(event.StartTime)
//                 }).then(() => {
//                     navigate('/RequestSuccess')
//                 })
//             })
//         })
//     })
// }

// export const checkoutpay = async (navigate, type, id, getPayment, cashfreeProd, cashfreeSandbox, isProd, event, eudata, profile, area, activeButtons, euRate, euRatefor30, rec) => {

//     navigate('/payment')
//     await addDoc(collection(db, `${type}/${auth?.currentUser?.uid}/Orders`), {
//         SUCCESS: null,
//         euId: type === explorer ? "euId" : id,
//         orderAmount: 0,

//     }).then(async (docRef) => {
//         getPayment(docRef, eudata, euRate, euRatefor30, activeButtons).then(async (data) => {
//             let parent = document.getElementById('drop_in_container');
//             let cashfree;
//             if (isProd) {
//                 cashfree = new cashfreeProd.Cashfree();
//             } else {
//                 cashfree = new cashfreeSandbox.Cashfree();
//             }
//             cashfree.initialiseDropin(parent, {
//                 orderToken: data.data.order_token,
//                 components: ["card", "app", "upi", "order-details", "netbanking"],
//                 onSuccess: async function cbs(pay) {
//                     await paymentDetails(event, pay, type, id, activeButtons, profile, eudata, area)
//                     navigate('/RequestSuccess')
//                 },
//                 onFailure: async function cbf(pay) {
//                     await FailurePayment(event, type, docRef, id, eudata, profile, pay)
//                 }
//             })
//         })
//     })
// }

export const orderBooking = async (props, navigate, getPayment, isProd, cashfreeProd, cashfreeSandbox) => {
    if (props.data.tier === 'TIER.T1') {
        await addDoc(collection(db, `${props.type}/${props?.notificationData?.sender_id}/Notifications`), {
            createdAt: new Date(),
            eu_id: props?.notificationData?.receiver_id,
            is_payment_done: false,
            is_recent: false,
            reason: props?.notificationData?.reason,
            request_id: props?.notificationData?.request_id,
            seeker_id: props?.notificationData?.sender_id,
            timeslot_id: props?.notificationData?.timeslot_id,
            type: "Type.Message"
        }).then(async () => {
            await updateDoc(doc(db, `${props.type}/${props?.notificationData?.sender_id}/Notifications/${props?.notificationData?.id}`), {
                is_payment_done: true
            }).then(async () => {
                await addDoc(collection(db, `${experts}/${props?.notificationData?.receiver_id}/Notifications`), {
                    createdAt: new Date(),
                    eu_id: props?.notificationData?.receiver_id,
                    is_payment_done: false,
                    is_recent: false,
                    reason: props?.notificationData?.reason,
                    request_id: props?.notificationData?.request_id,
                    seeker_id: props?.notificationData?.sender_id,
                    timeslot_id: props?.notificationData?.timeslot_id,
                    type: "Type.Message"
                }).then(async () => {
                    await setDoc(doc(db, `${props.type}/${props?.notificationData?.sender_id}/Orders/${props?.notificationData?.request_id}`), {
                        created_at: Date.now(),
                        euId: props?.notificationData?.receiver_id,
                        eu_img: "",
                        from: props?.notificationData?.from,
                        to: props?.notificationData?.to,
                        orderAmount: 0,
                        referenceId: "na",
                        paymentMode: "na",
                        seekerId: props?.notificationData?.sender_id,
                        timeslot_id: props?.notificationData?.timeslot_id,
                        txStatus: "SUCCESS",
                        txTime: "na"
                    }).then(async (docRef) => {
                        await addDoc(collection(db, `${experts}/${props?.notificationData?.receiver_id}/Sessions`), {
                            created_at: new Date(),
                            euId: props?.notificationData?.receiver_id,
                            eu_name: props?.notificationData?.receiver_name,
                            from: props?.notificationData?.from,
                            to: props?.notificationData?.to,
                            seekerId: props?.notificationData?.sender_id,
                            seeker_name: props?.notificationData?.sender_name,
                            session_status: "UPCOMING",
                            timeslot_id: props?.notificationData?.timeslot_id,
                            order_id: props?.notificationData?.request_id,
                            eu_tier: props.data.tier
                        }).then(async (docRef) => {
                            const docRefs = doc(db, `${experts}/${props?.notificationData?.receiver_id}/Sessions`, docRef.id);
                            const docSnap = await getDoc(docRefs);
                            await setDoc(doc(db, `${props.type}/${props?.notificationData?.sender_id}/Sessions`, docRef.id), {
                                created_at: new Date(),
                                euId: props?.notificationData?.receiver_id,
                                eu_name: props?.notificationData?.receiver_name,
                                from: props?.notificationData?.from,
                                to: props?.notificationData?.to,
                                seekerId: props?.notificationData?.sender_id,
                                seeker_name: props?.notificationData?.sender_name,
                                session_status: "UPCOMING",
                                timeslot_id: props?.notificationData?.timeslot_id,
                                order_id: docSnap.data().order_id,
                                eu_tier: props.data.tier
                            }).then(async () => {
                                await updateDoc(doc(db, `${experts}/${props?.notificationData?.receiver_id}/time_slots/${props?.notificationData?.timeslot_id}`), {
                                    status: "CONFIRMED"
                                }).then(() => {
                                    navigate('/sessionConfirmed')
                                })
                            })
                        })
                    })
                })
            })
        })
    }
    else {
        navigate('/payment')
        await setDoc(doc(db, `${props.type}/${props?.notificationData?.sender_id}/Orders/${props.notificationData.request_id}`), {
            SUCCESS: null,
            euId: props?.notificationData?.sender_id,
            orderAmount: 0,
        }).then(async () => {
            const docRef = doc(db, `${props.type}/${props?.notificationData?.sender_id}/Orders/${props?.notificationData.request_id}`);
            const docSnap = await getDoc(docRef)
            if (docSnap.exists()) {
                await axios.get(`https://us-central1-oraculi-90497.cloudfunctions.net/getOrder/order/?order_id=${props.notificationData.request_id}`).then((data) => {
                    if (data.data.code === "order_not_found") {
                        const payids = {
                            id: props.notificationData.request_id
                        }
                        getPayment(payids, props.data, props).then(async (data) => {
                            let parent = document.getElementById('drop_in_container');
                            let cashfree;
                            if (isProd) {
                                cashfree = new cashfreeProd.Cashfree();
                            } else {
                                cashfree = new cashfreeSandbox.Cashfree();
                            }
                            cashfree.initialiseDropin(parent, {
                                orderToken: data.data.order_token,
                                components: ["card", "app", "upi", "order-details", "netbanking"],
                                onSuccess: async function cbs(pay) {
                                    await axios.get(`https://us-central1-oraculi-90497.cloudfunctions.net/getPayment/pay/?order_id=${pay.order.orderId}&cf_payment_id=${pay.transaction.transactionId}`).then(async (pio) => {
                                        await addDoc(collection(db, `${props.type}/${props?.notificationData?.sender_id}/Notifications`), {
                                            createdAt: new Date(),
                                            eu_id: props?.notificationData?.receiver_id,
                                            is_payment_done: false,
                                            is_recent: false,
                                            reason: props?.notificationData?.reason,
                                            request_id: props?.notificationData?.request_id,
                                            seeker_id: props?.notificationData?.sender_id,
                                            timeslot_id: props?.notificationData?.timeslot_id,
                                            type: "Type.Message"
                                        }).then(async () => {
                                            await updateDoc(doc(db, `${props.type}/${props?.notificationData?.sender_id}/Notifications/${props?.notificationData?.id}`), {
                                                is_payment_done: true
                                            }).then(async () => {
                                                await addDoc(collection(db, `${experts}/${props?.notificationData?.receiver_id}/Notifications`), {
                                                    createdAt: new Date(),
                                                    eu_id: props?.notificationData?.receiver_id,
                                                    is_payment_done: false,
                                                    is_recent: false,
                                                    reason: props?.notificationData?.reason,
                                                    request_id: props?.notificationData?.request_id,
                                                    seeker_id: props?.notificationData?.sender_id,
                                                    timeslot_id: props?.notificationData?.timeslot_id,
                                                    type: "Type.Message"
                                                }).then(async () => {
                                                    await setDoc(doc(db, `${props.type}/${auth?.currentUser?.uid}/Orders`, pay.order.orderId), {
                                                        euId: props.notificationData.receiver_id,
                                                        from: props.notificationData.from,
                                                        to: props.notificationData.to,
                                                        orderAmount: pio?.data.payment_amount,
                                                        paymentMode: pio?.data.payment_group,
                                                        referenceId: pio?.data.cf_payment_id,
                                                        seekerId: auth?.currentUser?.uid,
                                                        // seeker_img: ?.profile_img_url,
                                                        txStatus: pio?.data?.payment_status,
                                                        txTime: pio.data.payment_time,
                                                        eu_img: props.data.profile_img_url,
                                                        timeslot_id: props.notificationData.timeslot_id
                                                    }).then(async (docRef) => {
                                                        await addDoc(collection(db, `${experts}/${props?.notificationData?.receiver_id}/Sessions`), {
                                                            created_at: new Date(),
                                                            euId: props?.notificationData?.receiver_id,
                                                            eu_name: props?.notificationData?.receiver_name,
                                                            from: props?.notificationData?.from,
                                                            to: props?.notificationData?.to,
                                                            seekerId: props?.notificationData?.sender_id,
                                                            seeker_name: props?.notificationData?.sender_name,
                                                            session_status: "UPCOMING",
                                                            timeslot_id: props?.notificationData?.timeslot_id,
                                                            order_id: pay.order.orderId
                                                        }).then(async (docRef) => {
                                                            const docRefs = doc(db, `${experts}/${props?.notificationData?.receiver_id}/Sessions`, docRef.id);
                                                            const docSnap = await getDoc(docRefs);
                                                            await setDoc(doc(db, `${props.type}/${props?.notificationData?.sender_id}/Sessions`, docRef.id), {
                                                                created_at: new Date(),
                                                                euId: props?.notificationData?.receiver_id,
                                                                eu_name: props?.notificationData?.receiver_name,
                                                                from: props?.notificationData?.from,
                                                                to: props?.notificationData?.to,
                                                                seekerId: props?.notificationData?.sender_id,
                                                                seeker_name: props?.notificationData?.sender_name,
                                                                session_status: "UPCOMING",
                                                                timeslot_id: props?.notificationData?.timeslot_id,
                                                                order_id: docSnap.data().order_id
                                                            }).then(async () => {
                                                                await updateDoc(doc(db, `${experts}/${props?.notificationData?.receiver_id}/time_slots/${props?.notificationData?.timeslot_id}`), {
                                                                    status: "CONFIRMED"
                                                                }).then(() => {
                                                                    navigate('/sessionConfirmed')
                                                                })
                                                            })
                                                        })
                                                    })


                                                })
                                            })
                                        })
                                    })

                                },
                                onFailure: async function cbf(pay) {

                                }
                            })
                        })
                    }
                    else {
                        let parent = document.getElementById('drop_in_container');
                        let cashfree;
                        if (isProd) {
                            cashfree = new cashfreeProd.Cashfree();
                        } else {
                            cashfree = new cashfreeSandbox.Cashfree();
                        }
                        cashfree.initialiseDropin(parent, {
                            orderToken: data.data.order_token,
                            components: ["card", "app", "upi", "order-details", "netbanking"],
                            onSuccess: async function cbs(pay) {
                                await axios.get(`https://us-central1-oraculi-90497.cloudfunctions.net/getPayment/pay/?order_id=${pay.order.orderId}&cf_payment_id=${pay.transaction.transactionId}`).then(async (pio) => {
                                    await addDoc(collection(db, `${props.type}/${props?.notificationData?.sender_id}/Notifications`), {
                                        createdAt: new Date(),
                                        eu_id: props?.notificationData?.receiver_id,
                                        is_payment_done: false,
                                        is_recent: false,
                                        reason: props?.notificationData?.reason,
                                        request_id: props?.notificationData?.request_id,
                                        seeker_id: props?.notificationData?.sender_id,
                                        timeslot_id: props?.notificationData?.timeslot_id,
                                        type: "Type.Message"
                                    }).then(async () => {
                                        await updateDoc(doc(db, `${props.type}/${props?.notificationData?.sender_id}/Notifications/${props?.notificationData?.id}`), {
                                            is_payment_done: true
                                        }).then(async () => {
                                            await addDoc(collection(db, `${experts}/${props?.notificationData?.receiver_id}/Notifications`), {
                                                createdAt: new Date(),
                                                eu_id: props?.notificationData?.receiver_id,
                                                is_payment_done: false,
                                                is_recent: false,
                                                reason: props?.notificationData?.reason,
                                                request_id: props?.notificationData?.request_id,
                                                seeker_id: props?.notificationData?.sender_id,
                                                timeslot_id: props?.notificationData?.timeslot_id,
                                                type: "Type.Message"
                                            }).then(async () => {
                                                await setDoc(doc(db, `${props.type}/${auth?.currentUser?.uid}/Orders`, pay.order.orderId), {
                                                    euId: props.notificationData.receiver_id,
                                                    from: props.notificationData.from,
                                                    to: props.notificationData.to,
                                                    orderAmount: pio?.data.payment_amount,
                                                    paymentMode: pio?.data.payment_group,
                                                    referenceId: pio?.data.cf_payment_id,
                                                    seekerId: auth?.currentUser?.uid,
                                                    // seeker_img: ?.profile_img_url,
                                                    txStatus: pio?.data?.payment_status,
                                                    txTime: pio.data.payment_time,
                                                    eu_img: props.data.profile_img_url,
                                                    timeslot_id: props.notificationData.timeslot_id
                                                }).then(async (docRef) => {
                                                    await addDoc(collection(db, `${experts}/${props?.notificationData?.receiver_id}/Sessions`), {
                                                        created_at: new Date(),
                                                        euId: props?.notificationData?.receiver_id,
                                                        eu_name: props?.notificationData?.receiver_name,
                                                        from: props?.notificationData?.from,
                                                        to: props?.notificationData?.to,
                                                        seekerId: props?.notificationData?.sender_id,
                                                        seeker_name: props?.notificationData?.sender_name,
                                                        session_status: "UPCOMING",
                                                        timeslot_id: props?.notificationData?.timeslot_id,
                                                        order_id: pay.order.orderId
                                                    }).then(async (docRef) => {
                                                        const docRefs = doc(db, `${experts}/${props?.notificationData?.receiver_id}/Sessions`, docRef.id);
                                                        const docSnap = await getDoc(docRefs);
                                                        await setDoc(doc(db, `${props.type}/${props?.notificationData?.sender_id}/Sessions`, docRef.id), {
                                                            created_at: new Date(),
                                                            euId: props?.notificationData?.receiver_id,
                                                            eu_name: props?.notificationData?.receiver_name,
                                                            from: props?.notificationData?.from,
                                                            to: props?.notificationData?.to,
                                                            seekerId: props?.notificationData?.sender_id,
                                                            seeker_name: props?.notificationData?.sender_name,
                                                            session_status: "UPCOMING",
                                                            timeslot_id: props?.notificationData?.timeslot_id,
                                                            order_id: docSnap.data().order_id
                                                        }).then(async () => {
                                                            await updateDoc(doc(db, `${experts}/${props?.notificationData?.receiver_id}/time_slots/${props?.notificationData?.timeslot_id}`), {
                                                                status: "CONFIRMED"
                                                            }).then(() => {
                                                                navigate('/sessionConfirmed')
                                                            })
                                                        })
                                                    })
                                                })


                                            })
                                        })
                                    })
                                })

                            },
                            onFailure: async function cbf(pay) {

                            }
                        })
                    }

                })
            }
            else {

            }


        })
    }
}

export const sendingRequestsToAllotedTIme = async (props, navigate, getPayment, cashfreeProd, cashfreeSandbox, isProd) => {
    if (props.eudata.tier === "TIER.T1") {
        await addDoc(collection(db, `${experts}/${props.id}/time_slots`), {
            StartTime: props.timedata.StartTime,
            EndTime: props.timedata.EndTime,
            recurrenceId: props.timedata.timeslot_id,
            status: "AWAITING",
            recurrenceRule: "",
            price: props?.eudata?.tier === "TIER.T1" ? 0 : (props?.eudata?.tier === "TIER.T2" ? pricingforTier2 : (props?.eudata?.tier === "TIER.T3" ? props?.euRatefor30?.price : "")),
            minss: 30,
            exception: arrayUnion()
        }).then(async (docRef) => {
            await addDoc(collection(db, `${props.type}/${auth.currentUser.uid}/Orders`), {
                euId: props.id,
                from: moment(props.timedata.StartTime).format("YYYY-MM-DD HH:mm:ss") + '.000',
                to: moment(props.timedata.StartTime).add(30, 'm').format("YYYY-MM-DD HH:mm:ss") + '.000',
                orderAmount: 0,
                paymentMode: null,
                referenceId: null,
                seekerId: auth?.currentUser?.uid,
                seeker_img: props.profile?.profile_img_url,
                txStatus: "SUCCESS",
                txTime: null,
                eu_img: props.eudata.profile_img_url,
                timeslot_id: docRef.id
            }).then(async (docRef) => {
                const docRefs = doc(db, `${props.type}/${auth.currentUser.uid}/Orders`, docRef.id);
                const docSnap = await getDoc(docRefs);
                await setDoc(doc(db, `${experts}/${props.id}/Requests`, docRef.id), {
                    createdAt: new Date(),
                    from: moment(props.timedata.StartTime).format("YYYY-MM-DD HH:mm:ss") + '.000',
                    to: moment(props.timedata.StartTime).add(30, 'm').format("YYYY-MM-DD HH:mm:ss") + '.000',
                    reason: props.area,
                    receiver_id: props.id,
                    receiver_name: props.eudata.username,
                    sender_id: auth?.currentUser?.uid,
                    sender_name: props.profile?.username,
                    paid: true,
                    status: 'request sent.',
                    timeslot_id: docSnap.data().timeslot_id
                }).then(async () => {
                    const docRefss = doc(db, `${experts}/${props.id}/time_slots/${docSnap.data().timeslot_id}`);
                    const docSnaps = await getDoc(docRefss)
                    await updateDoc(doc(db, `${experts}/${props.id}/time_slots/${docSnaps.data().recurrenceId}`), {
                        exception: arrayUnion(props.timedata.StartTime)
                    }).then(() => {
                        navigate('/RequestSuccess')
                    })
                })
            })
        })
    }
    else {
        navigate('/payment')
        await addDoc(collection(db, `${props.type}/${auth?.currentUser?.uid}/Orders`), {
            SUCCESS: null,
            euId: props.type === explorer ? "euId" : props.id,
            orderAmount: 0,

        }).then(async (docRef) => {
            getPayment(docRef, props.eudata, props).then(async (data) => {
                let parent = document.getElementById('drop_in_container');
                let cashfree;
                if (isProd) {
                    cashfree = new cashfreeProd.Cashfree();
                } else {
                    cashfree = new cashfreeSandbox.Cashfree();
                }
                cashfree.initialiseDropin(parent, {
                    orderToken: data.data.order_token,
                    components: ["card", "app", "upi", "order-details", "netbanking"],
                    onSuccess: async function cbs(pay) {
                        await paymentDetails(props.timedata, pay, props.type, props.id, props.profile, props.eudata, props.area, props)
                        navigate('/RequestSuccess')
                    },
                    onFailure: async function cbf(pay) {
                        await FailurePayment(props.timedata, props.type, docRef, props.id, props.eudata, props.profile, pay)
                    }
                })
            })
        })
    }
}


export const sendingRequests = async (props, navigate) => {
    await addDoc(collection(db, `${experts}/${props.id}/time_slots`), {
        StartTime: props.getDate,
        EndTime: new Date(new Date(props.getDate).setMinutes(new Date(props.getDate).getMinutes() + 30)),
        recurrenceId: "",
        status: "AWAITING",
        recurrenceRule: "",
        price: props?.eudata?.tier === "TIER.T1" ? 0 : (props?.eudata?.tier === "TIER.T2" ? pricingforTier2 : (props?.eudata?.tier === "TIER.T3" ? props?.euRatefor30?.price : "")),
        minss: 30,
        exception: arrayUnion()
    }).then(async (docRef) => {
        await addDoc(collection(db, `${experts}/${props.id}/Requests`), {
            createdAt: new Date(),
            from: moment(props.getDate).format("YYYY-MM-DD HH:mm:ss") + '.000',
            to: moment(props.getDate).add(30, 'm').format("YYYY-MM-DD HH:mm:ss") + '.000',
            reason: props.area ? props.area : "",
            receiver_id: props.id,
            receiver_name: props.eudata.username,
            sender_id: auth?.currentUser?.uid,
            sender_name: props.profile?.username,
            status: 'request sent.',
            timeslot_id: docRef.id,
            paid: false
        }).then(() => {
            navigate('/RequestSuccess')
        })
    })
}

export const acceptRequestsIfPaid = async (data, profile, getRequests, setType) => {
    const docRef = await getDoc(doc(db, experts, data.sender_id))
    if (docRef.exists()) {
        await updateDoc(doc(db, `${experts}/${data?.receiver_id}/Requests/${data?.id}`), {
            status: "Accepted"
        }).then(async () => {
            await addDoc(collection(db, `${experts}/${data?.sender_id}/Notifications`), {
                createdAt: new Date(),
                eu_id: data?.receiver_id,
                is_payment_done: true,
                is_recent: true,
                reason: data.reason,
                request_id: data?.id,
                seeker_id: data?.sender_id,
                timeslot_id: data?.timeslot_id,
                type: "Type.Message"
            }).then(async () => {
                await addDoc(collection(db, `${experts}/${data?.receiver_id}/Notifications`), {
                    createdAt: new Date(),
                    eu_id: data?.receiver_id,
                    is_payment_done: true,
                    is_recent: true,
                    reason: data.reason,
                    request_id: data?.id,
                    seeker_id: data?.sender_id,
                    timeslot_id: data?.timeslot_id,
                    type: "Type.Message"
                }).then(async () => {
                    await addDoc(collection(db, `${experts}/${data?.receiver_id}/Sessions`), {
                        created_at: new Date(),
                        euId: data?.receiver_id,
                        eu_name: data?.receiver_name,
                        eu_tier: profile?.tier,
                        from: data?.from,
                        to: data?.to,
                        seekerId: data?.sender_id,
                        seeker_name: data.sender_name,
                        session_status: "UPCOMING",
                        timeslot_id: data?.timeslot_id,
                        order_id: data?.id
                    }).then(async (docRef) => {
                        await setDoc(doc(db, `${experts}/${data?.sender_id}/Sessions`, docRef.id), {
                            created_at: new Date(),
                            euId: data.receiver_id,
                            eu_name: data.receiver_name,
                            eu_tier: profile?.tier,
                            from: data.from,
                            to: data.to,
                            seekerId: data.sender_id,
                            seeker_name: data.sender_name,
                            session_status: "UPCOMING",
                            timeslot_id: data?.timeslot_id,
                            order_id: data?.id
                        }).then(async () => {
                            await updateDoc(doc(db, `${experts}/${auth?.currentUser?.uid}/time_slots/${data?.timeslot_id}`), {
                                status: "CONFIRMED"
                            }).then(() => {
                                getRequests()
                            })
                        })
                    })
                })
            })
        })
    }
    else {
        setType(explorer)
        const docSnap = await getDoc(doc(db, explorer, data.sender_id))
        if (docSnap.exists()) {
            await updateDoc(doc(db, `${experts}/${data?.receiver_id}/Requests/${data?.id}`), {
                status: "Accepted"
            }).then(async () => {
                await addDoc(collection(db, `${explorer}/${data?.sender_id}/Notifications`), {
                    createdAt: new Date(),
                    eu_id: data?.receiver_id,
                    is_payment_done: true,
                    is_recent: true,
                    reason: data.reason,
                    request_id: data?.id,
                    seeker_id: data?.sender_id,
                    timeslot_id: data?.timeslot_id,
                    type: "Type.Message"
                }).then(async () => {
                    await addDoc(collection(db, `${experts}/${data?.receiver_id}/Notifications`), {
                        createdAt: new Date(),
                        eu_id: data?.receiver_id,
                        is_payment_done: true,
                        is_recent: true,
                        reason: data.reason,
                        request_id: data?.id,
                        seeker_id: data?.sender_id,
                        timeslot_id: data?.timeslot_id,
                        type: "Type.Message"
                    }).then(async () => {
                        await addDoc(collection(db, `${experts}/${data?.receiver_id}/Sessions`), {
                            created_at: new Date(),
                            euId: data?.receiver_id,
                            eu_name: data?.receiver_name,
                            eu_tier: profile?.tier,
                            from: data?.from,
                            to: data?.to,
                            seekerId: data?.sender_id,
                            seeker_name: data.sender_name,
                            session_status: "UPCOMING",
                            timeslot_id: data?.timeslot_id,
                            order_id: data?.id
                        }).then(async (docRef) => {
                            await setDoc(doc(db, `${explorer}/${data?.sender_id}/Sessions`, docRef.id), {
                                created_at: new Date(),
                                euId: data.receiver_id,
                                eu_name: data.receiver_name,
                                eu_tier: profile?.tier,
                                from: data.from,
                                to: data.to,
                                seekerId: data.sender_id,
                                seeker_name: data.sender_name,
                                session_status: "UPCOMING",
                                timeslot_id: data?.timeslot_id,
                                order_id: data?.id
                            }).then(async () => {
                                await updateDoc(doc(db, `${experts}/${auth?.currentUser?.uid}/time_slots/${data?.timeslot_id}`), {
                                    status: "CONFIRMED"
                                }).then(() => {
                                    getRequests()
                                })
                            })
                        })
                    })
                })
            })
        }
    }
}




export const rejectingReqifnotpaid = async (data, area, setOpen, getRequests) => {
    const docRef = await getDoc(doc(db, experts, data.sender_id))
    if (docRef.exists()) {
        await updateDoc(doc(db, `${experts}/${data?.receiver_id}/Requests/${data?.id}`), {
            status: 'rejected',
            reject_reason: area
        }).then(async () => {
            await updateDoc(doc(db, `${experts}/${data?.receiver_id}/time_slots/${data?.timeslot_id}`), {
                status: 'REJECTED'
            }).then(async () => {
                await addDoc(collection(db, `${experts}/${data?.sender_id}/Notifications`), {
                    createdAt: new Date(),
                    eu_id: data?.receiver_id,
                    is_payment_done: false,
                    is_recent: true,
                    reason: data.reason,
                    request_id: data?.id,
                    seeker_id: data?.sender_id,
                    timeslot_id: data?.timeslot_id,
                    type: "Type.RejectedRequest",
                }).then(() => {
                    setOpen(false)
                    getRequests()
                })
            })
        })
    }
    else {
        await updateDoc(doc(db, `${experts}/${data?.receiver_id}/Requests/${data?.id}`), {
            status: 'rejected',
            reject_reason: area
        }).then(async () => {
            await updateDoc(doc(db, `${experts}/${data?.receiver_id}/time_slots/${data?.timeslot_id}`), {
                status: 'REJECTED'
            }).then(async () => {
                await addDoc(collection(db, `${explorer}/${data?.sender_id}/Notifications`), {
                    createdAt: new Date(),
                    eu_id: data?.receiver_id,
                    is_payment_done: false,
                    is_recent: true,
                    reason: data.reason,
                    request_id: data?.id,
                    seeker_id: data?.sender_id,
                    timeslot_id: data?.timeslot_id,
                    type: "Type.RejectedRequest"
                }).then(() => {
                    setOpen(false)
                    getRequests()
                })
            })
        })
    }
}


export const ratingEU = async (state, area, senderData, navigate, type) => {
    if (auth.currentUser.uid === state?.data.euId) {
        const docRef = doc(db, `${state?.type}/${state?.data.seekerId}/ratings`, state.data.id)
        const sfD = doc(db, state?.type, state?.data.seekerId)
        await setDoc(doc(db, `${state?.type}/${state?.data?.seekerId}/ratings`, state?.data?.id), {
            feedback: area,
            img_url: null,
            rating: parseInt(state?.star),
            seeker_name: state?.data?.eu_name
        }).then(async () => {
            const numberRate = await runTransaction(db, async (transaction) => {
                const sfDoc = await transaction.get(sfD)
                if (sfDoc.exists()) {
                    const prevRating = senderData?.numRatings + 1
                    const newRatings = ((senderData?.numRatings * senderData?.avgRating) + parseInt(state?.star)) / prevRating
                    transaction.update(sfD, {
                        numRatings: prevRating,
                        avgRating: newRatings
                    })

                }
            }).then(() => {
                navigate('/reviews', {
                    state: {
                        details: state?.data,
                        v: state.v,
                        type: state.type,
                        type2: state.type2
                    }
                })
            })
        })
    }
    else {
        const docRef = doc(db, `${state?.type2}/${state?.data.euId}/ratings`, state.data.id)
        const sfD = doc(db, state?.type2, state?.data.euId)
        await setDoc(doc(db, `${state?.type2}/${state?.data?.euId}/ratings`, state?.data?.id), {
            feedback: area,
            img_url: null,
            rating: parseInt(state?.star),
            seeker_name: state?.data?.seeker_name
        }).then(async () => {
            const numberRate = await runTransaction(db, async (transaction) => {
                const sfDoc = await transaction.get(sfD)
                if (sfDoc.exists()) {
                    const prevRating = senderData?.numRatings + 1
                    const newRatings = ((senderData?.numRatings * senderData?.avgRating) + parseInt(state?.star)) / prevRating
                    transaction.update(sfD, {
                        numRatings: prevRating,
                        avgRating: newRatings
                    })

                }
            }).then(() => {
                navigate('/reviews', {
                    state: {
                        details: state?.data,
                        v: state.v,
                        type: state.type,
                        type2: state.type2
                    }
                })
            })
        })
    }

}


export const ReviewEu = async (e, state, tick, tick2, tick3, tick4) => {
    if (auth.currentUser.uid === state.details.euId) {
        if (e.activeIndex === 0) {

            await updateDoc(doc(db, `${state.type}/${state?.details?.seekerId}/ratings/${state?.details.id}`), {
                [`Did you get what you were looking for?`]: tick === true ? "Yes" : "No"
            })
        }
        if (e.activeIndex === 1) {

            await updateDoc(doc(db, `${state.type}/${state?.details?.seekerId}/ratings/${state?.details.id}`), {
                [`Was ${state?.details?.eu_name} easy to understand`]: tick2 === true ? "Yes" : "No"
            })
        }
        if (e.activeIndex === 2) {

            await updateDoc(doc(db, `${state.type}/${state?.details?.seekerId}/ratings/${state?.details.id}`), {
                [`Did ${state?.details?.eu_name} understand your problem`]: tick3 === true ? "Yes" : "No"
            })
        }
        if (e.activeIndex === 3) {

            await updateDoc(doc(db, `${state.type}/${state?.details?.seekerId}/ratings/${state?.details.id}`), {
                [`Was ${state?.details?.eu_name} polite and courteous`]: tick4 === true ? "Yes" : "No"
            })
        }
    }
    else {
        if (e.activeIndex === 0) {

            await updateDoc(doc(db, `${state.type2}/${state?.details?.euId}/ratings/${state?.details.id}`), {
                [`Did you get what you were looking for?`]: tick === true ? "Yes" : "No"
            })
        }
        if (e.activeIndex === 1) {

            await updateDoc(doc(db, `${state.type2}/${state?.details?.euId}/ratings/${state?.details.id}`), {
                [`Was ${state?.details?.seeker_name} easy to understand`]: tick2 === true ? "Yes" : "No"
            })
        }
        if (e.activeIndex === 2) {

            await updateDoc(doc(db, `${state.type2}/${state?.details?.euId}/ratings/${state?.details.id}`), {
                [`Did ${state?.details?.seeker_name} understand your problem`]: tick3 === true ? "Yes" : "No"
            })
        }
        if (e.activeIndex === 3) {

            await updateDoc(doc(db, `${state.type2}/${state?.details?.euId}/ratings/${state?.details.id}`), {
                [`Was ${state?.details?.seeker_name} polite and courteous`]: tick4 === true ? "Yes" : "No"
            })
        }
    }



}


export async function selectDomain(check, state, profile, navigate, types, dataDom,) {
    if (((state?.tier === 'TIER.T2' || profile?.tier === 'TIER.T2')) || ((state?.tier === "TIER.T3" || profile?.tier === "TIER.T3"))) {
        navigate(`/verification/${types}/${auth.currentUser?.uid}`, {
            state: {
                tier: state?.tier ? state?.tier : "",
                tags: check,
                dom: dataDom,
                status: 'reupload',
                profileDomains: types === "Seeker" ? check : profile?._tags.concat(check)
            }
        })
    }
    else {
        navigate(`/introduction/${types}/${auth.currentUser.uid}`, {
            state: {
                tier: state?.tier,
                dom: dataDom,
                tags: check,
            }
        })
    }


}

export async function PreviousDomains(e, check, data, setcheck, profile, setDataDom, checks) {
    if (e.target.checked === false) {
        check?.pop(data)
    }
    if (e.target.checked === true) {
        check?.push(data)
    }
    setcheck([...check])
    // if (profile?._tags?.length > 0) {
    //     const a = profile?._tags.concat(check)
    //     setDataDom(a)
    // }
    if (profile.unverified?.length > 0) {
        const b = profile?.unverified.concat(check)
        setDataDom(b)
    }



}

export async function removeDomains(value, type, data, getProfileById, setVerifyProfile, getUserType, setType, id, dataDom, setDataDom, check, setcheck) {
    if (value?._tags?.length > 0) {
        await updateDoc(doc(db, type, auth?.currentUser.uid), {
            _tags: arrayRemove(data)
        }).then(async () => {
            await getProfileById().then(async () => {
                setVerifyProfile(await getUserType(setType, id))
            })
        })
    }
    const r = dataDom.filter(el => el !== data)
    setDataDom(r)
    const bb = check.filter(el => el !== data)
    setcheck(bb)
}

export const verifyDocs = async (e, photo, setProgresspercent, imagePaths, serverTimestamp, state, profile, setOpen, pro, progresspercent) => {
    e.preventDefault()
    photo.map(async (img, index) => {
        {
            const fileRef = ref(storage, `files/${auth?.currentUser?.uid}/` + img.file.name)
            const uploadTask = uploadBytesResumable(fileRef, img.file)
            uploadTask.on('state_changed', (snapshot) => {
                const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                setProgresspercent(progress);

            }, (error) => {
                console.log(error.message)
            }, async () => {
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                    imagePaths.push(downloadURL)
                }).then(async () => {
                    await setDoc(doc(db, "VerifyReq", auth?.currentUser?.uid), {
                        createdAt: Date.now(),
                        domain_expertise: profile.unverified_domains?.concat(state.tags),
                        files: imagePaths,
                        status: "pending",
                        username: profile?.username,
                        tier: state?.tier === "TIER.T1" ? "TIER.T2" : (state?.tier || profile?.tier),
                        rejected: [],
                        verified: [],
                    }).then(async () => {
                        await updateDoc(doc(db, experts, auth?.currentUser?.uid), {
                            unverified_domains: profile.unverified_domains?.concat(state.tags)
                        }).then(() => {
                            setOpen(true)
                        })
                    })
                })
            })
        }

    })
}

export const insertLinkData = async (insertlink, state, profile, setOpen) => {
    await setDoc(doc(db, "VerifyReq", auth?.currentUser?.uid), {
        createdAt: Date.now(),
        domain_expertise: profile.unverified_domains?.concat(state.tags),
        files: arrayUnion(insertlink),
        status: "pending",
        username: profile?.username,
        tier: state?.tier === "TIER.T1" ? "TIER.T2" : (state?.tier || profile?.tier),
        rejected: [],
        verified: [],
    }).then(async () => {
        await updateDoc(doc(db, experts, auth?.currentUser?.uid), {
            unverified_domains: profile.unverified_domains?.concat(state?.tags)
        }).then(() => {
            setOpen(true)
        })
    })
}
export async function cancelBook(props, data, setSecond) {
    const a = new Date(props.canceldata.from)
    await axios.get(`https://us-central1-oraculi-90497.cloudfunctions.net/getOrder/order/?order_id=${props.canceldata.order_id}`).then(async (orderdatas) => {
        await axios.get(`https://us-central1-oraculi-90497.cloudfunctions.net/createRefund/refund/${props.canceldata.order_id}/${props.canceldata.order_id}/${orderdatas.data.order_amount}`).then(async (d) => {
            await deleteDoc(doc(db, `${experts}/${props.canceldata.euId}/time_slots/${props.canceldata.timeslot_id}`)).then(async () => {
                await updateDoc(doc(db, `${experts}/${props.canceldata.euId}/time_slots/${data.recurrenceId}`), {
                    exception: arrayRemove(a)
                }).then(async () => {
                    await deleteDoc(doc(db, `${experts}/${props.canceldata.euId}/Sessions/${props?.canceldata?.id}`)).then(async () => {
                        await updateDoc(doc(db, `${explorer}/${props.canceldata.seekerId}/Sessions/${props?.canceldata?.id}`), {
                            session_status: "CANCELLED"
                        })
                    }).then(async () => {
                        await updateDoc(doc(db, `${experts}/${props.canceldata.seekerId}/Sessions/${props?.canceldata?.id}`), {
                            session_status: "CANCELLED"
                        })
                    }).then(() => {
                        setSecond(true)
                        props.setOpen(false)
                    })
                })
            })
        })
    })


}

export async function profilepopup(setOpen) {
    const docSnap = await getDoc(doc(db, "GuideSteps", auth?.currentUser?.uid))
    if (!docSnap.exists()) {
        await setDoc(doc(db, "GuideSteps", auth?.currentUser?.uid), {
            domain_steps: false,
            edit_profile_steps: false,
            profile_card_steps: false
        }).then(async () => {
            setOpen(true)
            await updateDoc(doc(db, "GuideSteps", auth?.currentUser?.uid), {
                profile_card_steps: true
            })
        })

    }
    else if (docSnap.data().profile_card_steps === false) {
        setOpen(true)
        await updateDoc(doc(db, "GuideSteps", auth?.currentUser?.uid), {
            profile_card_steps: true
        })

    }
    else {

    }
}

export async function editpopup(setSecond, setOpen) {
    const docSnap = await getDoc(doc(db, "GuideSteps", auth?.currentUser?.uid))
    if (!docSnap.exists()) {
        await setDoc(doc(db, "GuideSteps", auth?.currentUser?.uid), {
            domain_steps: false,
            edit_profile_steps: false,
            profile_card_steps: false
        }).then(async () => {
            setSecond(true)
            await updateDoc(doc(db, "GuideSteps", auth?.currentUser?.uid), {
                edit_profile_steps: true
            })
        })
    }
    else if (docSnap.data().edit_profile_steps === false) {
        setSecond(true)
        await updateDoc(doc(db, "GuideSteps", auth?.currentUser?.uid), {
            edit_profile_steps: true
        })

    }
    else {
        setOpen(true)
    }
}
export async function newDomPopup(value, setOpen, types) {
    if ((value?.tier === "TIER.T1") || (value?.tier === "TIER.T2") || (value?.tier === "TIER.T3") || (types === "Seeker")) {
        const docSnap = await getDoc(doc(db, "GuideSteps", auth?.currentUser?.uid))
        if (!docSnap.exists()) {
            await setDoc(doc(db, "GuideSteps", auth?.currentUser?.uid), {
                domain_steps: false,
                edit_profile_steps: false,
                profile_card_steps: false
            }).then(async () => {
                setOpen(true)
                await updateDoc(doc(db, "GuideSteps", auth?.currentUser?.uid), {
                    domain_steps: true
                })
            })

        }
        else if (docSnap.data().domain_steps === false) {
            setOpen(true)
            await updateDoc(doc(db, "GuideSteps", auth?.currentUser?.uid), {
                domain_steps: true
            })
        }
        else {

        }
    }
}


export async function getMonetisationRates(id) {
    const docRef = doc(db, `${experts}/${id}/Pricing/p0`);
    const docSnap = await getDoc(docRef);
    return docSnap.data()

}

export async function getMonetisationRatesfo30min(id) {
    const docRef = doc(db, `${experts}/${id}/Pricing/p1`);
    const docSnap = await getDoc(docRef);
    return docSnap.data()

}

export async function checkUser(result, navigate, euset) {
    if (euset === "Explorers") {
        const docRef = doc(db, explorer, auth?.currentUser?.uid);
        const docSnap = await getDoc(docRef);
        if (docSnap.data().username === "") {
            navigate(`/name/${explorer}/${auth?.currentUser?.uid}`)
        }
        else if (!docSnap.data().Age) {
            navigate(`/age/${explorer}/${auth?.currentUser?.uid}`)
        }
        else if (!docSnap.data().State) {
            navigate(`/state/${explorer}/${auth?.currentUser?.uid}`)
        }
        else if (!docSnap.data().topic) {
            navigate(`/topics/${explorer}/${auth?.currentUser?.uid}`)
        }
        else {
            navigate('/home')
        }

    }
    else if (euset === "Experts") {
        const docRef = doc(db, experts, auth?.currentUser?.uid);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            if (docSnap.data().tier === "TIER.T1") {
                if (docSnap.data().tier === "TIER.T1" && docSnap.data()?._tags.length === 0) {
                    navigate(`/domain/${experts}/${auth.currentUser?.uid}`, {
                        state: {
                            tier: docSnap.data().tier
                        }
                    })
                }
                else {
                    navigate('/home')
                }
            }
            else if (docSnap.data().tier === "TIER.T2" || docSnap.data().tier === "TIER.T3") {
                const docRef = doc(db, 'VerifyReq', auth.currentUser?.uid);
                const docSnaps = await getDoc(docRef);
                if (docSnaps.exists()) {
                    if (docSnaps.data().domain_expertise?.length === 0) {
                        navigate(`/domain/${experts}/${auth.currentUser?.uid}`, {
                            state: {
                                tier: docSnap.data().tier
                            }
                        })
                    }
                    else {
                        const docRef = doc(db, 'BankDetails', auth.currentUser?.uid);
                        const docbank = await getDoc(docRef);
                        if (docbank.exists()) {
                            navigate('/home')
                        }
                        else {
                            navigate(`/paymentDetails/${auth.currentUser?.uid}`, {
                                state: {
                                    types: experts
                                }
                            })
                        }
                    }
                }
                else {
                    navigate(`/domain/${experts}/${auth.currentUser?.uid}`, {
                        state: {
                            tier: docSnap.data().tier
                        }
                    })
                }
            }
        }
        else {
            // navigate(`/newUser/${auth?.currentUser?.uid}`)
            navigate('/home')
        }
    }



    // if (docSnap.exists()) {
    //     if (docSnap.data().tier === "TIER.T1") {
    //         if (docSnap.data().tier === "TIER.T1" && docSnap?.data()?._tags.length === 0) {
    //             navigate(`/domain/${experts}/${result?.user?.uid}`, {
    //                 state: {
    //                     tier: "registration"
    //                 }
    //             })
    //         }
    //         else {
    //             navigate('/home')
    //         }
    //     }
    //     else if (docSnap.data().tier === "TIER.T2" || docSnap.data().tier === "TIER.T3") {
    //         const docRef = doc(db, 'VerifyReq', result.user.uid);
    //         const docSnaps = await getDoc(docRef);
    //         if (docSnaps.exists()) {
    //             if (docSnaps.data().domain_expertise?.length === 0) {
    //                 navigate(`/domain/${experts}/${result?.user?.uid}`, {
    //                     state: {
    //                         tier: "registration"
    //                     }
    //                 })
    //             }
    //             else {
    //                 const docRef = doc(db, 'BankDetails', result.user.uid);
    //                 const docbank = await getDoc(docRef);
    //                 if (docbank.exists()) {
    //                     navigate('/home')
    //                 }
    //                 else {
    //                     navigate(`/paymentDetails/${result?.user?.uid}`, {
    //                         state: {
    //                             types: experts
    //                         }
    //                     })
    //                 }
    //             }
    //         }
    //         else {
    //             navigate(`/domain/${experts}/${result?.user?.uid}`, {
    //                 state: {
    //                     tier: "registration"
    //                 }
    //             })
    //         }
    //     }
    // }
    // else {
    //     navigate(`/newUser/${auth?.currentUser?.uid}`)
    // }
}
export const checksComplete = async (navigate, type) => {
    if (type === explorer) {
        const docRef = doc(db, explorer, auth?.currentUser?.uid);
        const docSnap = await getDoc(docRef);
        if (docSnap.data().phone_number === "") {

            navigate(`/phone_number/${type}/${auth?.currentUser?.uid}`)
        }
        else if (docSnap.data().pronouns === "") {
            navigate(`/preferred/${type}/${auth?.currentUser?.uid}`)
        }
        else if (docSnap.data().username === "") {
            navigate(`/name/${type}/${auth?.currentUser?.uid}`)
        }
        else if (!docSnap.data().age) {
            navigate(`/age/${type}/${auth?.currentUser?.uid}`)
        }
        else if (!docSnap.data().state_address) {
            navigate(`/state/${type}/${auth?.currentUser?.uid}`)
        }
        else if (!docSnap.data().topic) {
            navigate(`/topics/${type}/${auth?.currentUser?.uid}`)
        }
        else {
            navigate('/home')
        }

    }
    else if (type === experts) {
        const docRef = doc(db, experts, auth?.currentUser?.uid);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            // if(docSnap.data().username==="")
            // {
            //     navigate(`/name/${type}/${auth?.currentUser?.uid}`)
            // }
            if (docSnap.data().tier === "TIER.T1") {
                if (docSnap.data().tier === "TIER.T1" && docSnap.data()?._tags.length === 0) {
                    navigate(`/domain/${experts}/${auth.currentUser?.uid}`, {
                        state: {
                            tier: docSnap.data().tier
                        }
                    })
                }
                else {
                    navigate('/home')
                }
            }
            else if (docSnap.data().tier === "TIER.T2" || docSnap.data().tier === "TIER.T3") {
                const docRef = doc(db, 'VerifyReq', auth.currentUser?.uid);
                const docSnaps = await getDoc(docRef);
                if (docSnaps.exists()) {
                    if (docSnaps.data().domain_expertise?.length === 0) {
                        navigate(`/domain/${experts}/${auth.currentUser?.uid}`, {
                            state: {
                                tier: docSnap.data().tier
                            }
                        })
                    }
                    else {
                        const docRef = doc(db, 'BankDetails', auth.currentUser?.uid);
                        const docbank = await getDoc(docRef);
                        if (docbank.exists()) {
                            navigate('/home')
                        }
                        else {
                            navigate(`/paymentDetails/${auth.currentUser?.uid}`, {
                                state: {
                                    types: experts
                                }
                            })
                        }
                    }
                }
                else {
                    navigate(`/domain/${experts}/${auth.currentUser?.uid}`, {
                        state: {
                            tier: docSnap.data().tier
                        }
                    })
                }
            }
        }
        else {
            // navigate(`/newUser/${auth?.currentUser?.uid}`)
            navigate('/home')
        }
    }
    // const docRef = doc(db, experts, auth.currentUser?.uid);
    // const docSnap = await getDoc(docRef);
    // if (docSnap.exists()) {
    //     if (docSnap.data().tier === "TIER.T1") {
    //         if (docSnap.data().tier === "TIER.T1" && docSnap.data()?._tags.length === 0) {
    //             navigate(`/domain/${experts}/${auth.currentUser?.uid}`, {
    //                 state: {
    //                     tier: docSnap.data().tier
    //                 }
    //             })
    //         }
    //         else {
    //             navigate('/home')
    //         }
    //     }
    //     else if (docSnap.data().tier === "TIER.T2" || docSnap.data().tier === "TIER.T3") {
    //         const docRef = doc(db, 'VerifyReq', auth.currentUser?.uid);
    //         const docSnaps = await getDoc(docRef);
    //         if (docSnaps.exists()) {
    //             if (docSnaps.data().domain_expertise?.length === 0) {
    //                 navigate(`/domain/${experts}/${auth.currentUser?.uid}`, {
    //                     state: {
    //                         tier: docSnap.data().tier
    //                     }
    //                 })
    //             }
    //             else {
    //                 const docRef = doc(db, 'BankDetails', auth.currentUser?.uid);
    //                 const docbank = await getDoc(docRef);
    //                 if (docbank.exists()) {
    //                     navigate('/home')
    //                 }
    //                 else {
    //                     navigate(`/paymentDetails/${auth.currentUser?.uid}`, {
    //                         state: {
    //                             types: experts
    //                         }
    //                     })
    //                 }
    //             }
    //         }
    //         else {
    //             navigate(`/domain/${experts}/${auth.currentUser?.uid}`, {
    //                 state: {
    //                     tier: docSnap.data().tier
    //                 }
    //             })
    //         }
    //     }
    // }
    // else {
    //     // navigate(`/newUser/${auth?.currentUser?.uid}`)
    //     navigate('/home')
    // }
}
export const RejectedRouting = (navigate, verifyDom) => {
    navigate(`/verification/${experts}/${auth.currentUser?.uid}`, {
        state: {
            tier: verifyDom?.tier,
            tags: [],
            status: 'reupload',
            status2: "reuploading"
        }
    })
}

export const lang = async (e, props, data) => {
    if (e.target.checked === true) {
        await updateDoc(doc(db, props.type, props.id), {
            language: arrayUnion(data)
        })
    }
    else if (e.target.checked === false) {
        await updateDoc(doc(db, props.type, props.id), {
            language: arrayRemove(data)
        })
    }
}


export const addCustomdomaininTier1 = async (checks) => {
    await updateDoc(doc(db, 'Domains', "wMkb6yf8DJO4aGRkN2VD"), {
        fields: arrayUnion(checks)
    })
}

export const removedomain = async (id, data) => {
    await updateDoc(doc(db, experts, id), {
        _tags: arrayRemove(data)
    })
}

export const addCustomdomain = async (id, checks) => {
    await updateDoc(doc(db, experts, id), {
        _tags: arrayUnion(checks)
    })
}


export async function tagsList(e, setCustom, c, setchecks) {
    setchecks(e.target.value)
    if (e.target.value != "") {
        const domList = c.filter((dat) => {
            return Object.values(dat).join(" ").toLowerCase().includes(e.target.value.toLowerCase())
        })
        setCustom(domList)
    }
    else {
        setCustom(c)
    }
}






export const HomePosts = async (setDynamic, props) => {
    const q = query(collection(db, `Single Posts/${props.name}/Posts`), where("name", "==", props.name));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const users = [];
        querySnapshot.forEach((doc) => {
            if (doc.data()) {
                users.push(doc.data());
            }
        });
        setDynamic(users)

    });
    return () => unsubscribe();
}



export const getSinglePosts = (setCareer) => {
    const q = query(collection(db, "Single Posts"));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const users = [];
        querySnapshot.forEach((doc) => {
            if (doc.data()) {
                users.push(doc.data());
            }

        });
        setCareer(users)

    });
    return () => unsubscribe();
}

export const WebinarPosts = async (setWebinarPosts, name) => {
    let q;
    if (name != undefined) {
        q = query(collection(db, `Webinar`), where("category", "==", name));
    }
    else {
        q = query(collection(db, `Webinar`));
    }

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const users = [];
        querySnapshot.forEach((doc) => {
            if (doc.data()) {
                users.push({ ...doc.data(), id: doc.id });
            }
        });
        setWebinarPosts(users)

    });
    return () => unsubscribe();
}

export const otpverificationforInstantUser = async (otp, navigate, confirmObj, setOpen, instantName, typeOfUser) => {
    if (otp === "" || otp === undefined) {
        console.log("Error")
    }
    try {
        await confirmObj.confirm(otp).then((async (result) => {
            await typeOfUser(result?.user, "Explorers", instantName).then(() => {
                setOpen(true)
            })

        }))
    }
    catch (error) {

    }
}
export const otpverification = async (props, navigate, typeOfUser, setErrorMessage, euset) => {
    if (props.otp === "" || props.otp === undefined) {
        console.log("Error")
    }
    try {
        await props.confirmObj.confirm(props.otp).then(async (result) => {
            const cred = getAdditionalUserInfo(result)
            if (cred.isNewUser === true) {
                // navigate(`/newUser/${result?.user?.uid}`)
                await typeOfUser(result?.user, props.state ? props.state : euset).then(() => {
                    navigate(`/name/${(props.state ? props.state : euset) === "Explorers" ? "Seeker" : "EUU"}/${auth?.currentUser?.uid}`)
                })
            }
            else {
                navigate('/home')
                // checkUser(result, navigate,euset)
            }

            // if (props.type === "Experts") {
            //     await typeOfUser(result.user, props.type, props.username)
            //     navigate(`/onBoardingScreens/${props.type}/${result.user?.uid}`)
            // }
            // else if (props.type === "Explorers") {
            //     await typeOfUser(result.user, props.type, props.username)
            //     navigate(`/onBoardingScreens/${props.type}/${result.user?.uid}`)

            // }
        })


        // else if (props.loginButton === true) {
        //     await props.confirmObj.confirm(props.otp).then(async (result) => {
        //         const cred = getAdditionalUserInfo(result)
        //         if (cred.isNewUser === true) {
        //             alert('SignUp first')
        //         }
        //         else if (cred.isNewUser === false) {
        //             navigate('/home')
        //         }

        //     })

        // }
        // navigate('/home')
    }
    catch (error) {
        const err = error.code
        setErrorMessage(err)
    }
}


export const newUser = async (state, username, navigate) => {
    if (state === "Experts") {
        const washingtonRef = doc(db, "EUU", auth?.currentUser?.uid);
        await updateDoc(washingtonRef, {
            username: username
        }).then(() => {
            navigate(`/name/${state}/${auth?.currentUser?.uid}`)
        });
        // await typeOfUser(user, type, username)
        // navigate(`/onBoardingScreens/${type}/${user?.uid}`)
        // navigate(`/tags/EUU/${result.user.uid}`)
        // navigate(`/name/${type}/${user?.uid}`)

    }
    else if (state === "Explorers") {
        const washingtonRef = doc(db, "Seeker", auth?.currentUser.uid);
        await updateDoc(washingtonRef, {
            username: username
        }).then(() => {
            navigate(`/name/${state}/${auth?.currentUser?.uid}`)
        });
        // await typeOfUser(user, type, username)
        // navigate(`/name/${type}/${user?.uid}`)
    }
}
export const phoneErrorHnadling = async (username, snackbarStart, phone, type, setSignUpButton, setUpRecaptcha, setConfirmObj, setFlag) => {
    if (!username) {
        snackbarStart("error", "Please enter your username")
    }
    else if (!phone) {
        snackbarStart("error", "Please enter your Phone number")
    }
    else if (phone.length < 12) {
        snackbarStart("error", "Please enter valid Phone number")
    }
    else if (!type) {
        snackbarStart("error", "Please enter type of User")
    }
    else {
        setSignUpButton(true)
        try {
            await setUpRecaptcha(phone, setConfirmObj)
            setFlag(true)
        }
        catch (error) {
            console.log(error.message)
        }
    }
}

export const signinOTP = async (phone, setLoginButton, setUpRecaptcha, setFlag, setConfirmObj, snackbarStart) => {
    console.log(phone)
    if (!phone || phone.length < 12) {
        snackbarStart("error", "Please enter Valid Phone Number")
    }
    else {
        setLoginButton(true)
        try {
            await setUpRecaptcha(phone, setConfirmObj)
            setFlag(true)
        }
        catch (error) {
            console.log(error.message)
        }
    }
}

export const eventClickStatus = (data, setOpen, setEvent) => {
    if (data.StartTime >= new Date().setHours(0, 0, 0, 0)) {
        setEvent(data)
        if (data?.status === 'CONFIRMED') {

        }
        else if (data?.status === 'AWAITING') {

        }
        else if (data?.status === "AVAILABLE") {
            setOpen(true)
        }
    }
}

export const cellStatus = (args, setRec) => {
    if (args.startTime <= new Date(new Date().setHours(0, 0, 0, 0))) {
        args.cancel = true
    }
    if (((args.startTime < new Date().setDate(new Date().getDate() + 14) && (args.startTime > new Date().setDate(new Date().getDate() - 1))))) {
        const scheduleObj = (document.querySelector('.e-schedule')).ej2_instances[0];
        var ev = scheduleObj.getEvents(args.startTime, args.endTime, true)
        // const recEv = events.filter((data) => {
        //     if (moment(data.StartTime).format('YYYY-MM-DD') === moment(args.startTime).format('YYYY-MM-DD')) {
        //         return data
        //     }
        // })
        setRec(ev)
    }
    else {
        args.cancel = true
    }
}

export const renderStatus = (args) => {
    if ((args.date < new Date().setDate(new Date().getDate() + 14)) && (args.date > new Date().setDate(new Date().getDate()))) {

    }
    else {
        args.element.classList.add('e-disable-cell');
        args.element.addEventListener('click', (event) => {
            event.preventDefault();
            event.stopPropagation()
        })
    }
}
export const paymentStatus = (area, activeButtons, setPayment, setOpen) => {
    if (!area || !activeButtons) {
        alert("Please enter ")
    }
    else {
        setPayment(true)
        setOpen(false)
    }
}

export const databoundStatus = () => {
    const indicator = document.querySelectorAll('.e-appointment-indicator');
    const now = new Date();
    now.setSeconds(0, 0);

    if (indicator.length > 0) {
        indicator.forEach((appointment) => {

            appointment.previousElementSibling.style.color = 'green';
            appointment.previousElementSibling.style.fontWeight = '500';

        });
    }
};

export const addDomainstoTier1Free = async (e, data, id) => {
    if (e.target.checked === true) {
        await updateDoc(doc(db, experts, id), {
            _tags: arrayUnion(data)
        })
    }
    else if (e.target.checked === false) {
        await updateDoc(doc(db, experts, id), {
            _tags: arrayRemove(data)
        })
    }
}

export const onBoardingNavigate = (navigate, id, type, state) => {
    if (type === explorer) {
        navigate(`/success/${type}/${id}`, { state: state })
    }
    else if (type === experts) {
        navigate(`/tags/${experts}/${id}`)
    }
}


export const getSessions = (types, setSess) => {
    const q = query(collection(db, `${types}/${auth?.currentUser?.uid}/Sessions`));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const arraySession = [];
        querySnapshot.forEach((doc) => {
            if (doc.data()) {
                arraySession.push({ ...doc.data(), id: doc.id });
            }
        });
        setSess(arraySession)

    });
    return () => unsubscribe()
}

export const getRating = (experts, setRate, id) => {
    const q = query(collection(db, `${experts}/${id}/ratings`));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
        let arrayRating = []
        querySnapshot.forEach((doc) => {
            if (doc.data()) {
                arrayRating.push(doc.data());
            }

        });
        setRate(arrayRating)

    });
    return () => unsubscribe()
}

export const getRatingForYou = (typess, setRateForYou, props) => {
    const unsub = onSnapshot(doc(db, `${typess}/${auth?.currentUser?.uid}/ratings/${props?.orderdata?.id}`), (doc) => {
        setRateForYou(doc.data());
    });
    return () => unsub()
}

export const getRatingbyYou = async (type, setRatebyYou, props) => {
    if (auth?.currentUser?.uid === props?.orderdata?.euId) {
        const unsub = onSnapshot(doc(db, `${type}/${props?.orderdata?.seekerId}/ratings/${props?.orderdata?.id}`), (doc) => {
            setRatebyYou(doc.data());
        });
        return () => unsub()
    }
    else {
        const unsub = onSnapshot(doc(db, `${type}/${props?.orderdata?.euId}/ratings/${props?.orderdata?.id}`), (doc) => {
            setRatebyYou(doc.data());
        });
        return () => unsub()
    }
}


export const getALLSesisonsforSplit = (type, setSession) => {
    const q = query(collection(db, `${type}/${auth?.currentUser?.uid}/Sessions`), where("session_status", "==", "UPCOMING"));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const arraySession = [];
        querySnapshot.forEach(async (docs) => {
            if (new Date() > new Date(docs.data().to)) {
                arraySession.push({ ...docs.data(), id: docs.id });
                await updateDoc(doc(db, `${type}/${auth?.currentUser?.uid}/Sessions/${docs.id}`), {
                    session_status: "DONE"
                })
            }
            else if (docs.data()) {
                arraySession.push({ ...docs.data(), id: docs.id })
            }
        });
        setSession(arraySession)

    });
    return () => unsubscribe()
}


export const getSessionPosts = async (setPostsSession) => {
    const q = query(collection(db, "SessionPosts"));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const p = [];
        querySnapshot.forEach((doc) => {
            p.push({ ...doc.data(), id: doc.id });
        });
        setPostsSession(p);
    });
    return () => unsubscribe()
}
export const getWebinarPosts = async (setWPosts, type) => {
    const q = query(collection(db, `${type}/${auth.currentUser?.uid}/Webinar`));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const p = [];
        querySnapshot.forEach((doc) => {
            p.push({ ...doc.data(), id: doc.id });
        });
        setWPosts(p);
    });
    return () => unsubscribe()
}

export const getEUWebinarDetails = (setEUWPosts) => {
    const q = query(collection(db, `Webinar`));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const p = [];
        querySnapshot.forEach((doc) => {
            p.push({ ...doc.data(), id: doc.id });
        });
        setEUWPosts(p);
    });
    return () => unsubscribe()
}
export const featuredPosts = (setAllData) => {
    const q = query(collection(db, "Featured Posts"));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const users = [];
        querySnapshot.forEach((doc) => {
            if (doc.data().status === "Now Showing") {
                users.push({ ...doc.data(), id: doc.id });
            }
        });
        setAllData(users)
    });
    return () => unsubscribe();
}

export const verifyRequestStatus = (setVerifyData, setSecond) => {
    const unsub = onSnapshot(doc(db, "VerifyReq", auth?.currentUser?.uid), (doc) => {
        if (doc.data()?.status === 'Rejected') {
            setSecond(true)
            setVerifyData(doc.data())
        }
        else if (doc.data()?.status === 'Partially accepted') {
            setSecond(true)
            setVerifyData(doc.data())
        }
    });
    return () => unsub();
}

export const NameOnboarding = async (id, name, navigate, type) => {
    if (type === explorer) {
        const washingtonRef = doc(db, "Seeker", id);
        const docSnap = await getDoc(washingtonRef);
        await updateDoc(washingtonRef, {
            username: name
        }).then(() => {
            if (docSnap.data().pronouns === "") {
                navigate(`/preferred/${type}/${id}`)
            }
            else {
                navigate('/home')
            }

        });
    }
    else {
        const washingtonRef = doc(db, "EUU", id);
        await updateDoc(washingtonRef, {
            username: name
        }).then(() => {
            navigate(`/onBoardingScreens/${type}/${id}`)
        });

    }
}

export const PhoneOnboarding = async (id, phone, navigate, type) => {
    if (type === explorer) {
        const washingtonRef = doc(db, "Seeker", id);
        const docSnap = await getDoc(washingtonRef);
        await updateDoc(washingtonRef, {
            phone_number: `+91${phone}`
        }).then(() => {
            if (docSnap.data().username === "") {
                navigate(`/name/${type}/${id}`)
            }
            else {
                navigate('/home')
            }

        });
    }
    else {
        const washingtonRef = doc(db, "EUU", id);
        await updateDoc(washingtonRef, {
            phone_number: phone
        }).then(() => {
            navigate(`/onBoardingScreens/${type}/${id}`)
        });

    }
}

export const ProblemDescription = async (id, area, navigate, type, state) => {
    if (type === explorer) {
        const washingtonRef = doc(db, "Seeker", id);
        await updateDoc(washingtonRef, {
            problem_description: area ? area : ""
        }).then(() => {
            navigate(`/success/${type}/${id}`, { state: state.title })
        });
    }
    else {
        const washingtonRef = doc(db, "EUU", id);
        await updateDoc(washingtonRef, {
            problem_description: area ? area : ""
        }).then(() => {
            navigate(`/onBoardingScreens/${type}/${id}`, { state: state.title })
        });

    }
}

export const LastVisited = async (id, type) => {
    if (type === explorer) {
        const washingtonRef = doc(db, "Seeker", id);
        await updateDoc(washingtonRef, {
            last_visit: true
        })
    }
    else {
        const washingtonRef = doc(db, "EUU", id);
        await updateDoc(washingtonRef, {
            last_visit: true
        })

    }
}
export const preferredOnBoarding = async (id, data, navigate, type) => {
    const washingtonRef = doc(db, "Seeker", id);
    const docSnap = await getDoc(washingtonRef);
    await updateDoc(washingtonRef, {
        pronouns: data?.title
    }).then(() => {
        if (!docSnap.data().age) {
            navigate(`/age/${type}/${id}`)
        }
        else {
            navigate('/home')
        }

    });
}

export const AgeOnboarding = async (id, age, navigate, type) => {
    const washingtonRef = doc(db, "Seeker", id);
    const docSnap = await getDoc(washingtonRef);
    await updateDoc(washingtonRef, {
        age: age
    }).then(() => {
        if (docSnap.data().state_address === "") {
            navigate(`/state/${type}/${id}`)
        }
        else {
            navigate('/home')
        }

    });
}
export const StateOnboarding = async (id, addState, navigate, type) => {
    const washingtonRef = doc(db, "Seeker", id);
    const docSnap = await getDoc(washingtonRef);
    await updateDoc(washingtonRef, {
        state_address: addState.value
    }).then(() => {
        if (docSnap.data().topic === "") {
            navigate(`/topics/${type}/${id}`)
        }
        else {
            navigate('/home')
        }

    });
}
export const BankUpdate = async (phone, email, account, holder, code, vpa, id, props, state, navigate) => {
    if (!phone || phone.length < 10 || phone === '') {

    }
    else if (!email) {

    }
    else if (!account) {

    }
    else if (!holder) {

    }
    else if (!code || !vpa) {

    }
    else {
        await setDoc(doc(db, `BankDetails`, id), {
            'vendor_id': id,
            'phone_number': phone,
            'email': email,
            'account_num': account,
            'account_holder_name': holder,
            ifsc: code,
            vpa: vpa,
            createdAt: new Date()
        }).then(() => {
            props?.settings ? <></> : navigate(`/introduction/${state.types}/${auth?.currentUser?.uid}`, {
                state: {
                    tier: state.tier,
                    tags: state.tags,
                    dom: state.dom,
                    types: state?.types
                }
            })
        }).then(() => {
            props?.snackbarStart("success", "Bank Details Updated")
        })
    }
}

export const NotificationsFlow = (types, id, setNotifications) => {
    const q = query(collection(db, `${types}/${id}/Notifications`));
    const unsub = onSnapshot(q, (querySnapshot) => {
        const notify = [];
        querySnapshot.forEach((docs) => {
            const unsub = onSnapshot(doc(db, `${experts}/${docs.data().eu_id}/Requests/${docs.data().request_id}`), async (docu) => {
                if (docu.data()) {
                    if (docu.data().receiver_id != id) {
                        const docRef = doc(db, "EUU", docu.data()?.receiver_id);
                        const docSnap = await getDoc(docRef);
                        if (docSnap.exists()) {
                            notify.push({ ...docs.data(), ...docu.data(), id: docs.id, profile_img_url: docSnap.data().profile_img_url })
                        }
                        else {
                            const docRef = doc(db, "Seeker", docu.data()?.receiver_id);
                            const docSnap = await getDoc(docRef);
                            if (docSnap.exists()) {
                                notify.push({ ...docs.data(), ...docu.data(), id: docs.id, profile_img_url: docSnap.data().profile_img_url })

                            }
                        }
                        setNotifications(notify)
                    }
                    if (docu?.data().receiver_id === id) {
                        const docRef = doc(db, "EUU", docu.data()?.sender_id);
                        const docSnap = await getDoc(docRef);
                        if (docSnap.exists()) {
                            notify.push({ ...docs.data(), ...docu.data(), id: docs.id, profile_img_url: docSnap.data().profile_img_url })
                        }
                        else {
                            const docRef = doc(db, "Seeker", docu.data()?.sender_id);
                            const docSnap = await getDoc(docRef);
                            if (docSnap.exists()) {
                                notify.push({ ...docs.data(), ...docu.data(), id: docs.id, profile_img_url: docSnap.data().profile_img_url })

                            }

                        }
                    }
                    setNotifications(notify)
                }

            });

        });


    });
    return () => unsub()
}

export const getPhotoUrl = (setPhoto, setPic, e, shortintro) => {
    setPhoto(e.target.files[0])
    const reader = new FileReader();
    reader.onload = () => {
        if (reader.readyState === 2) {
            setPic({ photo: reader.result, intro: shortintro ? shortintro : null })
        }
    }
    reader.readAsDataURL(e.target.files[0])
}

export const PayModel = async (setOpens, no, setData, setPricingDetails, setRejDetails) => {
    setOpens(true)
    const docRef = doc(db, experts, no.receiver_id);
    const docSnap = await getDoc(docRef)
    setData(docSnap.data())
    const price = doc(db, `${experts}/${no.receiver_id}/time_slots/${no?.timeslot_id}`);
    const priceSnap = await getDoc(price)
    setPricingDetails(priceSnap.data())
    setRejDetails(no)
}

export const PayModel1 = async (setOpen, setData, setNotificationData, notified, setPricingDetails) => {
    setOpen(true)
    const docRef = doc(db, experts, notified.receiver_id);
    const docSnap = await getDoc(docRef)
    setData(docSnap.data())
    setNotificationData(notified)
    const price = doc(db, `${experts}/${notified.receiver_id}/time_slots/${notified?.timeslot_id}`);
    const priceSnap = await getDoc(price)
    setPricingDetails(priceSnap.data())
}

export const truncate = (string, limit) => {
    if (string.length <= limit) {
        return string;
    }
    return string.slice(0, limit) + "..."
}
export const ImageUrlUsers = (database, ImageUsers, docSnap) => {
    let objImage = {}
    objImage = {
        createdAt: database.createdAt,
        eu_id: database.eu_id,
        from: database.from,
        to: database.to,
        is_payment_done: database.is_payment_done,
        is_recent: database.is_recent,
        paid: database.paid,
        reason: database.reason,
        receiver_id: database.receiver_id,
        receiver_name: database.receiver_name,
        request_id: database.request_id,
        seeker_id: database.seeker_id,
        sender_id: database.sender_id,
        sender_name: database.sender_name,
        status: database.status,
        timeslot_id: database.timeslot_id,
        type: database.type,
        profile_img_url: docSnap.data().profile_img_url,
        id: database.id
    }
    ImageUsers.push(objImage)

}

export const ImageOfUsersInPanel = (notifications, id, setURlforImage) => {
    let ImageUsers = []
    notifications && notifications?.filter(async (database) => {
        if (database.receiver_id != id) {
            const docRef = doc(db, "EUU", database?.receiver_id);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                ImageUrlUsers(database, ImageUsers, docSnap)
                setURlforImage(ImageUsers)

            }
            else {
                const docRef = doc(db, "Seeker", database?.receiver_id);
                const docSnap = await getDoc(docRef);
                if (docSnap.exists()) {
                    ImageUrlUsers(database, ImageUsers, docSnap)
                    setURlforImage(ImageUsers)

                }
            }
        }
        if (database?.receiver_id === id) {
            const docRef = doc(db, "EUU", database?.sender_id);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                ImageUrlUsers(database, ImageUsers, docSnap)
                setURlforImage(ImageUsers)

            }
            else {
                const docRef = doc(db, "Seeker", database?.sender_id);
                const docSnap = await getDoc(docRef);
                if (docSnap.exists()) {
                    ImageUrlUsers(database, ImageUsers, docSnap)
                    setURlforImage(ImageUsers)

                }
            }

        }
    })
}

export const Destinationofbanner = (data, navigate, type) => {
    if (data.category === "Business Consultants" || data.category === "Life Coaches" || data.category === "Career Counsellors") {
        navigate(`/Category/${data?.category}`)
    }
    else if (data.category === "profile_card" && data.eu_id) {
        navigate(`/profile/${data?.eu_id}`)
    }
    else if (data.category === "availability" && data.eu_id === "") {
        navigate(`/settings/${type}/${auth?.currentUser?.uid}`)
    }
}

export const windowScroll = (setIsVisible) => {
    let timeoutId;

    const handleScroll = () => {
        clearTimeout(timeoutId);
        setIsVisible(false);

        timeoutId = setTimeout(() => {
            setIsVisible(true);
        }, 500);
    };

    window.onscroll = handleScroll;

    return () => {
        window.onscroll = null;
    };
}
export const scrollEffect = (setIsVisible) => {
    window.onscroll = () => {
        setIsVisible(false);
    };

    return () => {
        window.onscroll = null;
    };
}

export const InstantConnectPayment = async (props, navigate, getInstantPayment, isProd, cashfreeProd, cashfreeSandbox) => {
    navigate('/payment')
    console.log(props.typeIns)
    await addDoc(collection(db, `${props.typeIns}/${auth?.currentUser?.uid}/Orders`), {
        SUCCESS: null,
        euId: props.typeIns === explorer ? "euId" : auth.currentUser?.uid,
        orderAmount: 500,

    }).then(async (docRef) => {
        getInstantPayment(docRef).then(async (data) => {
            let parent = document.getElementById('drop_in_container');
            let cashfree;
            if (isProd) {
                cashfree = new cashfreeProd.Cashfree();
            } else {
                cashfree = new cashfreeSandbox.Cashfree();
            }
            cashfree.initialiseDropin(parent, {
                orderToken: data.data.order_token,
                components: ["card", "app", "upi", "order-details", "netbanking"],
                onSuccess: async function cbs(pay) {
                    await paymentInstantExpert(pay, docRef, props, navigate)
                },
                onFailure: async function cbf(pay) {

                }
            })
        })
    })
}


export const getImageOfProfile = (types, id, setVerifyProfile) => {
    const unsub = onSnapshot(doc(db, types, id), (doc) => {
        if (doc.data()) {
            setVerifyProfile(doc.data());
        }
    });
    return () => unsub()
}

export const getUserDetails = (setVerifyProfile, setType, getProfileById, getUserType, type) => {
    getProfileById().then(async () => {
        setVerifyProfile(await getUserType(setType, auth?.currentUser?.uid))

    })
}