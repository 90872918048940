import { Button } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Model from '../../constants/Model'
import "../../css/Courses.css"
import WebinarBooking from './WebinarBooking'
import { useParams } from 'react-router';
import { CategoriesWebinar, filterWebinar, WebinarPosts } from '../../Functions/functions'
import moment from 'moment'
import { doc, getDoc } from 'firebase/firestore'
import { experts } from '../../constants/constant'
import { auth, db } from '../../firebase'
import { useUserAuth } from '../../context/AuthContext'
import CategoryCourses from './CategoryCourses'
import { Col, Container, Row } from 'react-bootstrap'
const Courses = () => {
    const { name } = useParams()
    const [webinarposts, setWebinarPosts] = useState([])
    const [bookingdata, setBookingData] = useState(null)
    const [EUData, setEUData] = useState()
    const [type, setType] = useState(experts)
    const [categoryweb, setCategoryWeb] = useState()
    const { getUserType } = useUserAuth()
    useEffect(() => {
        WebinarPosts(setWebinarPosts, name)
    }, [name])
    const [open, setOpen] = useState(false)
    const [third, setThird] = useState(false)
    const BookingWebinar = async (wData) => {
        const wEudata = doc(db, `${experts}/${wData.eu_id}`);
        const EU = await getDoc(wEudata)
        setEUData(EU.data())
        await getUserType(setType, auth?.currentUser?.uid)
        setBookingData(wData)
        setOpen(true)
    }
    const filterWebinarByDate = webinarposts.filter((web) => {
        if (new Date(moment(web.date.toDate()).format('YYYY-MM-DD hh:mm:ss A')) >= new Date()) {
            return web
        }
    })
    useEffect(() => {
        CategoriesWebinar(setCategoryWeb)
    }, [])
    return (
        <>
            <Container>
                <Row>
                    <Col> {filterWebinar(filterWebinarByDate)?.length > 0 ? <div className='courssweb'>
                        <h5 style={{paddingLeft:0,fontWeight:700,fontSize:30}}>Live Courses and Webinars </h5>
                        <p onClick={() => setThird(true)}>see more</p>
                    </div> : ""}
                            <br/>
                        <CategoryCourses filterWebinarByDate={filterWebinarByDate} BookingWebinar={BookingWebinar} filterWebinar={filterWebinar} /></Col>
                </Row>
            </Container>

            <Model
                show={open}
                close={() => setOpen(false)}
                component={<><WebinarBooking type={type} EUData={EUData} bookingdata={bookingdata} /></>}
                size='md'
                onHide={() => setOpen(false)}
            />
            <Model
                show={third}
                close={() => setThird(false)}
                component={<><CategoryCourses categoryweb={categoryweb} slice={'No Slice'} filterWebinarByDate={filterWebinarByDate} BookingWebinar={BookingWebinar} filterWebinar={filterWebinar} />
                </>}
                fullScreen
                onHide={() => setThird(false)}
                button="no"
                sign="cross"
            />
        </>

    )
}

export default Courses
