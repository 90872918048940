import { Button } from '@mui/material'
import React, { useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router'
import "../../css/OnBoardingExplorer.css"
import { ProblemDescription } from '../../Functions/functions'

const MatchExpert = () => {
    const { state } = useLocation()
    const {type,id}=useParams()
    const [area,setArea]=useState()
    const navigate=useNavigate()
    const navigateToCatPage=()=>{
        ProblemDescription(id, area, navigate, type,state)
      
    }
    return (
        <div className='newUser_container'>
            <div className='match_heading'>
                <h3>Please describe your problem so we can match you <br />with the best expert:</h3>
                <h6>(Optional)</h6>
                <br />
                <textarea onChange={(e)=>setArea(e.target.value)} style={{ width: "100%", height: 280, outline: "none", background: "#ECEEFC", border: "1px solid rgba(11, 83, 148, 0.7)", borderRadius: 15, padding: 15 }} />
                <br />
                <br />
                <br />
                <div className='name_button'>
                    <Button onClick={navigateToCatPage} style={{ background: "#0B5394", borderRadius: 10 }} variant='contained' className='name_button_width'>Next</Button>
                </div>
            </div>
        </div>
    )
}

export default MatchExpert
