import { Button } from '@mui/material'
import { doc, getDoc } from 'firebase/firestore'
import React, { useState } from 'react'
import { experts } from '../../constants/constant'
import Popup from '../../constants/Popup'
import "../../css/Webinar.css"
import { auth, db } from '../../firebase'
import { webinarExpertData, webinarPhoto } from '../../Functions/functions'
import WebinarConfirmed from './WebinarConfirmed'
const MeetLink = (props) => {
    const [second, setSecond] = useState(false)
    const [EUData, setEUData] = useState()
    const [googlemeet, setGoogleMeet] = useState()
    const storewebinarData = async () => {
        webinarPhoto(props.photo, props.category, props.language, props.date, props.description, props.duration, props.fees, props.title, props.webinarFor, props.requirements, props.time, props, props.pic, props.setOpen, props.open, props.types, setSecond, googlemeet,props.ytlink)
        webinarExpertData(setEUData)
    }
    return (
        <>
            <div className='webinarlink_heading'>
                <h3>Now its time to create your webinar link</h3>
                <br />
                <h6>Just follow these simple steps to create link</h6>
                <br />
                <div className='weblink_container'>
                    <h5>1 . Go to <u style={{ color: "black", cursor: "pointer" }} onClick={() => window.open("https://meet.google.com/")}>https://meet.google.com/</u> and sign in. If you're not signed in with Google, you won't be able to continue.</h5>
                    <br />
                    <h5>2. Click New Meeting. You'll see this towards the left side of the page next to an icon of a video camera.</h5>
                    <br />
                    <h5>3. Click Create a meeting for later. It's usually the first option in the menu and is next to an icon of a link.</h5>
                    <ul>
                        <li style={{ fontSize: 17, color: "rgba(0, 0, 0, 0.7)", fontWeight: 600 }}>You will also get a link to share if you choose Start an instant meeting or <u style={{ color: "black", cursor: "pointer" }} onClick={() => window.open("https://www.wikihow.com/Schedule-a-Google-Meet-in-Advance")}>Schedule in Google Calendar.</u></li>
                    </ul>
                    <h5>4. Tap the copy icon. This prompts your computer to copy the link to your clipboard so you can paste it anywhere.</h5>
                </div>
                <br />
                <div className='pastelinkcontainer'>
                    <input onChange={(e) => setGoogleMeet(e.target.value)} style={{ background: "rgba(218, 218, 218, 0.5)", border: "none" }} placeholder='Paste your Link here' type='text' />
                </div>
                <br />
                <div className='webinar_button'>
                    <Button onClick={storewebinarData} variant='contained' style={{ width: "100%", background: "#0B5394", color: "white", height: 45 }}>Done</Button>
                </div>
            </div>
            <Popup
                onClose={() => setSecond(false)}
                open={second}
                content={<>< WebinarConfirmed/></>}
            />
        </>
    )
}

export default MeetLink
