import { Box, Tab, Tabs } from '@mui/material'
import { doc, onSnapshot } from 'firebase/firestore'
import React from 'react'
import { useParams } from 'react-router'
import { db } from '../../firebase'
import { a11yProps, TabPanel } from '../../Functions/functions'
import SelectTier from '../Tier/SelectTier'
import Review from './Review'
import Sessions from './Sessions'
import domImg from "../../assets/images/Rectangle 267.png"
const Expert = (props) => {
    const { id } = useParams()
    const [acc, setAccept] = React.useState(null)
    const handleChange = (event, newValue) => {
        props.setValue(newValue);
    };
    React.useEffect(() => {
        const unsub = onSnapshot(doc(db, "VerifyReq", id), (doc) => {
            if (doc.data()) {
                setAccept(doc.data())
            }
        });
        return () => unsub();
    }, [acc?.status, id])
    return (
        <div>
            <Box sx={{ width: '100%', marginTop: "30px" }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs variant='scrollable' value={props.value} onChange={handleChange} aria-label="basic tabs example">
                        <Tab sx={{
                            color: '#749ec4',
                            '&.Mui-selected': {
                                color: '#0B5394',
                            },
                        }} className='slide_tabler' label="Domain Expertise" {...a11yProps(0)} />
                        <Tab sx={{
                            color: '#749ec4',
                            '&.Mui-selected': {
                                color: '#0B5394',
                            },
                        }} className='slide_tabler' label="Sessions" {...a11yProps(1)} />
                        <Tab sx={{
                            color: '#749ec4',
                            '&.Mui-selected': {
                                color: '#0B5394',
                            },
                        }} className='slide_tabler' label="Reviews" {...a11yProps(2)} />
                    </Tabs>
                </Box>
                <TabPanel value={props.value} index={0}>
                    <div className='domain_stuff'>
                        <div className='domain_tags'>
                            {
                                props?.profile?._tags?.length > 0 ? <>

                                    {
                                        props?.profile && props?.profile._tags?.map((data, item) => (

                                            <div key={item} className='tags1'>
                                                <h6>{data}</h6>
                                            </div>

                                        ))
                                    }
                                </> : <>

                                    {/* <div style={{ padding: 40 }}>
                                        <h5 style={{ color: "#0B5394" }}>No Domains Available for this user</h5>
                                    </div> */}
                                </>
                            }
                        </div>
                        <br />
                        <br />
                        <div className='nodomain_graphic'>
                            {
                                props?.profile?._tags?.length > 0 && acc?.status === "pending" ? <> {acc?.status === "pending" ? <><img alt="" src={domImg} /> <br />
                                    <br />
                                    <h5>Other Domain Experties is under review. <br />Our team will verify your Experties shortly</h5></> : ""}</> : <> {acc?.status === "pending" ? <><img alt="" src={domImg} /> <br />
                                        <br />
                                        <h5>Domain Experties is under review. <br />Our team will verify your Experties shortly</h5></> : ""}</>
                            }

                        </div>
                    </div>
                </TabPanel>
                <TabPanel value={props?.value} index={1}>
                    <Sessions sess={props?.sess} />
                </TabPanel>
                {/* <TabPanel value={props?.value} index={2}>
                    Your Current Plan
                    <br /> <br />
                    <SelectTier />
                </TabPanel> */}
                <TabPanel value={props?.value} index={2}>
                    <Review rate={props?.rate} />
                </TabPanel>
            </Box>
        </div>
    )
}

export default Expert