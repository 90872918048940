import { Button, Checkbox, FormControlLabel, FormGroup } from '@mui/material'
import { DatePickerComponent, TimePickerComponent } from '@syncfusion/ej2-react-calendars'
import moment from 'moment'
import React from 'react'

const DateTime = (props) => {
    return (
        <div>
            <div style={{ padding: "30px" }}>
                <div style={{ width: "fit-content" }}>
                    {props.checkedTwo === true ?
                        <FormGroup>
                            <FormControlLabel
                                label="EveryDay"
                                control={
                                    <Checkbox disabled />
                                }
                            />
                        </FormGroup> :
                        <FormGroup>
                            <FormControlLabel
                                label="EveryDay"
                                control={
                                    <Checkbox onChange={(e) => props.setCheckedOne(e.target.checked)} />
                                }
                            />
                        </FormGroup>
                    }
                    {
                        props.checkedOne === true ? <FormGroup>
                            <FormControlLabel
                                label="WeekDays"
                                control={
                                    <Checkbox disabled />
                                }
                            />
                        </FormGroup> : <FormGroup>

                            <FormControlLabel
                                label="WeekDays"
                                control={
                                    <Checkbox onChange={(e) => props.setCheckedTwo(e.target.checked)} />
                                }
                            />
                        </FormGroup>
                    }
                </div>
                <div className='date-picker'>
                    <DatePickerComponent delayUpdate={true} onChange={(e) => props.setValue(e.target.value)} min={moment().toDate()} /> to
                    <DatePickerComponent delayUpdate={true} onChange={(e) => props.setValues(e.target.value)} min={moment().toDate()} />
                </div>
                <h5 style={{ padding: "15px", color: "rgba(11, 83, 148, 1)", textAlign: "center" }}>Select Available Time</h5>
                <div className='time-picker'>
                    <TimePickerComponent delayUpdate={true} placeholder='select a time' onChange={(e) => props.setTime(e.target.value)} /> to
                    <TimePickerComponent delayUpdate={true} placeholder='select a time' onChange={(e) => props.setTimes(e.target.value)} />
                </div>
                <div style={{ textAlign: "center", padding: "10px" }}>
                    {props.values && props.time && props.times ? <Button onClick={props.handleSubmit} variant='contained'>Submit</Button> : <Button variant='contained' disabled>Submit</Button>}
                </div>
            </div>
        </div>
    )
}

export default DateTime