import { Button } from '@mui/material';
import React from 'react'
import { experts, pricingforTier2, pricingforTier3, tierExp, tierExp1, tierExp2 } from '../../constants/constant';
import { auth } from '../../firebase';

const Tiercards = (props) => {
    return (
        <div>
            <div className='tier_containers'>
                {
                    tierExp2 && tierExp2?.map((data, index) => (
                        <div onClick={()=>props.setActiveButtons(data)} key={index} style={{ boxShadow: props?.activeButtons === data ? "rgba(0, 0, 0, 1) 0px 0px 05px" : "", border: props?.activeButtons === data ? "1px solid " : (props?.profile?.tier === "TIER.T3" ? "1px solid" : "") }} onClick={() => { props.setActiveButtons(data) }} className={data.price === "FREE" ? "tier_options" : (data.price === pricingforTier2 ? "tier_options2" : (data.price === pricingforTier3 ? "tier_options3" : ""))}>
                            <div className='tier_headings'>
                                <h5 style={{ color: "#FFC734" }}>{data?.name}  <span style={{ fontFamily: "Pacifico", color: "#FFC734" }}>{data.subname}</span></h5>
                            </div>

                            <div className='frees'>
                                <h6 style={{ textAlign: "center" }}>INR {data.price}</h6>
                                <p>This is our POWERHOUSE! Unlock the best opportunities to engage & earn money</p>
                                {/* {
                                    props?.profile?.tier === "TIER.T3" ? <><Button variant='contained' disabled onClick={() => props.setActiveButtons(data)}>Select</Button></> : <><Button variant='contained' style={{ background: "#FFD25D" }} onClick={() => props.setActiveButtons(data)}>Select</Button></>
                                } */}
                            </div>
                            <div style={{ padding: "18px 0px" }}>
                                <div className='in_containers'>
                                    <div style={{ width: "100%", textAlign: "center" }}>
                                        <h5>{data.list}</h5>
                                        <br />
                                        <h5>{data.list1}</h5>
                                        <br />
                                        <h5>{data.list2}</h5>
                                        <br />
                                        <h5>{data.list3}</h5>
                                        <br />
                                        <h5>{data.list4}</h5>
                                    </div>

                                </div>
                            </div>
                        </div>
                    ))
                }
                {/* {
                    tierExp1 && tierExp1?.map((data, index) => (
                        <div onClick={() => { props.setActiveButtons(data) }} key={index} style={{ boxShadow: props.activeButtons === data ? "rgba(0, 0, 0, 1) 0px 0px 05px" : "", border: props.activeButtons === data ? "1px solid #0B5394" : (props.profile.tier === "TIER.T2" ? "1px solid" : "") }} className={data.price === "FREE" ? "tier_options" : (data.price === pricingforTier2 ? "tier_options2" : (data.price === pricingforTier3 ? "tier_options3" : ""))}>
                            <div className='tier_headings'>
                                <h5 style={{ color: "#895ECF" }}>{data.name}  <span style={{ fontFamily: "Pacifico", color: "#895ECF" }}>{data.subname}</span></h5>
                            </div>
                            <div className='frees'>
                                <h6 style={{ textAlign: "center" }}>INR {data.price}</h6>
                                <p>Our most popular tier and perfect to grow and nurture your connection with knowledge sharing.</p>

                                {props.profile.tier === "TIER.T2" || props.profile.tier === "TIER.T3" ? <><Button variant='contained' disabled onClick={() => props.setActiveButtons(data)}>Select</Button></> : <><Button style={{ background: "#895ECF" }} variant='contained' onClick={() => props.setActiveButtons(data)}>Select</Button></>
                                }


                            </div>
                            <div style={{ padding: "18px 0px" }}>
                                <div className='in_containers'>
                                    <div style={{ width: "100%", textAlign: "center" }}>
                                        <h5>{data.list}</h5>
                                        <br />
                                        <h5>{data.list1}</h5>
                                        <br />
                                        <h5>{data.list2}</h5>
                                        <br />
                                        <h5>{data.list3}</h5>
                                        <br />
                                        <h5>{data.list4}</h5>

                                    </div>

                                </div>

                            </div>
                        </div>

                    ))
                } */}
                {/* {
                    tierExp && tierExp?.map((data, index) => (
                        <div onClick={() => { props.setActiveButtons(data) }} key={index} style={{ boxShadow: props.activeButtons === data ? "rgba(0, 0, 0, 1) 0px 0px 05px" : "", border: props.activeButtons === data ? "1px solid #0B5394" : "" }} className={data.price === "FREE" ? "tier_options" : (data.price === pricingforTier2 ? "tier_options2" : (data.price === pricingforTier3 ? "tier_options3" : ""))}>
                            <div className='tier_headings'>
                                <h5 style={{ color: "#C95100" }}>{data.name}</h5>
                            </div>
                            <div className='frees'>
                                <h6 style={{ textAlign: "center" }}> {data.price}</h6>
                                <p>The perfect plan for you to just get started.</p>
                                {
                                    window.location.href === `http://localhost:3000/tags/${experts}/${auth?.currentUser?.uid}` ? <>
                                        {
                                            !props.profile?.tier === "TIER.T1" || props.profile?.tier === "TIER.T2" || props.profile?.tier === "TIER.T3" ? <><Button style={{ background: "#C95100" }} variant='contained' onClick={() => props.setActiveButtons(data)}>Select</Button></> : <><Button style={{ background: "#C95100" }} variant='contained' onClick={() => props.setActiveButtons(data)}>Select</Button></>
                                        }
                                    </> : <>
                                        {
                                            props.profile?.tier === "TIER.T1" || props.profile?.tier === "TIER.T2" || props.profile?.tier === "TIER.T3" ? <><Button style={{ background: "#C95100" }} variant='contained' onClick={() => props.setActiveButtons(data)}>Select</Button></> : <><Button style={{ background: "#C95100" }} variant='contained' onClick={() => props.setActiveButtons(data)}>Select</Button></>
                                        }
                                    </>
                                }
                            </div>
                            <div style={{ padding: "18px 0px" }}>
                                <div className='in_containers'>
                                    <div style={{ width: "100%", textAlign: "center" }}>
                                        <h5>{data.list}</h5>
                                        <p>{data.list1} </p>
                                    </div>

                                </div>

                            </div>
                        </div>

                    ))
                } */}


            </div>
        </div>
    )
}

export default Tiercards