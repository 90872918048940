import React, { useEffect, useState } from 'react'
import "../../css/RejectDocs.css"
import gif from "../../assets/icons/80164-reject-document-files.gif"
import CloseIcon from '@mui/icons-material/Close';
import { doc, onSnapshot } from 'firebase/firestore';
import { auth, db } from '../../firebase';
import DoneIcon from '@mui/icons-material/Done';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router';
import { RejectedRouting } from '../../Functions/functions';
import { experts } from '../../constants/constant';
const RejectDocs = (props) => {
    const [verifyDom, setVerifyDom] = useState([])
    const navigate = useNavigate()
    useEffect(() => {
        const unsub = onSnapshot(doc(db, experts, auth?.currentUser?.uid), async (doc) => {
            if (doc.data()) {
                setVerifyDom(doc.data())
            }
        });
        return () => unsub();
         // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [auth?.currentUser?.uid])
    const gotoVerify = () => {
        RejectedRouting(navigate, verifyDom)
    }
    return (
        <div>
            <div className='rejectDocs_title'>
                <h3>Documents Verification <br /> Failed</h3>
            </div>
            <div className='reject_gif'>
                <img alt="" src={gif} />
            </div>
            <p style={{ textAlign: "center" }}>Ooops! Something went wrong</p>
            <div className='reasonReject'>
                <p>Reasons for Rejection</p>
                <div>
                    <div className='reject_docs_uploaded'><CloseIcon style={{ color: "red" }} />
                        <p>Uploaded Documents are not correct</p></div>
                    {verifyDom?.unverified_domains?.length > 0 ? <h6 style={{ marginTop: "-10px", marginLeft: "40px", display: "flex" }}>{verifyDom?.unverified_domains && verifyDom?.unverified_domains.map((data, index) => (
                        <div key={index}>
                            <span>{(index ? "," : "") + data}</span>
                        </div>
                    ))}</h6> : <h6 style={{ marginTop: "-10px", marginLeft: "40px" }}>No Domains Rejected </h6>}
                </div>
                <div>
                    <div className='reject_docs_uploaded'><CloseIcon style={{ color: "red" }} />
                        <p style={{ color: "maroon" }}>Rejected Domains</p></div>
                    {verifyDom?.unverified_domains?.length > 0 ? <h6 style={{ marginTop: "-10px", marginLeft: "40px", display: "flex" }}>{verifyDom?.unverified_domains && verifyDom?.unverified_domains.map((data, index) => (
                        <div key={index}>
                            <span>{(index ? "," : "") + data}</span>
                        </div>
                    ))}</h6> : <h6 style={{ marginTop: "-10px", marginLeft: "40px" }}>No Domains Rejected </h6>}
                </div>
                <div>
                    <div className='reject_docs_uploaded'><DoneIcon style={{ color: "green" }} />
                        <span style={{ color: "green" }}>Domain Expertise</span><p> are Verified</p></div>
                    {verifyDom?.verified?.length > 0 ? <> <h6 style={{ marginTop: "-10px", marginLeft: "40px", display: "flex" }}>{verifyDom.verified && verifyDom.verified.map((data, index) => (
                        <div key={index}>
                            <span>{(index ? "," : "") + data}</span>
                        </div>
                    ))}</h6></> : <h6 style={{ marginTop: "-10px", marginLeft: "40px" }}>No Domains Verified</h6>}
                </div>
                <div style={{ textAlign: "center" }}>
                    <br />
                    {verifyDom?.unverified_domains?.length === 0 ? "" : <Button onClick={gotoVerify} style={{ background: " #0B5394" }} variant='contained'>Re - Upload</Button>}
                    <br />
                    <u style={{cursor:"pointer"}} onClick={() => props.setSecond(false)}>Back to explorer</u>
                </div>
            </div>
        </div>
    )
}

export default RejectDocs