import React from 'react'
import { connectRefinementList } from 'react-instantsearch-dom'
import "../../../css/Tags.css"
const RefineTags = ({ items, refine, hitsdata }) => {
  return (
    <>
      {
        hitsdata.length > 0 ? <>
          <div className='filter_option'>
            <h5 style={{ color: "rgba(0, 78, 150, 1)" }}>Results ({hitsdata.length})</h5>
          </div>
        </> : <>
          <div className='filter_option'>
            <h5 style={{ color: "rgba(0, 78, 150, 1)" }}>Tags</h5>
          </div>
          <div className='refine_tags'>
            {
              items.map((item, index) => (
                <div key={index} onClick={(e) => {
                  e.preventDefault()
                  refine(item.value)
                }} style={{ background: item.isRefined ? " rgba(0, 78, 150, 1)" : "", border: item.isRefined ? "1px solid white" : "0.5px solid rgba(11, 83, 148, 0.3)" }} className='refine_tags_map'>
                  <p style={{ color: item.isRefined ? "white" : "black" }} onClick={(e) => {
                    e.preventDefault()
                    refine(item.value)
                  }}>{item.label}</p>
                </div>
              ))
            }
          </div>
        </>
      }
    </>
  )
}

export default connectRefinementList(RefineTags)