import { Button } from '@mui/material'
import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import "../../css/OnBoardingExplorer.css"
import { NameOnboarding } from '../../Functions/functions'
const Name = () => {
  const navigate = useNavigate()
  const [name, setName] = useState()
  const { type, id } = useParams()
  const navigateToPreferred = async () => {
    NameOnboarding(id, name, navigate, type)
  }
  return (
    <div>
      <div className='newUser_container'>
        <div className='name_container_heading'>
          <h3>Thanks for providing your phone number</h3>
          <br />
          <h1>May we know your<br /> name?</h1>
          <br />
          <div className='name_input'>
            <input onChange={(e) => setName(e.target.value)} type='text_only' />
          </div>
          <br />
          <br />
          <br />
          <div className='name_button'>
            <Button onClick={navigateToPreferred} style={{ background: "#0B5394", borderRadius: 10 }} variant='contained' className='name_button_width'>Next</Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Name
