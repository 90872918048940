import React from 'react'
import "../../css/Block.css"
import fake from "../../assets/images/icons8-verified-account-96-2 1.png"
import rude from "../../assets/images/icons8-response-100 1.png"
import wrong from "../../assets/images/icons8-no-hidden-fees-96-2 1.png"
import time from "../../assets/images/icons8-music-time-96 1.png"
import { Button, Checkbox } from '@mui/material'
const Block = () => {
        return (
                <div className='bloc_user'>
                        <div className='block_container'>
                                <div className='block_upper'>
                                        <h5>Block and Report this person</h5>
                                        <p> Don’t worry, your feedback is safe <br /> and they won’t know that you’ve <br /> reported them.</p>
                                </div>
                                <div className='middle'>
                                        <div className='middle_img'>
                                                <img alt="" src={fake} /> &nbsp;
                                                <span>Fake profile</span>
                                        </div>
                                        <div className='middle_title'>

                                                <Checkbox />
                                        </div>
                                </div>
                                <div className='middle'>
                                        <div className='middle_img'>
                                                <img alt="" src={rude} /> &nbsp;
                                                <span>Rude or abusive behaviour</span>
                                        </div>
                                        <div className='middle_title'>
                                                <Checkbox />
                                        </div>
                               </div>
                                <div className='middle'>
                                        <div className='middle_img'>
                                                <img alt="" src={wrong} /> &nbsp;
                                                <span>Wrong Consultation Fee</span>
                                        </div>
                                        <div className='middle_title'>

                                                <Checkbox />
                                        </div>
                                </div>
                                <div className='middle'>
                                        <div className='middle_img'>
                                                <img alt="" src={time} /> &nbsp;
                                                <span>Wrong Timings </span>
                                        </div>
                                        <div className='middle_title'>
                                                <Checkbox />
                                        </div>
                                </div>
                                <div className='footer'>
                                        <Button variant='contained'>Submit</Button>
                                </div>
                        </div>
                </div>
        )
}

export default Block