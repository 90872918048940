import React from 'react'
import SplitPayment from '../SplitPayment/SplitPayment'
import "../../css/Splits.css"
const BankAccountDetails = (props) => {
  return (
    <div className='fromSettingscontainer'>
      <SplitPayment snackbarStart={props.snackbarStart} settings="fromSettings" />
    </div>

  )
}

export default BankAccountDetails
