import { Button } from '@mui/material'
import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import "../../css/OnBoardingExplorer.css"
import { AgeOnboarding } from '../../Functions/functions'
const Age = () => {
    const navigate = useNavigate()
    const [age, setAge] = useState()
    const { type, id } = useParams()
    const navigateToState = async() => {
        AgeOnboarding(id,age,navigate,type)
    }
    return (
        <div className='newUser_container'>
            <div className='age_heading'>
                <h4>Wonderful</h4>
                <h1>How old are  you ?</h1>
                <br />
                <br />
                <div className='name_input'>
                    <input onChange={(e) => setAge(e.target.value)} type='text_only' />
                </div>
                <br />
                <br />
                <div className='name_button'>
                    <Button onClick={navigateToState} style={{ background: "#0B5394", borderRadius: 10 }} variant='contained' className='name_button_width'>Next</Button>
                </div>
            </div>

        </div>
    )
}

export default Age
