import { Avatar, IconButton } from '@mui/material'
import React, { useState } from 'react'
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { auth } from '../../firebase';
import VideoDevice from './VideoDevice';
import moment from 'moment';
import Suspend from '../Profile/Suspend';
const AllSessions = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [canceldata, setCancelData] = useState()
  const handleClick = (event, data) => {
    setAnchorEl(event?.currentTarget);
    setCancelData(data)
   

  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
   <> <br/>
   <br/>
   <div className='ver_session_container'>
     {
       props.filterDate && props.filterDate.map((data, index) => (
         <div key={index} className='ver_card'>
           <div className='ver_card_avatar'>
             <Avatar style={{
               height: "58px",
               width: "58px",
               margin: "auto"
             }} />
             <h6 onClick={() => setCancelData(data)} className='avatarsett_icon'>
               <IconButton
                 aria-label="more"
                 id="long-button"
                 aria-controls={open ? 'long-menu' : undefined}
                 aria-expanded={open ? 'true' : undefined}
                 aria-haspopup="true"
                 onClick={handleClick}
               >
                 <MoreVertIcon style={{ width: 15, height: 40 }} />
               </IconButton>


             </h6>
           </div>
           <div className='ver_card_right'>
             <div style={{ display: "flex", justifyContent: "space-between" }}>
               {
                 auth?.currentUser?.uid !== data.seekerId ? <>
                   <h5>Session with {data.seeker_name}</h5>
                 </> : <h5>Session with {data.eu_name}</h5>
               }
               <h6 onClick={() => setCancelData(data)} className='sett_icon'>
                 <IconButton
                   aria-label="more"
                   id="long-button"
                   aria-controls={open ? 'long-menu' : undefined}
                   aria-expanded={open ? 'true' : undefined}
                   aria-haspopup="true"
                   onClick={handleClick}
                 >
                   <MoreVertIcon />
                 </IconButton>

               </h6>
             </div>
             <h6>Date : {moment(data.from).format('MMM DD,YYYY')}</h6>
             <h6>Time : {moment(data.from).format('hh:mm a')} to {moment(data.to).format('hh:mm a')} </h6>
             <VideoDevice color='color' joinChannel={props.joinChannel} data={data} />
           </div>
         </div>
       ))
     }
     <Suspend setSecond={props.setSecond} canceldata={canceldata} anchorEl={anchorEl} handleClose={handleClose} />
   </div></>
  )
}

export default AllSessions