import { Button } from '@mui/material'
import React from 'react'
import "../../css/Upcoming.css"

const VideoDevice = (props) => {
    const joinChanne = async (data) => {
        props.joinChannel(data)
    }
    return (
        <div className='join_sess_button'>
            <div style={{justifyContent:props.color?"right" :"center"}} className='join_all_session'>
            <h6>Upcoming Session</h6>
                <Button  onClick={() => joinChanne(props.data)} style={{ boxShadow: "2px 2px 4px 0px rgba(0, 0, 0, 0.25)",borderRadius:12, background: props.color === 'color' ? "#0B5394" : "#03CE55", color: props.color === 'color' ? "white" : "white" }} className='session_button' variant='contained'>Join</Button>
            </div>
            {/* {new Date(props.data.from)===new Date() ? <Button  onClick={() => joinChanne(props.data)} style={{ boxShadow: "2px 2px 4px 0px rgba(0, 0, 0, 0.25)", background: props.color==='color' ? " #0B5394" :"white", color:props.color==='color' ? "white" : "rgba(151, 114, 196, 1)" }} className='session_button' variant='contained'>Join</Button> : <Button disabled  style={{background:"gray"}} className='session_button' variant='contained'>Join</Button>} */}
        </div>
    )
}

export default VideoDevice