import React from 'react'
import { useLocation } from 'react-router'
const Payment = () => {
  const { state } = useLocation()
  console.log(state)
  return (
    state === true ? "gggg" : <div
      className="dropin-parent"
      id="drop_in_container"
      style={{ minHeight: '600px' }}
    >
    </div>
  )
}

export default Payment