import React, { useEffect, useState } from 'react'
import OTPInput from "otp-input-react";
import { Button } from '@mui/material';
import { useNavigate } from 'react-router';
import { useUserAuth } from '../../context/AuthContext';
import { otpverification, signinOTP } from '../../Functions/functions';
import Countdown from "react-countdown"
const OTP = (props) => {
    const { typeOfUser } = useUserAuth()
    const navigate = useNavigate()
    const [errorMessage, setErrorMessage] = useState()
    const [loginButton, setLoginButton] = useState(false)
    const { setUpRecaptcha } = useUserAuth()
    const [flag, setFlag] = useState(false)
    const [confirmObj, setConfirmObj] = useState("")
    const [counter, setCounter] = React.useState(45);
    const verifyOTP = async () => {
        otpverification(props, navigate, typeOfUser, setErrorMessage, props.euset)
    }
    const recap = () => {
        signinOTP(props.phone, setLoginButton, setUpRecaptcha, setFlag, setConfirmObj, "")
        setCounter(60)
    }
    useEffect(() => {
        counter > 0 && setTimeout(() => setCounter(counter - 1), 1000)
    }, [counter])
    return (
        <>
            {
                props.flag === true ? <>
                    <form>
                        <div className={props.instant ? 'instantotpscreen' : "otpscreen"}>
                            {props.instant ? "" : <h1>OTP Verification</h1>}
                            {props.instant ? "" : <>
                                <h6>Enter the OTP send to</h6>
                                <h6>+{props.phone}</h6></>}
                            <OTPInput value={props.otp} onChange={props.setOTP} OTPLength={6} otpType={props.instant ? 'numbers' : 'number'} />
                            <br />
                            {counter === 0 ? <><h6 onClick={recap}>Resend OTP</h6></> : <h6>Resend code in {counter} seconds</h6>}
                            <div id='recaptcha-container'>
                            </div>
                            <br />
                            <br />
                            {props.instant ? "":  <Button onClick={verifyOTP} style={{ background: "white", color: "#0B5394", borderRadius: "10px" }} variant='contained'>Verify OTP</Button>}
                        </div>
                    </form>
                </> : ""
            }
        </>
    )
}

export default OTP