import React, { useEffect, useState } from 'react'
import ReactPlayer from 'react-player'
import Model from '../../constants/Model'
import "../../css/Content.css"
import { HomePosts } from '../../Functions/functions'
const Content = (props) => {
    const [open, setOpen] = useState(false)
    const [dynamic, setDynamic] = useState()
    const [linkTo, setLinkTo] = useState()
    useEffect(() => {
        HomePosts(setDynamic, props)
    }, [dynamic,props])
    const redirectTo = (data) => {
        setOpen(true)
        setLinkTo(data)
    }
    
    return (
        <div >
            <div >
                <div className='admin_content' >
                    {dynamic?.map((data, index) => (
                        data?.status === "Now Showing" ? <div key={index} style={{ marginTop: 40 }} className='admin_content_img'>
                            <img onClick={() => redirectTo(data)} src={data?.img_url} alt="" />
                        </div> : ""
                    ))}
                </div>
            </div>
            <Model
                close={() => setOpen(false)}
                show={open}
                component={<>
                    {linkTo?.type === "Youtube Video" ? <ReactPlayer playing={true} controls={true} width="100%" height="100%" url={linkTo?.link} /> : (linkTo?.type === "Image" ?
                        <img style={{ width: "100%", height: "100%" }} alt="" src={linkTo?.img_url} />
                        : <iframe
                            title='player'
                            seamless width="100%"
                            height="100%"
                            src={linkTo?.link}
                        />)}
                </>}
            />
        </div>
    )
}

export default Content
