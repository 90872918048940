import React from 'react'

import { facebookicon, insta, twittericon } from '../../constants/constant'
import "../../css/SocialMedia.css"

const SocialMedia = () => {
  return (
    <div className='socialmedia_icons'>
            <h4 style={{fontSize:13}}>Follow us on : </h4>
            <div className='icons_media_social'>
                    <div className='media_grid'>
                            <a style={{background:"none"}} href={insta.Link}> {insta.icon}</a>
                    </div>
                    <div className='media_grid'>
                    <a style={{background:"none"}} href={facebookicon.Link}> {facebookicon.icon}</a>
                    </div>
                    <div className='media_grid'>
                    <a style={{background:"none"}} href={twittericon.Link}> {twittericon.icon}</a>
                    </div>
            </div>
    </div>
  )
}

export default SocialMedia