import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { business, singlePostsOnly } from '../../Functions/functions'
import Content from '../AdminPosts/Content'
import Categories from '../Posts/Categories'
import Search from '../Search/Search'
import "../../css/Categorycontent.css"
import Explore from './Explore'
import Courses from '../Webinar/Courses'
import { Col, Container, Row } from 'react-bootstrap'
const CategoryPage = () => {
  const { name } = useParams()
  const [sub, setSub] = useState([])
  const [businesspost, setBusinessPost] = useState(null)
  useEffect(() => {
    singlePostsOnly(setSub, name)
  }, [name])
  const fetchingPosts = async () => {
    await business(sub, setBusinessPost)
  }
  useEffect(() => {
    const timer = setTimeout(() => {
      fetchingPosts()
    }, 100);
    return () => clearTimeout(timer);
  }, [sub.length > 0])
  return (
    <div className='cat_scrollbar'>
      <Search name={name} />
      <Container>
        <Row>
          <Col>
            <Content name={name} />
            <Categories title={name} post={businesspost} />
            <Courses />
            <Explore post={businesspost} />
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default CategoryPage
