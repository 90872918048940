import React from 'react'
import StarIcon from '@mui/icons-material/Star';
import { Avatar } from '@mui/material';
import revImg from "../../assets/images/Rectangle 279-1.png"
const Review = (props) => {
    return (
        <div>
            {props.rate?.length > 0 ? <>
                <div className='stars_consult'>
                    {
                        props.rate && props.rate.map((data, item) => (
                            <div key={item} className='re_details'>
                                <div className='re_container'>
                                    <div className='res_avatar'>
                                        <Avatar />
                                        <h5>{data.seeker_name}</h5>
                                    </div>
                                    <div className='re_rating'>
                                        <div className='re_rating_points'>
                                            <StarIcon style={{
                                                width: "27px",
                                                height: "27px",
                                                marginTop: "2px",
                                                color: "#FFD966"
                                            }} />
                                            <p>{data.rating}</p>
                                        </div>

                                    </div>
                                </div>
                                <div className='re_text'>
                                    <p>{data.feedback}</p>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </> : <>
            <div className='session_dummy_icon'>
                        <img alt="" src={revImg}/>
                        <br/>
                        <br/>
                        <h5>No Reviews Available</h5>
                    </div>
            </>}
        </div>
    )
}

export default Review