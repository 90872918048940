import { Button } from '@mui/material'
import React from 'react'
import { addCustomdomain, addCustomdomaininTier1 } from '../../Functions/functions'

const CustomDomain = (props) => {
  const addCustomDomain = () => {
    if (props?.profile?.tier === "TIER.T1" || props.value?.tier === "TIER.T1") {
      addCustomdomaininTier1(props.checks).then(() => {
        addCustomdomain(props.id, props.checks)
      })
    }
    else {
      addCustomdomaininTier1(props.checks)
      props.check.push(props.checks)
      props.setcheck([...props.check])
    }

  }
  return (
    <div style={{ textAlign: "center" }}>
      <h6>{props?.checks}</h6>
      <Button onClick={addCustomDomain} variant='contained'>Add custom Domain</Button>
    </div>
  )
}

export default CustomDomain