import { Avatar, Button } from '@mui/material';
import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router';
import { cashfreeProd, cashfreeSandbox } from 'cashfree-dropjs';
import { tier, tier2, tier3 } from '../../constants/constant';
import { getPay } from '../../API/api';
import "../../css/VerifiedEUU.css"
import { seekerCards } from '../../Functions/functions';
import Sessions from './Sessions';
import { Col, Container, Row } from 'react-bootstrap';
import StarIcon from '@mui/icons-material/Star';
import EditIcon from '@mui/icons-material/Edit';
const SeekerProfile = (props) => {
    const [activeButtons, setActiveButtons] = useState("");
    const navigate = useNavigate()
    const { types, id } = useParams()
    // const [isProd, setIsProd] = useState(true);
    const isProd = true
    const payment = async (data) => {
        seekerCards(navigate, types, id, props?.type, getPay, isProd, cashfreeProd, cashfreeSandbox, data, props?.profile)
    }
    return (
        <div className='seekersessandtier'>
            <div className='seeker_boards'>
                {
                    tier3?.map((data, item) => (
                        <div key={item}   className='seeker_cards3'>
                            <div className='inner_circle_seeker'>
                                <h1>{data.name}</h1>
                                <br />
                                <Button onClick={()=>payment(data)}  variant='contained' style={{ background: "white", color: "#BCA4E4" }}>Rs {data.price}</Button>
                            </div>
                        </div>
                    ))
                }
                
            </div>
            <div className='seekerandsession'>
                  <Sessions from="seeker" sess={props?.sess} />
                  
            </div>
            {/* <div style={{ margin: "auto", textAlign: "center", width: "100%", padding: "10px 20px" }}>
                <Button onClick={payment} variant='contained' style={{ width: "140px", borderRadius: "15px", background: "#D4DAFF", color: "#0B5394" }}>NEXT</Button>
            </div> */}
        </div>
    )
}

export default SeekerProfile