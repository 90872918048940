import { Button } from '@mui/material';
import React, { useState } from 'react'
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import { useNavigate, useParams } from 'react-router';
import { states } from '../../constants/constant';
import "../../css/OnBoardingExplorer.css"
import { StateOnboarding } from '../../Functions/functions';
const State = () => {
    const [addState, setAddState] = useState()
    const navigate = useNavigate()
    const { type, id } = useParams()
    const navigateToCategory = async () => {
        StateOnboarding(id,addState,navigate,type)
    }
    return (
        <div className='newUser_container'>
            <div className='state_heading'>
                <h2>Where are you from ?</h2>
                <br />
                <Dropdown onChange={(data) => setAddState(data)} className='state_dropdown' options={states} placeholder="Select your state" />
                <br />
                <br />
                <div className='name_button'>
                    <Button onClick={navigateToCategory} style={{ background: "#0B5394", borderRadius: 10 }} variant='contained' className='name_button_width'>Next</Button>
                </div>
            </div>
        </div>
    )
}

export default State
