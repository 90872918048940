import React, { useState } from 'react'
import "../../css/FAQ.css"
import ReactPlayer from 'react-player/youtube'
import YouTubeIcon from '@mui/icons-material/YouTube';
import { videos } from '../../constants/constant';
import Model from '../../constants/Model';
const FAQ = () => {
    const [search, setSearch] = useState("")
    const [open, setOpen] = useState(false)
    const [url, setURL] = useState([])
    return (
        <div>
            <div style={{ textAlign: "center", padding: "10px" }}>
                <input style={{ maxWidth: 500, borderRadius: "20px", padding: "20px", background: "white" }} placeholder='search' onChange={(e) => setSearch(e.target.value)} type="text" />
            </div>
            {videos && videos?.filter((val, key) => {
                if (val.title.toLowerCase().includes(search.toLowerCase())) {
                    return val
                }
                return false
            })?.map((dat, index) => {
                return (

                    <div key={index} className='faq'>
                        <div className='faq_left'>
                            <img alt="" onClick={() => { setOpen(true); setURL(dat) }} src={dat.image} />
                            <YouTubeIcon onClick={() => { setOpen(true); setURL(dat) }} className='button_play' />
                        </div>
                        <div className='faq_right'>
                            <h4 onClick={() => { setOpen(true); setURL(dat) }}>{dat.title}</h4>
                        </div>

                    </div>

                )
            })}
            <Model
               
                show={open}
                 close={() => setOpen(false)}
                component={<div className='player-wrapper'>
                    <ReactPlayer width="100%"
                        height="100%"
                        url={url.link}
                        className='react-player'
                        playing={true}
                        controls={true}
                    />
                </div>}
                size='lg'
                style={{ margin: "100px auto" }}
               
            />
        </div>
    )
}

export default FAQ