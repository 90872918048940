import React, { useEffect, useState } from 'react'
import "../../css/Search.css"
import s2 from "../../assets/images/image 493splashScreen.png"
import s3 from "../../assets/images/Group 399.png"
import s1 from "../../assets/images/image 495splashScreen.png"
import s4 from "../../assets/images/image 493splashScreen-5.png"
import helpicon from "../../assets/images/icons8-help-96 1.png"
import s5 from "../../assets/images/image 496splashScreen.png"
import bLogo from "../../assets/images/bedot.logo.webp"
import bLogoDesktop from "../../assets/images/logo 2 transparent png (1).png"
import HomeIcon from '@mui/icons-material/Home';
import { Modal } from 'react-bootstrap'
import FAQ from '../FAQ/FAQ'
import { Button } from '@mui/material'
import { useNavigate, useParams } from 'react-router'
import Model from '../../constants/Model'
import Header from '../Header'
import { experts, explorer, smallScreen } from '../../constants/constant'
import { useUserAuth } from '../../context/AuthContext'
import SearchIcon from '@mui/icons-material/Search';
import SettingsIcon from '@mui/icons-material/Settings';
import NotificationsIcon from '@mui/icons-material/Notifications';
import PersonIcon from '@mui/icons-material/Person';
import slider from "../../assets/icons/icons8-slider-90 2.png"

import { auth } from '../../firebase'
import { getUserDetails } from '../../Functions/functions'
import { Link } from 'react-router-dom'
const SearchBar = (props) => {
    const navigate = useNavigate()
    const { getUserType } = useUserAuth()
    const [open, setOpen] = useState(false)
    const [type, setType] = useState(experts)
    const [profile, setVerifyProfile] = useState()
    const [isSmallScreen, setIsSmallScreen] = useState(false);
    const { types } = useParams()
    const getProfileById = async () => {
        await getUserType(setType, auth?.currentUser?.uid)
    }

    useEffect(() => {
        getUserDetails(setVerifyProfile, setType, getProfileById, getUserType)

    }, [])
    useEffect(() => {
        smallScreen(setIsSmallScreen, 500)
    }, [])

    const navigationTabs = [{

        id: 1,
        icon: <HomeIcon style={{ color: "#0B5394" }} />,
        name: "Home",
        link: `/home`
    },
    {
        id: 2,
        icon: <NotificationsIcon style={{ color: "#0B5394" }} />,
        name: "Notifications",
        link: `/notifications/${type}/${auth?.currentUser?.uid}`
    },
    {
        id: 3,
        icon: <PersonIcon style={{ color: "#0B5394" }} />,
        name: "Profile",
        link: `/userProfile/${type}/${auth?.currentUser?.uid}`
    },
    {
        id: 4,
        icon: <SettingsIcon style={{ color: "#0B5394" }} />,
        name: "Settings",
        link: `/settings/${type}/${auth?.currentUser?.uid}`,
        exp: types ? types : type
    },
    {
        id: 5,
        icon: <SearchIcon style={{ color: "#0B5394" }} />,
        link: `/searchCard`
    },
    ]
    return (
        <>
            <div style={{ height: props?.name ? 130 : "" }} className={props.from === "Home" ? 'searchBarNewHome' : 'searchBarNew'}>

                <div className={props.from == "Home" ? 'searchNewPropHome' : 'searchNewProp'}>
                    <img src={s1} />
                </div>
                <div className={props.from == "Home" ? 'searchNewProp1Home' : 'searchNewProp1'}>
                    <img src={s3} />
                </div>
                {props.from == "Home" ?
                    <>
                        <div className="searchNewProp3Home">
                            <img src={s4} />
                        </div>

                    </> : <div className={props.from === "Home" ? 'searchNewProp2Home' : 'searchNewProp2'}>
                        <img src={s2} />
                    </div>}
                <div style={{ gap: props.from === "Home" ? "" : isSmallScreen ? 0 : 40 }} className={props.from === "Home" ? "searchNewLogoHome" : isSmallScreen ? "searchNewLogoSmall" : "searchNewLogo"}>
                    <img onClick={() => navigate(`/home`)} style={{ cursor: "pointer", height: props.from ? "" : isSmallScreen ? "40px" : 55, width: props.from ? "" : isSmallScreen ? "40px" : "", margin: props.from ? "" : isSmallScreen ? "auto" : "" }} src={props.from === "Home" ? bLogo : bLogoDesktop} alt="" />
                    {props.from === "Home" ? <br /> : ""}
                    {props.from === "Home" ? <><div onClick={() => navigate('/searchCard')} className={props.from === "Home" ? 'searchNewContainerHome' : 'searchNewContainer'}>
                        <div style={{ marginTop: props.from === "Home" ? 15 : "" }} className="search-container">
                            {props.searchcard ? <input value={props.currentRefinement} onChange={event => props.refine(event.currentTarget.value)} type="textE" placeholder="Search" /> : <input type="textE" placeholder="Search" />}
                            <i className="fa fa-search search-icon"></i>
                        </div>

                    </div></> : <>
                        <div onClick={() => navigate('/searchCard')} className={props.from === "Home" ? 'searchNewContainerHome' : 'searchNewContainer'}>
                            <div className="search-container">
                                {props.searchcard ? <input value={props.currentRefinement} onChange={event => props.refine(event.currentTarget.value)} type="textE" placeholder="Search" /> : <input type="textE" placeholder="Search" />}
                                <i className="fa fa-search search-icon"></i>
                            </div>
                        </div>
                        {isSmallScreen ? <> <div onClick={() => setOpen(true)} className='searchNewHelp'>
                            <p>Help </p>
                            <img src={helpicon} alt="" />
                        </div>


                        </> : <div style={{ display: "none" }}></div>}
                    </>}
                    {/* {
                        props.fromfilter ? <><div className='filter_box_image'>
                            <img alt="" style={{ width: "fit-content" }} src={slider} />
                        </div></> : ""
                    } */}
                    {isSmallScreen ? <>
                    </> : <div className={props.from === "Home" ? 'searchNewFiltersHome' : 'searchNewFilters'}>
                        {/* <div onClick={() => props.setSecond(true)}>sss</div> */}
                        {props.from ? "" : <>
                            <div className='navigationtabs_'>
                                {navigationTabs?.map((data, index) => (
                                    <div key={index}>
                                        {!data.name ? "" : data.exp === explorer ? <div style={{ display: "none" }}></div> : <Link to={`${data.link}`}><li style={{ borderBottom: window.location.pathname === data.link ? "2px solid #0B5394" : "", color: "#0B5394", fontWeight: "600" }} key={data.id}>{data.name}</li></Link>}
                                    </div>
                                ))}

                            </div>
                        </>}
                        <div></div>

                    </div>

                    }
                </div>
                {props?.name ? <h4 style={{ color: "#0B5394", fontWeight: 700 }}>{props?.name}</h4> : ""}

                <div style={{ padding: window.location.pathname === '/home' ? "20px" : 10 }} onClick={() => setOpen(true)} className='searchNewHelp'>
                    <p>Help </p>
                    <img src={helpicon} alt="" />
                </div>
                <Model
                    show={open}
                    close={() => setOpen(false)}
                    button="no"
                    component={<><FAQ /></>}
                    sign="cross"
                />

            </div>

        </>


    )
}

export default SearchBar
