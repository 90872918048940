import { Avatar, Button } from '@mui/material'
import moment from 'moment'
import React, { useState } from 'react'
import { aboutWebinar } from '../../constants/constant'
import "../../css/Courses.css"
import group from "../../assets/images/Group 596.png"
import group1 from "../../assets/images/Group 597.png"
import rect from "../../assets/images/Rectangle 361.png"
import Model from '../../constants/Model'
import Payment from "../Requests/Payment"
import WebinarConfirmed from './WebinarConfirmed'
import { useNavigate } from 'react-router'
const WebinarBooking = (props) => {
  const [open, setOpen] = useState(false)
  const [second, setSecond] = useState(false)
  const copylinkToClipboard = () => {
    navigator.clipboard.writeText(props?.bookingdata?.google_meet_link).then(() => {
      alert('link Copied')
    })
  }
  const navigate=useNavigate()
  return (
    <div>
      <div className='bookingwebinarImage'>
        <img src={props.bookingdata?.image_url} />
      </div>
      <br />
      {aboutWebinar.map((about, index) => (
        <h5 key={index} style={{ borderBottom: "1px solid #000000", width: "fit-content", padding: 5 }}>{about.title}</h5>
      ))}
      <div className='bookingwebtitle'>
        <h5>{props?.bookingdata?.title}</h5>
      </div>
      <div className='bookingwebDate'>
        <h5>Date : {moment(props?.bookingdata?.date?.toDate()).format("DD MMMM YYYY")}  | Time : {moment(props.bookingdata?.date.toDate()).format('hh:mm A')}</h5>
        <h5>{props.bookingdata?.duration} Min</h5>
        <h5>English</h5>
      </div>
      <br />
      <h3 style={{ fontSize: 23, color: "rgba(0, 0, 0, 0.44)", fontWeight: 500 }}>About the Expert</h3>
      <div onClick={()=>navigate(`/profile/${props?.bookingdata?.eu_id}`)} className='aboutthe_expert'>
        <div className='aboutexpert_icon'>
          <Avatar src={props.EUData?.profile_img_url} style={{ height: 120, width: 120 }} />
          <div className='aboutepxert_tier'>
            {props.EUData?.tier === "TIER.T2" ? <img src={group} /> : (props.EUData?.tier === "TIER.T3" ? <img src={group1} /> : "")}
          </div>
        </div>
        <div className='aboutexpert_info'>
          <div className='aboutexpertinfo_name'>
            <h5>{props?.bookingdata?.eu_name}</h5>
          </div>
          <br />
          <div className='aboutexpertinfo_text'>
            <h5>{props.EUData?.short_intro}</h5>
          </div>
        </div>

      </div>
      <br />
      {/* <div className='bookingwebinarbanner'>
        <img src="https://www.vtiger.com/blog/wp-content/uploads/2022/10/Banner-1-800x300.png" />
      </div> */}

      <br />
      <h3 style={{ fontSize: 23, color: "rgba(0, 0, 0, 0.44)", fontWeight: 500 }}>What You Learn</h3>
      <br />
      <div className='bookingwebdescritpion'>
        <h5>{props?.bookingdata?.description}</h5>
      </div>
      <br />
      <h3 style={{ fontSize: 23, color: "rgba(0, 0, 0, 0.44)", fontWeight: 500 }}>Who is this Webinar For</h3>
      <br />
      <div className='bookingwebdescritpion'>
        <h5>{props?.bookingdata?.webinarFor}</h5>
      </div>
      <br />
      <h3 style={{ fontSize: 23, color: "rgba(0, 0, 0, 0.44)", fontWeight: 500 }}>Requirements and materials</h3>
      <br />
      <div className='bookingwebdescritpion'>
        <h5>{props?.bookingdata?.requirements}</h5>
      </div>
      <br />

      {props.upcomingshow ? <><h3 style={{ fontSize: 23, color: "rgba(0, 0, 0, 0.44)", fontWeight: 500 }}>Your google meet link for Webinar</h3>
        <br /><div className='bookingwebdescritpion'>
          <div className='copylink'>
            <h5>{props?.bookingdata?.google_meet_link}</h5>
            <Button onClick={copylinkToClipboard} variant='contained' style={{ widht: 140, color: "white", borderRadius: 20, background: "#0B5394" }}>Copy</Button>
          </div>
        </div></> : ""}
      <br />

      <div className='bookingwebfees'>
        <h5>Rs.{props.bookingdata?.fees}</h5>
        {props.upcomingshow ? "" : <Button onClick={() => setOpen(true)} style={{ background: "#0B5394", color: "white" }} variant='contained'>Book</Button>}
      </div>
      <Model
        show={open}
        close={() => setOpen(false)}
        component={<><Payment setSecond={setSecond} type={props.type} data={props.EUData} webinar="webinarBooking" Book={props.bookingdata} /></>}
        size='md'
        onHide={() => setOpen(false)}
        button='no'
      />
      <Model
        show={second}
        close={() => setOpen(false)}
        component={<><WebinarConfirmed setSecond={setSecond} type={props.type} data={props.EUData} Book={props.bookingdata} /></>}
        size='lg'
        onHide={() => setOpen(false)}
        button='no'
      />
    </div>
  )
}

export default WebinarBooking
