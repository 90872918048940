import { Button } from '@mui/material'
import React, { useState } from 'react'
import Model from '../../constants/Model'
import "../../css/AvailableSlots.css"
import Checkout from './Checkout'
const Request = (props) => {
    const [open,setOpen]=useState(false)
    const [area,setArea]=useState()
    const submitRequests=()=>{
        if(props.celldata && props.getDate && props.timedata && area){
            setOpen(true)
        }
        else if(!area){
            props.snackbarStart("error","Please provide Details")
        }
        else{
            props.snackbarStart("error","Please provide Time and Date")
        }
    }
    return (
        <>
            <br />
            <h5 className='noticeperiod'>Note: Some timing is added by the expert, you can request a different timing as per your choice</h5>
            <br />
            <h5 className='reachingout'>Why are you reaching out to me ?</h5>
            <div className='number_of_slotss'>
                <textarea  onChange={(e)=>setArea(e.target.value)} style={{ width: "100%", height: 200, padding: 20, outline: "none", border: "1px solid silver", resize: "" }} />
            </div>
            <br />
            <Model
                close={() => setOpen(false)}
                show={open}
                size='lg'
                button="no"
                sign="cross"
                component={<Checkout timedata={props.timedata} area={area} type={props.type} id={props.id} profile={props.profile} euRatefor30={props.euRatefor30} eudata={props.eudata} celldata={props.celldata} getDate={props.getDate} />}
            />
            <div className='buttonofreachingout'>
                <Button onClick={submitRequests} style={{ background: "#0B5394", color: "white", borderRadius: 5 }} variant='contained'>Submit</Button>
            </div>
        </>

    )
}

export default Request
