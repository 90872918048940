import { TimePickerComponent } from '@syncfusion/ej2-react-calendars'
import React, { useEffect, useState } from 'react'
import "../../css/AvailableSlots.css"
import 'react-dropdown/style.css'
import moment from 'moment';
import Dropdown from 'react-bootstrap/Dropdown'
import { times } from '../../constants/constant';
import Request from './Request';
import DisplaySnackbar from '../../constants/DisplaySnackbar';
const Time = (props) => {
  const [timedata, setTimeData] = useState()
  const [getDate, setGetDate] = useState()
  const [same, setSame] = useState([])
  const snackbardata = {
    open: false,
    severity: "",
    message: "",
    statusCode: ""
  }
  const [snackbarOpen, setSnackbarOpen] = useState(snackbardata)
  const snackbarStart = (severity, message, st = "", sc = "") => {
    setSnackbarOpen({
      open: true,
      message: message,
      severity: severity,
      statusCode: `${st} ${sc}`
    })
  }
  const getData = (data) => {
    if (!props.celldata) {
      snackbarStart("error", "Select the Date from Calendar before proceeding")
    }
    else {
      setTimeData(data)
      setGetDate(new Date(moment(props.celldata.startTime).format('YYYY-MM-DD') + ' ' + data.time))
    }
  }
  let array = []
  let obj1 = {}
  useEffect(() => {
    props.rec.length > 0 ? props.rec.map((rec) => (
      <>
        {times.filter((d) => {
          if (d.time == (moment(new Date(rec.StartTime)).format("hh:mm A"))) {
            obj1 = {
              id: d.id,
              time: d.time,
              color: "green",
              status: rec.status,
              timeslot_id: rec.id,
              StartTime: rec.StartTime,
              EndTime: rec.EndTime
            }
            array.push(obj1)
          }
          setSame(array)
        })}
      </>
    )) : setSame(0)

  }, [props.rec, times])
  const con = times.concat(same)
  let sorting = con.sort((a, b) => (a.id < b.id ? -1 : Number(a.id > b.id)))
  const ids = sorting.map(o => o.id)
  const filtered = sorting.filter(({ id }, index) => !ids.includes(id, index + 1))
  return (
    <>
      <div className='timepickerCom'>
        <h5 className='selectTime'>Select Time<span>*</span></h5>
        <DisplaySnackbar
          open={snackbarOpen.open}
          closeSnackbar={() => { setSnackbarOpen({ open: false, severity: "", message: "" }) }}
          message={snackbarOpen.message}
          severity={snackbarOpen.severity}
          statusCode={snackbardata.statusCode}
        />
        <Dropdown>
          <Dropdown.Toggle style={{ width: "100%", background: "white", border: "1px solid silver", color: "black", borderRadius: "15px" }}  >
            {timedata?.time ? timedata?.time : "Select Time"}
          </Dropdown.Toggle>
          <Dropdown.Menu style={{ width: "100%", textAlign: "center", height: 300, overflow: "scroll" }}>
            {filtered.map((data) => (
              <>
                <Dropdown.Item onClick={() => getData(data)}><p style={{ background: data.status === "AVAILABLE" ? "#90EE90" : "", width: "100%" }}>{data?.time}</p></Dropdown.Item>
              </>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <Request snackbarStart={snackbarStart} timedata={timedata} type={props.type} id={props.id} profile={props.profile} euRatefor30={props.euRatefor30} eudata={props.eudata} getDate={getDate} celldata={props.celldata} />
    </>
  )
}

export default Time
