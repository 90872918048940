import React from 'react'
import Calendar from "../Calendar/Calendar"
const Availability = () => {
  return (
    <>
      <Calendar />
    </>
  )
}

export default Availability