import React, { useState } from 'react'
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import Popup from '../../constants/Popup';
import ContactUs from "../Settings/ContactUs"
const Contact = () => {
  const [open, setOpen] = useState(false)
  return (
    <div>
      <div className='phone_login'><LocalPhoneIcon onClick={() => setOpen(true)} className='color_phone_icons' style={{ width: 40, height: 40, color: "#0B5394", cursor: "pointer" }} /></div>
      <Popup
        onClose={() => setOpen(false)}
        open={open}
        content={<><ContactUs /></>}
      />
    </div>
  )
}

export default Contact
