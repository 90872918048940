import { Button } from '@mui/material'
import React from 'react'
import { Modal } from 'react-bootstrap'
import CloseIcon from '@mui/icons-material/Close';

const Model = (props) => {
    return (
        <div>
            <Modal    onHide={props.onHide} aria-labelledby="contained-modal-title-vcenter"
                centered show={props.show} size={props.size} fullscreen={!props.size}>
                {props.button === "no" ? "" : <Modal.Header style={{ background: "white" }}>
                    <h5>{props.title}</h5>
                    {/* <div style={{border:"1px solid",marginTop:40}}>
                        {props.button === 'no' ? <Contact /> : ""}
                    </div> */}
                    
                </Modal.Header>}
                <Modal.Body  style={{ background: "white",borderRadius:props.button==="no" ? 15 :"",boxShadow:props.button ==="no" ? "rgba(0, 0, 0, 0.35) 0px 5px 15px":"" }}>
                    {props.sign ? <><div style={{float:"right",color:"#0B5394"}}><CloseIcon onClick={props.close}/></div></> :""}
                    {props.component}

                </Modal.Body>
                {props.button === "no" ? "" : <Modal.Footer style={{ background: "white", padding: "20px" }}>
                    {props.Button ? props.Button : ""}
                    {props.button === 'no' ? "" : <Button variant='contained' style={{ background: props.color === "yes" ? "#FFD966" : "red", color: props.color === "yes" ? "black" : "white" }} onClick={props.close}>Close</Button>}
                </Modal.Footer>}

            </Modal>
        </div>
    )
}

export default Model