import React from 'react'
import './App.css';
import { Routes, Route } from "react-router"
import { AuthContextProvider } from './context/AuthContext';
import ClimbingBoxLoader from "react-spinners/SyncLoader"
import { useEffect, useState } from 'react';
import Logo from "./assets/images/logo 2 transparent png (1).png"
import Login from './Components/Authentication/Login';
import BoardingScreens from "../src/Components/OnBoarding/BoardingScreens"
import { Routing } from './Routes/global-constants';
import { Detector } from "react-detect-offline";
import NoInternet from './Components/NoInternet';
import moment from 'moment';
import Home from './Components/Posts/Home';
import NewUser from './Components/Authentication/NewUser';
import Profile from "./Components/Profile/Profile"
import VerifiedEuu from "./Components/Profile/VerifiedEuu"
import ExperienceUser from "./Components/ExperienceUser/ExperienceUser"
import Settings from "./Components/Settings/Settings"
import NewDomain from './Components/ExperienceUser/NewDomain';
import Introduction from './Components/ExperienceUser/Introduction'
import Verification from "./Components/ExperienceUser/Verification"
import Notifications from "./Components/Requests/Notifications"
import SearchCard from './Components/Search/SearchCard';
import Search from './Components/Search/Search';
import Reviews from "./Components/Rating/Reviews"
import Star from "./Components/Rating/Star"
import ReviewSuccess from './Components/Rating/ReviewSuccess';
import AvailableSlots from "./Components/Availability/AvailableSlots"
import Calendar from './Components/Calendar/Calendar';
import Payment from './Payment';
import Tags from "./Components/Profile/Tags"
import Confirmation from './Components/Confirmation';
import ConfirmedPayment from "./Components/Availability/ConfirmedPayment"
import SplitPayment from "./Components/SplitPayment/SplitPayment"
import { onMessageListener } from './firebase';
import CategoryPage from './Components/Categories/CategoryPage';
import Name from "./Components/OnBoarding/Name"
import Preferred from './Components/OnBoarding/Preferred';
import Age from './Components/OnBoarding/Age';
import State from './Components/OnBoarding/State';
import Topics from './Components/OnBoarding/Topics';
import MatchExpert from './Components/OnBoarding/MatchExpert';
import SuccessMatching from './Components/OnBoarding/SuccessMatching';
import SignUp from './Components/Authentication/SignUp';
import LandingPage from './Components/LandingPage/LandingPage';
import SessionConfirmed from './Components/Requests/SessionConfirmed';
import InstantExpert from './Components/InstantExpert/InstantExpert';
import PhoneNumber from './Components/OnBoarding/PhoneNumber';
import ConfirmedInstantPayment from './Components/InstantExpert/ConfirmedInstantPayment';
// import Dyte from './Components/Dyte';
export const ThemeContext = React.createContext(null)
function App() {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
    }, 1000)


  }, [])
  useEffect(() => {
    onMessageListener().then(payload => {
      console.log(payload);
    }).catch(err => console.log('failed: ', err));
  }, [])
  return (

    <>
     
      <Detector
        render={({ online }) => (
          <div>
            {online === true ? <>
              {moment.suppressDeprecationWarnings = true}
              {loading ? <>
                <div className="loading">
                  <img alt="" style={{ width: 90 }} src={Logo}></img><br />
                  <ClimbingBoxLoader size={7} loading={loading} color={"#0B5394"}></ClimbingBoxLoader>
                </div>
              </> : <>

                <div className='App'>
                  <AuthContextProvider>
                    <Routes>
                      <Route path={Routing.landing} element={<LandingPage />} />
                      <Route exact path={Routing.loginRoutes} element={<><Login /></>} />
                      <Route path={Routing.signupRoutes} element={<><SignUp /></>} />
                      <Route path={Routing.homeRoutes} element={<><Home /></>} />
                      <Route path={Routing.newUser} element={<><NewUser /></>} />
                      <Route path={Routing.onBoarding} element={<><BoardingScreens /></>} />
                      <Route path={Routing.profileRoutes} element={<><Profile /></>} />
                      <Route path={Routing.VerifiedEUURoutes} element={<><VerifiedEuu /></>} />
                      <Route path={Routing.apply} element={<><ExperienceUser /></>} />
                      <Route path={Routing.SettingsRoutes} element={<><Settings /></>} />
                      <Route path={Routing.next} element={<><NewDomain /></>} />
                      <Route path={Routing.short_intro} element={<><Introduction /></>} />
                      <Route path={Routing.verification} element={<><Verification /></>} />
                      <Route path={Routing.notifications} element={<><Notifications /></>} />
                      <Route path={Routing.searchCard} element={<><SearchCard /></>} />
                      <Route path={Routing.SearchRoutes} element={<><Search /></>} />
                      <Route path={Routing.reviews} element={<><Reviews /></>} />
                      <Route path={Routing.Rating} element={<><Star /></>} />
                      <Route path={Routing.reviewSuccess} element={<><ReviewSuccess /></>} />
                      <Route path={Routing.AvailableSlotsRoutes} element={<><AvailableSlots /></>} />
                      <Route path={Routing.CalendarRoutes} element={<><Calendar /></>} />
                      <Route path={Routing.payment} element={<><Payment /></>} />
                      <Route path={Routing.tags} element={<Tags />} />
                      <Route path={Routing.lottie} element={<><Confirmation /></>} />
                      <Route path={Routing.availablePayment} element={<><ConfirmedPayment /></>} />
                      <Route path={Routing.split} element={<><SplitPayment /></>} />
                      <Route path={Routing.newCat} element={<><CategoryPage /></>} />
                      <Route path={Routing.name} element={<><Name /></>} />
                      <Route path={Routing.preferred} element={<><Preferred /></>} />
                      <Route path={Routing.age} element={<><Age /></>} />
                      <Route path={Routing.state} element={<><State /></>} />
                      <Route path={Routing.topics} element={<><Topics /></>} />
                      <Route path={Routing.match} element={<><MatchExpert /></>} />
                      <Route path={Routing.matchSuccess} element={<><SuccessMatching /></>} />
                      <Route path={Routing.sessionConfirmed} element={<><SessionConfirmed/></>} />
                      <Route path={Routing.instantExpert} element={<><InstantExpert/></>} />
                      <Route path={Routing.phoneNumber} element={<><PhoneNumber/></>} />
                      <Route path={Routing.instantPaySuccess} element={<><ConfirmedInstantPayment/></>} />
                   
                    </Routes>
                  </AuthContextProvider>
                </div>
              </>}

            </> : <>
              <NoInternet />
            </>}
          </div>

        )}
      />
    </>

  );
}

export default App;
