import { Button } from '@mui/material'
import { doc, onSnapshot } from 'firebase/firestore'
import React, { useEffect, useState } from 'react'
import { experts } from '../../constants/constant'
import Popup from '../../constants/Popup'
import "../../css/CancelBooking.css"
import { db } from '../../firebase'
import { cancelBook } from '../../Functions/functions'
import CancelSuccess from './CancelSuccess'
const CancelBooking = (props) => {
    const [data, setData] = useState({})
    const [second, setSecond] = useState(false)
    useEffect(() => {
        const unsub = onSnapshot(doc(db, `${experts}/${props?.canceldata?.euId}/time_slots/${props?.canceldata?.timeslot_id}`), (doc) => {
            if (doc.data()) {
                setData(doc.data())
            }

        });
        return () => unsub()
    }, [data?.recurrenceId, props.canceldata?.euId, props?.canceldata?.timeslot_id])
    const CancelBooking = async () => {
        cancelBook(props, data, setSecond)
    }
    return (
        <div>
            <div className='cancel_booking'>
                <h2>Are you sure you want to <br /> cancel the session ?</h2>
                <p>You will not recieve the session fees</p>
                <div className='cancel_booking_buttons'>
                    <Button onClick={() => props.setOpen(false)} style={{ background: "#0B5394" }} variant='contained'>Back</Button>
                    <Button onClick={CancelBooking} style={{ background: "#EA8577" }} variant='contained'>Cancel</Button>
                </div>
            </div>
            <Popup
                open={second}
                onClose={() => { setSecond(false); props.setSecond(false) }}
                content={<CancelSuccess />}
            />
        </div>
    )
}

export default CancelBooking