import { Button } from '@mui/material'
import React from 'react'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
const Success = (props) => {
    return (
        <>
            <div style={{ textAlign: "center" }}>
                <CheckCircleIcon style={{ width: "50px", height: "40px", color: "green" }} />
            </div>
            <div style={{ textAlign: "center", color: "green", fontSize: "25px" }}>Great!</div>
            <div>
                <h5 style={{ color: "#0B5394" }}>Your data has been successfully saved</h5>
            </div>
            <div style={{ textAlign: "center" }}>
                <Button onClick={props.setThird} style={{ background: "#0B5394", borderRadius: "8px" }} variant='contained'>Okay</Button>
            </div>
        </>
    )
}

export default Success