import { Menu, MenuItem } from '@mui/material';
import React, {  useState } from 'react'
import Popup from '../../constants/Popup';
import OrderDetails from './OrderDetails';
const Orders = (props) => {
  const [open,setOpen]=useState(false)
  return (
    <div>
         <Menu
                id="menu-appbar"
                anchorEl={props.anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={Boolean(props.anchorEl)}
                onClose={props.handleClose}
            >
                <MenuItem onClick={()=>{setOpen(true);props.handleClose()}}>Session Details</MenuItem>              
            </Menu>
            <Popup
            open={open}
            onClose={()=>setOpen(false)}
            content={<OrderDetails orderdetails={props.orderdetails}   orderdata={props.orderdata} />}
            />
    </div>
  )
}

export default Orders
