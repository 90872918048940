import { Button } from '@mui/material'
import { doc, onSnapshot } from 'firebase/firestore'
import React, { useEffect } from 'react'
import { useState } from 'react'
import { useParams } from 'react-router'
import { experts } from '../../constants/constant'
import "../../css/Monetisation.css"
import { db } from '../../firebase'
import { addingRates } from '../../Functions/functions'
const Monetisation = (props) => {
    const { id } = useParams()
    const [rating, setRating] = useState([])
    const [monetisationRates, setMonetisationRates] = useState(rating?.price)
    useEffect(() => {
        const unsub = onSnapshot(doc(db, `${experts}/${id}/Pricing`, "p1"), (doc) => {
            setRating(doc.data())
        });
        return () => unsub();
    }, [rating?.price, id])
    const addMonetisation = async () => {
        addingRates(id, monetisationRates, rating, props.snackbarStart)
    }
    return (
        <div className='mon'>
            <div className='monetisation'>
                <h3>Monetisation Setting</h3>
                <>
                    <div className='mon_fifteen'>
                        &nbsp;&nbsp;<span>For 30 minutes</span>
                    </div>
                    <input defaultValue={rating?.price ? rating?.price : ""} onChange={(e) => setMonetisationRates(e.target.value)} style={{ borderRadius: "10px", background: "rgba(236, 238, 252, 0.5)", border: "0.5px solid #DADADA", marginTop: "-5px" }}
                        placeholder={`set your fee for 30 minutes per session`}
                        type='text'></input>
                </>
                <br />
                <br />
                <div className='mon_button'>
                    <Button onClick={addMonetisation} variant='contained' style={{ width: "100%", background: " #0B5394", borderRadius: "10px", height: "50px", fontSize: "20px" }}>Save</Button>
                </div>
            </div>
        </div>
    )
}

export default Monetisation