import ShoppingCart from '@mui/icons-material/ShoppingCart'
import { Avatar, Button } from '@mui/material'
import { cashfreeProd, cashfreeSandbox } from 'cashfree-dropjs'
import moment from 'moment'
import React from 'react'
import { useNavigate } from 'react-router'
import { getInstantPayment, getPayment } from '../../API/api'
import { InstantConnectPayment, sendingRequests, sendingRequestsToAllotedTIme } from '../../Functions/functions'
// import { checkoutpaying, Tier1Booking } from '../../Functions/functions'
import {  pricingforTier2 } from '../../constants/constant'
const Checkout = (props) => {
    const navigate = useNavigate()
    const isProd = true;
    const sendRequests = async () => {
        if (props.instant) {
            InstantConnectPayment(props, navigate, getInstantPayment, isProd, cashfreeProd, cashfreeSandbox)
        } else if (props.timedata.status === "AVAILABLE") {
            sendingRequestsToAllotedTIme(props, navigate, getPayment, cashfreeProd, cashfreeSandbox, isProd)
        } else { sendingRequests(props, navigate) }
    }
    return (
        <div>
            <div style={{ background: props.instant ? "" : props.timedata?.status === "AVAILABLE" ? "#0B5394" : "" }} className='checkout'>
                {props.instant ? <h3 style={{ textAlign: "center", color: "#0B5394" }}>Checkout</h3> : <div className='checkout_display'>
                    <div className='left_checkout'>
                        <Avatar
                            className='check_avatar'
                            style={{
                                height: "110px",
                                width: "110px",
                                margin: "auto"
                            }}
                            src={props?.eudata?.profile_img_url}
                        />
                    </div>
                    <div className='right_checkout'>
                        <h4>{props?.eudata?.username}</h4>
                        <h6 style={{ color: props.timedata?.status === "AVAILABLE" ? "white" : "" }}> Date: {new Date(props.celldata?.startTime).toDateString()}</h6>
                        <h6 style={{ color: props.timedata?.status === "AVAILABLE" ? "white" : "" }}> Time: {moment(new Date(props?.getDate).getTime()).format('hh:mm A')}</h6>
                        <h6 style={{ color: props.timedata?.status === "AVAILABLE" ? "white" : "" }}> 30 minutes</h6>
                    </div>
                </div>}
                <br />
                {props.instant ? "" : <h6 style={{ color: "white", fontSize: "15px", padding: "10px" }}>Session(s)</h6>}
                <div className='clean_code'>
                    <div className='clean_code_title'>
                        {props.instant ? <h6>Bedot instant Expert Connect</h6> : <h6 style={{ color: props.timedata?.status === "AVAILABLE" ? "white" : "" }}>Session Appointment<p>({new Date(props.getDate).toDateString()} {moment(new Date(props.getDate).getTime()).format(('hh:mm:A'))} - {moment(new Date(props.getDate).getTime()).add(30, 'm').format(('hh:mm:A'))} )</p></h6>}
                        {props.instant ? "" : <h6 style={{ color: props.timedata?.status === "AVAILABLE" ? "white" : "" }}>Convenience Fee</h6>}
                    </div>
                    <div className='clean_code_price'>
                        {props.instant ? <h5>&#8377;500</h5> : <>{props?.eudata?.tier === "TIER.T1" ? <h5 style={{ color: props.timedata?.status === "AVAILABLE" ? "white" : "" }}>&#8377;0</h5> : (props?.eudata?.tier === "TIER.T2" ? <h5 style={{ color: props.timedata?.status === "AVAILABLE" ? "white" : "" }}>&#8377;{pricingforTier2}</h5> : (props?.eudata?.tier === "TIER.T3" ? <h5 style={{ color: props.timedata?.status === "AVAILABLE" ? "white" : "" }}>&#8377;{props?.euRatefor30?.price}</h5> : ""))}
                            <h5 style={{ marginTop: 22, color: props.timedata?.status === "AVAILABLE" ? "white" : "" }}>&#8377; 20</h5></>}
                    </div>

                </div>
                <div style={{ borderTop: props.instant ? "1px solid #0B5394 " : props.timedata?.status === "AVAILABLE" ? "white" : "1px solid #0B5394" }}></div>
                <div className='check_total'>
                    <div className='check_total_left'>
                        <ShoppingCart className='shoppingcart' style={{ color: props.instant ? "#0B5394" : "white" }} />
                        <h6 style={{ color: props.instant ? "" : props.timedata?.status === "AVAILABLE" ? "white" : "" }}>Total:</h6>
                    </div>
                    <div className='check_total_right'>
                        {props.instant ? <h5>&#8377;500</h5> : <>{props?.eudata?.tier === "TIER.T1" ? <h5 style={{ color: props.timedata?.status === "AVAILABLE" ? "white" : "" }}>&#8377;{0+20}</h5> : (props?.eudata?.tier === "TIER.T2" ? <h5 style={{ color: props.timedata?.status === "AVAILABLE" ? "white" : "" }}>&#8377;{pricingforTier2 + 20}</h5> : (props?.eudata?.tier === "TIER.T3" ? <h5 style={{ color: props.timedata?.status === "AVAILABLE" ? "white" : "" }}>&#8377;{props?.euRatefor30?.price + 20}</h5> : ""))}</>}

                    </div>
                </div>
            </div>
            <div className='notes'>
                <h5>Your note</h5>
                <textarea disabled defaultValue={props?.area} style={{ width: "100%", height: "120px", outline: "none", border: "none", resize: "none", padding: "15px", background: "rgba(215, 232, 240, 0.6)", borderRadius: "20px" }} />
            </div>
            {props.instant ? <p style={{ padding: "5px", color: "rgba(0, 0, 0, 0.41)" }}>Please note:  The "48 hours or your money back" feature of Bedot Instant Expert Connect refers to two working days.
            </p> : ""}
            <div style={{ textAlign: "center" }}>
                {props.instant ? <Button onClick={sendRequests} style={{ background: "#0B5394", color: "white" }} variant='contained'>Pay &#8377;500</Button> : <>{props.timedata?.status === "AVAILABLE" ? <Button onClick={sendRequests} variant='contained' style={{ background: "#0B5394", color: "white" }}>
                    {props?.eudata?.tier === "TIER.T1" ? <h5> Pay&#8377;0</h5> : (props?.eudata?.tier === "TIER.T2" ? <h5>&#8377;{pricingforTier2 + 20}</h5> : (props?.eudata?.tier === "TIER.T3" ? <h5>Pay &#8377;{props?.euRatefor30?.price + 20}</h5> : ""))}
                </Button> : <Button onClick={sendRequests} variant='contained' style={{ background: "#0B5394", color: "white" }}>
                    Send Request
                </Button>}</>}
            </div>
        </div >
    )
}

export default Checkout