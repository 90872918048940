import React from 'react'
import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';
import "../css/Popcards.css"
const DomainCards = (props) => {
    return (
        <div>
            <div>
                <AwesomeSlider
                    organicArrows={false}
                    mobileTouch={true}
                >
                   {props.card1}
                   {props.card2}
                   {props.card3}
                   {props.card4}
                </AwesomeSlider>
            </div>
        </div>
    )
}

export default DomainCards