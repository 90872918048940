import React from 'react'
import "../../css/Settings.css"
const Options = () => {
  return (
    <div className='optionscontainer'>
            <img alt="" src={"https://firebasestorage.googleapis.com/v0/b/oraculi-90497.appspot.com/o/105345-setting-animation.gif?alt=media&token=8180b034-7c79-466a-92cd-bf00fd061831"}/>
            <br/>
            <br/>
            <h4>Customize the settings to your preference <br/>by accessing the sidebar menu.</h4>
    </div>
  )
}

export default Options
