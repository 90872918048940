import React, { useState } from 'react'
import { connectSearchBox } from 'react-instantsearch-dom'
import HelpIcon from '@mui/icons-material/Help';
import hLogo from "../../../assets/images/bedot.logo.webp"
import bLogo from "../../../assets/images/logo 2 transparent png (1).png"
import SplashJ from '../../Splash/SplashJ';
import FAQ from '../../FAQ/FAQ';
import slider from "../../../assets/icons/icons8-slider-90 2.png"
import { Modal } from 'react-bootstrap'
import { Button } from '@mui/material';
import Search from '../Search';
const SearchBox = ({ currentRefinement, refine, setSecond, setActiveButtons, setGenderButtons, setSlider, setSessionSlider,sessionslider,toggle,fromSearch }) => {
    const [open, setOpen] = useState(false)
    return (
        <>
            <Search fromSearch={fromSearch} currentRefinement={currentRefinement} refine={refine} sessionslider={sessionslider} toggle={toggle} setSecond={setSecond} setActiveButtons={setActiveButtons} setGenderButtons={setGenderButtons} setSlider={setSlider} setSessionSlider={setSessionSlider} searchcard="searchcard"/>
            {/* <div className='search_screen' >
                <SplashJ />
                <div className='home_logo'>
                    <img alt="" srcSet={hLogo}  />
                    <div>
                        <div className='filter_box'>
                            <div className="box">
                                <i className="fa fa-search" aria-hidden="true"></i>
                                <input value={currentRefinement} onChange={event => refine(event.currentTarget.value)} placeholder='search' type="texts" name="" />
                            </div>
                            &nbsp; &nbsp; &nbsp;
                            <div className='boxoffilters'>
                                <div className='filter_box_image' onClick={() => setSecond(true)} style={{ margin: "auto", padding: 12, background: "#0B5394", borderRadius: "20px" }}>
                                    <img alt="" style={{ width: "fit-content" }} src={slider} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div onClick={() => setOpen(true)} className='need_help'>
                        <HelpIcon style={{ color: "#0B5394", height: "20px", width: "20px" }} /> &nbsp;
                        <span style={{ color: "#0B5394", textDecoration: "underline" }}>Need Help?</span>
                    </div>
                </div>
                <div style={{ marginTop: 20 }} className='bLog'>
                    <img alt="" src={bLogo}  />
                    <div className='filter_box'>
                        <div className="box">
                            <i className="fa fa-search" aria-hidden="true"></i>
                            <input value={currentRefinement} onChange={event => refine(event.currentTarget.value)} placeholder='search' type="texts" name="" />
                        </div>
                        &nbsp; &nbsp; &nbsp;
                        <div className='filter_box_image' onClick={() => setSecond(true)} style={{ margin: "auto", padding: 12, background: "#0B5394", borderRadius: "20px", cursor: "pointer" }}>
                            <img alt="" style={{ width: "fit-content" }} src={slider} />
                        </div>
                    </div>
                    <div onClick={() => setOpen(true)} className='need_help'>
                        <HelpIcon style={{ color: "#0B5394", height: "20px", width: "20px" }} /> &nbsp;
                        <span style={{ color: "#0B5394", textDecoration: "underline" }}>Need Help?</span>
                    </div>

                </div>
            </div> */}
        </>
    )
}

export default connectSearchBox(SearchBox)