import React from 'react'
import "../../css/Selecttier.css"
import ExperienceUser from '../ExperienceUser/ExperienceUser';
const SelectTier = () => {
  return (
    <>
      <ExperienceUser />
    </>
  )
}

export default SelectTier