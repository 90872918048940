import React from 'react'
import splash1 from "../../assets/icons/image 496splashScreen.png"
import splash2 from "../../assets/icons/image 494splashScreen.png"
const SplashInstant = () => {
    return (
        <div>
            <div className='splash91'>
                <img src={splash1} alt="" />
            </div>
            <div className='splash92'>
                <img src={splash2} alt="" />
            </div>
        </div>
    )
}

export default SplashInstant
