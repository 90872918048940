import {  Dialog, DialogContent } from '@mui/material'
import React from 'react'
const Popup = (props) => {
  return (
    <div>
        <Dialog fullWidth={props.full ? true :false}  onClose={props.onClose} open={props.open}>
            <DialogContent >
                    {props.content}
            </DialogContent>
        </Dialog>
    </div>
  )
}

export default Popup