import { Button } from '@mui/material'
import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import { PhoneOnboarding } from '../../Functions/functions'
import phoneNo from "../../assets/icons/Rectangle 376.png"
const PhoneNumber = () => {
    const { type, id } = useParams()
    const navigate = useNavigate()
    const [phone, setPhone] = useState()
    const navigatetoName = () => {
        PhoneOnboarding(id, phone, navigate, type)
    }
    return (
        <div>
            <div className='newUser_container'>
                <div className='name_container_heading'>
                    <img style={{ height: 120, objectFit: "cover" }} src={phoneNo} />
                    <br />
                    <br />
                    {type === "Seeker" ? <h5>We have a few questions which will enable us to match you with the right experts.</h5> : <h5>We are glad you want to join our platform as an Expert. Please help us with a few destails <br /> to ensure we are able to onboard you.</h5>}
                    <br />
                    <h1>Please provide your mobile number?</h1>
                    <br />
                    <div className='name_input'>
                        <input onChange={(e) => setPhone(e.target.value)} style={{ borderRadius: 15, background: "#D4DAFF" }} type='number' />
                    </div>
                    <br />
                    <br />
                    <br />
                    <div className='name_button'>
                        <Button onClick={navigatetoName} style={{ background: "#0B5394", borderRadius: 10 }} variant='contained' className='name_button_width'>Next</Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PhoneNumber
