import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { addDomainstoTier1Free,  removedomain, tagsList } from '../../Functions/functions'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {  doc, onSnapshot } from 'firebase/firestore';
import { db } from '../../firebase';
import CloseIcon from '@mui/icons-material/Close';
import DomainCards from '../../constants/DomainCards';
import { Button } from '@mui/material';
import { domaincard1, domaincard2, domaincard3, domaincard4, experts } from '../../constants/constant';
import img from "../../assets/icons/8.png"
import img1 from "../../assets/icons/9.png"
import img2 from "../../assets/icons/10.png"
import img3 from "../../assets/icons/11.png"
import Model from '../../constants/Model';
import CustomDomain from './CustomDomain';
const Tier1Domain = () => {
    const [custom, setCustom] = useState([])
    const [domains, setDomains] = useState([])
    let c = domains?.map((str, index) => ({ value: str }))
    const [checks, setchecks] = useState()
    const [open, setOpen] = useState(false)
    const { id } = useParams()
    const [value, setValue] = useState(null)
    const domCheck = async (data, e) => {
        addDomainstoTier1Free(e, data, id)
    }
    const searchTags = (e) => {
        tagsList(e, setCustom, c, setchecks)
    }
    useEffect(() => {
        const unsub = onSnapshot(doc(db, "Domains", "wMkb6yf8DJO4aGRkN2VD"), (doc) => {
            if (doc.data()) {
                setDomains(doc.data().fields)
            }
        });
        return () => unsub();
    }, [])
    const f = () => {
        document.getElementById("myDropdown").classList.toggle("show");
    }
    useEffect(() => {
        const unsub = onSnapshot(doc(db, experts, id), (doc) => {
            if (doc.data()) {
                setValue(doc.data());
            }
        });
        return () => unsub();
    }, [id])

    const remove = async (data) => {
        removedomain(id, data)
    }
    // useEffect(async () => {
    //     newDomPopup(value, setOpen, types)
    // }, [])
    return (
        <div className='dos'>
            <div className='domain_heading'>
                <h5>Please select the fields you are experienced in</h5>
            </div>
            <div className='search_dom'>
                <div className="dropdown">
                    <button onClick={f} className="dropbtn">Select Domain  <ArrowDropDownIcon /> </button>
                    <div id="myDropdown" className="dropdown-content">
                        <br />
                        <div style={{ padding: "0px 20px" }}>   <input onChange={(e) => searchTags(e)} type='search' placeholder='search' /></div>
                        <br />
                        <div className='inner_domain'>
                            {
                                checks ? <>  {custom.length > 0 ? <>  {
                                    custom && custom.map((data, index) => (
                                        <div key={index} style={{ display: "flex" }}>
                                            <input style={{ width: "10%", margin: "10px" }} onClick={(e) => domCheck(data.value, e)} type='checkbox' />
                                            <p style={{ margin: "8px", marginLeft: "-12px" }}>{data.value}</p>
                                        </div>
                                    ))
                                }</> : <CustomDomain checks={checks} id={id} value={value} />}</> : c.map((alllist, index) => (
                                    <div key={index} style={{ display: "flex" }}>
                                        <input style={{ width: "10%", margin: "10px" }} onClick={(e) => domCheck(alllist.value, e)} type='checkbox' />
                                        <p style={{ margin: "8px", marginLeft: "-12px" }}>{alllist.value}</p>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>
            {

                value?._tags?.length > 0 ? <>
                    <div style={{ maxWidth: "600px", gap: "10px", display: "flex", paddingBottom: "80px", flexWrap: "wrap", margin: "auto", justifyContent: "center", alignItems: "center" }}>
                        {
                            value?._tags?.map((data, index) => (
                                <div key={index} className='tags1'>
                                    <h6>{data}&nbsp;<CloseIcon style={{ cursor: "pointer" }} onClick={() => remove(data)} /></h6>
                                </div>
                            ))
                        }
                    </div>
                </> : ""
            }
            <Model
                close={() => setOpen(false)}
                show={open}
                component={<><DomainCards setOpen={setOpen}
                    card1={<>
                        <div className='popcards'>
                            <img alt="" src={img} />
                            <p>{domaincard1.title}</p>
                            <Button disabled variant='contained'>Lets do this</Button>
                        </div>
                    </>}
                    card2={<>
                        <div className='popcards'>
                            <img alt="" src={img1} />
                            <br />
                            <p>{domaincard2.title}</p>
                            <Button disabled variant='contained'>Lets do this</Button>
                        </div>
                    </>}
                    card3={<>
                        <div className='popcards'>
                            <img alt=""  src={img2} />
                            <br />
                            <p>{domaincard3.title} </p>
                            <Button disabled variant='contained'>Lets do this</Button>
                        </div>
                    </>}
                    card4={<>
                        <div className='popcards'>
                            <h5 style={{ width: "100%", textAlign: "right" }}><CloseIcon onClick={() => setOpen(false)} /></h5>
                            <img alt=""  src={img3} />
                            <br />
                            <p>{domaincard4.title}</p>
                            <Button onClick={() => setOpen(false)} style={{ background: "#0B5394" }} variant='contained'>Lets do this</Button>
                        </div>
                    </>}
                /></>}
                size='mg'
                button='no'
            />
        </div>
    )
}

export default Tier1Domain