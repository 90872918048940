import React from 'react'
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import { Button } from '@mui/material';
import { doc, updateDoc } from 'firebase/firestore';
import { auth, db } from '../../firebase';
import { useNavigate } from 'react-router';
const Swipe = (props) => {
    const navigate=useNavigate()
    const sub=async()=>{
        if(auth.currentUser.uid === props?.state?.details.euId)
        {
            await updateDoc(doc(db, `${props?.state?.type}/${props.state?.details?.seekerId}/ratings/${props.state?.details.id}`), {
                [`Was ${props.state?.details?.eu_name} polite and courteous`]: props.tick4 === true ? "Yes" : "No"
            }).then(()=>{
                navigate('/reviewSuccess')
            })
        }
        else{
            await updateDoc(doc(db, `${props?.state?.type2}/${props.state?.details?.euId}/ratings/${props.state?.details.id}`), {
                [`Was ${props.state?.details?.seeker_name} polite and courteous`]: props.tick4 === true ? "Yes" : "No"
            }).then(()=>{
                navigate('/reviewSuccess')
            })
        }
       
    }
    return (
        <div>
            <div className='slide1_contents'>
                <div>
                    <h3 style={{ color: "black" }}>{props.title}</h3>
                </div><br />
                <div className='inside_container_rev'>
                    <div onClick={() => { props.setCount('#FFD25E'); props.setTick(true) }} style={{ background: props.count, border: `1px solid silver ` }} className='closeicon_rev'>
                        <DoneIcon style={{ color: " #0B5394", width: 70, height: 70 }} />
                    </div>
                    <div onClick={() => { props.setCount('white'); props.setTick(false) }} style={{ background: props.count1, border: `1px solid silver` }} className='closeicon_rev'>
                        <CloseIcon style={{ color: " #0B5394", width: 70, height: 70 }} />
                    </div>
                   
                </div>
                <br/>
                {props.button ? <><Button style={{background:"rgb(11, 83, 148)"}} variant='contained'onClick={sub}>Submit</Button></> :""}
            </div>
        </div>
    )
}
export default Swipe