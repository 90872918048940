import { Button, } from '@mui/material';
import React, { useEffect, useState } from 'react'
import "../../css/ApplyForVerifiedEuu.css"
import { useNavigate, useParams } from 'react-router';
import { useUserAuth } from '../../context/AuthContext';
import hLogo from "../../assets/images/bedot.logo.webp"
import { cashfreeProd, cashfreeSandbox } from 'cashfree-dropjs';
import { Toaster } from 'react-hot-toast';
import Tiercards from './Tiercards';
import { getPay } from '../../API/api';
import { TiercardsALL } from '../../Functions/functions';
import { experts } from '../../constants/constant';

const ExperienceUser = () => {
    const [type, setType] = useState(experts)
    const { getUserType } = useUserAuth()
    const [activeButtons, setActiveButtons] = useState("");
    const [profile,setVerifyProfile]=useState()
    const navigate = useNavigate()
    const { id } = useParams()
    const isProd = true
    async function getProfileById() {
        await getUserType(setType, id)
    }
    useEffect(() => {
        let isMounted = true
        getProfileById().then(async () => {
            if (isMounted) {
                setVerifyProfile(await getUserType(setType, id))
            }
        })
        return () => { isMounted = false }
         // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])
    const paymentClick = async (e) => {
        e.preventDefault()
        TiercardsALL(activeButtons, type, id, profile, isProd, cashfreeProd, cashfreeSandbox, getPay, navigate)

    }
    return (
        <>

            <div style={{ padding: "0" }} className='padding'>
                <div className='select_tiers'>
                    <div className='share'>
                        <h1>Share and earn on </h1>
                        <img src={hLogo} alt='' />
                        <h1>Today</h1>
                    </div>
                    <Toaster
                        toastOptions={{
                            style: {
                                borderRadius: '10px',
                                background: '#ECEEFC',
                                color: '#0B5394',
                            },
                        }}
                    />
                    <Tiercards setActiveButtons={setActiveButtons} activeButtons={activeButtons} profile={profile} />

                    <div style={{ margin: "auto", textAlign: "center", width: "100%", padding: "10px 20px" }}>
                        <Button onClick={paymentClick} variant='contained' style={{ width: "140px", borderRadius: "15px", background: "#D4DAFF", color: "#0B5394" }}>NEXT</Button>
                    </div>

                </div>
            </div>

        </>
    )
}

export default ExperienceUser