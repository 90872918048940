import { doc, onSnapshot } from 'firebase/firestore'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { languages } from '../../constants/constant'
import Model from '../../constants/Model'
import "../../css/Language.css"
import { db } from '../../firebase'
import { lang } from '../../Functions/functions'
const Language = (props) => {
    const [open, setOpen] = useState(false)
    const [euData, setEUData] = useState({})
    const { id, types } = useParams()
    const addLanguage = async (data, e) => {
        lang(e, props, data)
    }
    useEffect(() => {
        const unsub = onSnapshot(doc(db, types, id), (doc) => {
            if (doc.data()) {
                setEUData(doc.data())
            }
        });
        return () => unsub();
          // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])
    return (
        <div className='language'>
            <div className='defaultlang'>
                <div className='lang_flex'>
                    <h6>Languages<span>*</span></h6>
                    <a href={() => false} onClick={() => setOpen(true)}><u>Add Language</u></a>

                </div>
                {
                    euData.language && euData.language.map((data) => (
                        <div style={{ display: "flex" }}>
                            <p style={{ color: "green" }}>{data}</p>
                        </div>
                    ))
                }
            </div>
            <div>
            </div>
            <Model
                show={open}
                component={<>  <div style={{ textAlign: "center", padding: "10px" }}>
                    <input style={{ maxWidth: 500, borderRadius: "20px", padding: "20px", background: "white" }} placeholder='search' type="text" />
                </div>
                    <div className='languages_all'>
                        {languages && languages.map((data, index) => (
                            <div key={index}><div style={{ display: "flex", justifyContent: "space-between" }}>
                                <h5 style={{ color: "#0B5394" }}>{data}</h5><input onClick={(e) => addLanguage(data, e)} type='checkbox' />

                            </div>
                                <hr /></div>
                        ))}
                    </div>
                </>}
                close={() => setOpen(false)}
                size='sm'
                title="Languages"
            />
        </div>
    )
}

export default Language