import React, { useEffect, useState } from 'react'
import Search from '../Search/Search';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import NotificationsPanel from './NotificationsPanel';
import RequestPanel from './RequestPanel';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { useParams } from 'react-router';
import { db } from '../../firebase';
import { useUserAuth } from '../../context/AuthContext';
import { experts } from '../../constants/constant';
import { a11yProps, TabPanel } from '../../Functions/functions';
const Notifications = () => {
  const [value, setValue] = React.useState(0);
  const [request, setRequest] = useState([])
  const [profile, setVerifyProfile] = useState({})
  const [type, setType] = useState(experts)
  const { getUserType } = useUserAuth()
  const { types } = useParams()
  const { id } = useParams()
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getRequests = async () => {
    let req = []
    const q = query(collection(db, `${experts}/${id}/Requests`), where("status", "==", "request sent."));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      if (doc.data()) {
        req.push({ ...doc.data(), id: doc.id });
      }
    });
    setRequest(req)

  }
  useEffect(() => {
    getRequests()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])
  const getProfileById = async () => {
    await getUserType(setType, id)
  }

  useEffect(() => {
    let isMounted = true
    getProfileById().then(async () => {
      if (isMounted) {
        setVerifyProfile(await getUserType(setType, id))
      }
    })
    return () => { isMounted = false }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])
  return (
    <Box className='homestuffs'>
      <Box className='home_pages'>
        <Search types={types} type={type} />
      </Box>
      <Box>
        <Box sx={{ width: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs variant='scrollable' value={value} onChange={handleChange} aria-label="basic tabs example">
              <Tab style={{ marginTop: 50 }} label="Notifications" {...a11yProps(0)} />
              <Tab style={{ marginTop: 50 }} label="Requests" {...a11yProps(1)} />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <NotificationsPanel type={type} accepted='Accepted' rejected='Rejected' profile={profile} />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <RequestPanel type={type} profile={profile} types={types} request={request} getRequests={getRequests} id={id} />
          </TabPanel>

        </Box>
      </Box>
    </Box>
  )
}

export default Notifications