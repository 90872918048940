import React from 'react'
import "../../css/Categorycontent.css"
import { useNavigate } from 'react-router'
import { Col, Container, Row } from 'react-bootstrap'
const CategoriesContent = React.memo(({ career }) => {
    const navigate = useNavigate()
    const gotoSpecificCategory = (data) => {
        navigate(`/Category/${data?.name}`, {
            state: {
                career: career,
            }
        })
    }
    return (
        <Container>
            <Row>
                <Col>
                    <div className='heading_led'>
                        <h5 style={{ paddingLeft: 0, fontWeight: 700, fontSize: 30 }}>Category</h5>
                    </div>
                    <br />
                    <div className='categorycontent'>
                        {
                            career?.map((data, index) => (
                                <div onClick={() => gotoSpecificCategory(data)} key={index} className='categoryposts_all'>
                                    <div className='categoryposts_avatar'>
                                        <img src={data?.category_img_url} alt="" />
                                    </div>
                                    <h4>{data?.name === "More Experts" ? "Others" : data?.name}</h4>
                                </div>
                            ))
                        }
                    </div>
                </Col>
            </Row>
        </Container>
    )
})

export default CategoriesContent
