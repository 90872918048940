import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router'
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import "../../css/Signup.css"
import { newUser } from '../../Functions/functions';
const NewUser = () => {
    const [username, setUsername] = useState("")
    // const [type, setType] = useState("")
    const { state } = useLocation()
    const navigate = useNavigate()
    const handleSubmit = async (e) => {
        e.preventDefault()
        newUser(state, username, navigate)
    }
    return (
        <div className='newUser_container'>
            {/* Type of User */}
            <h1 className='newUser_heading'>Welcome !</h1>
            <p className='newUser_subheading'>Please enter following details to move forward</p>
            <div className='newUser_form_container'>
                <form>
                    <div className='login_container'>
                        <div className='icon'>
                            <PersonOutlineIcon style={{
                                width: "40px",
                                height: "45",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                margin: "auto",
                                color: "#0B5394"
                            }} />
                        </div>
                        <div className='field'>
                            <input onChange={(e) => setUsername(e.target.value)} type="text" placeholder='Enter username' />
                        </div>
                    </div>
                    <br />
                </form>
            </div>
            <div>
                <div className='login_container'>
                    <div className='buttonfield'>
                        <button onClick={handleSubmit} style={{ background: "#0B5394", color: "white" }} type="submit" className='logButton'>
                            Submit
                        </button>
                    </div>
                </div>
            </div>


        </div>
    )
}

export default NewUser