export const Routing ={
    landing:"/",
    homePageRoutes:"/homepage",
    loginRoutes:"/login",
    signupRoutes:"/signup",
    homeRoutes:"/home",
    forgotRoutes:"/forgot",
    profileRoutes:"/profile/:id",
    VerifiedEUURoutes:"/userProfile/:types/:id",
    NewUserRoutes:"/NewUser",
    SettingsRoutes:"/settings/:types/:id",
    CalendarRoutes:"/userProfile/:types/:id/slots",
    AvailableSlotsRoutes:"/profile/:id/slots",
    SearchRoutes:"/search",
    tags:"/tags/:type/:id",
    searchCard:"/searchCard",
    // domain:"/domain/:types/:id",
    notifications:"/notifications/:types/:id",
    Rating:"/rating",
    apply:"/apply/:types/:id",
    next:"/domain/:types/:id",
    short_intro:"/introduction/:types/:id",
    verification:"/verification/:types/:id",
    lottie:"/confirmation/:types/:id",
    confirm:"/confirm",
    videoCall:"/videocall",
    reviews:"/reviews",
    payment:"/payment",
    availablePayment:"/RequestSuccess",
    reviewSuccess:'/reviewSuccess',
    onBoarding:'/onBoardingScreens/:types/:id',
    split:'/paymentDetails/:id',
    newUser:'/newUser/:id',
    newCat:"/Category/:name",
    name:"/name/:type/:id",
    preferred:"/preferred/:type/:id",
    age:"/age/:type/:id",
    state:"/state/:type/:id",
    topics:"/topics/:type/:id",
    match:"/describe/:type/:id",
    matchSuccess:"/success/:type/:id",
    sessionConfirmed:"/sessionConfirmed",
    instantExpert:"/instantExpert",
    phoneNumber:"/phone_number/:type/:id",
    instantPaySuccess:"/instantPaymentSuccess"
}