import { Menu, MenuItem } from '@mui/material'
import React, { useState } from 'react'
import Popup from '../../constants/Popup'
import CancelBooking from './CancelBooking'
import ReschedulePopup from './ReschedulePopup'

const Suspend = (props) => {
   const [opens,setOpen]=useState(false)
   const [third,setThird]=useState(false)
  return (
    <div>
          <Menu
                id="menu-appbar"
                anchorEl={props.anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={Boolean(props.anchorEl)}
                onClose={props.handleClose}
            >
                <MenuItem onClick={()=>{setOpen(true);props.handleClose()}} >Cancel</MenuItem>
                {/* <MenuItem onClick={()=>{setThird(true);props.handleClose()}} >Reschedule</MenuItem> */}
            </Menu>
            <Popup
            open={opens}
            onClose={()=>setOpen(false)}
            content={<CancelBooking setSecond={props.setSecond} canceldata={props.canceldata} setOpen={setOpen}/>}
            />
             {/* <Popup
            open={third}
            onClose={()=>setThird(false)}
            content={<ReschedulePopup setThird={setThird} setSecond={props.setSecond} canceldata={props.canceldata} setOpen={setOpen}/>}
            /> */}
    </div>
  )
}

export default Suspend