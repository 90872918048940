import React from 'react'
import gif from "../../assets/icons/96119-cancelled-trip.gif"
import "../../css/CancelBooking.css"
const CancelSuccess = () => {
  return (
    <div>
        <div className='gif_cancel'>
            <img alt="" src={gif}/>
        </div>
        <br/>
        
       <div className='heading_cancel'>
          <h2>Session Cancelled</h2>
          <p> Your amount will be refunded in 5-7 working days.</p>
       </div>
    </div>
  )
}
export default CancelSuccess