import { Button } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router'
import Logo from "../../assets/images//Hurrayy gif.gif"
import "../../css/Rating.css"
const SessionConfirmed = () => {
    const navigate=useNavigate()
    return (
        <div>
            <div style={{paddingBottom:20}} className='reviewSuccess'>
                <img alt="" src={Logo} />
                <br/>
                <h5 style={{color:"#1E874B"}}>Hurrayyy! Session Confirmed.</h5>
                <h5 style={{color:"#0B53948C"}}>Thats it! Your session has been Confirmed. Don’t worry, we will remind you when the session is near</h5>
                <br />
                <h6 style={{color:"#B2B2B2B2"}}>Now you will see the session details on homepage. click below</h6>
                <br/>
                <Button onClick={()=>navigate('/home')} style={{ width: "40%", background: "#D4DAFF",color:"#0B5394",border:"1px solid #0B5394" }} variant='contained'>Back to home</Button>
            </div>
        </div>
    )
}

export default SessionConfirmed
