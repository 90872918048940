import { Avatar } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'
import "../../css/SearchCard.css"
import group from "../../assets/images/Group 596.png"
import group1 from "../../assets/images/Group 597.png"
import StarIcon from '@mui/icons-material/Star';
const Explore = (props) => {
    return (
        <div>
            <div style={{ display: "flex", justifyContent: "space-between" }} className='heading_led'>
                <h5 style={{ color: "#0B5394" }}>Explore More Experts </h5>
                <p> see all</p>
            </div>
            <br />
            <div className='hit_contain'>
                {props?.post?.sort((a, b) => {
                    if (a.is_eu_verified && !b.is_eu_verified) {
                        return -1; // a comes first
                    } else if (!a.is_eu_verified && b.is_eu_verified) {
                        return 1; // b comes first
                    } else {
                        return 0; // leave them in the same order
                    }
                }).map((hit, index) => (
                    <Link key={index} to={`/profile/${hit?.eu_id}`}>
                        <div style={{ background: "#ECEEFC" }} className='hit_grid_contain'>
                            <div className='avatar'>
                                <Avatar style={{
                                    height: "75px",
                                    width: "75px",
                                }}
                                    src={hit?.profile_img_url}
                                />
                            </div>
                            <div style={{ background: "white" }} className='right_details'>
                                <div style={{ background: "white" }} className='right_flex_details'>
                                    <div className='hit_username'>
                                        <h6>{hit?.username}</h6>
                                        {hit?.avgRating > 0 ? <div className='hit_rate'>
                                            <span><StarIcon style={{
                                                height: "20px",
                                                width: "20px",
                                                color: "#FFE894",
                                                marginTop: "3px",
                                                marginLeft: 5,
                                            }} /></span>
                                            <h6>{Math.round((hit?.avgRating + Number.EPSILON) * 100) / 100}</h6>
                                        </div> : ""}
                                    </div>
                                    {hit?.no_of_sessions > 0 ? <div className='hit_sessions'>
                                        <h6>Sessions : {hit?.no_of_sessions}</h6>
                                    </div> : ""}
                                    {hit?.yearOfXP > 0 ? <div className='hit_exp'>
                                        <div>
                                            <h6>Experiance : {hit?.yearOfXP} yr</h6>
                                        </div>
                                    </div> : ""}


                                </div>
                                <div>
                                    {hit?.tier === 'TIER.T2' && hit?.is_eu_verified === true ? <div className='expert_icons'>
                                        <img alt="" src={group} />
                                    </div> : (hit?.tier === 'TIER.T3' && hit?.is_eu_verified === true ? <div className='expert_icons'>
                                        <img alt="" src={group1} />
                                    </div> : "")}
                                </div>
                            </div>
                        </div>

                    </Link>
                ))}

            </div>

        </div>
    )
}

export default Explore
