import { Button } from '@mui/material'
import React, { useState } from 'react'
import { useNavigate } from 'react-router'
import payment from "../../assets/images/payment_done.gif"
import Popup from '../../constants/Popup'
const ConfirmedInstantPayment = () => {
    const [open, setOpen] = useState(false)
    const navigate=useNavigate()
    return (
        <div style={{background:"white"}}>
            <div style={{ background: "white" }} className='cp_payment'>
                <img src={payment} alt="" />
                <br />
                <br />
                <h1>Yay !</h1>
                <h6>Payment Successfull.
                </h6>
                <br />
                <Button onClick={() => setOpen(true)} style={{ background: "rgba(0, 78, 150, 0.83)", color: " white" }} variant='contained'>Ok</Button>
                <Popup
                    onClose={() => setOpen(false)}
                    open={open}
                    content={<><h4>Whats next ? </h4>
                        <h6>Our team will contact you to confirm timing and expert details today. Stay tuned!</h6>
                        <div style={{ textAlign: "center" }}>
                            <Button onClick={()=>navigate('/home')} style={{ background: "rgba(0, 78, 150, 0.83)", color: " white" }} variant='contained'>Ok</Button>
                        </div>
                    </>}
                />
            </div>
        </div>
    )
}

export default ConfirmedInstantPayment
