import React, { useEffect, useState } from 'react'
import "../../css/VerifiedEUU.css"
import { Avatar, Button } from '@mui/material'
import { useUserAuth } from '../../context/AuthContext'
import { useParams } from 'react-router'
import Search from '../Search/Search'
import Expert from './Expert'
import Model from '../../constants/Model'
import PopupCards from '../../constants/PopupCards'
import img from "../../assets/icons/5.png"
import img1 from "../../assets/icons/5-1.png"
import CloseIcon from '@mui/icons-material/Close';
import { experts, profilecard1, profilecard2 } from '../../constants/constant'
import { getRating, getSessions } from '../../Functions/functions'
import ShareIcon from '@mui/icons-material/Share';
import { RWebShare } from "react-web-share";
import ProfileTop from './ProfileTop'
const VerifiedEuu = () => {
  const { types, id } = useParams()
  const [profile, setVerifyProfile] = useState({})
  const [type, setType] = useState(experts)
  const { getUserType } = useUserAuth()
  const [open, setOpen] = useState(false)
  const [value, setValue] = React.useState(0);
  const [sess, setSess] = useState([])
  const [rate, setRate] = useState([])
  const getProfileById = async () => {
    await getUserType(setType, id)
  }
  useEffect(() => {
    let isMounted = true
    getProfileById().then(async () => {
      if (isMounted) {
        setVerifyProfile(await getUserType(setType, id))
      }
    })
    return () => { isMounted = false }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])
  useEffect(() => {
    getRating(experts, setRate, id)
  }, [id])
  useEffect(() => {
    getSessions(types, setSess)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sess?.status, id])
  // const SettingsClick = () => {
  //   if (profile.tier === "TIER.T1") {
  //     navigate(`/settings/${types}/${id}`, {
  //       state: {
  //         tier: "TIER.T1"
  //       }
  //     })
  //   }
  //   else {
  //     navigate(`/settings/${types}/${id}`)
  //   }
  // }


  // useEffect(() => {
  //   profilepopup(setOpen)
  // }, [])
  return (
    <div className='homestuffs'>
      <div className='home_pages'>
        <Search types={types} />
      </div>
      <div className='verify_stuff'>
        <div className='verify_header'>
          {/* <div style={{ position: 'absolute', right: "2%", width: "fit-content", cursor: "pointer" }}>
           
            <RWebShare
              data={{
                text: "Let's connect and get your questions answered schedule 1:1 audio/video session with me and gain insights that matter to you from my experiences I am an Expert on bedot, and can help you unlock answers to accelerate your growth      Click on the link below and join me on bedot!",
                url: "https://bedot.page.link/TG78",
                title: "Bedot",         
              }}
              onClick={()=>console.log('')}
            >
              <ShareIcon />
              
            </RWebShare>
          </div> */}
          {/* <Avatar
            style={{
              height: "100px",
              width: "100px",
              margin: "auto"
            }}
            src={profile?.profile_img_url}
          /><br /> */}
          {/* <SettingsIcon onClick={() => SettingsClick()} style={{ margin: "auto", cursor: "pointer", color: "#0B5394" }} /> */}
        </div>
        {/* <h5 style={{ textAlign: "center" }}>{profile?.username}</h5>
        <br />
        <div className='text_editor'>
          <p>{profile?.short_intro}</p>
        </div> */}
        <br />
        {
          types === "Seeker" ? <>
            <ProfileTop sess={sess} type={type} profile={profile} />

          </> :
            <>
              <ProfileTop nocard='no' rate={rate} value={value} setValue={setValue} expert="yes" sess={sess} type={type} profile={profile} />
             
            </>
        }
        <Model
          close={() => setOpen(false)}
          show={open}
          component={<><PopupCards setOpen={setOpen}
            card1={<>
              <div className='popcards'>
                <img alt="" src={img} />
                <p>{profilecard1.title}</p>
                <Button disabled variant='contained'>Lets do this</Button>
              </div>
            </>}
            card2={<>
              <div className='popcards'>
                <h5 style={{ width: "100%", textAlign: "right" }}><CloseIcon onClick={() => setOpen(false)} /></h5>
                <img alt="" src={img1} />
                <br />
                <p>{profilecard2?.title}</p>
                <Button onClick={() => setOpen(false)} style={{ background: "#0B5394" }} variant='contained'>Lets do this</Button>
              </div>
            </>}
          /></>}
          size='mg'
          button='no'
        />
      </div>
    </div>
  )
}

export default VerifiedEuu