import React from 'react'
import "../../css/ApplyForVerifiedEuu.css"
import SelectTier from '../Tier/SelectTier';
const ApplyForVerifiedEUU = () => {

  return (
    <>
      <div style={{ paddingBottom: "60px" }}>
        <SelectTier />
      </div>
    </>
  )
}

export default ApplyForVerifiedEUU
