import { Button } from '@mui/material'
import React from 'react'
import img from "../../assets/icons/3.png"
import img1 from "../../assets/icons/logo 1 3.png"
import "../../css/Swipe.css"
const SlotCard = (props) => {
    return (
        <div className='swipe_containerss'>
            <div className='swipe_logss'>
                <div className='swipe_img_logo'>
                    <img alt="" src={img1} />
                </div>
            </div>
            <div style={{ marginTop: "20px" }}>
                <div className='swipe1_card' >
                    <img alt="" src={img} />
                </div>
                <br />
                <br />
                <div className='areas-of-exps'>
                    <h1>Upload documents <br /> for  verification</h1>
                </div>
                <br />
                <Button onClick={props.tier} style={{ background: "#D4DAFF", color: "#0B5394" }} variant='contained'>Get Started</Button>
            </div>

        </div>
    )
}

export default SlotCard