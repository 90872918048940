import { doc, onSnapshot } from 'firebase/firestore';
import React, { useState } from 'react'
import { useEffect } from 'react';
import { useParams } from 'react-router';
import { experts } from '../../constants/constant';
import "../../css/Domain.css"
import { db } from '../../firebase';
import NewDomain from '../ExperienceUser/NewDomain';
import Tier1Domain from '../ExperienceUser/Tier1Domain';
const DomainExpertise = () => {
  const [expertdomain, setExpertDomain] = useState(null)
  const [verifyDom, setVerifyDom] = useState(null)
  const { id } = useParams()
  useEffect(() => {
    const unsub = onSnapshot(doc(db, experts, id), (doc) => {
      setExpertDomain(doc.data());
    });
    return () => unsub()
  }, [id])

  useEffect(() => {
    const unsub = onSnapshot(doc(db, "VerifyReq", id), async (doc) => {
      if (doc.data()) {
        setVerifyDom(doc.data())
      }
    });
    return () => unsub();
  }, [id])
  const current = window.location.pathname
  return (
    <>
      {current === `/settings/${experts}/${id}` ? (expertdomain?.tier === "TIER.T2" || expertdomain?.tier === "TIER.T3" ? <NewDomain verifyDom={verifyDom} /> : <Tier1Domain />) : ""}
    </>
  )
}

export default DomainExpertise