import { Avatar, Button } from '@mui/material'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import ShoppingCart from '@mui/icons-material/ShoppingCart'
import { useNavigate } from 'react-router'
import { getPayment, getPaymentWebinar } from '../../API/api'
import { cashfreeProd, cashfreeSandbox } from 'cashfree-dropjs'
import group from "../../assets/images/Group 596.png"
import group1 from "../../assets/images/Group 597.png"
import { orderBooking, webinarPaymentDone } from '../../Functions/functions'
import "../../css/Request.css"
import { pricingforTier2 } from '../../constants/constant'
const Payment = (props) => {
    let isProd = true
    const navigate = useNavigate()
    const paying = async () => {
        orderBooking(props, navigate, getPayment, isProd, cashfreeProd, cashfreeSandbox)
    }
    const webinarPaying = async () => {
        webinarPaymentDone(props,navigate,getPaymentWebinar,isProd,cashfreeProd,cashfreeSandbox)
    }
    return (
        <div>
            <div className='checkout'>
                {props.webinar === "webinarBooking" ? <div className='webinar_session_summary'><h5>Session Summary</h5><p>Confirm your session details and Pay</p></div> : ""}
                <br />
                <div className='checkout_display'>
                    <div className='left_checkout'>
                        <Avatar
                            className='check_avatar'
                            style={{
                                height: "110px",
                                width: "110px",
                                margin: "auto"
                            }}
                            src={props?.data?.profile_img_url}
                        />
                        {props.data?.tier === "TIER.T2" ? <img className='leftcheckouttierimage' src={group} /> : (props.data?.tier === "TIER.T3" ? <img className='leftcheckouttierimage' src={group1} /> : "")}
                    </div>
                    <div className='right_checkout'>
                        {props.webinar === "webinarBooking" ? <h4>{props?.Book?.eu_name}</h4> : <h4>{props?.data?.username}</h4>}
                        {props.webinar === "webinarBooking" ? <h6> Date: {moment(props?.Book?.date.toDate()).format("MMM DD, YYYY")}</h6> : <h6> Date: {new Date(props?.notificationData?.from).toDateString()}</h6>}
                        {props.webinar === "webinarBooking" ? <h6> Time: {moment(props.Book.date.toDate()).format('hh:mm A')}</h6> : <h6> Time: {moment(new Date(props?.notificationData?.from).getTime()).format('hh:mm A')}</h6>}
                        {props.webinar === "webinarBooking" ? <h6>{props.Book.duration} minutes</h6> : <h6>30 minutes</h6>}
                    </div>
                </div>
                <h6 style={{ color: "white", fontSize: "15px", padding: "10px" }}>Session(s)</h6>
                <div className='clean_code'>
                    <div className='clean_code_title'>
                        {props.webinar === "webinarBooking" ? <h6>{props.Book.title}</h6> : <h6>Session Appointment<p>({new Date(props.notificationData?.from).toDateString()} {moment(new Date(props.notificationData?.from).getTime()).format(('hh:mm:A'))} - {moment(new Date(props?.getDate || props?.notificationData?.from).getTime()).add(30, 'm').format(('hh:mm:A'))} )</p></h6>}
                        <h6>Convenience Fee</h6>
                    </div>
                    <div className='clean_code_price'>
                        {props.webinar === "webinarBooking" ? <h5>&#8377;{props.Book.fees}</h5> : props?.data?.tier === "TIER.T1" ? <h5>&#8377;0</h5> : (props?.data?.tier === "TIER.T2" ? <h5>&#8377;{pricingforTier2}</h5> : (props?.data?.tier === "TIER.T3" ? <h5>&#8377;{props?.euRatefor30?.price}</h5> : ""))}
                        {props.webinar === "webinarBooking" ? <h5 style={{ marginTop: 22 }}>&#8377;20</h5> : <h5 style={{ marginTop: 22 }}>&#8377;{props?.data?.tier === "TIER.T1" ? 0 : 20}</h5>}
                    </div>

                </div>
                <div style={{ borderTop: "1px solid #0B5394" }}></div>
                <div className='check_total'>
                    <div className='check_total_left'>
                        <ShoppingCart className='shoppingcart' style={{ color: "white" }} />
                        <h6>Total:</h6>
                    </div>
                    <div className='check_total_right'>
                        {props.webinar === "webinarBooking" ? <h5>&#8377;{props.Book.fees + 20}</h5> : props?.data?.tier === "TIER.T1" ? <h5>&#8377;0</h5> : (props?.data?.tier === "TIER.T2" ? <h5>&#8377;{pricingforTier2 + 20}</h5> : (props?.data?.tier === "TIER.T3" ? <h5>&#8377;{props?.euRatefor30?.price + 20}</h5> : ""))}
                    </div>
                </div>
            </div>
            {
                props.webinar === "webinarBooking" ? "" : <div className='notes'>
                    <h5>Your note</h5>
                    <textarea disabled defaultValue={props?.notificationData?.reason} style={{ width: "100%", height: "120px", outline: "none", border: "none", resize: "none", padding: "15px", background: "rgba(215, 232, 240, 0.6)", borderRadius: "20px" }} />
                    <br />
                    <br />
                    {props?.notificationData?.type === "Type.RejectedRequest" ? <><h5>Why Expert has rejected your session request ?</h5> <textarea disabled defaultValue={props?.notificationData?.reject_reason} style={{ width: "100%", height: "120px", outline: "none", border: "none", resize: "none", padding: "15px", background: "rgba(215, 232, 240, 0.6)", borderRadius: "20px" }} /></> : ""}
                </div>
            }
            <div style={{ textAlign: "center" }}>
                {props.webinar === "webinarBooking" ? <Button onClick={webinarPaying} variant='contained' style={{ background: "#0B5394", color: "white" }}>Continue</Button> : props?.notificationData?.type === "Type.RejectedRequest" ? <Button onClick={() => navigate(`/profile/${props?.notificationData?.receiver_id}`)} variant='contained' style={{ background: "#0B5394", color: "white" }}>
                    Change date
                </Button> : <Button onClick={paying} variant='contained' style={{ background: "#0B5394", color: "white" }}>
                    Pay Now
                </Button>}
            </div>
        </div>
    )
}

export default Payment
