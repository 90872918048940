import { Button } from '@mui/material'
import React from 'react'
import {  useNavigate, useParams } from 'react-router'
import { auth } from '../firebase'

const Confirmation = () => {
    const navigate=useNavigate()
    const {types}=useParams()
  const profile=()=>{
        navigate(`/userProfile/${types}/${auth.currentUser.uid}`)
  }
  return (
    <div><Button onClick={profile}>Go back to profile</Button></div>
  )
}

export default Confirmation