import React, { useEffect, useState } from 'react'
import "../../css/Login.css"
import "../../css/Signup.css"
import Logo from "../../assets/images/logo 2 transparent png (1).png"
import { useUserAuth } from '../../context/AuthContext'
import { Toaster } from 'react-hot-toast'
import PhoneInput from 'react-phone-input-2'
import OTP from './OTP'
import DisplaySnackbar from '../../constants/DisplaySnackbar'
import { signinOTP } from '../../Functions/functions'
import homn_log from "../../assets/images/22.png"
import Contact from './Contact'
import { smallLoginScreen, smallScreen } from '../../constants/constant'
import { useLocation } from 'react-router'
const snackbardata = {
  open: false,
  severity: "",
  message: "",
  statusCode: ""
}
const Login = () => {
  const [phone, setPhone] = useState('')
  const [euset, SetEUSet] = useState("Explorers")
  const [otp, setOTP] = useState("");
  const [loginButton, setLoginButton] = useState(false)
  const { googleSignIn } = useUserAuth()
  const { setUpRecaptcha } = useUserAuth()
  const location = useLocation()
  const [state, setState] = useState(location.state)
  const [flag, setFlag] = useState(false)
  const [confirmObj, setConfirmObj] = useState("")
  const [snackbarOpen, setSnackbarOpen] = useState(snackbardata)
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const handleGooglelogIn = async (e) => {
    e.preventDefault()
    try {
      await googleSignIn(euset)
    }
    catch (error) {

    }
  }
  const handleSubmit = async (e) => {
    e.preventDefault()
    signinOTP(phone, setLoginButton, setUpRecaptcha, setFlag, setConfirmObj, snackbarStart)
  }
  const snackbarStart = (severity, message, st = "", sc = "") => {
    setSnackbarOpen({
      open: true,
      message: message,
      severity: severity,
      statusCode: `${st} ${sc}`
    })
  }
  const setUserExpert = () => {
    SetEUSet("Experts")
    setState(null)
    snackbarStart("success", "You have selected Expert User, Please Login")

  }
  const setUserExplorer = () => {
    SetEUSet("Explorers")
    setState(null)
    snackbarStart("success", "You have selected Explorer User, Please Login")
  }
  useEffect(() => {
    smallScreen(setIsSmallScreen, 845)
  }, [])
  return (
    <div className={isSmallScreen ? "main_login_container" : 'main_login_containerFullScreen'}>
      <div className={isSmallScreen ? 'main_login_image' : 'main_login_imageFullScreen'}>

        {isSmallScreen ? <Contact /> : ""}
        <div className={isSmallScreen ? "main_login_up" : "main_login_upFullScreen"}>

          <img alt="" src={Logo} />
          {euset === "Explorers" ? <>   <h1 className='main_span'>Get 1:1 Expert Help</h1>
            <h6 className='main_spans'> From qualified Experts</h6></> :
            <>
              <h1 className='main_span'>Earn money to help others</h1>
              <h6 className='main_spans'>share your knowledge with people in need</h6></>}
          <div className={isSmallScreen ? "" : 'illusImageFullScreen'}>
            {isSmallScreen ? "" : <img alt="" src={homn_log} />}
          </div>
        </div>
        {isSmallScreen ? <div className='illustrations'>  <img alt="" src={homn_log} /></div> : ""}
        <div className={isSmallScreen ? 'login_containers_back' : 'login_containers_backFullScreen'}>
          <div className={isSmallScreen ? 'login' : "loginFullScreen"}>

            <h2 style={{ color: "white", fontWeight: 700 }}>Login or Sign Up</h2>
            {isSmallScreen ? "" : <br />}

            {
              flag === false ? <>  <form onSubmit={handleSubmit}>

                <DisplaySnackbar
                  open={snackbarOpen.open}
                  closeSnackbar={() => { setSnackbarOpen({ open: false, severity: "", message: "" }) }}
                  message={snackbarOpen.message}
                  severity={snackbarOpen.severity}
                  statusCode={snackbardata.statusCode}
                />
                <Toaster
                  toastOptions={{
                    style: {
                      borderRadius: '10px',
                      background: '#ECEEFC',
                      color: '#0B5394',
                    },
                  }}
                />

                <div className='log_inside'>
                  <div className='text_center_imgs'>
                    <h1><span id="spins"></span></h1>
                  </div>
                </div>
                <div className='login_container'>
                  <div className='fields'>
                    <PhoneInput
                      placeholder="Enter phone number"
                      value={phone}
                      onChange={setPhone}
                      enableSearch
                      country="in"
                    />
                  </div>
                </div>
                <br />
                <div className='login_container'>
                  <div className='buttonfield'>
                    <button type="submit" className='logButton'>
                      CONTINUE
                    </button>
                    <br />
                    <br />
                    <div id='recaptcha-container'>
                    </div>
                  </div>
                </div>
                <br />
                <h5 style={{ textAlign: "center", padding: 5, height: "18px", color: " #BDBDBD" }}>OR</h5>
                <br />
                <div className='login_container'>
                  <div className='googlefield'>
                    <button onClick={handleGooglelogIn} type="button" className="login-with-google-btn" >
                      <span className='googleicon'></span>
                      LOGIN WITH GOOGLE
                    </button>
                  </div>
                </div>
                {isSmallScreen ? "" : <br />}
                {state ?
                  state === "Explorers" ? <div className='login_container'>
                    <h5 onClick={setUserExpert}
                      style={{ textAlign: "center", cursor: "pointer" }}>
                      <span className='forgots'>Are you an Expert ? <br /><u style={{ color: "#FFE894" }}>Sign up with us</u> </span>
                    </h5>
                  </div> : <div className='login_container'>
                    <h5 onClick={setUserExplorer}
                      style={{ textAlign: "center", cursor: "pointer" }}>
                      <span className='forgots'>Are you an Explorer ?<br /><u style={{ color: "#FFE894" }}>Sign up with us</u> </span>
                    </h5>
                  </div>
                  : euset === "Explorers" ? <div className='login_container'>
                    <h5 onClick={setUserExpert}
                      style={{ textAlign: "center", cursor: "pointer" }}>
                      <span className='forgots'>Are you an Expert ? <br /><u style={{ color: "#FFE894" }}>Sign up with us</u> </span>
                    </h5>
                  </div> : <div className='login_container'>
                    <h5 onClick={setUserExplorer}
                      style={{ textAlign: "center", cursor: "pointer" }}>
                      <span className='forgots'>Are you an Explorer ?<br /><u style={{ color: "#FFE894" }}>Sign up with us</u> </span>
                    </h5>
                  </div>}
              </form></> : <><OTP euset={euset} state={state} loginButton={loginButton} phone={phone} setPhone={setPhone} flag={flag} setOTP={setOTP} otp={otp} confirmObj={confirmObj} /></>
            }
            <br />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Login