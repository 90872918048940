import React from 'react'
import "../../css/Swipe.css"
import img from "../../assets/icons/1.png"
import img1 from "../../assets/icons/logo 1 3-1.png"
const DomainCard = (props) => {
    return (
        <div className='swipe_container'>
            <div className='swipe_log'>
                <div className='swipe_img_logo'>
                    <img alt="" src={img1} />
                </div>
                <div className='letter'>
                    <h5 onClick={props.tier}>Skip</h5>
                </div>
            </div>
            <div style={{ marginTop: "20px" }}>
                <div className='swipe1_card' >
                    <img alt="" src={img} />
                </div>
                <br />
                <br />
                <div className='areas-of-exp'>
                    <h1>Select area(s)  of expertise</h1>
                </div>

            </div>

        </div>
    )
}

export default DomainCard