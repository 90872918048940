import { Button } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router'
import payment from "../../assets/images/Group-4.png"
import "../../css/AvailableSlots.css"
const ConfirmedPayment = () => {
    const navigate = useNavigate()
    const home = () => {
        navigate('/home')
    }
    return (
        <div>
            <div className='cp_payment'>
                <img src={payment} alt="" />
                <br />
                <br />
                <h1>Request Sent</h1>
                <h6>Your Session request has been sent to the expert and we will notify you once the expert have accepted .Stay tuned
                </h6>
                <br />
                <Button onClick={home} style={{ background: " #D4DAFF", color: " #0B5394" }} variant='contained'>Back to Home</Button>
            </div>
        </div>
    )
}

export default ConfirmedPayment