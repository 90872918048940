import React from 'react'
import "../../css/HomeInstant.css"
import instantIcon from "../../assets/icons/Rectangle 367.png"
import fly from "../../assets/icons/ezgif.com-crop (1).gif"
import { Button } from '@mui/material'
import { useNavigate } from 'react-router'
import { Col, Container, Row } from 'react-bootstrap'
const HomeInstant = () => {
        const navigate = useNavigate()
        return (
                <Container>
                        <Row>
                                <Col>
                                        <div className='HomeInstant'>
                                                <div className='HomeInstantLeft'>
                                                        <div className='HomeInstantDesc'>
                                                                <div className='HomeInstantDescleft'>
                                                                        <img src={instantIcon} alt="" />
                                                                </div>
                                                                <div className='HomeInstantDescRight'>
                                                                        <Button onClick={() => navigate('/instantExpert')} variant='contained' style={{ background: "#FFD25E", borderRadius: 8, color: "#0B5394" }}>Book Now</Button>
                                                                </div>
                                                        </div>
                                                        <div className='HomeInstanttitle'>
                                                                <h5>Get <span>Instant</span> help from top certified</h5>
                                                                <h5>experts within <span>48 hours</span> or</h5>
                                                                <h5> <span>money back</span> </h5>
                                                        </div>
                                                </div>
                                                <div className='HomeInstantRight'>
                                                        <img src={fly} alt="" />
                                                </div>
                                        </div>
                                </Col>
                        </Row>
                </Container>
        )
}

export default HomeInstant
