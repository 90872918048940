import React, { useEffect, useState } from 'react'
import "../../css/Profile.css"
import { Avatar, Button, IconButton, Tooltip } from '@mui/material'
import { useNavigate, useParams } from 'react-router'
import { collection, doc, onSnapshot, query } from 'firebase/firestore'
import { auth, db } from '../../firebase'
import Search from '../Search/Search'
import StarIcon from '@mui/icons-material/Star';
import group from "../../assets/images/Group 596.png"
import group1 from "../../assets/images/Group 597.png"
import { experts, windowheight } from '../../constants/constant'
import revImg from "../../assets/images/Rectangle 279-1.png"
import InfoIcon from '@mui/icons-material/Info';
import { Col, Container, Row } from 'react-bootstrap'
const Profile = () => {
    const [rate, setRate] = useState([])
    const [isExpanded, setIsExpanded] = useState(false);
    const [showMore, setShowMore] = useState(false);
    const { id } = useParams()
    const [profile, setProfile] = useState({})
    const nav = false
    const navigate = useNavigate()
    const words = profile.short_intro?.split(" ");
    const limitedText = words?.slice(0, 35).join(" ");
    const tagslength = profile?._tags
    useEffect(() => {
        const unsub = onSnapshot(doc(db, experts, id), (doc) => {
            if (doc.data()) {
                setProfile(doc.data())
            }
        });
        return () => unsub()
    }, [id])

    const Slots = () => {
        navigate(`/profile/${id}/slots`)
    }
    const ChooseSlots = () => {
        navigate(`/userProfile/${experts}/${auth?.currentUser?.uid}/slots`, {
            state: {
                location: nav
            }
        })
    }
    useEffect(() => {
        const q = query(collection(db, `${experts}/${id}/ratings`));
        const unsub = onSnapshot(q, (querySnapshot) => {
            const arrayRating = [];
            querySnapshot.forEach((doc) => {
                arrayRating.push(doc.data());
            });
            setRate(arrayRating)
        });
        return () => unsub()
    }, [id])
    return (
        <>
            <div className='homestuffs'>
                <div className='home_pages'>
                    <Search />
                </div>
                <div className='profile_stuffs'>
                    <Container>
                        <Row>
                            <Col style={{ paddingBottom: 150 }}>
                                <div className='profile_details'>
                                    <div className='profile_title'>
                                        <Avatar src={profile?.profile_img_url} className='profiler_avatar' style={{ height: 100, width: 100 }} />
                                        <div className='profile_experiance_column'>
                                            <h3>{profile.username}</h3>
                                            <h5><span>{profile?.yearOfXP}</span> yrs Experience</h5>
                                        </div>
                                    </div>

                                </div>
                                <div className='details_profile_container'>
                                    <div className='details_profile_left'>
                                        <div className="readmorecontainer">
                                            <p className="text">{isExpanded ? profile?.short_intro : limitedText}</p>
                                            {words?.length > 35 && (
                                                <span className="read-more-link" onClick={() => setIsExpanded(!isExpanded)}>
                                                    {isExpanded ? "Read Less" : "Read More"}
                                                </span>
                                            )}
                                        </div>
                                        <br />
                                        <div className='credentials_editor'>
                                            {profile.credentials === "" ? "" : <><h5> <Tooltip title="This is why we have approved this Expert.">
                                                <IconButton>
                                                    <InfoIcon style={{ width: 20, height: 20 }} />
                                                </IconButton>
                                            </Tooltip> <span style={{ color: "black" }}>Credentials</span> : <span>{profile?.credentials}</span></h5></>}

                                        </div>
                                        <br />
                                        <div>
                                            <h3 style={{fontSize:24}}>Languages I know</h3>
                                            <br />
                                            <div className='domain_tags1'>
                                                {
                                                    profile && profile?.language?.map((data, index) => (
                                                        <div key={index}>
                                                            <div className='tags_lang'>
                                                                <h6>{data}</h6>
                                                            </div>
                                                        </div>
                                                    ))
                                                }


                                            </div>
                                        </div>
                                    </div>
                                    <div className='details_profile_right'>
                                        <div className='domain_exp'>
                                            <div className='linear'>
                                                <p></p>
                                            </div>
                                            <div className='domain_head'>
                                                Domain Expertise
                                            </div>
                                            <div className='linear'>
                                                <p></p>
                                            </div>
                                        </div>
                                        <br />
                                        <div className='domain_stuff1'>
                                            <div className='domain_tags2'>
                                                {tagslength?.slice(0, showMore ? tagslength.length : 4).map((data, index) => (
                                                    <div key={index} className='tags4'>
                                                        <h6>{data?.length > 30 ? data.substr(0, 30) + "..." : data.substr(0, 30)} </h6>
                                                    </div>
                                                ))}
                                                {tagslength?.length > 4 && !showMore && (
                                                    <span className='readmorelink' onClick={() => setShowMore(true)}>Read More</span>
                                                )}
                                                {tagslength?.length > 4 && showMore && (
                                                    <span className='readmorelink' onClick={() => setShowMore(false)}>Show Less</span>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <br />
                                <div className='profile_reviews'>
                                    <div className='prof_reviews'>
                                        <div className='rev_heading'>
                                            <h5 >Reviews</h5>
                                        </div>
                                    </div>
                                    <br />
                                    {
                                        rate?.length > 0 ? <>
                                            <div className='review_container'>
                                                <div className='stars_consult'>
                                                    {rate.map((data, index) => (

                                                        <div key={index} className='re_details'>
                                                            <div className='re_container'>
                                                                <div className='res_avatar'>
                                                                    <Avatar />
                                                                    <h5>{data.seeker_name}</h5>
                                                                </div>
                                                                <div className='re_rating'>
                                                                    <div className='re_rating_points'>
                                                                        <StarIcon style={{
                                                                            width: "27px",
                                                                            height: "27px",
                                                                            marginTop: "2px",
                                                                            color: "#FFD966"
                                                                        }} />
                                                                        <p>{data.rating}</p>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                            <div className='re_text'>
                                                                <p className="text">{data?.feedback}</p>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </> : <>
                                            <div className='session_dummy_icon'>
                                                <img alt="" src={revImg} />
                                                <br />
                                                <br />
                                                <h5>No Reviews Available</h5>
                                            </div>
                                        </>
                                    }
                                </div>
                                {
                                    id === auth?.currentUser?.uid ? <>
                                        <div className='prof_button'>
                                            <Button onClick={() => ChooseSlots(profile)} style={{ background: "#0B5394" }} variant='contained'>Choose Available Slots</Button>
                                        </div>
                                    </> : <>
                                        <div className='prof_button'>
                                            <Button onClick={() => Slots(profile)} style={{ background: "#0B5394" ,borderRadius:20}} variant='contained'>Request Session</Button>
                                        </div>
                                    </>
                                }
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </>
    )
}

export default Profile