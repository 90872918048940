
import React, { useEffect, useState } from 'react'
import "../../css/VerifiedEUU.css"
import { collection, doc, onSnapshot, query } from 'firebase/firestore';
import { db } from '../../firebase';
import { useNavigate, useParams } from 'react-router';
import Status from './Status';
import Suspend from '../Profile/Suspend';
import { NotificationsFlow } from '../../Functions/functions';
const NotificationsPanel = (props) => {
  const navigate = useNavigate()
  const [acc, setAccept] = useState(null)
  const { types, id } = useParams()
  const [notifications, setNotifications] = useState()
  // const [nav, setNav] = useState(false)
  const nav = false
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  useEffect(() => {
    const unsub = onSnapshot(doc(db, "VerifyReq", id), (doc) => {
      if (doc.data()) {
        setAccept(doc.data())
      }
    });
    return () => unsub();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [acc?.status])

  const getNotifications = async () => {
    NotificationsFlow(types,id,setNotifications)
  }
  useEffect(() => {
    getNotifications()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, types])
  const go = () => {
    navigate(`/userProfile/${types}/${props?.profile?.eu_id}/slots`, {
      state: {
        location: nav
      }
    })
  }
  // var now = new Date()
  // const filterDate = sess.filter((data) => new Date(data.to) >= now);
  return (
    <div  className='veriify_session'>
      {/* <h5>Today</h5> */}
      <Status type={props.type} notifications={notifications} accepted={props?.accepted} acc={acc} rejected={props.rejected} image={props?.profile?.img_url} open={open} go={go} handleClick={handleClick} />
      <br />
      {/* <SessionHistory /> */}
      <Suspend anchorEl={anchorEl} handleClose={handleClose} />
    </div>
  )
}

export default NotificationsPanel