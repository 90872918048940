import { Avatar, Rating } from '@mui/material'
import React, { useState } from 'react'
import { useNavigate } from 'react-router'
import "../../css/Home.css"
import group from "../../assets/images/Group 596.png"
import group1 from "../../assets/images/Group 597.png"
import SinglePost from '../AdminPosts/SinglePost'
import { useEffect } from 'react'
import {  getSinglePostsRedirect, truncate } from '../../Functions/functions'
const Categories = (props) => {
    const navigate = useNavigate()
    const [singlePostsOnly,setSinglePostsOnly]=useState(null)
    const Profile = (data) => {
        navigate(`/profile/${data.eu_id}`)
    }
    useEffect(()=>{
       getSinglePostsRedirect(setSinglePostsOnly,props.title)
    },[singlePostsOnly?.status,singlePostsOnly?.name,props?.title])
    return (
        <div style={{background:"white"}}>
            <div  className='leaderboards'>
                <div className='heading_led'>
                    <h5>Top {props?.title==="More Experts" ? "Experts" :props?.title} </h5>
                    <p></p>
                    {/* <p>see all</p> */}
                </div>
                <div className='music_containers'>
                    <SinglePost  singlePostsOnly={singlePostsOnly}/>
                    <div className='music_consults'>
                        {
                            props?.post && props?.post.slice(0-10).map((data, index) => (
                                data?.avgRating>=0 ? <div style={{width:270,height:244}} key={index} onClick={() => Profile(data)} className='music_detail'>
                                <div className='allpost_avatar'>
                                    <Avatar style={{
                                        width: "60px",
                                        height: "60px"
                                    }}
                                        src={data.profile_img_url}
                                    />
                                </div>
                                <div style={{height:(data?.avgRating>0 || data?.no_of_sessions > 0 )? "" :114}} className='allpost_details'>
                                    <div className='allpost_username'>
                                        <p style={{height:(data?.avgRating>0 || data?.no_of_sessions > 0) ? 20 :30}}>{truncate(data.username,20)} {data?.is_eu_verified === true && data?.tier === "TIER.T2" ?
                                            <img style={{ height: "40px", width: "40px" }} alt="" src={group} />
                                            : (data?.is_eu_verified === true && data?.tier === "TIER.T3" ?
                                                <img style={{ height: "40px", width: "40px" }} alt="" src={group1} />
                                                : "")}</p>
                                    </div>
                                   {data?.no_of_sessions > 0 ?  <p className='allpost_hello' style={{ color: "#0B5394", fontSize: "20px", padding: "5px", textAlign: "center" }}>Sessions : {data.no_of_sessions}</p>:""}
                                    <div className='allpost_rating'>
                                        {data?.avgRating > 0 ? <Rating readOnly className='rates' name="half-rating" defaultValue={data?.avgRating} /> :""}
                                        {data?.avgRating > 0 ?   <h5>{(Math.round((data?.avgRating + Number.EPSILON) * 100) / 100).toString()}</h5> :""}
                                    </div>

                                </div>
                            </div> :""
                            ))
                        }
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Categories