import React from 'react'
import "../../css/Contact.css"
import EmailIcon from '@mui/icons-material/Email';
import { inquiry, socialemail, whatsappnumber } from '../../constants/constant';
const ContactUs = () => {
  return (
    <div className='contact'>
      <div className='contact_us'>
        <h3>Get in touch</h3>
        <p> {inquiry}</p>
        <br />
        <div className='social_bed'>
          <EmailIcon style={{ height: 35, width: 35, color: "#0B5394" }} />
          <a style={{ textDecoration: "none", background: "none" }} href='mailto:social@bedot.io'><span>{socialemail}</span></a>
        </div>
        <br />
        <div className='social_bed'>
          <a style={{ textDecoration: "none", background: "none" }} href={`https://wa.me/${whatsappnumber}`}>
            <img alt="" src="https://img.icons8.com/color/40/000000/whatsapp--v1.png" />&nbsp;
            <span>+{whatsappnumber}</span>
          </a>
        </div>

      </div>

    </div>

  )
}

export default ContactUs