
import React from 'react'
import { useLocation, useNavigate, useParams } from 'react-router'
import DomainCard from './DomainCard';
import LiquidSwipe from 'react-liquidswipe';
import VerificationCard from './VerificationCard';
import SlotCard from './SlotCard';
import { onBoardingNavigate } from '../../Functions/functions';
const BoardingScreens = () => {
    const { types, id } = useParams()
    const navigate = useNavigate()
    const {state}=useLocation()
    const tier = () => {
        onBoardingNavigate(navigate, id, types,state)
    }
    let components = [
        <>
            <DomainCard tier={tier} />
        </>,
        <>
            <VerificationCard tier={tier} />
        </>,
        <>
            <SlotCard tier={tier} />
        </>
    ];
    return (
        <div className='swipers_com'>
            <LiquidSwipe
                components={components}
                style={{
                    height: '100vh',
                    width: '100%',
                    borderRadius: '20px',
                }}
            />

        </div>
    )
}

export default BoardingScreens