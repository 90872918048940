import React, { useEffect, useState } from 'react'
import "../../css/Search.css"
import { useNavigate, useParams } from 'react-router';
import Header from '../Header'
import SearchBar from './SearchBar';
const Search = (props) => {
    const [open, setOpen] = useState(false)
    const { name } = useParams()
    return (
        <>
            <SearchBar fromfilter={props.fromSearch} name={props.name} from={props.from} currentRefinement={props.currentRefinement} refine={props.refine} sessionslider={props.sessionslider} toggle={props.toggle} setSecond={props.setSecond} setActiveButtons={props.setActiveButtons} setGenderButtons={props.setGenderButtons} setSlider={props.setSlider} setSessionSlider={props.setSessionSlider} open={props.open} setOpen={props.setOpen} searchcard="searchcard" />
            <Header from={props.from} types={props.types} />
          
        </>
    )
}

export default Search
